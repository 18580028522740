import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Modal,
  MenuItem,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import Sample from "../../images/sampleEmp.png";
import RightSection from "./rightSection/rightSection.js";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import axios from "axios";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from '@mui/icons-material/Search';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import './employee.css';

export default function Employee() {
  const { user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, []);

  // Tabs
  const [value, setValue] = React.useState('Employee');
  const handleChangeTab = (newValue) => {
    setValue(newValue);
  };

  // Employee Data
  const [email, setEmail] = React.useState();
  const [firstname, setFirstname] = React.useState();
  const [middlename, setMiddlename] = React.useState();
  const [lastname, setLastname] = React.useState();
  const [position, setPosition] = React.useState();
  const [user_id, setUser_id] = React.useState();
  const [department, setDepartment] = React.useState();
  const [date_employed, setDate_employed] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [barangay, setBarangay] = React.useState();
  const [city, setCity] = React.useState();
  const [province, setProvince] = React.useState();
  const [bank_name, setBank_name] = React.useState();
  const [bank_account_name, setBank_account_name] = React.useState();
  const [bank_account_number, setBank_account_number] = React.useState();
  const [sss_id, setSss_id] = React.useState();
  const [pagibig_id, setPagibig_id] = React.useState();
  const [tin_id, setTin_id] = React.useState();
  const [philhealth_id, setPhilhealth_id] = React.useState();
  const [gender, setGender] = React.useState();
  const [birth_date, setBirth_date] = React.useState();
  const [civil_status, setCivil_status] = React.useState();
  const [nationality, setNationality] = React.useState();
  const [father_name, setFather_name] = React.useState();
  const [father_occupation, setFather_occupation] = React.useState();
  const [mother_name, setMother_name] = React.useState();
  const [mother_occupation, setMother_occupation] = React.useState();
  const [spouse_name, setSpouse_name] = React.useState();
  const [parent_address, setParent_address] = React.useState();
  const [emergency_name, setEmergency_name] = React.useState();
  const [emergency_contact, setEmergency_contact] = React.useState();
  const [basic_pay, setBasic_pay] = React.useState(0);
  const [allowance, setAllowance] = React.useState(0);
  const [account_role, setAccount_role] = React.useState();
  const [execomm, setExecomm] = React.useState();
  const [trigger, setTrigger] = useState(false);
  // Modal View/Edit Employee
  const [open, setOpen] = React.useState(false);
  const handleOpenEmp = (idx) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/user/viewEmployee" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEmail(data.email);
        setFirstname(data.firstname);
        setMiddlename(data.middlename);
        setLastname(data.lastname);
        setPosition(data.position);
        setUser_id(data.user_id);
        setDepartment(data.department);
        setDate_employed(data.date_employed);
        setMobile(data.mobile);
        setBarangay(data.barangay);
        setCity(data.city);
        setProvince(data.province);
        setBank_name(data.bank_name);
        setBank_account_name(data.bank_account_name);
        setBank_account_number(data.bank_account_number);
        setSss_id(data.sss_id);
        setPagibig_id(data.pagibig_id);
        setTin_id(data.tin_id);
        setPhilhealth_id(data.philhealth_id);
        setGender(data.gender);
        setBirth_date(data.birth_date);
        setCivil_status(data.civil_status);
        setNationality(data.nationality);
        setFather_name(data.father_name);
        setFather_occupation(data.father_occupation);
        setMother_name(data.mother_name);
        setMother_occupation(data.mother_occupation);
        setSpouse_name(data.spouse_name);
        setParent_address(data.parent_address);
        setEmergency_name(data.emergency_name);
        setEmergency_contact(data.emergency_contact);
        setBasic_pay(data.basic_pay);
        setAllowance(data.allowance);
        setAccount_role(data.account_role)
        setExecomm(data.execomm)
        // console.log(data.basic_pay + data.allowance);
      });
  };
  const handleCloseEmp = () => setOpen(false);

  // Modal Add New Employee (To be implemented)
  const [openNewE, setOpenNewE] = React.useState(false);
  // const handleOpenNewEmp = () => setOpenNewE(true);
  // const handleCloseNewEmp = () => setOpenNewE(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Retrieve Employee
  const [employee, setEmployee] = useState(null);
  React.useEffect(() => {
    if (userData.account_role === "superadmin") {
      fetch("https://hr.geospectrum.com.ph/api/api/user/selectEmp", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEmployee(data);
        });
    }
    else {
      fetch("https://hr.geospectrum.com.ph/api/api/user/selActive", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEmployee(data);
        });
    }
  }, [trigger]);

  const filteredData = employee
    ? employee.filter((item) => {
      const itemName = item.firstname + item.lastname + item.department + item.position || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Retrieve Digitizer
  const [digitizer, setDigitizer] = useState(null);
  React.useEffect(() => {
    if (userData.account_role === 'superadmin') {
      fetch("https://hr.geospectrum.com.ph/api/api/user/selectDigitizer", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setDigitizer(data);
        });
    }
    else {
      fetch("https://hr.geospectrum.com.ph/api/api/user/selActiveDigitizer", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setDigitizer(data);
        });
    }
  }, [trigger]);

  const filteredDataDigitizer = digitizer
    ? digitizer.filter((item) => {
      const itemName = item.firstname + item.lastname + item.department + item.position || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Success Updating Alert
  const alert = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Information has been updated",
    });
  };

  // Polling Function to fetch data
  React.useEffect(() => {
    const fetchData = () => {
      if (userData.account_role === "superadmin") {
        fetch("https://hr.geospectrum.com.ph/api/api/user/selectEmp", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setEmployee(data);
          });

        fetch("https://hr.geospectrum.com.ph/api/api/user/selectDigitizer", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setDigitizer(data);
          });
      }
      else {
        fetch("https://hr.geospectrum.com.ph/api/api/user/selActive", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setEmployee(data);
          });

        fetch("https://hr.geospectrum.com.ph/api/api/user/selActiveDigitizer", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setDigitizer(data);
          });
      }


    };
    // Start polling
    const intervalId = setInterval(fetchData, 15000); // Poll every  10 seconds
    // Clear interval on cleanup
    return () => {
      clearInterval(intervalId);
    };
  }, [user.email, user.token]);

  // Update Employee Data
  const updateData = (e) => {
    e.preventDefault();
    axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + email, {
      firstname,
      middlename,
      lastname,
      position,
      user_id,
      department,
      date_employed,
      mobile,
      barangay,
      city,
      province,
      bank_name,
      bank_account_name,
      bank_account_number,
      sss_id,
      pagibig_id,
      tin_id,
      philhealth_id,
      gender,
      birth_date,
      civil_status,
      nationality,
      father_name,
      father_occupation,
      mother_name,
      mother_occupation,
      spouse_name,
      parent_address,
      emergency_name,
      emergency_contact,
      allowance,
      basic_pay,
      // account_role,
      execomm
    });
    handleCloseEmp();
    alert();
    setTrigger(!trigger)
  };

  // Update Employee Account Status
  const [message, setMessage] = useState('')
  const [account_status, setAccount_status] = useState();
  const updateStatus = async (empEmail) => {
    try {
      fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + empEmail)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          axios.put("https://hr.geospectrum.com.ph/api/api/user/statusupdate" + empEmail, {
            account_status: !data.account_status,
          })
          sendMail(empEmail, data.account_status);
        });
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle errors as needed
    }
    setTrigger(!trigger)
  };

  const sendMail = async (UserEmail, stat) => {
    var formData = new FormData();

    formData.append("userEmail", user.email)
    formData.append("message", message)
    formData.append("subject", 'Account Status')

    const send = async () => {
      let message;
      // Set message conditionally based on the status
      if (stat) {
        message = 'Your account is deactivated by the Human Resource Officer. Contact the administrator if you have any questions, thank you.';
      } else {
        message = 'This is to inform you that your account is now activated! You can start exploring all the features and benefits right away. If you have any questions/assistance or any suggestions, feel free to reach out to our IT/Administrator team.';
      }
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: UserEmail,
        message: message,
        subject: 'Account Status'
      })
        .then(response => {
          // Handle the success response if needed
          console.log(response.data);
        })
        .catch((err) => {
          // Handle errors
          console.error(err);
        });
    };

    send();
  };

  // Delete Employee
  const deleteEmp = () => {
    handleCloseEmp();
    Swal.fire({
      title: "Delete employee?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete("https://hr.geospectrum.com.ph/api/api/user/delete" + email);
        Swal.fire({
          title: "Deleted!",
          text: "Employee's details has been deleted.",
          icon: "success"
        });
        setTrigger(!trigger)
      }
    });

  };

  // Masked Salary
  const [showSalary, setShowSalary] = useState(false);
  const handleClickShowPay = () => setShowSalary(!showSalary);
  const handleMouseDownPay = () => setShowSalary(!showSalary);

  // Masked Allowance
  const [showAllowance, setShowAllowance] = useState(false);
  const handleClickShowAllowance = () => setShowAllowance(!showAllowance);
  const handleMouseDownAllowance = () => setShowAllowance(!showAllowance);


  // Basic Salary Format
  const handleAmountChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    setBasic_pay(inputValue);
  };
  // Format the number with commas for display
  const formattedAmount = new Intl.NumberFormat('en-US').format(parseInt(basic_pay, 10));

  // Allowance Format
  const handleAmountChangeAllo = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    setAllowance(inputValue);
  };
  // Format the number with commas for display
  const formattedAmountAllo = new Intl.NumberFormat('en-US').format(parseInt(allowance, 10));

  const handleChangerole = (e) => {
    setAccount_role(e.target.value);
    let role = (e.target.value)
    let vacationLeave = 0
    let sickLeave = 0
    let paternityLeave = 0
    let maternityLeave = 0
    handleCloseEmp()
    Swal.fire({
      title: "Change the account role?",
      text: "This action will modify the employee's privileges and reset the employee's leave balance based on the new account role.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (role === "superadmin" || role === "supervisor" || role === "user") {
          vacationLeave = 15
          sickLeave = 15
          paternityLeave = 60
          maternityLeave = 90
        } else if (role === "digitizer") {
          vacationLeave = 1
        }
        axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + email, {
          vacationLeave: vacationLeave,
          sickLeave: sickLeave,
          paternityLeave: paternityLeave,
          maternityLeave: maternityLeave,
          account_role: role
        });
        Swal.fire({
          title: "Updated!",
          text: "Employee's account role updated.",
          icon: "success"
        });
        setTrigger(!trigger)
      }
    });
  };

  const downloadExcel = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "Download employee list",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const response = await fetch("https://hr.geospectrum.com.ph/api/api/user/export",
            {
              headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Geospectrum_Employee_List.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Fetch error:", error);
        }
        Swal.fire({
          title: "Success!",
          text: "Your file has been downloaded.",
          icon: "success"
        });
      }
    });

  };

  return (
    <>
      {/* Modal For Viewing Employee Info */}
      <Modal
        open={open}
        onClose={handleCloseEmp}
        sx={{
          overflow: "hidden",
        }}
      >
        <Box sx={style}>
          <Grid container item direction="row" spacing={2}
            justifyContent="flex-end" alignItems="space-between">
            <Grid item xs={9}>
              <Typography variant="h5">Employee Information </Typography>
            </Grid>
            {userData.account_role === "superadmin" || userData.email === "accounting@geospectrum.com.ph" ? (
              <>            <Grid item>
                <Button
                  variant="contained"
                  onClick={deleteEmp}
                  color="error">
                  Delete
                </Button>
              </Grid>
                <Grid item >
                  <Button variant="contained" onClick={updateData}>
                    Save
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid container item direction="column"
            sx={{
              overflow:
                userData.account_role === "superadmin" || userData.email === "accounting@geospectrum.com.ph"
                  ? "scroll"
                  : "auto",
              height: userData.account_role === "superadmin"
                ? "80vh"
                : 330,
            }}>
            <Grid container item direction="row" spacing={2}
              justifyContent="flex-end" alignItems="space-between">
              <Grid item xs='12'>
              </Grid>
              {userData.account_role === "superadmin" || userData.email === "accounting@geospectrum.com.ph" ? (
                <>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Account Role"
                      value={account_role || ''}
                      onChange={handleChangerole}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={account_role} disabled selected>
                        {account_role}
                      </MenuItem>
                      <MenuItem value={"probationary"}>
                        Probationary
                      </MenuItem>
                      <MenuItem value={"digitizer"}>
                        Digitizer
                      </MenuItem>
                      <MenuItem value={"user"}>
                        Regular Employee
                      </MenuItem>
                      <MenuItem value={"supervisor"}>
                        Supervisor
                      </MenuItem>
                      <MenuItem value={"superadmin"}>
                        Superadmin
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Executive Committee"
                      value={execomm || ''}
                      onChange={(e) => setExecomm(e.target.value)}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={execomm} disabled selected>
                        {execomm}
                      </MenuItem>
                      <MenuItem value={false}>
                        No
                      </MenuItem>
                      <MenuItem value={true}>
                        Yes
                      </MenuItem>
                    </TextField>
                  </Grid>
                  {userData.email === "medillojianna@geospectrum.com.ph" || userData.email === "accounting@geospectrum.com.ph" ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          focused
                          color="success"
                          fullWidth
                          label="Basic Pay"
                          // value={basic_pay}
                          // onChange={(e) => setBasic_pay(e.target.value)}
                          onChange={handleAmountChange}
                          value={formattedAmount}
                          type={showSalary ? "Text" : "Password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPay}
                                  onMouseDown={handleMouseDownPay}
                                  disableRipple
                                >
                                  {showSalary ? (
                                    <VisibilityIcon fontSize="small" />
                                  ) : (
                                    <VisibilityOffIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          focused
                          color="success"
                          fullWidth
                          label="Allowance"
                          // value={allowance}
                          // onChange={(e) => setAllowance(e.target.value)}
                          onChange={handleAmountChangeAllo}
                          value={formattedAmountAllo}
                          type={showAllowance ? "Text" : "Password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowAllowance}
                                  onMouseDown={handleMouseDownAllowance}
                                  disableRipple
                                >
                                  {showAllowance ? (
                                    <VisibilityIcon fontSize="small" />
                                  ) : (
                                    <VisibilityOffIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}


              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  fullWidth
                  label="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  fullWidth
                  label="Middle Name"
                  value={middlename}
                  onChange={(e) => setMiddlename(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  fullWidth
                  label="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  fullWidth
                  label="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  fullWidth
                  label="Employee ID:"
                  value={user_id}
                  onChange={(e) => setUser_id(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  select
                  label="Deparment"
                  value={department || ''}
                  onChange={(e) => setDepartment(e.target.value)}
                  fullWidth
                  size="small"
                >
                  <MenuItem value={department} disabled selected>
                    {department}
                  </MenuItem>
                  <MenuItem value={"Administration and Finance Department"}>
                    Administration and Finance Department
                  </MenuItem>
                  <MenuItem value={"Sales and Marketing Department"}>
                    Sales and Marketing Department
                  </MenuItem>
                  <MenuItem value={"Business Operations Department"}>
                    Business Operations Department
                  </MenuItem>
                  <MenuItem value={"GIS and Remote Sensing Department"}>
                    GIS and Remote Sensing Department
                  </MenuItem>
                  <MenuItem value={"Information Technology Department"}>
                    Information Technology Department
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                  size="small"
                  fullWidth
                  label="Date Employed:"
                  type="date"
                  onChange={(e) => setDate_employed(e.target.value)}
                  value={date_employed}
                />
              </Grid>
              {userData.account_role === "superadmin" ? (
                <>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                      size="small"
                      fullWidth
                      label="Contact Number:"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)} />
                  </Grid><Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                      size="small"
                      fullWidth
                      label="Barangay:"
                      value={barangay}
                      onChange={(e) => setBarangay(e.target.value)} />
                  </Grid><Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                      size="small"
                      fullWidth
                      label="City / Municipality:"
                      value={city}
                      onChange={(e) => setCity(e.target.value)} />
                  </Grid><Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={["user", "supervisor", "digitizer", "probationary"].includes(userData.account_role)}
                      size="small"
                      fullWidth
                      label="Province:"
                      value={province}
                      onChange={(e) => setProvince(e.target.value)} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={6}></Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {userData.account_role === "superadmin" || userData.email === "accounting@geospectrum.com.ph" ? (
                <>
                  <Grid item xs={12}>
                    <h3>Bank and Government Number Details</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Bank Name:"
                      value={bank_name}
                      onChange={(e) => setBank_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Bank Account Name:"
                      value={bank_account_name}
                      onChange={(e) => setBank_account_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Bank Number:"
                      value={bank_account_number}
                      onChange={(e) => setBank_account_number(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="SSS Number::"
                      value={sss_id}
                      onChange={(e) => setSss_id(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Pag-Ibig Number:"
                      value={pagibig_id}
                      onChange={(e) => setPagibig_id(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="TIN Number:"
                      value={tin_id}
                      onChange={(e) => setTin_id(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Philhealth Number:"
                      value={philhealth_id}
                      onChange={(e) => setPhilhealth_id(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Other Information</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Gender"
                      value={gender || ''}
                      onChange={(e) => setGender(e.target.value)}
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={gender} disabled>
                        {gender}
                      </MenuItem>
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Birth Date:"
                      type="date"
                      value={birth_date}
                      onChange={(e) => setBirth_date(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      select
                      size="small"
                      label="Civil Status"
                      value={civil_status || ''}
                      onChange={(e) => setCivil_status(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={civil_status} disabled>
                        {civil_status}
                      </MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Widowed "}>Widowed</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Nationality:"
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Father Name:"
                      value={father_name}
                      onChange={(e) => setFather_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Father's Occupation:"
                      value={father_occupation}
                      onChange={(e) => setFather_occupation(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Mother's Name:"
                      value={mother_name}
                      onChange={(e) => setMother_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Mother's Occupation:"
                      value={mother_occupation}
                      onChange={(e) => setMother_occupation(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Spouse Name:"
                      value={spouse_name}
                      onChange={(e) => setSpouse_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Parent's Address:"
                      value={parent_address}
                      onChange={(e) => setParent_address(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Emergency Information</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Name:"
                      value={emergency_name}
                      onChange={(e) => setEmergency_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Contact Number:"
                      value={emergency_contact}
                      onChange={(e) => setEmergency_contact(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Account Information</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      label="Email:"
                      value={email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      type={"password"}
                      defaultValue="qwe"
                      fullWidth
                      label="Password:"
                    />
                  </Grid>

                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal >

      <div />

      {/* Modal For Adding New Employee (To be implemented)*/}
      {/* <Modal
        open={openNewE}
        onClose={handleCloseNewEmp}
        sx={{
          overflow: "hidden",
        }}>
        <Box sx={style}>
          <NewEmpModal />
        </Box>
      </Modal> */}

      <div id='employee-container'>
        <div id={userData.account_role === "superadmin" ? 'employee-list-super' : 'employee-list'}>
          <div className='tab'>
            <button className={value === 'Employee' ? "tablinks-active" : "tablinks"} onClick={() => handleChangeTab('Employee')}>EMPLOYEE</button>
            <button className={value === 'Digitizer' ? "tablinks-active" : "tablinks"} onClick={() => handleChangeTab('Digitizer')}>DIGITIZER</button>
          </div>
          <div id={value === 'Employee' ? 'Employee' : 'none'} className='tabcontent'>
            <div id='employee-content-header'>
              <label id='search'>
                <SearchIcon />
                <input id='search' type='text' label="Search" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} />
              </label>
              {userData.account_role === "superadmin" || userData.account_role === "supervisor" ? (
                <button id='employee-download' onClick={() => downloadExcel()}>
                  <FileOpenIcon /> DOWNLOAD
                </button>
              ) : null}
            </div>
            <div id='employee-cards'>
              {filteredData.map((emp, index) => (
                <>
                  <div id={userData.account_role === "superadmin" ? 'employee-card-super' : 'employee-card'}
                    key={emp.user_id} onClick={() => {
                      handleOpenEmp(emp.user_id);
                    }}>
                    <div id='employee-profile-image'>
                      <img id='employee-image' src={emp.photo_path === ""
                        ? `https://hr.geospectrum.com.ph/api/profile/default.png`
                        : `https://hr.geospectrum.com.ph/api/profile/${emp.photo_path}`
                      } alt='Profile' height='auto' />
                    </div>
                    <div id='employee-card-details'>
                      <h3 id='employee-name'>
                        {emp.firstname + " " + emp.lastname}
                      </h3>
                      <p id='employee-position'>
                        {emp.position}
                      </p>
                    </div>
                    <div id='employee-toggle-switch'>
                      {userData.account_role === "superadmin" ? (
                        <>
                          <label className="switch">
                            <input type="checkbox" defaultChecked={emp.account_status} onChange={() => {
                              updateStatus(emp.email);
                            }} />
                            <span className="slider round" />
                          </label>
                          <p id='employee-position'>Status</p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div id={value === 'Digitizer' ? 'Digitizer' : 'none'} className='tabcontent'>
            <div id='employee-content-header'>
              <label id='search'>
                <SearchIcon />
                <input id='search' type='text' label="Search" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} />
              </label>
              {userData.account_role === "superadmin" || userData.account_role === "supervisor" ? (
                <button id='employee-download' onClick={() => downloadExcel()}>
                  <FileOpenIcon /> DOWNLOAD
                </button>
              ) : null}
            </div>
            <div id='employee-cards'>
              {filteredDataDigitizer.map((emp, index) => (
                <>
                  <div id={userData.account_role === "superadmin" ? 'employee-card-super' : 'employee-card'}
                    key={emp.user_id} onClick={() => {
                      handleOpenEmp(emp.user_id);
                    }}>
                    <div id='employee-profile-image'>
                      <img id='employee-image' src={emp.photo_path === ""
                        ? `https://hr.geospectrum.com.ph/api/profile/default.png`
                        : `https://hr.geospectrum.com.ph/api/profile/${emp.photo_path}`
                      } alt='Profile' height='auto' />
                    </div>
                    <div id='employee-card-details'>
                      <h3 id='employee-name'>
                        {emp.firstname + " " + emp.lastname}
                      </h3>
                      <p id='employee-position'>
                        {emp.position}
                      </p>
                    </div>
                    <div id='employee-toggle-switch'>
                      {userData.account_role === "superadmin" ? (
                        <>
                          <label className="switch">
                            <input type="checkbox" defaultChecked={emp.account_status} onChange={() => {
                              updateStatus(emp.email);
                            }} />
                            <span className="slider round" />
                          </label>
                          <p id='employee-position'>Status</p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>

          </div>
        </div>


        <div id={userData.account_role === "superadmin" ? 'employee-right-super' : 'employee-right'}>
          <RightSection />
        </div>
      </div>

    </>
  );
}
