import React from "react";
import { useState } from "react";
import {
  Box,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  Typography,
  MenuItem,
  TextField,
  FormGroup,
} from "@mui/material";
import logo from "../../images/gms_logo.png";
import { useSignup } from "../../hooks/useSignup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Stepper
const steps = [
  "Employee Information",
  "Bank and Government Number Details",
  "Other Information",
];

const Signup = () => {
  // Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleSkip = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleComplete = () => {
    let validationError = "";

    // Validation checks
    if (activeStep === 0) {
      // Validate fields for Step 1
      if (!email.trim() || !password.trim() || !firstname.trim() || !lastname.trim() || !position.trim() || !date_employed.trim() || !company.trim() || !mobile.trim() || !gender.trim() || !civil_status.trim() || !birth_date.trim() || !nationality.trim() || !province.trim() || !city.trim() || !barangay.trim()) {
        validationError = 'Please fill out all required fields before proceeding.';
      }
    } else if (activeStep === 1) {
      // Validate fields for Step 2
      // if (!sss_num.trim() || !pagibig_num.trim() || !philhealth_num.trim() || !tin_num.trim() || !bank_name.trim() || !bank_account_name.trim() || !bank_account_number.trim()) {
      //   validationError = 'Please fill out all required fields before proceeding.';
      // }
    } else if (activeStep === 2) {
      // Validate fields for Step 3
      // if (!father_name.trim() || !mother_name.trim() || !parent_address.trim() || !emergency_name.trim() || !emergency_contact.trim()) {
      //   validationError = 'Please fill out all required fields before proceeding.';
      // }
    }

    // If there's a validation error, show the Snackbar
    if (validationError) {
      setSnackbarMessage(validationError);
      setSnackbarOpen(true);
      return;
    }

    // If all validations pass, proceed to the next step
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleSkip();
  };
  const handleResDownload = () => {
    setActiveStep(0);
    setCompleted({});
  };
  // End Stepper


  // Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //
  const [supervisor, setSupervisor] = useState("");
  const [user_id, setUser_id] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [date_employed, setDate_employed] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [civil_status, setCivil_status] = useState("");
  const [birth_date, setBirth_date] = useState("");
  const [nationality, setNationality] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [barangay, setBarangay] = useState("");
  // Bank Details
  const [sss_num, setSss_num] = useState("");
  const [pagibig_num, setPagibig_num] = useState("");
  const [philhealth_num, setPhilhealth_num] = useState("");
  const [tin_num, setTin_num] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [bank_account_name, setBank_account_name] = useState("");
  const [bank_account_number, setBank_account_number] = useState("");
  // Other Information
  const [father_name, setFather_name] = useState("");
  const [father_occupation, setFather_occupation] = useState("");
  const [mother_name, setMother_name] = useState("");
  const [mother_occupation, setMother_occupation] = useState("");
  const [spouse_name, setSpouse_name] = useState("");
  const [parent_address, setParent_address] = useState("");
  const [emergency_name, setEmergency_name] = useState("");
  const [emergency_contact, setEmergency_contact] = useState("");


  const { signup, error, isLoading } = useSignup();

  const handleChange = (event) => {
    setDepartment(event.target.value);
  };

  // Show Password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const sendMail = async () => {
    var formData = new FormData();
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMailNewAccount', {
        message: firstname + " " + lastname + " " + "has created an account. Please take a moment to review the details and information on the portal. Once reviewed, you can set the account to active status, allowing them to fully utilize their account. Thank you!",
        subject: 'New Account Created'
      }).then().catch((err) => console.error(err))
    }

    send()
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const signupSuccess = await signup(
        supervisor,
        user_id,
        email,
        password,
        firstname,
        middlename,
        lastname,
        position,
        department,
        date_employed,
        company,
        mobile,
        gender,
        civil_status,
        birth_date,
        nationality,
        province,
        city,
        barangay,
        sss_num,
        pagibig_num,
        philhealth_num,
        tin_num,
        bank_name,
        bank_account_name,
        bank_account_number,
        father_name,
        father_occupation,
        mother_name,
        mother_occupation,
        spouse_name,
        parent_address,
        emergency_name,
        emergency_contact,
      );

      if (signupSuccess) {
        // If signup is successful, proceed with sending the email
        sendMail();
      } else {
        // Handle the case where signup failed (error handling logic)
      }
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  const GenerateID = async (e) => {
    let lastID;
    const response = await fetch("https://hr.geospectrum.com.ph/api/api/user/checklast");
    const data = await response.json();
    lastID = data[0].user_id;

    var lastFour = lastID.slice(-4);
    var lastFourAsInt = parseInt(lastFour, 10);
    var lastFourAsInt =   lastFourAsInt + 1;
    console.log(lastFourAsInt);
    
    let idPrefix; // Use let instead of var for the second declaration
    if (company === "Geospectrum Marketing Services") {
      idPrefix = "GMS0";
    } else {
      idPrefix = "GASI0";
    }
    setUser_id(idPrefix + lastFourAsInt);

    // console.log(user_id)

    var supervEmail = "";
    if (department == "Administration and Finance Department") {
      var supervEmail = "medillojianna@geospectrum.com.ph"
    }
    else if (department == "Sales and Marketing Department") {
      var supervEmail = "miguel.delrosario@geospectrum.com.ph"
    }
    else if (department == "Business Operations Department") {
      var supervEmail = "gianver.babagay@geospectrum.com.ph"
    }
    else if (department == "GIS and Remote Sensing Department") {
      var supervEmail = "harry.merida@geospectrum.com.ph"
    }
    else if (department == "Information Technology Department") {
      var supervEmail = "pgtodoc7@gmail.com"
    }
    else {
      var supervEmail = ""
    }
    setSupervisor(supervEmail);
    // console.log(supervisor)
  };

  // Alert
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const handleMobileChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setMobile(numericValue);
  };

  const handleContactMobileChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setEmergency_contact(numericValue);
  };

  const handleSssNumChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9-]/g, '');
    const formattedValue = sanitizedValue.slice(0, 12);
    const formattedSssNum = formattedValue.replace(/(\d{2})(\d{7})(\d{1})/, '$1-$2-$3');
    setSss_num(formattedSssNum);
  };

  const handlePagibigNumChange = (e) => {
    // Filter out non-numeric and non-hyphen characters
    const filteredValue = e.target.value.replace(/[^0-9-]/g, '');
    setPagibig_num(filteredValue);
  };

  const handlePhilhealthNumChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    for (let i = 0; i < filteredValue.length; i++) {
      if (i === 2 || i === 11) {
        formattedValue += '-';
      }
      formattedValue += filteredValue[i];
    }
    setPhilhealth_num(formattedValue);
  };

  const handleTinNumChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');
    // Add hyphens to create the desired format
    let formattedValue = '';
    for (let i = 0; i < filteredValue.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedValue += '-';
      }
      formattedValue += filteredValue[i];
    }
    setTin_num(formattedValue);
  };
  return (
    <>
      {/* Snackbar Alrte */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity="error"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: "center",
          }}
        >
          <img src={logo} height="auto" width="20%" />
        </Grid>

        <Grid
          container
          item
          xs={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Box sx={{ width: "98%" }}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                sx={{
                  boxShadow: 2,
                  backgroundColor: "#F1F0F0",
                  padding: 2,
                }}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Registration Cancelled
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleResDownload}
                        sx={{
                          mr: 1,
                          marginBottom: 5,
                        }}
                      >
                        Reset
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* Content */}

                    {/* <ContentForm activeStep={activeStep} /> */}

                    <form className="signup" onSubmit={handleSubmit}>
                      <FormGroup
                        sx={{
                          display: activeStep === 0 ? "" : "none",
                          m: 1,
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          sx={{
                            maxHeight: 740,
                          }}
                        >
                          <Grid
                            container
                            item
                            direction="row"
                            spacing={2}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                color: "#cf3f3f",
                              }}
                            >
                              {/* <Button onClick={GenerateID}>asdas</Button> */}
                              Please fill out the required fields in the form to complete your
                              registration.*
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Email"
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type={showPassword ? "Text" : "Password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        disableRipple
                                      >
                                        {showPassword ? (
                                          <VisibilityIcon fontSize="small" />
                                        ) : (
                                          <VisibilityOffIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Firstname"
                                type="text"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Middlename"
                                type="text"
                                onChange={(e) => setMiddlename(e.target.value)}
                                value={middlename}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Lastname"
                                type="text"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Position"
                                type="text"
                                onChange={(e) => setPosition(e.target.value)}
                                value={position}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                select
                                label="Department"
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                                fullWidth
                              >
                                <MenuItem value={"Administration and Finance Department"}>
                                  Administration and Finance Department
                                </MenuItem>
                                <MenuItem value={"Sales and Marketing Department"}>
                                  Sales and Marketing Department
                                </MenuItem>
                                <MenuItem value={"Business Operations Department"}>
                                  Business Operations Department
                                </MenuItem>
                                <MenuItem value={"GIS and Remote Sensing Department"}>
                                  GIS and Remote Sensing Department
                                </MenuItem>
                                <MenuItem value={"Information Technology Department"}>
                                  Information Technology Department
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Date Employed"
                                type="date"
                                onChange={(e) => setDate_employed(e.target.value)}
                                value={date_employed}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                onClick={GenerateID}
                                required
                                select
                                label="Company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                fullWidth
                              >
                                <MenuItem value={"Geospectrum Marketing Services"}>
                                  Geospectrum Marketing Services
                                </MenuItem>
                                <MenuItem
                                  value={"Geospectrum Analytics Services Incorporation"}
                                >
                                  Geospectrum Analytics Services Incorporation
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Mobile Number"
                                type="text"
                                onChange={handleMobileChange}
                                value={mobile}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                select
                                label="Gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                fullWidth
                              >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                select
                                label="Civil Status"
                                value={civil_status}
                                onChange={(e) => setCivil_status(e.target.value)}
                                fullWidth
                              >
                                <MenuItem value={"Married"}>Married</MenuItem>
                                <MenuItem value={"Single"}>Single</MenuItem>
                                <MenuItem value={"Divorced"}>Divorced</MenuItem>
                                <MenuItem value={"Widowed "}>Widowed</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Birthdate"
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                onChange={(e) => setBirth_date(e.target.value)}
                                value={birth_date}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Nationality"
                                type="text"
                                onChange={(e) => setNationality(e.target.value)}
                                value={nationality}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Province"
                                type="text"
                                onChange={(e) => setProvince(e.target.value)}
                                value={province}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Municipality / City"
                                type="text"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Street / Barangay"
                                type="text"
                                onChange={(e) => setBarangay(e.target.value)}
                                value={barangay}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </FormGroup>

                      <FormGroup
                        sx={{
                          display: activeStep === 1 ? "" : "none",
                          m: 1,
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          sx={{
                            maxHeight: 740,
                          }}
                        >
                          <Grid
                            container
                            item
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                color: "#cf3f3f",
                              }}
                            >
                              Please fill out the required fields in the form to complete your
                              registration.*
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="SSS Number"
                                type="text"
                                onChange={handleSssNumChange}
                                value={sss_num}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9-]*',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Pag-ibig Number"
                                type="text"
                                onChange={handlePagibigNumChange}
                                value={pagibig_num}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9-]*',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Philhealth Number"
                                type="text"
                                onChange={handlePhilhealthNumChange}
                                value={philhealth_num}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9-]*',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="TIN Number"
                                type="text"
                                onChange={handleTinNumChange}
                                value={tin_num}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9-]*',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Bank Name"
                                type="text"
                                onChange={(e) => setBank_name(e.target.value)}
                                value={bank_name}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Bank Account Name"
                                type="text"
                                onChange={(e) => setBank_account_name(e.target.value)}
                                value={bank_account_name}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Bank Account Number"
                                type="text"
                                onChange={(e) => setBank_account_number(e.target.value)}
                                value={bank_account_number}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </FormGroup>

                      <FormGroup
                        sx={{
                          display: activeStep === 2 ? "" : "none",
                          m: 1,
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        <Grid
                          container
                          item
                          direction="column"
                          sx={{
                            maxHeight: 740,
                          }}
                        >
                          <Grid
                            container
                            item
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                color: "#cf3f3f",
                              }}
                            >
                              Please fill out the required fields in the form to complete your
                              registration.*
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                onClick={GenerateID}
                                required
                                fullWidth
                                label="Father's Name"
                                type="text"
                                onChange={(e) => setFather_name(e.target.value)}
                                value={father_name}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Father's Occupation"
                                type="text"
                                onChange={(e) => setFather_occupation(e.target.value)}
                                value={father_occupation}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Mother's Name"
                                type="text"
                                onChange={(e) => setMother_name(e.target.value)}
                                value={mother_name}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Mother's Occupation"
                                type="text"
                                onChange={(e) => setMother_occupation(e.target.value)}
                                value={mother_occupation}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Spouse's Name"
                                type="text"
                                onChange={(e) => setSpouse_name(e.target.value)}
                                value={spouse_name}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Parent's Address"
                                type="text"
                                onChange={(e) => setParent_address(e.target.value)}
                                value={parent_address}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Contact Person"
                                type="text"
                                onChange={(e) => setEmergency_name(e.target.value)}
                                value={emergency_name}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                fullWidth
                                label="Contact Person's Phone Number"
                                type="text"
                                onChange={handleContactMobileChange}
                                value={emergency_contact}
                                InputLabelProps={{
                                  style: { width: '100%' }
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="success"
                                disabled={isLoading}
                              >
                                Sign up
                              </Button>
                              <Typography sx={{ textAlign: "center", color: "red" }}>{error && error}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </form>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{
                          mr: 1,
                          marginBottom: 5,
                        }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        variant="contained"
                        onClick={handleSkip}
                        sx={{ mr: 1, marginBottom: 5 }}
                      >
                        Skip
                      </Button>
                      {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-block" }}
                          >
                            Step {activeStep + 1} already completed
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={handleComplete}
                            sx={{ mr: 1, marginBottom: 5 }}
                          >
                            {completedSteps() === totalSteps() - 1
                              ? "Cancel"
                              : "Next"}
                          </Button>
                        ))}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Signup;
