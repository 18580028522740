import React from 'react'
import './missionContainer.css'

export default function missionContainer() {
  return (
    <div id='mv-container'>
      <div id='mv-box'>
        <h3 id='mission-title'>MISSION</h3>
        <p id='mission-text'>
          To enable actionable and data driven decisions by promoting high accuracy, reliable and
          affordable geospatial information enhanced by innovative analytics to support national development.
        </p>
      </div>
      <div id='mv-box'>
        <h3 id='mission-title'>VISION</h3>
        <p id='mission-text'>
          The leading people-centered developer and innovator of
            geospatial products, services and analytics solutions in the Philippines.
        </p>
      </div>
    </div>
  )
}
