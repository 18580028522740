import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  ListSubheader,
  InputAdornment,
  List,
  IconButton,
  Toolbar,
  AppBar,
  ListItemAvatar,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogContent,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  DialogContentText,
  DialogTitle,
  Chip,
  Stack
} from "@mui/material";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import { useAuthContext } from "../../hooks/useAuthContext.js";
import moment from 'moment'
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment)
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Employeeleave() {
  const { user } = useAuthContext();
  // Retrieve Employee
  const [allEmployee, setAllEmployee] = useState();
  const [employee, setEmployee] = useState();
  const [digitizer, setDigitizer] = useState(null);
  const [events, setEvents] = React.useState();
  const [trigger, setTrigger] = useState(false);
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {

    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, [trigger]);

  React.useEffect(() => {
    if (userData.account_role === "superadmin") {
      fetch("https://hr.geospectrum.com.ph/api/api/user/all", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data)
          setAllEmployee(data);
        });

      fetch("https://hr.geospectrum.com.ph/api/api/user/allAccounts", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEmployee(data);
        });
      fetch(
        "https://hr.geospectrum.com.ph/api/api/calendarLeave/events"
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data[0].end)
          setEvents(data)
        });
    } else {
      fetch("https://hr.geospectrum.com.ph/api/api/calendarLeave/all" + userData.department, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data)
          setAllEmployee(data);
          setEmployee(data);
        });
      fetch(
        "https://hr.geospectrum.com.ph/api/api/calendarLeave/eventsup" + userData.department
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data[0].end)
          setEvents(data)
        });
    }

    fetch("https://hr.geospectrum.com.ph/api/api/user/selectDigitizer", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDigitizer(data);
      });

  }, [trigger, userData]);



  const [searchTerm, setSearchTerm] = useState('');
  const filteredData = employee
    ? employee.filter((item) => {
      const itemName = item.firstname + item.lastname + item.account_role || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  const transformedEvents = Array.isArray(events) ? events.map(event => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end)
  })) : [];


  // const events =
  //   [
  //     {
  //     start: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     end: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     title: "MRI Registration",
  //   },
  //   {
  //     start: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     end: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     title: "MRI Registration",
  //   },
  //   {
  //     start: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     end: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     title: "MRI Registration",
  //   },
  //   {
  //     start: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     end: moment("2024-02-13T00:00:00.000+00:00").toDate(),
  //     title: "MRI Registration",
  //   },

  // ]

  // Dialog user
  const [open, setOpen] = React.useState(false);
  const [empData, setEmpData] = React.useState();
  const [empLeave, setEmpLeave] = React.useState();
  const handleClickOpen = (id) => {
    setOpen(true);
    fetch(
      "https://hr.geospectrum.com.ph/api/api/user/viewEmployee" + id
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEmpData(data);
      });

    fetch(
      "https://hr.geospectrum.com.ph/api/api/calendarLeave/get" + id
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEmpLeave(data)
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Dialog leave summary
  const [openLS, setOpenLS] = React.useState(false);
  const handleClickOpenLS = () => {
    setOpenLS(true);
  };
  const handleCloseLS = () => {
    setOpenLS(false);
  };

  return (
    <>
      {/* Dialog Box View Employee Leave */}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {empData && empData.firstname} {empData && empData.lastname}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12} md={6} xl={6}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Leave Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}
                        >
                          Remaining Leave (day/s)
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {empData && empData.account_role === "digitizer" ? (
                        <>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>Sick Leave/Vacation Leave</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.vacationLeave}</TableCell>
                            {/* <TableCell sx={{ textAlign: "center" }}>{12 - userData.vacationLeave}</TableCell> */}
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>Vacation Leave</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.vacationLeave}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.vacationLeave !== undefined ? 15 - empData.vacationLeave : ''}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>Sick Leave</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.sickLeave}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.sickLeave !== undefined ? 15 - empData.sickLeave : ''}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>Maternity Leave</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.maternityLeave}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.maternityLeave !== undefined ? 90 - empData.maternityLeave : ''}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>Paternity Leave</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.paternityLeave}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{empData && empData.paternityLeave !== undefined ? 60 - empData.paternityLeave : ''} </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <List
                  sx={{
                    width: "100%",
                    height: 250,
                    overflow: "auto",
                    backgroundColor: "#f9f9f9",
                  }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Requested leave
                    </ListSubheader>
                  }
                >
                  {empLeave &&
                    empLeave.map((lve, index) => (
                      <ListItem key={index}>
                        <ListItemButton
                          sx={{
                            "&:hover": {
                              backgroundColor: "#d7d7d7",
                              color: "black",
                              "& .MuiListItemIcon-root": {
                                color: "black",
                              },
                            },
                          }}
                        >
                          <ListItemText>{moment(lve.start_date).format("LL")} - {moment(lve.end_date).format("LL")} | {lve.request_type} | <Chip size="small" label={lve.status}
                            color={
                              lve.status === "Rejected"
                                ? "error"
                                : lve.status === "Approved"
                                  ? "success"
                                  : lve.status === "Cancelled"
                                  ? "warning"
                                  : lve.status === "Partially Cancelled"
                                  ? "success"
                                  : lve.status === "Final approval"
                                    ? "primary"
                                    : lve.status === "Partial Cancellation"
                                    ? "primary"
                                    : lve.status === "In Progress"
                                      ? "primary"
                                      : "default" // or any other default color
                            } />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog >

      {/* Dialog Box Leave Summary */}
      <Dialog
        fullScreen
        open={openLS}
        onClose={handleCloseLS}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: "#04051f" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseLS}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Employee's leave summary
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseLS}>
              Exit
            </Button>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper} sx={{ m: "auto", paddingLeft: 5, pr: 5, mt: 5, mb: 5, width: "60%" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Vacation Leave
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Sick Leave
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Maternity Leave
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Paternity Leave
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allEmployee &&
                allEmployee.map((emp, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }, }}>
                    <TableCell sx={{ textAlign: "left" }}>{emp.firstname} {emp.lastname}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{emp.vacationLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{emp.sickLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{emp.maternityLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{emp.paternityLeave}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>

      <Grid
        container
        item
        direction="row"
        spacing={2}
        sx={{ backgroundColor: "white" }}
      >
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          // justifyContent="center"
          alignItems="center"
          spacing={1} >
          <Grid item container direction="row">
            <Grid item xs={12}>
              {/* <Typography
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#d5d5d5",
                  paddingLeft: 2,
                  padding: 1,
                }}
              >
              </Typography> */}
              <Button variant="contained" onClick={handleClickOpenLS} startIcon={<FormatListBulletedIcon size="small" />} sx={{ backgroundColor: '#445858', color: "white", }}> Leave Summary</Button>
              <List
                sx={{
                  width: "100%",
                  height: 700,
                  overflow: "auto",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <TextField
                  size='small'
                  label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ marginTop: '10px' }}
                />
                {/* <Button variant="contained" color="error">View</Button> */}
                {filteredData &&
                  filteredData.map((usr, index) => (
                    <ListItem key={index}>
                      <ListItemButton
                        onClick={() => handleClickOpen(usr.user_id)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#d7d7d7",
                            color: "black",
                            "& .MuiListItemIcon-root": {
                              color: "black",
                            },
                          },
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={`https://hr.geospectrum.com.ph/api/profile/` + usr.photo_path}
                          />
                        </ListItemAvatar>
                        <ListItemText>{usr.firstname} {usr.lastname}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          // justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Calendar
            views={["month", "day"]}
            localizer={localizer}
            events={transformedEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', width: '100%' }}
            eventPropGetter={(event) => {
              const backgroundColor = event.colorEvento ? event.colorEvento : "#f5493d";
              const fontSize = event.fontSize ? event.fontSize : '11px';
              return { style: { fontSize, backgroundColor } }
            }}
          />

        </Grid>
      </Grid>
    </>
  )
}
