import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Box,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Button,
  TextField,
  Typography,
  Chip,
  InputAdornment,
  Stack,
  Badge,
  Dialog,
  FormGroup,
  DialogContent,
  Checkbox,
  DialogActions,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import ArchiveIcon from '@mui/icons-material/Archive';
import moment from "moment";
import { useAuthContext } from "../../../hooks/useAuthContext.js";
import GMSlogo from "../../../images/gms_logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import SearchIcon from '@mui/icons-material/Search';
import LeavePDF from '../../../documents/leaveRequest.js'
import { PDFDownloadLink } from "@react-pdf/renderer";
import CancelIcon from '@mui/icons-material/Cancel';
import Skeleton from '@mui/material/Skeleton';
import EventBusyIcon from '@mui/icons-material/EventBusy';

export default function TableCont() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    height: { md: '95vh', lg: '80vh', xl: "65vh" },
    overflowY: 'scroll',
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Fetch Current User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, [user]);

  // Retrieve All Leave Request
  const [searchTerm, setSearchTerm] = useState('');
  const [leaveRequest, setLeaveRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    if (userData.account_role === "superadmin" && userData.account === "Manager") {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/leaveManager", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLeaveRequest(data);
          setLoading(false);
        });
    }
    else if (userData.account_role === "superadmin") {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/getAllLeave", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLeaveRequest(data);
          setLoading(false);
        });
    }
    else {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/LeaveSupervisor" + user.email, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          //  console.log(data);
          setLeaveRequest(data);
          setLoading(false);

        });
    }
  }, [leaveRequest]);

  const filteredData = leaveRequest
    ? leaveRequest.filter((item) => {
      const itemName = item.user[0].firstname + item.user[0].lastname + item.request_type || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Modal View Specific Request
  const [viewOneRequest, setViewOneRequest] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (idx) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/viewOneLeave" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setViewOneRequest(data);
      });
  };
  const handleClose = () => {
    setOpen(false)
    setSupervisor_approve('')
    setCancelRemarks("")
  };
  //

  const currentDate = new Date();
  const time = currentDate.getTime();

  const [hr_approve, setHr_approve] = useState();
  const [supervisor_approve, setSupervisor_approve] = useState();
  const [manager_approve, setManager_approve] = useState();
  const [status, setStatus] = useState();
  const [remarks, setRemarks] = useState('');
  const [FAremarks, setFAremarks] = useState('');
  const [newRemarks, setNewRemarks] = useState(remarks);
  const [newFARemarks, setNewFARemarks] = useState(FAremarks);

  const [cancelRemarks, setCancelRemarks] = useState();
  const [newcancelRemarks, setNewcancelRemarks] = useState(cancelRemarks);

  const [message, setMessage] = useState('')
  const [messageHR, setMessageHR] = useState('')
  const [messageManager, setMessageManager] = useState('')
  // const [subject, setSubject] = useState('Your leave request status')

  // Radio Button function
  const handleChangeHRApprove = (event, isExe, role, stat) => {
    setHr_approve(event.target.value);

    if (stat === "Partial Cancellation") {
      // console.log('partial check')
      if (event.target.value === "false") {
        setStatus("Cancellation Rejected");
        setMessage("The Human Resource Officer has declined your request to modify the dates of your leave. To access the latest updates on your leave request, kindly visit the portal.")
      } else {
        setStatus("Partially Cancelled");
        setMessage("Your request to adjust the dates of your leave has been successfully approved by the Human Resource Officer. For the most current details regarding your leave request, please refer to the portal.")
      }
    }
    else if (stat === "Full Cancellation") {
      // console.log('full check')
      if (event.target.value === "false") {
        setStatus("Cancellation Rejected");
        setMessage("The Human Resource Officer has declined your request to cancel your leave. To access the latest updates on your leave request, kindly visit the portal.")
      } else {
        setStatus("Cancelled");
        setMessage("Your request to cancel your leave has been successfully approved by the Human Resource Officer. For the most current details regarding your leave request, please refer to the portal.")
      }
    }
    else {
      if (isExe === true) {
        if (event.target.value === "false") {
          setStatus("Rejected");
          setMessage("Your leave request is rejected  by the Human Resource Officer. Please check the status of your leave request on the portal.")
        } else {
          setStatus("Final approval");
          setMessage("The Human Resource Officer approves your leave request and is for final approval. Please check the status of your leave request on the portal.")
          setMessageManager("The Human Resource Officer approved a leave request. Please check the status of the request on the portal.")
        }
      } else {
        if (event.target.value === "false") {
          setStatus("Rejected");
          setMessage("Your leave request is rejected  by the Human Resource Officer. Please check the status of your leave request on the portal.")
        } else {
          setStatus("Approved");
          setMessage("Your leave request is approved by the Human Resource Officer. Please check the status of your leave request on the portal.")

        }
      }
    }
  };

  const handleRadioChangeSupervisorApprove = (event) => {
    setSupervisor_approve(event.target.value);
    if (event.target.value === "false") {
      setSupervisor_approve(false);
      setStatus("Rejected");
      setMessage("Your leave request is rejected by your supervisor. Please check the status of your leave request on the portal. Contact your supervisor for further details.")

    } else {
      setSupervisor_approve(true);
      setStatus("In Progress");
      setMessage("Your leave request is approved by your supervisor and is given for the approval of the Human Resource Officer. Please check the status of your leave request on the portal.")
      setMessageHR("This is to inform you that " + userData.firstname + " " + userData.lastname + " has approved an employee leave request. Please update the records accordingly and ensure that the employee's leave status is reflected accurately.")
    }
  };

  const handleChangeGenMngrApprove = (event) => {
    setManager_approve(event.target.value);

    if (event.target.value === "false") {
      setStatus("Rejected");
      setMessage("Your leave request is rejected  by the General Manager. Please check the status of your leave request on the portal.")
    } else {
      setStatus("Approved");
      setMessage("Your leave request is approved by the General Manager. Please check the status of your leave request on the portal.")
    }

  };

  // Update Request Remarks and Approval (Radiobutton)
  const updateButton = (reqIDx, usrEmail, isExeCm, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave, role, cancReDays, stat) => {
    try {

      if (stat === "Partial Cancellation" || stat === "Full Cancellation") {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + reqIDx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            cancelRemarks: newcancelRemarks,
            hr_approve,
            status,
          }
        )
        if (hr_approve === 'true') {
          cancelRecompense(usrEmail, retype, stat, vacationLeave, sickLeave, paternityLeave, maternityLeave, cancReDays, redays)
        }
        sendMail(usrEmail);
        alertSuccess();
      }
      else {
        if (userData.account === "Manager") {
          axios.put(
            "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + reqIDx,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
              remarks: newRemarks,
              FAremarks: newFARemarks,
              status,
              manager_approve
            }
          )
        } else if (userData.account_role === "superadmin") {
          axios.put(
            "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + reqIDx,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
              remarks: newRemarks,
              FAremarks: newFARemarks,
              hr_approve,
              status,
            }
          )
        } else {
          axios.put(
            "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + reqIDx,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
              remarks: newRemarks,
              FAremarks: newFARemarks,
              status,
              supervisor_approve,
            }
          )

        }
        alertSuccess();
        sendMail(usrEmail);
        if (supervisor_approve === false) {
          recompense(usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave)
        } else if (hr_approve === 'false') {
          recompense(usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave)
        } else if (manager_approve === 'false') {
          recompense(usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave)
        }
        if (supervisor_approve === true) {
          sendMailHR();
        };
        if (hr_approve === 'true' && role === 'digitizer') {
          sendMailTL("gis.support@geospectrum.com.ph")
        }
        if (hr_approve === 'true' && isExeCm === true) {
          sendMailManager();

        }
        setSupervisor_approve('')
      }
    } catch (error) {
      console.error('Error updating leave request:', error);
    }

  };

  const cancelRecompense = async (usrEmail, retype, stat, vacationLeave, sickLeave, paternityLeave, maternityLeave, cancReDays, redays) => {
    // console.log(usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave)
    console.log("I'm clicked")
    let leaveVacation = vacationLeave
    let leaveSick = sickLeave
    let leaveMaternity = maternityLeave
    let leavePaternity = paternityLeave
    let days = redays - cancReDays

    if (stat === "Partial Cancellation") {
      if (retype === "Emergency" || retype === "Personal Leave") {
        leaveVacation = (vacationLeave + days)
      } else if (retype === "Sick Leave") {
        leaveSick = (sickLeave + days)
      } else if (retype === "Maternity") {
        leaveMaternity = (maternityLeave + days)
      } else if (retype === "Paternity") {
        leavePaternity = (paternityLeave + days)
      }
    }
    else {
      if (retype === "Emergency" || retype === "Personal Leave") {
        leaveVacation = (vacationLeave + redays)
        console.log(vacationLeave, redays)
      } else if (retype === "Sick Leave") {
        leaveSick = (sickLeave + redays)
      } else if (retype === "Maternity") {
        leaveMaternity = (maternityLeave + redays)
      } else if (retype === "Paternity") {
        leavePaternity = (paternityLeave + redays)
      }
    }

    axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + usrEmail, {
      vacationLeave: leaveVacation,
      sickLeave: leaveSick,
      paternityLeave: leavePaternity,
      maternityLeave: leaveMaternity,
    });
  }

  const recompense = async (usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave) => {
    // console.log(usrEmail, retype, redays, vacationLeave, sickLeave, paternityLeave, maternityLeave)
    let leaveVacation = vacationLeave
    let leaveSick = sickLeave
    let leaveMaternity = maternityLeave
    let leavePaternity = paternityLeave

    if (retype === "Emergency" || retype === "Personal Leave") {
      leaveVacation = (vacationLeave + redays)
    } else if (retype === "Sick Leave") {
      leaveSick = (sickLeave + redays)
    } else if (retype === "Maternity") {
      leaveMaternity = (maternityLeave + redays)
    } else if (retype === "Paternity") {
      leavePaternity = (paternityLeave + redays)
    }

    axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + usrEmail, {
      vacationLeave: leaveVacation,
      sickLeave: leaveSick,
      paternityLeave: leavePaternity,
      maternityLeave: leaveMaternity,
    });
  }

  const sendMail = async (userEMAIL) => {
    var formData = new FormData();
    formData.append("userEmail", user.email)
    formData.append("message", message)
    formData.append("subject", 'Your leave request status')

    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: userEMAIL,
        message,
        subject: 'Leave request status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailTL = async (userEMAIL) => {
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: userEMAIL,
        message: "Please be advised that the HR officer has approved a Digitizer Leave request. For additional information, kindly access the portal.",
        subject: 'Digitizer leave request'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailHR = async (userEMAIL) => {
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMailtoHR', {
        message: messageHR,
        subject: 'Employee Leave Request Status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailManager = async () => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/managerSend', {
        message: messageManager,
        subject: 'Employee Leave Request Status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }
  // Success Alert message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been Updated",
    });
    handleClose();
  };

  const archive = (idx) => {
    Swal.fire({
      title: "Archive Notification",
      text: "Are you sure you want to archive this request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            isArchive: true,
          }
        )
        Swal.fire({
          title: "Archived!",
          text: "Your file has been archived.",
          icon: "success"
        });
      }
    });
  }
  const archiveUser = (idx) => {
    Swal.fire({
      title: "Archive Notification",
      text: "Are you sure you want to archive this request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            isArchiveSup: true,
          }
        )
        Swal.fire({
          title: "Archived!",
          text: "Your file has been archived.",
          icon: "success"
        });
      }
    });
  }
  // Cancel Leave Request
  const cancelRequest = async (id, reDays, reType) => {
    let leaveVacation = userData.vacationLeave
    let leaveSick = userData.sickLeave
    let leaveMaternity = userData.maternityLeave
    let leavePaternity = userData.paternityLeave
    // console.log("1" + leaveVacation, leaveSick, leaveMaternity, leavePaternity)
    const sendHR = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: "medillojianna@geospectrum.com.ph",
        message: "This is to inform you that " + userData.firstname + " " + userData.lastname + " has cancelled a leave request. You can review and manage leave requests on the portal. Thank you",
        subject: 'Leave Request Cancelled'
      }).then().catch((err) => console.error(err))
    }

    Swal.fire({
      title: "Cancel leave request?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        sendHR()
        if (reType === "Emergency" || reType === "Personal Leave") {
          leaveVacation = (userData.vacationLeave + reDays)
        } else if (reType === "Sick Leave") {
          leaveSick = (userData.sickLeave + reDays)
        } else if (reType === "Maternity") {
          leaveMaternity = (userData.maternityLeave + reDays)
        } else if (reType === "Paternity") {
          leavePaternity = (userData.paternityLeave + reDays)
        }


        axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + user.email, {
          vacationLeave: leaveVacation,
          sickLeave: leaveSick,
          paternityLeave: leavePaternity,
          maternityLeave: leaveMaternity,
        });

        // console.log("2"+leaveVacation, leaveSick, leaveMaternity, leavePaternity)

        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/delete" + id, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        )
        Swal.fire({
          title: "Request cancelled!",
          text: "Your request has been deleted.",
          icon: "success"
        });
      }
    });
  }

  // Dialog (Cancellation Request for approved leave request)
  const [openReqCancel, setOpenReqCancel] = React.useState(false);
  const [dates, setDates] = useState([]);
  const [txtisDisabled, setTxtisDisabled] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [cancelDays, setCancelDays] = useState(0);
  const handleClickOpenReqCancel = (idx) => {
    fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/viewOneLeave" + idx,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setViewOneRequest(data);
        const generatedDates = getDatesBetween(data[0].start_date, data[0].end_date);

        // Filter out weekends
        const filteredDates = generatedDates.filter(date => {
          const day = date.getDay();
          return day !== 0 && day !== 6; // 0 is Sunday, 6 is Saturday
        });

        setDates(filteredDates);
      })
      .catch((error) => {
        console.error('There has been a problem with fetch operation:', error);
      });
    setOpenReqCancel(true);
  };
  const handleCloseReqCancel = () => {
    setOpenReqCancel(false);
    setTxtisDisabled(false)
    setSelectedStartDate();
    setSelectedEndDate();
    setCancelReason("");
    setCancelDays(0)
  };

  function getDatesBetween(start, end) {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= new Date(end)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const handleChangeStartDate = (event, value) => {
    if (value) {
      // Keep the value as a date
      setSelectedStartDate(value);
    }
  };
  const handleChangeEndDate = (event, value) => {
    if (value) {
      setSelectedEndDate(value);
    }
  };

  // Checkbox
  const setSelectAll = (start, end, day) => {
    if (txtisDisabled === false) {
      setSelectedStartDate();
      setSelectedEndDate();
      setCancelDays()
    }
    else {
      setSelectedStartDate();
      setSelectedEndDate();
      setCancelDays(0)
    }
    setTxtisDisabled(!txtisDisabled)
    // console.log(start, end, day)
  }

  const calculateTotalCancelDays = () => {
    // Initialize total days counter
    let totalDays = 0;
    if (selectedStartDate && selectedEndDate) {
      // Ensure startDate is before endDate
      if (selectedStartDate > selectedEndDate) {
        //throw new Error("Start date should be before end date.");
        alertDateError();
      }

      // Loop through each day from start date to end date
      let currentDate = new Date(selectedStartDate);
      while (currentDate <= selectedEndDate) {
        // Check if the current day is not Saturday (6) or Sunday (0)
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          totalDays++;
        }
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    setCancelDays(totalDays); // Return the total number of weekdays
  };

  const submitError = () => {
    Swal.fire({
      icon: "error",
      title: "Missing Details",
      text: "Please complete all required details.",
    });
    handleCloseReqCancel();
  };

  const submitSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Submitted",
      text: "Your request is sent and is given for approval.",
    });
    handleCloseReqCancel();
  };


  const submitCancellation = async (idx) => {
    let stat;
    if (txtisDisabled === true) {
      stat = "Full Cancellation"
    }
    else {
      stat = "Partial Cancellation"
    }

    if (!cancelReason.trim()) {
      return submitError();
    }

    if (!txtisDisabled && cancelDays === 0) {
      return submitError();
    }
    try {
      const requestData = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        status: stat,
        isArchive: false,
        selectedEndDate: selectedEndDate,
        selectedStartDate: selectedStartDate,
        cancelDays: cancelDays,
        cancelReason: cancelReason,
        hr_approve: null,
      };

      await axios.put("https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx, requestData);

      // Actions after successful update
      sendMailHRCancel();
      handleCloseReqCancel();
      submitSuccess();
    } catch (error) {
      console.error("Failed to update cancellation:", error);
      submitError();
    }
  };

  const sendMailHRCancel = async () => {
    const send = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: "medillojianna@geospectrum.com.ph",
          // userEmail: "it.support@geospectrum.com.ph",
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has submitted a cancellation of leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
          subject: "Cancellation of Leave Request",
        })
        .then()
        .catch((err) => console.error(err));
    };
    send();
  };

  const alertDateError = () => {
    Swal.fire({
      icon: "error",
      title: "Input correct date",
      text: "Please select a date equal to or later than the current date.",
    });
    handleCloseReqCancel();
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {viewOneRequest &&
            viewOneRequest.map((request, index) => (
              <Grid
                key={request._id}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  <img src={GMSlogo} width="310" height="auto" />
                </Grid>

                {request.status === "Partial Cancellation" || request.status === "Partially Cancelled" ? (
                  <>
                    {request.user.map((users, index) => (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <Grid item xs={2}>
                          Name:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.firstname + " " + users.lastname}</b>
                        </Grid>
                        <Grid item xs={2}>
                          Request Type:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{request.request_type}</b>
                        </Grid>

                      </Grid>
                    ))}
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={2}>
                        <Typography>
                          <i> Original Date:</i>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.start_date).format("ll")}
                          label="Start Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.end_date).format("ll")}
                          label="End Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          value={request.days}
                          label="Day/s"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          <i>New Date:</i>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.selectedStartDate).format("ll")}
                          label="Start Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.selectedEndDate).format("ll")}
                          label="End Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          value={request.cancelDays}
                          label="Day/s"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <TextField
                          multiline
                          disabled
                          rows={3}
                          label="Reason for Cancellation"
                          defaultValue={request.cancelReason}
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Remarks"
                          disabled={userData.account_role === "supervisor"}
                          defaultValue={request.cancelRemarks}
                          size="small"
                          onChange={(e) => setNewcancelRemarks(e.target.value)}
                          multiline
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : request.status === "Cancelled" ? (
                  <>
                    {request.user.map((users, index) => (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <Grid item xs={2}>
                          Name:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.firstname + " " + users.lastname}</b>
                        </Grid>
                        <Grid item xs={2}>
                          Request Type:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{request.request_type}</b>
                        </Grid>

                      </Grid>
                    ))}
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={2}>
                        <Typography>
                          <i> Original Date:</i>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.start_date).format("ll")}
                          label="Start Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={moment(request.end_date).format("ll")}
                          label="End Date"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          value={request.days}
                          label="Day/s"
                          disabled
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <TextField
                          multiline
                          disabled
                          rows={3}
                          label="Reason for Cancellation"
                          defaultValue={request.cancelReason}
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Remarks"
                          defaultValue={request.cancelRemarks}
                          size="small"
                          disabled
                          // onChange={(e) => setNewcancelRemarks(e.target.value)}
                          multiline
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : request.status === "Full Cancellation" ? (
                  <>
                    {request.user.map((users, index) => (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <Grid item xs={2}>
                          Name:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.firstname + " " + users.lastname}</b>
                        </Grid>
                        <Grid item xs={2}>
                          ID Number:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.user_id}</b>
                        </Grid>

                      </Grid>
                    ))}
                    <Grid
                      item
                      container
                      direction="row"
                      sx={{
                        marginBottom: "20px",
                      }}
                    >
                      <Grid item xs={3}>
                        Request Type:
                      </Grid>
                      <Grid item xs={3}>
                        <b>{request.request_type}</b>
                      </Grid>
                      <Grid item xs={3}>
                        Date Start:
                      </Grid>
                      <Grid item xs={3}>
                        <b> {moment(request.start_date).format("LL")}</b>
                      </Grid>
                      <Grid item xs={3}>
                        Date Requested:
                      </Grid>
                      <Grid item xs={3}>
                        <b>{moment(request.createdAt).format("lll")}</b>
                      </Grid>

                      <Grid item xs={3}>
                        Date End:
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <b> {moment(request.end_date).format("LL")}</b>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <TextField
                          multiline
                          disabled
                          rows={3}
                          label="Reason for Cancellation"
                          defaultValue={request.cancelReason}
                          InputLabelProps={{
                            style: { width: '100%' },
                            shrink: true
                          }}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Remarks"
                          defaultValue={request.cancelRemarks}
                          size="small"
                          onChange={(e) => setNewcancelRemarks(e.target.value)}
                          multiline
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {request.user.map((users, index) => (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <Grid item xs={2}>
                          Name:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.firstname + " " + users.lastname}</b>
                        </Grid>
                        <Grid item xs={2}>
                          Designation:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.position}</b>
                        </Grid>
                        <Grid item xs={2}>
                          Department:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.department}</b>
                        </Grid>
                        <Grid item xs={2}>
                          ID Number:
                        </Grid>
                        <Grid item xs={4}>
                          <b>{users.user_id}</b>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      container
                      direction="row"
                      sx={{
                        marginBottom: "20px",
                      }}
                    >
                      <Grid item xs={3}>
                        Request Type:
                      </Grid>
                      <Grid item xs={3}>
                        <b>{request.request_type}</b>
                      </Grid>
                      <Grid item xs={3}>
                        Date Start:
                      </Grid>
                      <Grid item xs={3}>
                        <b> {moment(request.start_date).format("LL")}</b>
                      </Grid>
                      <Grid item xs={3}>
                        Date Requested:
                      </Grid>
                      <Grid item xs={3}>
                        <b>{moment(request.createdAt).format("lll")}</b>
                      </Grid>

                      <Grid item xs={3}>
                        Date End:
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        <b> {moment(request.end_date).format("LL")}</b>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          multiline
                          disabled
                          rows={3}
                          defaultValue={request.message}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Initial Approver Remarks"
                          defaultValue={request.remarks}
                          // value={remarks}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          disabled={userData.account === "Manager" || userData.account_role === "superadmin"}
                          onChange={(e) => setNewRemarks(e.target.value)}
                          multiline
                          rows={2}
                          sx={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Final Approver Remarks"
                          defaultValue={request.FAremarks}
                          disabled={userData.account_role === "supervisor" || request.hr_approve === true}
                          // value={remarks}
                          size="small"
                          onChange={(e) => setNewFARemarks(e.target.value)}
                          multiline
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}





                {userData.account_role === "superadmin" && userData.account === "Manager" ? (
                  <>
                    <Grid item container direction="row">
                      <Grid xs={12}>
                        {request.hr_approve === false ? (
                          <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                            Employee's request is rejected by the Human Resource Officer
                          </Typography>
                        ) : request.manager_approve === true ? (
                          <Typography sx={{ color: "#3d9cf5", textAlign: "center" }}>
                            Leave request is approved
                          </Typography>
                        ) : request.manager_approve === false ? (
                          <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                            Leave request is rejected
                          </Typography>
                        ) : request.hr_approve === true ? (
                          <>
                            <Grid item container direction="row">
                              <Grid item xs={12} sx={{ color: "blue" }}>
                                The Human Resource Officer approved the employee's request
                              </Grid>
                              <Grid item xs={4}>
                                General Manager:
                              </Grid>
                              <Grid item xs={8}>

                                <RadioGroup
                                  row
                                  defaultValue={request.manager_approve}
                                  onChange={(event) => handleChangeGenMngrApprove(event)}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    value={true}
                                    label="Approve"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    value={false}
                                    label="Reject"
                                  />
                                </RadioGroup>

                              </Grid>
                            </Grid>
                            <Grid item container justifyContent="flex-end">
                              <Button variant="contained"
                                // onClick={updateButton}
                                onClick={() => updateButton(request.request_uniqueId, request.user_email, request.user[0].execomm, request.request_type, request.days, request.user[0].vacationLeave, request.user[0].sickLeave, request.user[0].paternityLeave, request.user[0].maternityLeave, request.user[0].account_role, request.cancelDays, request.status)}

                              >
                                confirm
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <Typography sx={{ color: "green" }}>
                            The request remains pending as the human resource officer has yet to decide or even open it.
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : userData.account_role === "superadmin" ? (
                  <Grid item container direction="row">
                    <Grid xs={12}>
                      {request.supervisor_approve === false ? (
                        <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                          Employee's request is rejected by the Supervisor
                        </Typography>
                      ) : request.hr_approve === false ? (
                        <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                          Leave request is rejected
                        </Typography>
                      ) : request.hr_approve === true ? (
                        <Typography sx={{ color: "#3d9cf5", textAlign: "center" }}>
                          Leave request is approved
                        </Typography>
                      ) : request.supervisor_approve === true ? (

                        <Grid item container direction="row">

                          {request.user[0].execomm === true ? (
                            <></>
                          ) : (
                            <Grid item xs={12} sx={{ color: "blue" }}>
                              The supervisor approved the employee's request
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            Human Resource Officer:
                          </Grid>
                          <Grid item xs={8}>
                            <RadioGroup
                              row
                              defaultValue={request.hr_approve}
                              onChange={(event) => handleChangeHRApprove(event, request.user[0].execomm, request.user[0].account_role, request.status)}
                            >
                              <FormControlLabel

                                control={<Radio />}
                                value={true}
                                label="Approve"
                              />
                              <FormControlLabel

                                control={<Radio />}
                                value={false}
                                label="Reject"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item container justifyContent="flex-end">
                            <Button variant="contained"
                              // onClick={updateButton}
                              disabled={hr_approve === null || hr_approve === undefined}
                              onClick={() => updateButton(request.request_uniqueId, request.user_email, request.user[0].execomm, request.request_type, request.days, request.user[0].vacationLeave, request.user[0].sickLeave, request.user[0].paternityLeave, request.user[0].maternityLeave, request.user[0].account_role, request.cancelDays, request.status)}
                            >
                              confirm
                            </Button>
                          </Grid>
                        </Grid>

                      ) : (
                        <Typography sx={{ color: "green" }}>
                          The request remains pending as the supervisor has yet to decide or even open it.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {request.manager_approve === true ? (
                      <Typography sx={{ color: "#2265a8" }}>
                        Leave request approved
                      </Typography>
                    ) : request.hr_approve === false ? (
                      <Typography sx={{ color: "#f75276" }}>
                        The HR rejected the request.
                      </Typography>
                    ) : request.hr_approve === true ? (
                      <>
                        <Typography sx={{ color: "#3d9cf5" }}>
                          The HR approved the request
                        </Typography>
                      </>
                    ) : request.supervisor_approve === false ? (
                      <>
                        <Typography sx={{ color: "#f75276" }}>
                          You have rejected this request.
                        </Typography>
                      </>
                    ) : request.supervisor_approve === true ? (
                      <>
                        <Typography sx={{ color: "#3d9cf5" }}>
                          The HR department has received the request and is under review; please await approval.
                        </Typography>
                      </>
                    ) : (
                      <Grid item container direction="row">
                        <Grid item xs={4}>
                          Supervisor Officer :
                        </Grid>
                        <Grid item xs={8}>

                          <RadioGroup
                            row
                            defaultValue={request.supervisor_approve}
                            value={supervisor_approve}
                            // onChange={handleRadioChangeSupervisorApprove}
                            onChange={(event) => handleRadioChangeSupervisorApprove(event)}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              value={true}
                              label="Approve"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              value={false}
                              label="Reject"
                            />
                          </RadioGroup>

                        </Grid>
                      </Grid>

                    )}

                    {request.hr_approve === false || request.supervisor_approve === true || request.supervisor_approve === false ||
                      request.hr_approve === true ? (
                      <p></p>
                    ) : (
                      <Grid item container justifyContent="flex-end">
                        <Button variant="contained"
                          // onClick={updateButton}
                          disabled={supervisor_approve === null || supervisor_approve === undefined}
                          onClick={() => updateButton(request.request_uniqueId, request.user_email, request.user[0].execomm, request.request_type, request.days, request.user[0].vacationLeave, request.user[0].sickLeave, request.user[0].paternityLeave, request.user[0].maternityLeave, request.user[0].account_role, request.cancelDays, request.status)}>
                          confirm
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            ))}
        </Box >
      </Modal >

      {/* Table */}
      <Grid
        container
        sx={{
          backgroundColor: 'white',
          height: { lg: "47vh", xl: "70vh" }
        }}
        spacing={1}
      >
        <Grid item xs={12}>

          <TextField
            size='small'
            label="Search request"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: '10px' }}
          />
          <TableContainer component={Paper} sx={{ marginBottom: "1vh", maxHeight: { lg: "35vh", xl: "63vh" } }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Date Requested
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Request Type
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      paddingLeft: "40px"
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Display Skeleton components while loading
                  Array(filteredData.length || 3).fill().map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    {filteredData.map((leave) => (
                      <TableRow>
                        {leave.user.map((users, index) => (
                          <TableCell key={index} align="left">
                            {users.firstname + " " + users.lastname}
                          </TableCell>
                        ))}
                        <TableCell align="left">
                          {moment(leave.createdAt).format("l")}
                        </TableCell>
                        <TableCell align="left">
                          {(leave.request_type === "Unpaid Leave" || leave.request_type === "Personal Leave" || leave.request_type === "Sick Leave") ?
                            leave.request_type.slice(0, -5) :
                            leave.request_type
                          }
                        </TableCell>
                        <TableCell align="left">
                          {moment(leave.start_date).format("l")}
                        </TableCell>
                        <TableCell align="left">
                          {moment(leave.end_date).format("l")}
                        </TableCell>
                        <TableCell align="left">

                          <Stack direction="row">
                            <Chip size="small" color={
                              leave.status === "Rejected"
                                ? "error"
                                : leave.status === "Approved"
                                  ? "success"
                                  : leave.status === "Cancellation Rejected"
                                    ? "success"
                                    : leave.status === "Final approval"
                                      ? "primary"
                                      : leave.status === "Full Cancellation"
                                        ? "info"
                                        : leave.status === "Partial Cancellation"
                                          ? "info"
                                          : leave.status === "Cancelled"
                                            ? "warning"
                                            : leave.status === "Partially Cancelled"
                                              ? "success"
                                              : leave.status === "In Progress"
                                                ? "primary"
                                                : "default" // or any other default color
                            } label={leave.status} />
                          </Stack>

                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                          >
                            <Grid item>
                              <Tooltip
                                title="View"
                                sx={{
                                  color: "#0F1140",
                                }}
                              >
                                <IconButton>
                                  <RemoveRedEyeIcon sx={{
                                    fontSize: {
                                      xs: '10px',
                                      sm: '12px',
                                      md: '16px',
                                      lg: '17px',
                                      xl: '20px',
                                    },
                                  }}
                                    onClick={() => {
                                      handleOpen(leave.request_uniqueId);
                                    }} />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item>
                              <PDFDownloadLink document={<LeavePDF
                                name={leave.user[0].firstname + " " + leave.user[0].lastname}
                                id={leave.user[0].user_id}
                                department={leave.user[0].department}
                                position={leave.user[0].position}
                                leaveType={leave.request_type}
                                reason={leave.message}
                                datesRequested={moment(leave.start_date).format("LL") + " - " + moment(leave.end_date).format("LL")}
                                date={moment(leave.createdAt).format("LL")}
                                days={leave.days}
                                supervisor={leave.supervisor[0].firstname + " " + leave.supervisor[0].lastname}
                                isSupApproved={leave.supervisor_approve === true ? "APPROVED" : leave.supervisor_approve === false ? "REJECTED" : "Pending"}
                                isHRApproved={leave.hr_approve === true ? "APPROVED" : leave.hr_approve === false ? "REJECTED" : "Pending"}
                              />
                              }
                                fileName={`LeaveRequest_${leave.user[0].lastname}${time}`}>

                                <Tooltip
                                  title="Download"
                                  sx={{
                                    color: "#1B4B59",
                                  }}
                                // onClick={() => {
                                //   downloadPDF(leave.request_uniqueId);
                                // }}
                                >
                                  <IconButton>
                                    <SimCardDownloadIcon sx={{
                                      fontSize: {
                                        xs: '10px',
                                        sm: '12px',
                                        md: '16px',
                                        lg: '17px',
                                        xl: '20px',
                                      },
                                    }} />
                                  </IconButton>
                                </Tooltip>
                              </PDFDownloadLink>


                            </Grid>
                            {userData.account_role === "superadmin" ? (
                              <Grid item>
                                <Tooltip
                                  title="Archive"
                                  sx={{
                                    color: "#1B4B59",
                                  }}
                                  onClick={() => {
                                    archive(leave.request_uniqueId);
                                  }}
                                >
                                  <IconButton>
                                    <ArchiveIcon sx={{
                                      fontSize: {
                                        xs: '10px',
                                        sm: '12px',
                                        md: '16px',
                                        lg: '17px',
                                        xl: '20px',
                                      },
                                    }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Tooltip
                                  title="Archive"
                                  sx={{
                                    color: "#0F1140",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => archiveUser(leave.request_uniqueId)}
                                  >
                                    {/* <Badge color="error" variant="dot"                                > */}
                                    <ArchiveIcon fontSize="small" />
                                    {/* </Badge> */}
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            {leave.status !== "Approved" && leave.status !== "Cancellation Rejected" &&
                              leave.status !== "Full Cancellation" && leave.status !== "Partial Cancellation" && leave.status !== "Partially Cancelled" &&
                              leave.status !== "Cancelled" && leave.status !== "Rejected" && leave.user_email === userData.email && (
                                <Grid item>
                                  <Tooltip
                                    title="Cancel"
                                    sx={{
                                      color: "#0F1140",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => cancelRequest(leave.request_uniqueId, leave.days, leave.request_type)}
                                    >
                                      <CancelIcon sx={{
                                        fontSize: {
                                          xs: '10px',
                                          sm: '12px',
                                          md: '16px',
                                          lg: '17px',
                                          xl: '20px',
                                        },
                                      }} />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}

                            {leave.status === "Approved" && leave.user_email === userData.email && (
                              <>
                                <Grid item>
                                  <Tooltip
                                    title="Cancel"
                                    sx={{
                                      color: "#0F1140",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleClickOpenReqCancel(leave.request_uniqueId) //cancel/modify request after approval
                                      }
                                    >
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                      >
                                        <EventBusyIcon fontSize="small" />
                                      </Badge>
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>


      {/* Dialog for leave cancellation (Approved Request) */}
      {viewOneRequest &&
        viewOneRequest.map((request, index) => (
          <Dialog
            open={openReqCancel}
            onClose={handleCloseReqCancel}
            maxWidth={"md"}
            fullWidth
          >
            <DialogContent>
              <Grid
                key={request._id}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                  <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2em" }}>
                    Request for Cancellation of Leave Request
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#4188e0" }}>
                    <i>REMINDER: To cancel your leave, please check the corresponding box. Alternatively, you can choose a different date within the initially specified range or specify the exact dates on which you have taken the leave.</i>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    <i>Leave Details:</i>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup >
                    <FormControlLabel control={<Checkbox onChange={() => {
                      setSelectAll(request.start_date, request.end_date, request.days);
                    }} />} label="Cancel Leave" style={{ width: '100%' }} />
                  </FormGroup>
                </Grid>
                <Grid item xs={3.3}>
                  <TextField
                    value={request.request_type}
                    label="Request Type"
                    disabled
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      style: { width: '100%' },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={3.3}>
                  <TextField
                    value={moment(request.start_date).format("ll")}
                    label="Start Date"
                    disabled
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      style: { width: '100%' },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={3.3}>
                  <TextField
                    value={moment(request.end_date).format("ll")}
                    label="End Date"
                    disabled
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      style: { width: '100%' },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={request.days}
                    label="Day/s"
                    disabled
                    fullWidth
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      style: { width: '100%' },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: "italize" }}>
                    <i>Select New Date:</i>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    onBlur={calculateTotalCancelDays}
                    disabled={txtisDisabled}
                    options={dates}
                    value={selectedStartDate ? selectedStartDate : null}
                    onChange={handleChangeStartDate}
                    getOptionLabel={(option) => moment(option).format('LL')} // Format the date as a string for display
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="Start Date" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    onBlur={calculateTotalCancelDays}
                    disabled={txtisDisabled}
                    options={dates}
                    value={selectedEndDate ? selectedEndDate : null}
                    onChange={handleChangeEndDate}
                    getOptionLabel={(option) => moment(option).format('LL')} // Format the date as a string for display
                    renderInput={(params) => (
                      <TextField size="small" {...params} label="End Date" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Day/s"
                    value={cancelDays}
                    fullWidth
                    disabled
                    InputLabelProps={{
                      style: { width: '100%' },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => setCancelReason(e.target.value)}
                    value={cancelReason}
                    label="Reason for Cancellation"
                    required
                    multiline
                    rows={3}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { submitCancellation(request.request_uniqueId) }}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        ))}
    </>
  );
}
