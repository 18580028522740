import { useState } from "react"
import { useAuthContext } from './useAuthContext'

// export const useSignup = () => {
//   const [error, setError] = useState(null)
//   const [isLoading, setIsLoading] = useState(null)
//   const { dispatch } = useAuthContext()

//   const signup = async (user_id, email, password, firstname, middlename, lastname, position, department, date_employed, company,
//     mobile, gender, civil_status, birth_date, nationality, province, city, barangay,
//     sss_id, pagibig_id, philhealth_id, tin_id, bank_name, bank_account_name, bank_account_number,
//     father_name, father_occupation, mother_name, mother_occupation, spouse_name, parent_address, emergency_name, emergency_contact, account_status
//     ) => {
//     setIsLoading(true)
//     setError(null)

//     const response = await fetch('https://hr.geospectrum.com.ph/api/api/user/signup', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' }, 
//       body: JSON.stringify({user_id, email, password, firstname, middlename, lastname, position, department, date_employed, company, 
//         mobile, gender, civil_status, birth_date, nationality, province, city, barangay,
//         sss_id, pagibig_id, philhealth_id, tin_id, bank_name, bank_account_name, bank_account_number,
//         father_name, father_occupation, mother_name, mother_occupation, spouse_name, parent_address, emergency_name, emergency_contact, account_status
//       })
//     })

//     const json = await response.json()

//     if (!response.ok) {
//       setIsLoading(false)
//       setError(json.error)
//     }
//     if (response.ok) {
//       localStorage.setItem('user', JSON.stringify(json))

//       dispatch({ type: 'LOGIN', payload: json })
//       setIsLoading(false)

//     }
//   }

//   return { signup, isLoading, error }
// } 


export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (supervisor, user_id, email, password, firstname, middlename, lastname, position, department, date_employed, company,
    mobile, gender, civil_status, birth_date, nationality, province, city, barangay,
    sss_id, pagibig_id, philhealth_id, tin_id, bank_name, bank_account_name, bank_account_number,
    father_name, father_occupation, mother_name, mother_occupation, spouse_name, parent_address, emergency_name, emergency_contact

  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://hr.geospectrum.com.ph/api/api/user/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          supervisor, user_id, email, password, firstname, middlename, lastname, position, department, date_employed, company,
          mobile, gender, civil_status, birth_date, nationality, province, city, barangay,
          sss_id, pagibig_id, philhealth_id, tin_id, bank_name, bank_account_name, bank_account_number,
          father_name, father_occupation, mother_name, mother_occupation, spouse_name, parent_address, emergency_name, emergency_contact,

        })
      });

      const json = await response.json();

      if (!response.ok) {
        throw new Error(json.error);
      }

      // If signup is successful, return true
      setIsLoading(false);
      localStorage.setItem('user', JSON.stringify(json));
      dispatch({ type: 'LOGIN', payload: json });
      return true;
    } catch (error) {
      // If signup fails, set error and return false
      setIsLoading(false);
      setError(error.message);
      return false;
    }
  };

  return { signup, isLoading, error };
};