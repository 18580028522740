import React, { useState, useEffect } from "react";
import "./leaverequest.css";
import { Button, Grid, Modal, Box, TextField, MenuItem, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, Hidden } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import TabBar from "./tabBar/tabBar.js";
import TableContainer from "./tableContainer/tableContainer.js";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GMSlogo from "../../images/gms_logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export default function LeaveRequest() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);
  const user_id = userData.user_id;
  // Fetch User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, [user]);

  // Count Request
  const [allLeaveCount, setAllLeaveCount] = useState("");
  const [inProgressCount, setInProgressCount] = useState("");
  const [completedCount, setCompletedCount] = useState("");
  React.useEffect(() => {
    if (userData.account_role === "superadmin") {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/allLeaveCount", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAllLeaveCount(data);
        });

      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/countLeaveProgress", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data)
          setInProgressCount(data.InProgressCount);
          setCompletedCount(data.CompletedCount);
        });
    } else {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/supervisorLeaveCount" + user.email, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAllLeaveCount(data);
        });

      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/supervisorcountProgress" + user.email, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data)
          setInProgressCount(data.InProgressCount);
          setCompletedCount(data.CompletedCount);
        });
    }
  }, [userData]);


  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setCountDays(0)
    setRequest_type("");
    setStart_date("");
    setEnd_date("");
    setMessage("");
  
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "60vw" },
    // height: {md: '95vh'},
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };
  //

  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Create Leave Request
  const [request_type, setRequest_type] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [message, setMessage] = useState("");
  const create = async () => {
    setButtonDisabled(true);
    let leaveVacation = userData.vacationLeave
    let leaveSick = userData.sickLeave
    let leaveMaternity = userData.maternityLeave
    let leavePaternity = userData.paternityLeave

    if (request_type === "Emergency" || request_type === "Personal Leave") {
      if (countDays > userData.vacationLeave) {
        errorLeave()
        return;
      }
      leaveVacation = (userData.vacationLeave - countDays)
    } else if (request_type === "Sick Leave") {
      if (countDays > userData.sickLeave) {
        errorLeave()
        return;
      }
      leaveSick = (userData.sickLeave - countDays)
    } else if (request_type === "Maternity") {
      if (countDays > userData.maternityLeave) {
        errorLeave()
        return;
      }
      leaveMaternity = (userData.maternityLeave - countDays)
    } else if (request_type === "Paternity") {
      if (countDays > userData.paternityLeave) {
        errorLeave()
        return;
      }
      leavePaternity = (userData.paternityLeave - countDays)
    }
    if (
      request_type.trim() === "" ||
      start_date.trim() === "" ||
      end_date.trim() === "" ||
      message.trim() === ""
    ) {
      // Input is empty
      alertError();
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
      return;
    }


    if (userData.account_role === "superadmin") {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/leaveRequest/createLeave",
        {
          user_email: user.email,
          user_id,
          request_type,
          start_date,
          end_date,
          message,
          supervisor: userData.supervisor,
          days: countDays,
          supervisor_approve: true,
          hr_approve: true,
          status: "Final approval"
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
       sendMailMngr()
  } else if (userData.execomm === true) {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/leaveRequest/createLeave",
        {
          user_email: user.email,
          user_id,
          request_type,
          start_date,
          end_date,
          message,
          supervisor: userData.supervisor,
          days: countDays,
          supervisor_approve: true,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
        sendMail()
  } else if(userData.account_role === "supervisor"){
    axios.post(
      "https://hr.geospectrum.com.ph/api/api/leaveRequest/createLeave",
      {
        user_email: user.email,
        user_id,
        request_type,
        start_date,
        end_date,
        message,
        supervisor: userData.supervisor,
        days: countDays,
        supervisor_approve: true,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
     sendMail()
  }

    axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + user.email, {
      vacationLeave: leaveVacation,
      sickLeave: leaveSick,
      paternityLeave: leavePaternity,
      maternityLeave: leaveMaternity,
    });

    setTimeout(() => {
      setButtonDisabled(false);
    }, 3000);

    cancel()
    alertSuccess();

  };

  const cancel = async () => {
    setRequest_type("");
    setStart_date("");
    setEnd_date("");
    setMessage("");
    handleClose();
    setCountDays(0)
  }
  const errorLeave = () => {
    Swal.fire({
      icon: "error",
      title: "Check remaining Leave",
      text: "Request is not submitted",
    });
    cancel()
    handleClose();
  };


  // Send Mail
  const sendMail = async (userEMAIL) => {
    // var formData = new FormData();
    // formData.append("userEmail", user.email )
    // formData.append("message", message)
    // formData.append("subject", 'Your leave request status')
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: "medillojianna@geospectrum.com.ph",
        message: "This is to inform you that " + userData.firstname + " " + userData.lastname + " has submitted a new leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
        subject: 'New Leave Request'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailMngr = async (userEMAIL) => {
    // var formData = new FormData();
    // formData.append("userEmail", user.email )
    // formData.append("message", message)
    // formData.append("subject", 'Your leave request status')
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: "vssantos@geospectrum.com.ph",
        message: "This is to inform you that " + userData.firstname + " " + userData.lastname + " has submitted a new leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
        subject: 'New Leave Request'
      }).then().catch((err) => console.error(err))
    }
    send()
  }


  // Count Leave Day/s
  const [countDays, setCountDays] = useState(0);
  const handleStartDateChange = (e) => {
    const latestValue = e.target.value;
    setEnd_date(latestValue);
  };

  const calculateTotalDays = () => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    // Check if endDate is today or in the future
    const today = new Date();
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 4);
    currentDateMinus3Days.setHours(0, 0, 0, 0);

    if (end_date !== "") {
      if (endDate >= currentDateMinus3Days) {
        let totalDays = 0;
        let currentDate = startDate;
        while (currentDate <= endDate) {
          // Check if the current day is not Saturday (6) or Sunday (0)
          if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            totalDays++;
          }
          // Move to the next day
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setCountDays(parseInt(totalDays));
        // console.log(totalDays);
      } else {
        // Handle the case where endDate is in the past
        // console.error('End date should be today or in the future.');
        alertDateError();
      }
    }
  };
  const alertDateError = () => {
    Swal.fire({
      icon: "error",
      title: "Input correct date",
      text: "End date should be today or in the future.",
    });
    handleClose();
  };

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();

    // Get the current date 3 days ago
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 8); //changed to 1 week

    if (start_date !== "") {
      if (selectedDate < currentDateMinus3Days || selectedDate > start_date) {
        // If the selected date is more than 3 days ago, show an error
        alertDateError();
      } else {
        // Update the state with the selected date
        setStart_date(selectedDate.toISOString().split('T')[0]);
      };
    } else {
      if (selectedDate < currentDateMinus3Days) {
        // If the selected date is more than 3 days ago, show an error
        alertDateError();
      } else {
        // Update the state with the selected date
        setStart_date(selectedDate.toISOString().split('T')[0]);
      };
    };
  };

  const handleDateChangeEnd = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date();

    // Get the current date 3 days ago
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 8); //changed to 1 week
    // Compare the selected date with the current date
    if (selectedDate < currentDateMinus3Days ) {
      // If the selected date is in the past, don't update the state
      alertDateError();
    } else {
      // Update the state with the selected date
      setEnd_date(selectedDate);
    }
  };

  // Modal Leave Remaining
  const [openLeaveCont, setOpenLeaveCont] = React.useState(false);
  const handleOpenLeave = () => {
    setOpenLeaveCont(true);
  };
  const handleCloseLeave = () => setOpenLeaveCont(false);
  
  React.useEffect(() => {
    window.onclick = function(event){
      console.log(event.target.className)
      if (event.target.className === 'modal') {
        setOpen(false)
      }
    }
  })

  return (
    <>
      
      {/* Create Request Modal */}
      {user && (
        <form>
          <div id={open ? 'modal-box' : 'modal-box-hidden'} className='modal'>
            <div id='modal-leave'>
              <div id='leave-modal-content'>
                <img src={GMSlogo} width="310" height="auto" alt="Logo"/>
                <div id='leave-modal-row'>
                  <p id='leave-modal-row-detail'>
                    <i>Employee Name:</i>
                    <b>{userData.firstname + " " + userData.lastname}</b>
                  </p>
                  <p id='leave-modal-row-detail'>
                    <i>Department:</i>
                    <b>{userData.department}</b>
                  </p>
                </div>
                {/* <div id='leave-modal-row'>
                  <p id='leave-modal-row-detail'>
                    <i>Designation:</i>
                    <b>{userData.position}</b>
                  </p>
                  <p id='leave-modal-row-detail'>
                    <i>Employment Number:</i>
                    <b>{userData.user_id}</b>
                  </p>
                </div> */}
                <label for='input' id='leave-request-dropdown'>
                  <span>Request Type</span>
                  <select label="Request Type" value={request_type || ""}
                    onChange={(e) => setRequest_type(e.target.value)}>
                    <option name=" "> </option>
                    {userData.vacationLeave !== 0 && (
                      <option name="Personal Leave">Personal Leave</option>
                    )}
                    <option name="Unpaid Leave">Unpaid Leave</option>
                    {userData.sickLeave !== 0 && (
                      <option name="Sick Leave">Sick Leave</option>
                    )}
                    {userData.vacationLeave !== 0 && (
                      <option name="Emergency">Emergency</option>
                    )}
                    {userData.maternityLeave !== 0 && (
                      <option name="Maternity">Maternity</option>
                    )}
                    {userData.paternityLeave !== 0 && (
                      <option name="Paternity">Paternity</option>
                    )}
                  </select>
                </label>
                <div id='leave-modal-row'>
                  <label for='input' id='leave-request-date'>
                    <span>Leave from</span>
                    <input type='date' onChange={handleDateChange}
                      value={start_date}/>
                  </label>
                  <label for='input' id='leave-request-date'>
                    <span>To</span>
                    <input type='date' onChange={handleDateChangeEnd}  onBlur={calculateTotalDays}
                      value={end_date}/>
                  </label>
                  <label for='input' id='leave-request-days'>
                    <span>Day/s</span>
                    <input type='string' disabled label='Day/s'
                      value={countDays}/>
                  </label>
                </div>
                <label for='input' id='leave-request-dropdown'>
                  <span>Reason for Requested Leave</span>
                  <textarea value={message} rows='4' 
                    onChange={(e) => setMessage(e.target.value)}/>
                </label>
                <div id='leave-modal-row-button'>
                  <Button id='modal-information-button' onClick={()=> {create(); handleClose();}}>SUBMIT</Button>
                  <Button id='modal-information-cancel' onClick={()=> {cancel(); handleClose();}}>CANCEL</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {/* Modal Open Remaining Leave */}
      <Modal open={openLeaveCont} onClose={handleCloseLeave}>
        <Box sx={style}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Leave Type
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Remaining Leave (day/s)
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: 'center'
                  }}
                >
                  Used Leave (day/s)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.account_role === "digitizer" ? (
                <>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>Personal Leave</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{userData.vacationLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{1 - userData.vacationLeave}</TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>Vacation Leave</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{userData.vacationLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{15 - userData.vacationLeave}</TableCell>
                  </TableRow>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>Sick Leave</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{userData.sickLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{15 - userData.sickLeave}</TableCell>
                  </TableRow>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>Maternity Leave</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{userData.maternityLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{90 - userData.maternityLeave}</TableCell>
                  </TableRow>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>Paternity Leave</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{userData.paternityLeave}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{60 - userData.paternityLeave} </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>

          <Typography sx={{ padding: 1, color: "#adadad", fontStyle: "italic" }}>
            Note: Please be aware that the vacation leave encompasses both emergency and personal leave.</Typography>
        </Box>
      </Modal>
      

      {userData.account_role === "superadmin" ? (
        <TabBar />
      ) : (
        <></>
      )}

      {/* Request Container */}

      <div id='leave-container'>
        <button id='modal-information-button' className='remaining-leave' onClick={handleOpenLeave}>
          <ReceiptLongIcon/>&nbsp;REMAINING LEAVE
        </button>
        <button id='modal-information-button' className='create-request' onClick={handleOpen}>
          CREATE REQUEST
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div id='leave-details'>
          <AssignmentIcon className='tab-icon'/>
          <div id='leave-details-text'>
            <b>In progress</b>
            {String(inProgressCount).padStart(3, '0')}
          </div>
        </div>
        <div id='leave-details'>
          <AssignmentTurnedInIcon className='tab-icon'/>
          <div id='leave-details-text'>
            <b>Completed</b>
            {String(completedCount).padStart(3, '0')}
          </div>
        </div>
        <div id='leave-details'>
          <SummarizeIcon className='tab-icon'/>
          <div id='leave-details-text'>
            <b>All request</b>
            {String(allLeaveCount).padStart(3, '0')}
          </div>
        </div>
      </div>


      <Grid item container direction="row">
        <TableContainer />
      </Grid>
    </>
  );
}
