import {
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  InputAdornment,
  TableRow,
  Tooltip,
  IconButton,
  Chip,
  Stack,
  useMediaQuery,
  DialogContent,
  DialogActions,
  Dialog,
  MenuItem
} from "@mui/material";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import GMSlogo from "../../../images/gms_logo.png";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import Swal from "sweetalert2";
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ItemRequest() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);

  // Dialog (submit request)
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setQuantity("")
    setItem("")
    setPurpose("")
    setEnd_user("")
    setPrice(0)
    setShop_link("")
    setUnitPrice(0)
    setOffice("")
  };

  // Dialog (vuew request)
  const [reqViewOne, setReqViewOne] = useState([]);
  const [openReq, setOpenReq] = React.useState(false);
  const handleClickOpenReq = (id) => {
    setOpenReq(true);
    fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/viewRequest" + id, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setReqViewOne(data);
      });
  };
  const handleCloseReq = () => {
    setOpenReq(false);
  };


  const [quantity, setQuantity] = useState("");
  const [item, setItem] = useState("");
  const [purpose, setPurpose] = useState("");
  const [end_user, setEnd_user] = useState("");
  const [price, setPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [shop_link, setShop_link] = useState("");
  const [office, setOffice] = useState("");
  const [request, setRequest] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserData(data);
      });
  }, [user]);

  const [trigger, setTrigger] = useState(false);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getRequest" + userData.user_id, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRequest(data);
      });
  }, [trigger, userData]);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredData = request
    ? request.filter((item) => {
      const itemName = item.item + item.status + item.price || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  const handlePriceChange = (e) => {
    // Allow only numbers and ignore other characters
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    // Set the numeric value without commas to the state
    setUnitPrice(parseFloat(inputValue) || 0)
  };
  // Format the number with commas for display
  const formattedUnitPrice = new Intl.NumberFormat('en-US').format(parseFloat(unitPrice, 10));
  const formattedPrice = new Intl.NumberFormat('en-US').format(parseFloat(price, 10));
  const calculateTotal = () => {
    let total = parseFloat(quantity * unitPrice)
    setPrice(total)

  }

  // create request
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };
  const createRequest = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    // send email notification
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: ["medillojianna@geospectrum.com.ph", "pgtodoc7@gmail.com"], //["medillojianna@geospectrum.com.ph", "pgtodoc7@gmail.com"]
        message: `Please be informed that ${userData.firstname} ${userData.lastname} has submitted a request for an item through the portal. Kindly check the portal at your earliest convenience to review and process the request. Thank you for your attention to this matter.`,
        subject: 'Equipment Request'
      }).then().catch((err) => console.error(err))
    }
    // validate if inputs are empty
    if (
      quantity.trim() === "" ||
      item.trim() === "" ||
      purpose.trim() === "" ||
      price === 0 ||
      end_user.trim() === ""
    ) {
      // Input is empty
      setIsSubmitting(false);
      alertError();
      return;
    }

    axios.post(
      "https://hr.geospectrum.com.ph/api/api/itemRequest/create",
      {
        quantity, item, purpose, end_user, price, shop_link, user_id: userData.user_id, unitPrice, office
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    ).then(() => {
      setTrigger(!trigger);
    })
    setTrigger(!trigger);
    send()
    // Reset submitting state after the request is complete
    setIsSubmitting(false);
    alertSuccess()
  }

  // Cancel Item Request
  const cancelRequest = async (id) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: ["medillojianna@geospectrum.com.ph", "pgtodoc7@gmail.com"], //["medillojianna@geospectrum.com.ph", "pgtodoc7@gmail.com"]
        message: "This is to inform you that " + userData.firstname + " " + userData.lastname + " has canceled their previously submitted item/equipment request. You can review and manage leave requests on the portal. Thank you",
        subject: 'Equipment Request Cancelled'
      }).then().catch((err) => console.error(err))
    }

    Swal.fire({
      title: "Cancel eqipment request?",
      text: "Your request will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        send()
        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/itemRequest/delete" + id, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        ).then(() => {
          setTrigger(!trigger);
          Swal.fire({
            title: "Request cancelled!",
            text: "Your request has been deleted.",
            icon: "success"
          });
        })
        setTrigger(!trigger);
      }
    });

  }
  return (
    <>
      {/* submit a request dialog */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                marginBottom: "30px",
              }}
            >
              <img src={GMSlogo} width="310" height="auto" />
            </Grid>

            <Grid
              item
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              EQUIPMENT REQUEST FORM
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Grid item xs={2}>
                <TextField
                  required
                  fullWidth
                  label="Quantity"
                  onBlur={calculateTotal}
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                  InputLabelProps={{
                    style: { width: '100%' }
                  }}
                />
              </Grid>   <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  label="Item"
                  onChange={(e) => setItem(e.target.value)}
                  value={item}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handlePriceChange}
                  onBlur={calculateTotal}
                  value={formattedUnitPrice}
                  label="Unit Price Estimation"
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  label="Total Price"
                  value={formattedPrice}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Purpose of request"
                  required
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => setPurpose(e.target.value)}
                  value={purpose}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  label="End user of requested item/s:"
                  onChange={(e) => setEnd_user(e.target.value)}
                  value={end_user}
                  InputLabelProps={{
                    style: { width: '100%' }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label="Office"
                  onChange={(e) => setOffice(e.target.value)}
                  value={office}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' }
                  }}
                  select
                >
                  <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                  <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Shop Link (if available)"
                  onChange={(e) => setShop_link(e.target.value)}
                  value={shop_link}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={() => {
            createRequest(); setTrigger(!trigger);
          }} autoFocus disabled={isSubmitting}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* view a request dialog */}
      <Dialog
        fullScreen={fullScreen}
        open={openReq}
        onClose={handleCloseReq}
        maxWidth={'md'}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                marginBottom: "30px",
              }}
            >
              <img src={GMSlogo} width="310" height="auto" />
            </Grid>

            <Grid
              item
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              EQUIPMENT REQUEST FORM
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Grid item xs={2}>
                <TextField
                  required
                  fullWidth
                  label="Quantity"
                  type="number"
                  disabled
                  value={reqViewOne.quantity}
                  InputLabelProps={{ 
                    shrink: true ,
                    style: { width: '100%' }
                  }}
                />
              </Grid>   <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  label="Item"
                  disabled
                  value={reqViewOne.item}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={reqViewOne.unitPrice}
                  label="Unit Price Estimation"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={reqViewOne.price}
                  label="Total Price"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Purpose of request"
                  required
                  multiline
                  rows={4}
                  fullWidth
                  disabled
                  value={reqViewOne.purpose}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  label="End user of requested item/s:"
                  disabled
                  value={reqViewOne.end_user}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label="Office:"
                  disabled
                  value={reqViewOne.office}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Shop Link (if available)"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  multiline
                  value={reqViewOne.shop_link}
                />
              </Grid>
              {reqViewOne.status === "Approved" ||
                reqViewOne.status === "Rejected" ? (
                <>
                  <Grid item xs={6}>
                    <i> Remarks:</i>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      required
                      multiline
                      value={reqViewOne.remarks}
                      rows={4}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>{" "}
                </>
              ) : (
                <p></p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {user && (
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ backgroundColor: "white" }}
        >
          <Grid item >
            <Typography variant="h4"> Equipment Request</Typography>
          </Grid>
          <Grid item sx={{ textAlign: "center", marginTop: "auto", marginBottom: "auto", paddingLeft: 2 }}>
            <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: '#04051f', color: "white", }}>submit request</Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              size='small'
              label="Search request"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ paddingBottom: '10px', marginTop: '15px' }}
            />
            <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {filteredData.map((req) => (
                    <TableRow
                      key={req._id}
                    >
                      <TableCell align="left">
                        {req.quantity}
                      </TableCell>
                      <TableCell align="left">
                        {req.item}
                      </TableCell>
                      <TableCell align="left">
                        {req.price.toLocaleString()}
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row">
                          <Chip size="small" color={
                            req.status === "Rejected"
                              ? "error"
                              : req.status === "Approved"
                                ? "success"
                                : req.status === "Done"
                                ? "success"
                                : req.status === "In Progress"
                                  ? "primary"
                                  : "default" // or any other default color
                          } label={req.status} />
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item>
                            <Tooltip
                              title="View"
                              sx={{
                                color: "#0F1140",
                              }}
                            >
                              <IconButton onClick={() => handleClickOpenReq(req.request_uniqueId)}
                              >
                                <RemoveRedEyeIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {req.status !== "Approved" && req.status !== "Rejected" && (
                            <Grid item>
                              <Tooltip
                                title="Cancel"
                                sx={{
                                  color: "#0F1140",
                                }}
                              >
                                <IconButton
                                  onClick={() => cancelRequest(req.request_uniqueId)}
                                >
                                  <CancelIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  )
}
