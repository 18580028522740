import React from 'react';
import { AppBar, BottomNavigation, BottomNavigationAction, IconButton, Menu, MenuItem, Paper, Tooltip } from "@mui/material";
import { Route, Routes, useNavigate } from 'react-router-dom';

import { useLogout } from '../../../../hooks/useLogout';

import LogoutIcon from '@mui/icons-material/Logout';
import FeedIcon from '@mui/icons-material/Feed';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArchiveIcon from '@mui/icons-material/Archive';

import bannerLogo from "../../../../images/gspec_header_png_whiteG_B.png";

// Routes
import RegularUser from "../../Dashboard/UserDashboard";
import RegularUserRequest from "../../RequestPage/index";
import RegularUserCashAdvance from "../../CashAdvance/index";
import UserDepartment from "../../../department/index";
import UserEmployee from "../../../employee/index";
import UserRegulation from "../../../rules/index";
import UserPayslip from "../../payslip/index";
import UserArchive from "../../Archive/index";
import ItemRequest from "../../ItemRequest/index";

// Payslip
import Payslip from '../../../payslip/index'
import Employeeleave from "../../../employeeLeave/index.js";

import './index.css';

import { PDFDownloadLink } from '@react-pdf/renderer';
import COEPDF from '../../../../documents/certOFEmp.js'
import moment from 'moment';

export default function UserMobileLayout(props) {
  const {userData} = props;
  const [anchorElRequest, setAnchorElRequest] = React.useState(null);


  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleClickRequest = (event) => {
    setAnchorElRequest(event.currentTarget);
  };

  const handleCloseRequest = (target) => {
    setAnchorElRequest(null);
    // console.log(['leaverequest', 'cashadvance', 'itemrequest'].includes(target))
    if(['leaverequest', 'cashadvance', 'itemrequest'].includes(target)){
      navigate(`/user/${target.toLowerCase().replace(/\s/g, "")}`)
    }
    
  };

  const handleClickLogout = () => {
    logout();
  };

  const currentDate = new Date();

  return (
    <div className='mobile-container'>
      <AppBar position='fixed'>
        <div id='toolbar'>
          <img src={bannerLogo} alt="Banner Logo" id='banner-logo'/>
          <div style={{flexGrow: 1}}/>
          <div className='toolbar-name'> 
            Employee
            <div style={{width: 5}}/>
            <Tooltip title="Logout">
              <IconButton onClick={handleClickLogout}>
                <LogoutIcon sx={{color: 'white'}}/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </AppBar>
      <div component="main" id='routes-container'>
        <div id='header-filler'/>
        <Routes>
          <Route path="/"> </Route>
          <Route path="/home" element={<RegularUser />}></Route>
          <Route path="/leaverequest" element={<RegularUserRequest />}></Route>
          <Route path="/cashadvance" element={<RegularUserCashAdvance />}></Route>
          <Route path="/departments" element={<UserDepartment />}></Route>
          <Route path="/employees" element={<UserEmployee />}></Route>
          <Route path="/rules&regulations" element={<UserRegulation />}></Route>
          <Route path="/archive" element={<UserArchive />}></Route>
          <Route path="/itemrequest" element={<ItemRequest />}></Route>
          <Route path="/payslip" element={<UserPayslip />}></Route>
          <Route path="/employeeleave" element={userData.account_role !== 'user' ? <RegularUser /> : <Employeeleave />}></Route>
          <Route path="/employeepayslip" element={userData.email !== 'accounting@geospectrum.com.ph' ? <UserPayslip /> : <Payslip />}></Route>
          <Route path="*" element={<div>page note found</div>} />
        </Routes>
      </div>
      
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels
          onChange={(event, newValue) => {
            console.log(newValue);
            if(newValue !== "request"){
              navigate(`/user/${newValue.toLowerCase().replace(/\s/g, "")}`)
            }
          }}>
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} value="home"/>
          <BottomNavigationAction label="Requests" icon={<FeedIcon onClick={handleClickRequest}/>} value="request" />
          <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} value="archive"/>
          
        </BottomNavigation>
        <Menu
          anchorEl={anchorElRequest}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElRequest)}
          onClose={handleCloseRequest}>
          <MenuItem onClick={()=>handleCloseRequest('leaverequest')}>Leave Request</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('cashadvance')}>Cash Advance</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('itemrequest')}>Item Requests</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('COErequest')}>
            <PDFDownloadLink
              document={<COEPDF
                date={moment(currentDate).format("LL")}
                position={userData.position}
                department={userData.department}
                fname={userData.firstname}
                lname={userData.lastname}
                title={userData.civil_status === "Single" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Married" && userData.gender === "Female" ? "Mrs." : userData.civil_status === "Divorced" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Widowed" && userData.gender === "Female" ? "Mrs.": "Mr."}
                dateEmployed={moment(userData.date_employed).format("LL")}
                email={userData.email}
              />}
              fileName={`Certificate of Employment_${userData.lastname}`}
            >
              COE Request
            </PDFDownloadLink>
          </MenuItem>
        </Menu>
      </Paper>
    </div>
  )
}