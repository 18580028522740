import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Tooltip,
  CardActionArea,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  Card,
  CardContent,
  MenuItem,
  Button,
  Skeleton
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Swal from "sweetalert2";
import moment from "moment";
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import AggreementForm from '../../documents/inventoryAgreement.js'


export default function InventoryEmployeeContainer() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(true);
  // Retrieve All Employee w/ their Item listed
  const [employee, setEmployee] = useState(null);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/inventory/getAllEmpItem", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEmployee(data);
        setLoading(false);
        // console.log(data)
      });
  }, [employee]);

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    height: 530,

  };

  // Modal style
  const styleAssign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto",

  };

  // Modal View Specific Employee w/ listed item
  const [info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (itms) => {
    setOpen(true);
    setInfo(itms);
    // console.log(itms)
  };
  const handleClose = () => setOpen(false);

  // Modal View Specific Item
  const [viewOneInventory, setViewOneInventory] = useState("");
  const [openEdit, setOpeEdit] = React.useState(false);
  const handleOpenEdit = (idx) => {
    setOpeEdit(true);
    fetch("https://hr.geospectrum.com.ph/api/api/inventory/viewOneInventory" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setViewOneInventory(data);
        {
          data &&
            data.map(
              (itm, index) => (
                setItem_name(itm.item_name),
                setItem_description(itm.item_description),
                setAssigned_to(itm.assigned_to),
                setNotes(itm.notes),
                setIssue_date(itm.issue_date),
                setReturn_date(itm.return_date),
                setItem_status(itm.item_status),
                setSerial_no(itm.serial_no),
                setQuantity(itm.quantity),
                setOffice(itm.office)
              )
            );
        }
      });
  };
  const handleCloseEdit = () => setOpeEdit(false);

  // Reassign/Update Item
  const [item_name, setItem_name] = useState("");
  const [item_description, setItem_description] = useState("");
  const [assigned_to, setAssigned_to] = useState("");
  const [notes, setNotes] = useState();
  const [issue_date, setIssue_date] = useState("");
  const [return_date, setReturn_date] = useState("");
  const [item_status, setItem_status] = useState("");
  const [serial_no, setSerial_no] = useState("");
  const [office, setOffice] = useState("");
  const [quantity, setQuantity] = useState(0);
  // Form
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [user_email, setUser_email] = useState("");
  const [assigned_toNext, setAssigned_toNext] = useState("");
  const handleUpdateItem = (unq) => {
    const alertReasssign = () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The item/equipment is updated and reassigned.",
      });
      handleCloseEdit();
    };

    if (assigned_toNext === '') {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
        {
          item_name,
          item_description,
          notes,
          issue_date,
          item_status,
          return_date,
          serial_no,
          quantity,
          office
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      alertSuccess();
    } else {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
        {
          item_name,
          assigned_to: assigned_toNext,
          item_description,
          notes,
          issue_date,
          item_status,
          return_date,
          serial_no,
          quantity,
          office
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      CreatePDF()
      alertReasssign();
    }
    setAssigned_toNext('')
  };

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const time = currentDate.getTime();
  const CreatePDF = async () => {
    try {
      const element = <AggreementForm
        issueDate={moment(issue_date).format("LL")}
        returnDate={moment(return_date).format("LL")}
        name={FName + " " + LName}
        department={department}
        designation={designation}
        id={assigned_to}
        equipment={item_name}
        serial={serial_no}
        description={item_description}
        remarks={notes}
      />;
      const myPdfBlob = await pdf(element).toBlob();

      var formData = new FormData();
      formData.append("file", myPdfBlob, `Agreement_form_${assigned_to}${month}${time}.pdf`);
      formData.append("email", user_email);


      await sendMail(formData);
    } catch (error) {
      console.error('Error in function email:', error);
    }
  };

  const sendMail = async (formData) => {
    try {
      await fetch('https://hr.geospectrum.com.ph/api/api/email/inventoryEmailPDF', {
        method: 'POST',
        body: formData,
      });
    } catch (err) {
      console.error('Error sending mail:', err);
    }
  };

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "The item/equipment is updated.",
    });
    handleCloseEdit();
    handleClose();
  };

  // Delete Item
  const handleDeleleItem = (uqid) => {
    handleClose();
    handleCloseEdit();
    Swal.fire({
      title: "Delete Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!"
    }).then((result) => {
      if (result.isConfirmed) {

        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/inventory/delete" + uqid,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        Swal.fire({
          title: "Deleted!",
          text: "Item has been deleted.",
          icon: "success"
        });
      }
    });
  };

  const handleStatusChange = (e) => {
    const value = e.target.value;
    // Validate that the selected value is one of the available options
    if (["Issued", "Available", "Maintenance", "Condemned"].includes(value)) {
      setItem_status(value);
    } else {
      // Handle invalid selection if needed
      console.error(`Invalid item status: ${value}`);
    }
  };
  return (
    <>
      {loading ? (
        // Display Skeleton components while loading
        Array(18).fill().map((_, index) => (
          <Grid item key={index} xs={6} sm={6} md={4} lg={3} xl={2}>
            <Card sx={{ height: { xs: 110, sm: 240, md: 150, lg: '20vh', xl: '15vh' }, display: "flex", backgroundColor: "#f5f5f5" }}>
              <CardActionArea>
                <CardContent sx={{ flex: "1  0 auto" }}>
                  <Typography component="div" variant="h5">
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    <Skeleton variant="text" />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      ) : (
        <>
          {employee &&
            employee.map((emp, index) => (
              <Grid item key={index} xs={6} sm={6} md={4} lg={3} xl={2} >
                <CardActionArea>
                  <Card
                    sx={{
                      height: { xs: 110, sm: 240, md: 150, lg: '20vh', xl: '15vh' },
                      display: "flex",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Box
                      onClick={() => handleOpen(emp)}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                          {emp.firstname + " " + emp.lastname}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {emp.position}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
        </>
      )}

      {/* Modal View Employee Items */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid container direction="row">
            <Grid item xs="8">
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                {info.firstname + " " + info.lastname}
              </Typography>
              <Typography sx={{ fontStyle: "italic" }}>
                {" "}
                {info.department}
              </Typography>
              <Typography sx={{ paddingBottom: "20px", fontStyle: "italic" }}>
                {" "}
                {info.position}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Item Description
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Issue Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {info.items &&
                      info.items.map((itms, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {itms.item_name}
                          </TableCell>
                          <TableCell>{itms.item_description}</TableCell>
                          <TableCell>{itms.issue_date}</TableCell>
                          <TableCell>
                            <Tooltip
                              title="Edit"
                              sx={{
                                color: "#0F1140",
                              }}
                            >
                              <EditIcon
                                fontSize="small"
                                onClick={() => {
                                  handleOpenEdit(itms.inventory_uniqueId);
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal Edit Employee Item */}
      <Modal open={openEdit} onClose={handleCloseEdit}>
        <Box sx={styleAssign}>
          {viewOneInventory &&
            viewOneInventory.map((item, indexx) => (
              <Grid container item direction="row" spacing={2}>
                <Grid item xs="12">
                  <h2> Update Equipment/Item Information</h2>
                </Grid>
                <Grid item xs="2">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    value={quantity}
                    required
                    label="Quantity"
                    fullWidth
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Grid>
                <Grid item xs="4">
                  <TextField
                    value={item_name}
                    required
                    label="Item/Equipment"
                    fullWidth
                    onChange={(e) => setItem_name(e.target.value)}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    value={issue_date}
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Issue Date"
                    fullWidth
                    onChange={(e) => setIssue_date(e.target.value)}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    value={return_date}
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Return Date"
                    fullWidth
                    onChange={(e) => setReturn_date(e.target.value)}
                  />
                </Grid>
                <Grid item xs="9">
                  <TextField
                    value={serial_no}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Serial No."
                    fullWidth
                    onChange={(e) => setSerial_no(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    label="Office"
                    onChange={(e) => setOffice(e.target.value)}
                    value={office}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                  >
                    <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                    <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs="12">
                  <TextField
                    value={item_description}
                    multiline
                    rows={3}
                    required
                    label="Item/Equipment Description"
                    fullWidth
                    onChange={(e) => setItem_description(e.target.value)}
                  />
                </Grid>
                {item.user.map((itms, index) => (
                  <Grid key={index} item xs="4">
                    <TextField
                      disabled
                      value={itms.firstname + " " + itms.lastname}
                      required
                      label="Assigned to"
                      rows={3}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs="1" sx={{ textAlign: "center", margin: "auto" }}>
                  <TrendingFlatIcon />
                </Grid>
                <Grid item xs="4">
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setAssigned_toNext(newValue ? newValue.user_id : null);
                      setUser_email(newValue ? newValue.email : null);
                      setFName(newValue ? newValue.firstname : null);
                      setLName(newValue ? newValue.lastname : null);
                      setDepartment(newValue ? newValue.department : null);
                      setDesignation(newValue ? newValue.position : null)
                    }}
                    options={employee}
                    getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                    isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reassign to"
                        fullWidth
                        required
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.user_id} value={option}>
                        {`${option.firstname} ${option.lastname}`}
                      </MenuItem>
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    required
                    select
                    label="Item Status"
                    value={item_status}
                    onChange={handleStatusChange}
                    fullWidth
                  >
                    <MenuItem value={"Issued"}>Issued</MenuItem>
                    <MenuItem value={"Available"}>Available</MenuItem>
                    <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                    <MenuItem value={"Condemned"}>Condemned</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs="12">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={notes}
                    multifield
                    label="Notes"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item xs="12" sx={{ textAlign: "right" }}>
                  <Button
                    sx={{ marginRight: "10px" }}
                    color="error"
                    variant="contained"
                    onClick={() => handleDeleleItem(item.inventory_uniqueId)}
                  >
                    delete
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => handleUpdateItem(item.inventory_uniqueId)}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Modal>
    </>
  );
}
