import React, { useState, useEffect } from "react";
import {
  Hidden
} from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import axios from "axios";
import Swal from "sweetalert2";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AnnoImage from "../../../images/announcement.jpg";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AvatarGroup from "@mui/material/AvatarGroup";
import moment from "moment/moment";
import ChecklistIcon from '@mui/icons-material/Checklist';
import AvatarEditor from "react-avatar-editor";
import Slider from "@mui/material/Slider";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { useNavigate } from 'react-router-dom';


import './UserDashboard.css';

export default function UserDashboard() {
  // Alert Message
  const alert = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Information has been updated",
    });
  };
  const { user } = useAuthContext();
  const [isDisabled, setIsDisabled] = useState(true);
  const [userData, setUserData] = useState([]);

  const [photo_path, setPhoto_path] = useState()

  // User Information
  const [mobile, setMobile] = useState();
  const [civil_status, setCivil_status] = useState();
  const [barangay, setBarangay] = useState();
  const [province, setProvince] = useState();
  const [zip_code, setZip_code] = useState();
  const [city, setCity] = useState();
  const [sss_id, setSss_id] = useState();
  const [pagibig_id, setPagibig_id] = useState();
  const [philhealth_id, setPhilhealth_id] = useState();
  const [tin_id, setTin_id] = useState();
  const [bank_name, setBank_name] = useState();
  const [bank_account_name, setBank_account_name] = useState();
  const [bank_account_number, setBank_account_number] = useState();
  const [father_name, setFather_name] = useState();
  const [father_occupation, setFather_occupation] = useState();
  const [mother_name, setMother_name] = useState();
  const [mother_occupation, setMother_occupation] = useState();
  const [spouse_name, setSpouse_name] = useState();
  const [user_id, setUser_id] = useState();
  const [parent_address, setParent_address] = useState();
  const [emergency_name, setEmergency_name] = useState();
  const [emergency_contact, setEmergency_contact] = useState();
  const [announcement, setAnnouncement] = useState(null);
  const [announcementDetails, setAnnouncementDetails] = useState("");
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  // Change profile

  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setPhoto_path(selectedFile);
        setScale(1); // Reset scale when a new image is selected
      } else {
        // Handle invalid file type (display an error, etc.)
        console.error("Invalid file type. Please select a JPG or PNG file.");
      }
    }
  };


  // Upload Profile
  const handleUpload = async (e) => {
    const alerterror = () => {
      Swal.fire({
        icon: "error",
        title: "Please select image",

      });
      handleCloseProfile()
    };

    if (photo_path === null) {
      alerterror()
    } else {
      if (editor) {
        const canvasScaled = editor.getImageScaledToCanvas();
        const blob = await new Promise((resolve) => canvasScaled.toBlob(resolve));

        const formData = new FormData();
        formData.append("image", blob);
        axios
          .post("https://hr.geospectrum.com.ph/api/api/user/uploadFile" + user.email, formData)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
      setPhoto_path(null)
      handleCloseProfile()
      alert()
    }
  };
  // Fetch Current User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
        setMobile(data.mobile);
        setCivil_status(data.civil_status);
        setBarangay(data.barangay);
        setProvince(data.province);
        setZip_code(data.zip_code);
        setCity(data.city);
        setSss_id(data.sss_id);
        setPagibig_id(data.pagibig_id);
        setPhilhealth_id(data.philhealth_id);
        setTin_id(data.tin_id);
        setBank_name(data.bank_name);
        setBank_account_name(data.bank_account_name);
        setBank_account_number(data.bank_account_number);
        setFather_name(data.father_name);
        setFather_occupation(data.father_occupation);
        setMother_name(data.mother_name);
        setMother_occupation(data.mother_occupation);
        setSpouse_name(data.spouse_name);
        setParent_address(data.parent_address);
        setEmergency_name(data.emergency_name);
        setEmergency_contact(data.emergency_contact);
        setUser_id(data.user_id)
      });
  }, [user.email, photo_path]);


  const handleCancel = async (e) => {
    setPhoto_path(null)
    setOldpass('')
    setNewpass('')
    setError(null)
    handleCloseProfile()
  };


  // Show Announcement
  React.useEffect(() => {
    if (userData.account_role === "user" || userData.account_role === "probationary") {
      fetch("https://hr.geospectrum.com.ph/api/api/announcement/selectAll", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
          setAnnouncement(data);
        });
    } else if (userData.account_role === "digitizer") {
      fetch("https://hr.geospectrum.com.ph/api/api/announcement/selectDigitizer", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
          setAnnouncement(data);
        });
    }
  }, [userData]);

  // Modal View Announcement
  const [openAnno, setOpenAnno] = React.useState(false);
  const handleOpen = (idx) => {
    setOpenAnno(true);
    fetch("https://hr.geospectrum.com.ph/api/api/announcement/annoData" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setAnnouncementDetails(data);
      });
  };

  // Modal Profile Picture
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenProfile = () => {
    setOpenProfile(true);
  };
  const handleCloseProfile = () => setOpenProfile(false);

  // Modal View Inventorys
  const [inventory, setInventory] = React.useState(false);
  const [openInvent, setOpenInvent] = React.useState(false);
  const handleOpenInvent = () => {
    setOpenInvent(true);
    fetch("https://hr.geospectrum.com.ph/api/api/inventory/viewOwn" + userData.user_id, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setInventory(data);
      });
  };

  // Modal View Leave
  const [openLeave, setOpenLeave] = React.useState(false);
  const handleOpenLeave = () => {
    setOpenLeave(true);
  };

  // Update User Data
  const update = (e) => {
    e.preventDefault();
    axios.put("https://hr.geospectrum.com.ph/api/api/user/update" + user.email, {
      mobile,
      civil_status,
      barangay,
      province,
      city,
      sss_id,
      zip_code,
      pagibig_id,
      philhealth_id,
      tin_id,
      bank_name,
      bank_account_name,
      bank_account_number,
      father_name,
      father_occupation,
      mother_name,
      mother_occupation,
      spouse_name,
      parent_address,
      emergency_name,
      emergency_contact,
      user_id,
    });
    setIsDisabled(!isDisabled);
    alert();
  };

  // Count
  const [countLeave, setCountLeave] = React.useState("");
  const [countCashAdv, setCountCashAdv] = React.useState("");
  React.useEffect(() => {
    fetch(
      "https://hr.geospectrum.com.ph/api/api/leaveRequest/countPendingLeave" + user.email
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setCountLeave(data);
      });
    fetch(
      "https://hr.geospectrum.com.ph/api/api/cashAdvance/countPendingCashAdv" + user.email
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setCountCashAdv(data);
      });

  }, []);

  const cancel = (e) => {
    setMobile(userData.mobile);
    setCivil_status(userData.civil_status);
    setBarangay(userData.barangay);
    setProvince(userData.province);
    setZip_code(userData.zip_code);
    setCity(userData.city);
    setSss_id(userData.sss_id);
    setPagibig_id(userData.pagibig_id);
    setPhilhealth_id(userData.philhealth_id);
    setTin_id(userData.tin_id);
    setBank_name(userData.bank_name);
    setBank_account_name(userData.bank_account_name);
    setBank_account_number(userData.bank_account_number);
    setFather_name(userData.father_name);
    setFather_occupation(userData.father_occupation);
    setMother_name(userData.mother_name);
    setMother_occupation(userData.mother_occupation);
    setSpouse_name(userData.spouse_name);
    setParent_address(userData.parent_address);
    setEmergency_name(userData.emergency_name);
    setEmergency_contact(userData.emergency_contact);
    setIsDisabled(!isDisabled);
  }


  const handleMobileChange = (e) => {
    // Filter out non-numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setMobile(numericValue);
  };

  const handleSssNumChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9-]/g, '');
    const formattedValue = sanitizedValue.slice(0, 12);
    const formattedSssNum = formattedValue.replace(/(\d{2})(\d{7})(\d{1})/, '$1-$2-$3');
    setSss_id(formattedSssNum);
  };

  const handlePagibigNumChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9-]/g, '');
    setPagibig_id(filteredValue);
  };

  const handlePhilhealthNumChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    for (let i = 0; i < filteredValue.length; i++) {
      if (i === 2 || i === 11) {
        formattedValue += '-';
      }
      formattedValue += filteredValue[i];
    }
    setPhilhealth_id(formattedValue);
  };
  const handleTinNumChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    for (let i = 0; i < filteredValue.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedValue += '-';
      }
      formattedValue += filteredValue[i];
    }
    setTin_id(formattedValue);
  };

  const [error, setError] = useState(null);
  const alertChangepass = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Your password has been changed successfully",
    });
    handleCloseProfile();
    setOldpass('')
    setNewpass('')
    setError(null)
  };
  const changepassword = () => {
    const sendMailSuccess = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: userData.email,
        message: "<p>Your password has been successfully changed!</p> <p>If you did not initiate this password change or have any concerns about your account's security, please get in touch with our IT team or administrator immediately. Your account security is of utmost importance to us, and we want to ensure that your information is always protected.</p> <p>You can reach out to our IT support team at it.support@geospectrum.com.ph or contact us through Slack. Please provide details about the situation so that we can assist you promptly. Thank you.</p > ",
        subject: 'Password Recovery'
      }).then().catch((err) => console.error(err))
    }

    axios.post("https://hr.geospectrum.com.ph/api/api/user/changepassword", {
      oldpass,
      newpass,
      email: userData.email,
    })
      .then((res) => {
        // console.log(res.data);
        //success
        alertChangepass();
        sendMailSuccess();
      })
      .catch((err) => {
        // console.error(err);
        if (err.response) {
          // console.log(err.response.data);
          setError({ message: err.response.data.message });
        } else {
          // console.error("Error:", err.message);
          setError({ message: "An unexpected error occurred." });
        }
      });
  };

  const currentDate = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const monthName = monthNames[currentDate.getMonth()];

  React.useEffect(() => {
    window.onclick = function(event){
      console.log(event.target.className)
      if (event.target.className === 'modal') {
        setOpenAnno(false)
        setOpenInvent(false)
        setOpenProfile(false)
        setOpenLeave(false)
      }
    }
  })

  const navigate = useNavigate();

  return (
    <>
      {/* View Announcement Modal */}
      <div id={openAnno ? 'modal-box' : 'modal-box-hidden'} className="modal">
        <div id='modal-container'>
          <div id='ann-modal-content'>
            <img id='ann-modal-image' src={AnnoImage} alt='announcements'/>
            
            <h2 style={{marginBottom: 0}}>{announcementDetails[0]?.title || 'No Title'}</h2>
            <b id='user-ann-text'>
              {announcementDetails[0]?.created[0]?.firstname && announcementDetails[0]?.created[0]?.lastname
                ? `${announcementDetails[0]?.created[0]?.firstname} ${announcementDetails[0]?.created[0]?.lastname}`
                : 'Unknown'}
            </b>
            <b id='user-ann-text'>
              {announcementDetails[0]?.createdAt
                ? moment(announcementDetails[0]?.createdAt).format('LLL')
                : 'Unknown Date'}
            </b>

            <p>
              {announcementDetails[0]?.content || 'No Content'}
            </p>
          </div>
        </div>
      </div>

      {/* View Equipment Inventory */}
      <div id={openInvent ? 'modal-box' : 'modal-box-hidden'} className="modal">
        <div id='modal-container'>
          <table id='modal-inv-table'>
            <tr>
              <th>Item Name</th>
              <th>Item Description</th>
              <th>Issue Date</th>
              <th>Notes</th>
            </tr>
            {inventory && 
              inventory.map((invt, index) => (
              <tr>
                <td>{invt.item_name}</td>
                <td>{invt.item_description}</td>
                <td>{invt.issue_date}</td>
                <td>{invt.item_notes}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      {/* Modal in updating profile picture/password */}
      <div id={openProfile ? 'modal-box' : 'modal-box-hidden'} className='modal'>
        <div id='modal-container'>
          <div id='modal-top'>
            <div id='modal-image'>
              <Hidden lgDown>
                <AvatarEditor
                  ref={(editor) => setEditor(editor)}
                  image={photo_path}
                  width={0.09375 * window.innerWidth}
                  height={0.1759 * window.innerHeight}
                  border={1}
                  scale={scale}
                />
              </Hidden>
              <Hidden lgUp>
                <AvatarEditor
                  ref={(editor) => setEditor(editor)}
                  image={photo_path}
                  width={150}
                  height={175}
                  border={1}
                  scale={scale}
                />
              </Hidden>
              <br/>
              <label id='modal-top-button'>
                <input type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} />
                CHOOSE FILE
              </label>
              <button id='modal-top-button' onClick={handleUpload}>
                UPLOAD PROFILE
              </button>
              <div id='modal-slider'>
                <Slider
                  value={scale}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, value) => setScale(value)}
                />
              </div>
            </div>
            <div id='modal-password'>
              <h5 id='modal-password-label'>Change password</h5>
              <input id='modal-password-text' type="password" placeholder="Old password"
                onChange={(e) => setOldpass(e.target.value)}/>
              <input id='modal-password-text' type="password" placeholder="New password"
                onChange={(e) => setNewpass(e.target.value)}/>
              <br/>
              {error && <p id='modal-password-error'>{error.message}</p>}

              <button id='modal-top-button' onClick={changepassword}>
                SUBMIT
              </button>
              <button id='modal-top-cancel' onClick={handleCancel}>
                CANCEL
              </button>

            </div>
          </div>
        </div>
      </div>

      {/* Modal Open Remaining Leave */}
      <div id={openLeave ? 'modal-box' : 'modal-box-hidden'} className="modal">
        <div id='modal-container'>
          {userData.vacationLeave <= 0 && monthName === "December" ? (
            <p style={{ color: "#F1463F", textAlign: "left" }}>
              Please be reminded that your <b>vacation leave credit for this year has been fully utilized.</b> As a result, you won't be able to apply for any further vacation leave for the remainder of this year. Please note that your leave credit will reset at the beginning of next year, allowing you to plan your time off accordingly.
            </p>
          ) : userData.vacationLeave === 0 && userData.account_role === "digitizer" ? (
            <p style={{ color: "#1F9AEE", textAlign: "left" }}>REMINDER: You do not have any permitted leave credits available. Therefore, you can not submit any further vacation leave requests at this time. </p>
          ) : userData.vacationLeave <= 0 && userData.account_role === "user" ? (
            <p style={{ color: "#F1463F", textAlign: "left" }}> Please be reminded that your <b>vacation leave credit for this year has been fully utilized.</b> As a result, you won't be able to apply for any further vacation leave for the remainder of this year. Please note that your leave credit will reset at the beginning of next year, allowing you to plan your time off accordingly. </p>
          ) : (
            <p></p>
          )}
          <table id='modal-inv-table-leave'>
            <tr>
              <th style={{textAlign: 'left'}}>Leave Type</th>
              <th>Remaining Leave (day/s)</th>
            </tr>
            {userData.account_role === "digitizer" ? (
            <tr style={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td>Sick Leave/Vacation Leave</td>
              <td style={{ textAlign: "center" }}>{userData.vacationLeave >= 0 ? userData.vacationLeave : 0}</td>
            </tr>
            ) : (
            <>
              <tr style={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td>Vacation Leave</td>
                <td style={{ textAlign: "center" }}>{userData.vacationLeave}</td>
                <td style={{ textAlign: "center" }}>{15 - userData.vacationLeave}</td>
              </tr>
              <tr style={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td>Sick Leave</td>
                <td style={{ textAlign: "center" }}>{userData.sickLeave}</td>
                <td style={{ textAlign: "center" }}>{15 - userData.sickLeave}</td>
              </tr>
              <tr style={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td>Maternity Leave</td>
                <td style={{ textAlign: "center" }}>{userData.maternityLeave}</td>
                <td style={{ textAlign: "center" }}>{90 - userData.maternityLeave}</td>
              </tr>
              <tr style={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td>Paternity Leave</td>
                <td style={{ textAlign: "center" }}>{userData.paternityLeave}</td>
                <td style={{ textAlign: "center" }}>{60 - userData.paternityLeave} </td>
              </tr>
            </>
            )}
          </table>
          <br/>
          <i style={{color: 'gray'}}>Note: Please be aware that the vacation leave encompasses both emergency and personal leave.</i>
        </div>
      </div>

      {user && (
        <div className='user-information-dashboard'>
          <div className='user-dashboard-details'>
            <AvatarEditor
              image={`https://hr.geospectrum.com.ph/api/profile/` + userData.photo_path}
              width={160}
              height={170}
              border={1}
              borderRadius={5}
              color={[255, 255, 255, 0.6]} // RGBA
            />
            <h4 style={{marginTop: '2vh', marginBottom: '3vh'}}>{userData.firstname + " " + userData.lastname}</h4>
            <p style={{marginTop: -15, marginBottom: '0.25vh'}}>{userData.position}</p>
            <p style={{marginTop: 0, marginBottom: '0.25vh'}}><i>{userData.department}</i></p>
            <p style={{marginTop: 0, marginBottom: '1.5vh'}}>{userData.date_employed + " | " + userData.user_id}</p>
            <button id='edit-profile' onClick={() => handleOpenProfile()}>
              UPDATE PROFILE
            </button>
            <br/>
            <Hidden lgUp>
              <div className="user-details-header">
                <div className="user-header-pending">
                  <div className="user-header-box" onClick={()=> navigate('/user/leaverequest')}>
                    <h1>{countLeave}</h1>
                    <h6>Pending Leave Request</h6>
                  </div>
                
                  <div className="user-header-box" onClick={()=> navigate('/user/cashadvance')}>
                    <h1>{countCashAdv}</h1>
                    <h6>Pending Cash Advance</h6>
                  </div>
                </div>
                <div className="user-header-button">
                  <button className="user-button-box" onClick={handleOpenLeave}>
                    <ReceiptLongIcon sx={{ marginRight: 3 }} />
                    <h6>Remaining Leaves</h6>
                  </button>
                  <button className="user-button-box" onClick={handleOpenInvent}>
                    <ChecklistIcon sx={{ marginRight: 3 }} />
                    <h6>Equipment Inventory</h6>
                  </button>
                </div>
              </div>
              
            </Hidden>
            <table className='user-announcements-table'>
              <tr>
                <th>ANNOUNCEMENTS</th>
              </tr>
              <div className='user-table-container'>
              {announcement && 
                announcement.map((anno, index) => (
                  <tr className='user-announcement-listitem' 
                  onClick={() => handleOpen(anno.announcement_uniqueId)}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <AnnouncementIcon
                      fontSize="small"
                      sx={{ color: "#ff6060" }}
                    />
                    {anno.title}
                  </tr>
                ))}
              </div>
            </table>
            
          </div>
          <div className="user-details-edit">
            <Hidden lgDown>
              <div className="user-details-header">
                <div className="user-header-pending">
                  <div className="user-header-box" onClick={()=> navigate('/user/leaverequest')}>
                    <h1>{countLeave}</h1>
                    <h6>Pending Leave Request</h6>
                  </div>
                
                  <div className="user-header-box" onClick={()=> navigate('/user/cashadvance')}>
                    <h1>{countCashAdv}</h1>
                    <h6>Pending Cash Advance</h6>
                  </div>
                </div>
                <div className="user-header-button">
                  <button className="user-button-box" onClick={handleOpenLeave}>
                    <ReceiptLongIcon sx={{ marginRight: 3 }} />
                    <h6>Remaining Leaves</h6>
                  </button>
                  <button className="user-button-box" onClick={handleOpenInvent}>
                    <ChecklistIcon sx={{ marginRight: 3 }} />
                    <h6>Equipment Inventory</h6>
                  </button>
                </div>
              </div>
              
            </Hidden>
            <div className="user-details-information">
              <div className="user-information-header">
                <div className="user-infoheader-text">
                  <h1>Employee Information</h1>
                  <p>Note: You may not be able to edit some of your information for
                  validation purposes.</p>
                </div>
                <div className="user-infoheader-button">
                  <button id='modal-information-button' disabled={!isDisabled} onClick={() => setIsDisabled(!isDisabled)}>
                    EDIT
                  </button>
                  <button id='modal-information-button' disabled={isDisabled} onClick={update}>
                    UPDATE
                  </button>
                  {isDisabled ? <></>:
                  <button id='modal-information-cancel' onClick={() => cancel()}>
                    CANCEL
                  </button>
                  }
                </div>
              </div>
              <div className="user-information-textbox">
                <div id='modal-information-entries'>
                  <label for='input'>
                    <span>First Name</span>
                    <input type='text' label="First Name" disabled
                      value={userData.firstname}/>
                  </label>
                  <label for='input'>
                    <span>Middle Name</span>
                    <input type='text' label="Middle Name" disabled
                      value={userData.middlename}/>
                  </label>
                  <label for='input'>
                    <span>Last Name</span>
                    <input type='text' label="Last Name" disabled
                      value={userData.lastname}/>
                  </label>
                  <label for='input'>
                    <span>Mobile Number</span>
                    <input type='text' label="Mobile Number" disabled={isDisabled}
                      value={mobile} onChange={(e) => handleMobileChange(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Gender</span>
                    <input type='text' label="Gender" disabled
                      value={userData.gender}/>
                  </label>
                  <label for='input'>
                    <span>Civil Status</span>
                    <select label="Civil Status" disabled={isDisabled}
                      value={civil_status || ''} onChange={(e) => setCivil_status(e.target.value)}>
                      <option name="Single">Single</option>
                      <option name="Single">Married</option>
                      <option name="Single">Divorced</option>
                      <option name="Single">Widowed</option>
                    </select>
                  </label>
                  <label for='input'>
                    <span>Birthdate</span>
                    <input type='text' label="Birthdate" disabled
                      value={moment(userData.birth_date).format("LL")}/>
                  </label>
                  <label for='input'>
                    <span>Nationality</span>
                    <input type='text' label="Nationality" disabled
                      value={userData.nationality}/>
                  </label>
                  <label for='input'>
                    <span>Barangay</span>
                    <input type='text' label="Barangay" disabled={isDisabled}
                      value={barangay} onChange={(e) => setBarangay(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>City/Municipality</span>
                    <input type='text' label="City/Municipality" disabled={isDisabled}
                      value={city} onChange={(e) => setCity(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Province</span>
                    <input type='text' label="Province" disabled={isDisabled}
                      value={province} onChange={(e) => setProvince(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Zip Code</span>
                    <input type='text' label="Province" disabled={isDisabled}
                      value={zip_code} onChange={(e) => setZip_code(e.target.value)}/>
                  </label>
                </div>
                <h2>Bank and Government Details</h2>
                <div id='modal-information-entries'>
                  <label for='input'>
                    <span>SSS Number</span>
                    <input type='text' label="SSS Number" disabled={isDisabled}
                      value={sss_id} onChange={handleSssNumChange}/>
                  </label>
                  <label for='input'>
                    <span>PAG-IBIG Number</span>
                    <input type='text' label="PagIBIG Number" disabled={isDisabled}
                      value={pagibig_id} onChange={handlePagibigNumChange}/>
                  </label>
                  <label for='input'>
                    <span>PhilHealth Number</span>
                    <input type='text' label="PhilHealth Number" disabled={isDisabled}
                      value={philhealth_id} onChange={handlePhilhealthNumChange}/>
                  </label>
                  <label for='input'>
                    <span>TIN Number</span>
                    <input type='text' label="TIN Number" disabled={isDisabled}
                      value={tin_id} onChange={handleTinNumChange}/>
                  </label>
                  <label for='input'>
                    <span>Bank Name</span>
                    <input type='text' label="Bank Name" disabled={isDisabled}
                      value={bank_name} onChange={(e) => setBank_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Bank Account Name</span>
                    <input type='text' label="Bank Account Name" disabled={isDisabled}
                      value={bank_account_name} onChange={(e) => setBank_account_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Bank Account Number</span>
                    <input type='text' label="Bank Account Number" disabled={isDisabled}
                      value={bank_account_number} onChange={(e) => setBank_account_number(e.target.value)}/>
                  </label>
                </div>
                <h2>Other Information</h2>
                <div id='modal-information-entries'>
                  <label for='input'>
                    <span>Father's Name</span>
                    <input type='text' label="Father's Name" disabled={isDisabled}
                      value={father_name} onChange={(e) => setFather_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Father's Occupation</span>
                    <input type='text' label="Father's Occupation" disabled={isDisabled}
                      value={father_occupation} onChange={(e) => setFather_occupation(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Mother's Name</span>
                    <input type='text' label="Mother's Name" disabled={isDisabled}
                      value={mother_name} onChange={(e) => setMother_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Mother's Occupation</span>
                    <input type='text' label="Mother's Occupation" disabled={isDisabled}
                      value={mother_occupation} onChange={(e) => setMother_occupation(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Spouse's Name</span>
                    <input type='text' label="Spouse's Name" disabled={isDisabled}
                      value={spouse_name} onChange={(e) => setSpouse_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Parent's Address</span>
                    <input type='text' label="Parent's Address" disabled={isDisabled}
                      value={parent_address} onChange={(e) => setParent_address(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Contact Person</span>
                    <input type='text' label="Contact Person" disabled={isDisabled}
                      value={emergency_name} onChange={(e) => setEmergency_name(e.target.value)}/>
                  </label>
                  <label for='input'>
                    <span>Contact Person's Phone Number</span>
                    <input type='text' label="Contact Person's Phone Number" disabled={isDisabled}
                      value={emergency_contact} onChange={(e) => setEmergency_contact(e.target.value)}/>
                  </label>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      )}
    </>
  );
}
