import {
  Grid,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  MenuItem,
  Box,
  Autocomplete,
  Modal,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Stack,
  Chip,
  Skeleton,
  Checkbox
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import SearchIcon from "@mui/icons-material/Search";
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import AggreementForm from '../../documents/inventoryAgreement.js'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const InventoryTableContainer = ({ dataFromParent }) => {
  const { user } = useAuthContext();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // When dataFromParent changes, update storedData
    setEmployee(dataFromParent);
  }, [dataFromParent]);

  // Retrieve All Inventory Item
  const [items, setItems] = useState(null);
  const [trigger, setTrigger] = useState(false);

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/inventory/getAllInventory", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      });
  }, [trigger]);


  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = items
    ? items.filter((item) => {
      const itemName =
        item.item_name +
        item.item_description +
        item.item_status +
        item.office +
        (item.user?.[0]?.lastname || "") +
        (item.user?.[0]?.firstname || "") +
        (item.user?.[0]?.department || "") || "";

      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Modal View Specific Request
  const [viewOneInventory, setViewOneInventory] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (idx) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/inventory/viewOneInventory" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setViewOneInventory(data);
        {
          data &&
            data.map(
              (itm, index) => (
                setItem_name(itm.item_name),
                setItem_description(itm.item_description),
                setAssigned_to(itm.assigned_to),
                setNotes(itm.notes),
                setIssue_date(itm.issue_date),
                setReturn_date(itm.return_date),
                setItem_status(itm.item_status),
                setSerial_no(itm.serial_no),
                setQuantity(itm.quantity),
                setDate_acquire(itm.date_acquire),
                setOffice(itm.office)
              )
            );
        }
      });
  };
  const handleClose = () => {
    setOpen(false)
    setAssigned_toNext('')

  };

  // Reassign/Update Item
  const [item_name, setItem_name] = useState("");
  const [item_description, setItem_description] = useState("");
  const [assigned_to, setAssigned_to] = useState();
  const [assigned_toNext, setAssigned_toNext] = useState("");
  const [notes, setNotes] = useState();
  const [issue_date, setIssue_date] = useState("");
  const [date_acquire, setDate_acquire] = useState("");
  const [return_date, setReturn_date] = useState("");
  const [office, setOffice] = useState("");
  const [item_status, setItem_status] = useState("");
  const [serial_no, setSerial_no] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [isSigned, setIsSigned] = useState();
  // Form
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [user_email, setUser_email] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleUpdateItem = (unq) => {
    setButtonDisabled(true)
    const alertReasssign = () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The item/equipment is updated and reassigned.",
      });
      handleClose();
    };

    if (assigned_toNext === '') {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
        {
          item_name,
          item_description,
          notes,
          issue_date,
          item_status,
          return_date,
          serial_no,
          quantity,
          date_acquire,
          office
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ).then(() => {
        // This code will execute after the axios request is completed
        setTrigger(!trigger);
      })
      alertSuccess();
    } else {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
        {
          item_name,
          assigned_to: assigned_toNext,
          item_description,
          notes,
          issue_date,
          item_status,
          return_date,
          serial_no,
          quantity,
          date_acquire
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ).then(() => {
        // This code will execute after the axios request is completed
        setTrigger(!trigger);
      })
      CreatePDF()
      alertReasssign();
    }
    setTimeout(() => {
      setButtonDisabled(false);
    }, 2000);
    setAssigned_toNext('')
  };

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const time = currentDate.getTime();
  const CreatePDF = async () => {
    try {
      const element = <AggreementForm
        issueDate={moment(issue_date).format("LL")}
        returnDate={moment(return_date).format("LL")}
        name={FName + " " + LName}
        department={department}
        designation={designation}
        id={assigned_to}
        equipment={item_name}
        serial={serial_no}
        description={item_description}
        remarks={notes}
      />;
      const myPdfBlob = await pdf(element).toBlob();

      var formData = new FormData();
      formData.append("file", myPdfBlob, `Agreement_form_${assigned_to}${month}${time}.pdf`);
      formData.append("email", user_email);


      await sendMail(formData);
    } catch (error) {
      console.error('Error in function email:', error);
    }
  };

  const sendMail = async (formData) => {
    try {
      await fetch('https://hr.geospectrum.com.ph/api/api/email/inventoryEmailPDF', {
        method: 'POST',
        body: formData,
      });
    } catch (err) {
      console.error('Error sending mail:', err);
    }
  };

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "The item/equipment is updated.",
    });
    handleClose();
  };

  // Delete Item
  const handleDeleleItem = (uqid) => {
    handleClose();
    Swal.fire({
      title: "Delete Item?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!"
    }).then((result) => {
      if (result.isConfirmed) {

        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/inventory/delete" + uqid,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        ).then(() => {
          // This code will execute after the axios request is completed
          setTrigger(!trigger);

          Swal.fire({
            title: "Deleted!",
            text: "Item has been deleted.",
            icon: "success"
          });
        })
      }
    });
  };

  const [disableBTN, setDisableBTN] = useState(false);

  const handleStatusChange = (e) => {
    const value = e.target.value;
    // Validate that the selected value is one of the available options
    if (["Issued", "Available", "Maintenance", "Condemned"].includes(value)) {
      setItem_status(value);
      if (value === "Available"){

        setDisableBTN(true)
        setIssue_date("")
        setAssigned_toNext("-")
      }
      else{
        setDisableBTN(false)
      }
    } else {
      // Handle invalid selection if needed
      console.error(`Invalid item status: ${value}`);
    }
  };

  const handleSigned = (event, idx) => {
    Swal.fire({
      title: "Document Status Check",
      text: "Has this document been signed by all parties involved?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(
          "https://hr.geospectrum.com.ph/api/api/inventory/update" + idx,
          {
            isSigned: event,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        ).then(() => {
          // This code will execute after the axios request is completed
          setTrigger(!trigger);
          Swal.fire({
            title: "Confirmed",
            text: "Your file has been updated.",
            icon: "success"
          });
        })
      }
    });
  }

  const handleMoveItem = (idx) => {
    handleClose();
    Swal.fire({
      title: "Move to peripherals?",
      // text: "Has this document been signed by all parties involved?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(
          "https://hr.geospectrum.com.ph/api/api/inventory/update" + idx,
          {
            isPeris: true,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        ).then(() => {
          // This code will execute after the axios request is completed
          setTrigger(!trigger);
          Swal.fire({
            title: "Confirmed",
            text: "Your file has been updated.",
            icon: "success"
          });
        })
      }
    });
  }

  return (
    <>
      <Grid item >
        <TextField
          size="small"
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ paddingBottom: "20px", marginTop: "20px",  '& .MuiOutlinedInput-root': {
            borderRadius: '20px', // Set your desired border-radius here
        }, }}
        />
        <TextField
          size="small"
          label="Status"
          select
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            minWidth: 110, ml: 1, mt: 2.5, '& .MuiOutlinedInput-root': {
              borderRadius: '20px', // Set your desired border-radius here
            },
          }}
        >
          <MenuItem value={""}>
            <Stack>
              <Chip size="small" color="primary"
                label={"All"} />
            </Stack>
          </MenuItem>
          <MenuItem value={"Available"}>
            <Stack>
              <Chip size="small" color="default"
                label={"Available"} />
            </Stack>
          </MenuItem>
          <MenuItem value={"Issued"}>
            <Stack>
              <Chip size="small" color="success"
                label={"Issued"} />
            </Stack>
          </MenuItem>
          <MenuItem value={"Maintenance"}>
            <Stack>
              <Chip size="small" color="warning"
                label={"Maintenance"} />
            </Stack>
          </MenuItem>
          <MenuItem value={"Condemned"}>
            <Stack>
              <Chip size="small" color="error"
                label={"Condemned"} />
            </Stack>
          </MenuItem>
        </TextField>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: { xs: '', sm: '', md: '36vh', lg: '45vh', xl: '55vh' }, overflowY: 'scroll' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Signed
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Office
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Assigned To
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Date Issued
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
                {loading ? (
                  // Display Skeleton components while loading
                  Array(7).fill().map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    {filteredData.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell align="left"><Checkbox checked={item.isSigned} onChange={(e) => handleSigned(e.target.checked, item.inventory_uniqueId)} color="default" /></TableCell>
                        <TableCell align="left">{item.item_name}</TableCell>
                        <TableCell align="left">
                          {item.office !== null ? item.office : "N/A"}
                        </TableCell>

                        {item.user.length > 0 ? (
                          item.user.map((users, index) => (
                            <TableCell key={index} align="left">
                              {users.firstname + " " + users.lastname}
                            </TableCell>
                          ))
                        ) : (
                          <TableCell></TableCell>
                        )}

                        <TableCell key={index} align="left">
                        {item.issue_date ? moment(item.issue_date).format("L") : ''}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row">
                            <Chip
                              size="small"
                              color={
                                item.item_status === "Condemned"
                                  ? "error"
                                  : item.item_status === "Issued"
                                    ? "success"
                                    : item.item_status === "Maintenance"
                                      ? "warning"
                                      : "default" // or any other default color
                              }
                              label={item.item_status}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <Tooltip
                                title="View"
                                sx={{
                                  color: "#0F1140",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleOpen(item.inventory_uniqueId);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <PDFDownloadLink document={<AggreementForm
                                issueDate={moment(item.issue_date).format("LL")}
                                returnDate={moment(item.return_date).format("LL")}
                                // name={item.user[0].firstname + " " + item.user[0].lastname}
                                name={item.user && item.user[0] ? `${item.user[0].firstname} ${item.user[0].lastname}` : ''}
                                department={item.user && item.user[0] ? `${item.user[0].department}` : ''}
                                designation={item.user && item.user[0] ? `${item.user[0].position}` : ''}
                                id={item.user && item.user[0] ? `${item.user[0].user_id}` : ''}
                                equipment={item.item_name}
                                serial={item.serial_no}
                                description={item.item_description}
                                remarks={item.notes}
                              />
                              }
                                fileName={`AgreementForm_${item.item_name}`}>
                                <Tooltip
                                  title="Download"
                                  sx={{
                                    color: "#1B4B59",
                                  }}>
                                  <IconButton>
                                    <SimCardDownloadIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </PDFDownloadLink>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableHead>
              <TableBody>
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      {/* Modal Update Reassign Item */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {viewOneInventory &&
            viewOneInventory.map((item, indexx) => (
              <Grid container item direction="row" spacing={2}>
                <Grid item xs="12">
                  <h2> Update Equipment/Item Information</h2>
                </Grid>
                <Grid item xs="2">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    value={quantity}
                    required
                    label="Quantity"
                    fullWidth
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Grid>
                <Grid item xs="10">
                  <TextField
                    value={item_name}
                    required
                    label="Item/Equipment"
                    fullWidth
                    onChange={(e) => setItem_name(e.target.value)}
                  />
                </Grid>
                <Grid item xs="4">
                  <TextField
                    required
                    type="date"
                    label="Date Acquired"
                    value={date_acquire}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) => setDate_acquire(e.target.value)}
                  />
                </Grid>
                <Grid item xs="4">
                  <TextField
                    value={issue_date}
                    type="date"
                    label="Issue Date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setIssue_date(e.target.value)}
                  />
                </Grid>
                <Grid item xs="4">
                  <TextField
                    value={return_date}
                    type="date"
                    label="Return Date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setReturn_date(e.target.value)}
                  />
                </Grid>
                <Grid item xs="8">
                  <TextField
                    value={serial_no}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Serial No."
                    fullWidth
                    onChange={(e) => setSerial_no(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    fullWidth
                    label="Office"
                    onChange={(e) => setOffice(e.target.value)}
                    value={office}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                  >
                    <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                    <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs="12">
                  <TextField
                    value={item_description}
                    multiline
                    rows={3}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Item/Equipment Description"
                    fullWidth
                    onChange={(e) => setItem_description(e.target.value)}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    required
                    select
                    label="Item Status"
                    value={item_status}
                    onChange={handleStatusChange}
                    fullWidth
                  >
                    <MenuItem value={"Issued"}>Issued</MenuItem>
                    <MenuItem value={"Available"}>Available</MenuItem>
                    <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                    <MenuItem value={"Condemned"}>Condemned</MenuItem>
                  </TextField>
                </Grid>
                {item.user.map((itms, index) => (
                  <Grid key={index} item xs="4">
                    <TextField
                      disabled
                      value={itms.firstname + " " + itms.lastname}
                      required
                      label="Assigned to"
                      rows={3}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs="1" sx={{ textAlign: "center", margin: "auto" }}>
                  <TrendingFlatIcon />
                </Grid>
                <Grid item xs="4">
                  <Autocomplete
                  disabled ={disableBTN}
                    onChange={(event, newValue) => {
                      setAssigned_toNext(newValue ? newValue.user_id : null);
                      setUser_email(newValue ? newValue.email : null);
                      setFName(newValue ? newValue.firstname : null);
                      setLName(newValue ? newValue.lastname : null);
                      setDepartment(newValue ? newValue.department : null);
                      setDesignation(newValue ? newValue.position : null)
                    }}
                    options={employee}
                    getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                    isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reassign to"
                        fullWidth
                        required
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.user_id} value={option}>
                        {`${option.firstname} ${option.lastname}`}
                      </MenuItem>
                    )}
                  />
                </Grid>

                <Grid item xs="12">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={notes}
                    multifield
                    label="Notes"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item xs="12" sx={{ textAlign: "right" }}>
                  <Button
                    sx={{ marginRight: "10px" }}
                    color="warning"
                    variant="outlined"
                    onClick={() => handleMoveItem(item.inventory_uniqueId)}
                  >
                    move to peripherals
                  </Button>
                  <Button
                    sx={{ marginRight: "10px" }}
                    color="error"
                    variant="contained"
                    onClick={() => handleDeleleItem(item.inventory_uniqueId)}
                  >
                    delete
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => handleUpdateItem(item.inventory_uniqueId)}
                    disabled={buttonDisabled}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Modal>
    </>
  );
};

export default InventoryTableContainer;
