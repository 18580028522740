import React from "react";
import {
  Grid,
  Card,
  Box,
  Button,
  Modal,
  CardActionArea,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import sampleimg from "../../images/gms_logo_small.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState } from "react";

export default function Department() {
  const { user } = useAuthContext();
  const [trigger, setTrigger] = useState(false);

  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, []);

  // Create Department
  const [name, setName] = useState("");
  const [description, setDesciption] = useState("");
  const create = async () => {
    if (name.trim() === "" || description.trim() === "") {
      // Input is empty
      // console.log(user_id)
      alertError();
      return;
    }
    axios.post(
      "https://hr.geospectrum.com.ph/api/api/department/create",
      {
        name,
        description,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    ).then(response => {
      // Handle response if needed
      setTrigger(!trigger);
    }).catch(error => {
      // Handle error if needed
      console.error("Error:", error);
    });
    setTrigger(!trigger);
    alertSuccess();
    setDesciption("");
    setName("");
  };
  //

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };
  //

  // Retrieve all departments
  const [departments, setDepartments] = useState(null);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/department/getDepartment", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setDepartments(data);
      });
  }, [trigger]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{
                fontSize: "20px",
              }}
            >
              Add Department
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              Department Name:
            </Grid>
            <Grid item xs={9}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                size="small"
              ></TextField>
            </Grid>
            <Grid item xs={3}>
              Department Description:
            </Grid>
            <Grid item xs={9}>
              <TextField
                onChange={(e) => setDesciption(e.target.value)}
                value={description}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  create();
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid
          item
          sx={{
            fontSize: "60px",
          }}>
          Departments
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}>
          {userData.account_role === "superadmin" ? (
            <Button variant="contained" onClick={handleOpen}>
              Create
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "30px",
        }}
      >
        <Grid container item direction="row" xs={12} columns={15}>
          {departments &&
            departments.map((dept, index) => (
              <Grid item key={index} xs={12} sm={6} md={3} lg={3} xl={3}>
                <CardActionArea>
                  <Card
                    sx={{
                      minHeight: 365,
                      backgroundColor: "#ffffff",
                      color: "black",
                      margin: "10px",
                    }}
                  >
                    <CardContent>
                      <Grid item container direction="column">
                        <Grid item xs={12}>
                          <img src={sampleimg} height="auto" width="100%" />
                        </Grid>
                        <Grid item>
                          <h3>{dept.name}</h3>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            fontSize:{xs: "400px", sm: "600px", md: "14px", xl: "17px"},
                          }}
                        >
                          <p>{dept.description}</p>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
}
