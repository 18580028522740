import React, { useState, useEffect } from "react";
import { Grid, Card } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import BusinessIcon from "@mui/icons-material/Business";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import { useAuthContext } from "../../../hooks/useAuthContext";


import './rightSection.css';

// Pie Chart
const pieParams = { height: 200 };

export default function RightSection() {
  const { user } = useAuthContext();

  //Count Active/Inactive Accounts
  const [inactiveCount, setInactiveCount] = useState("");
  const [activeCount, setActiveCount] = useState("");
  const [count, setCount] = useState("");
  const [empCount, setEmpCount] = useState([])
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/countActive", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setActiveCount(data);
      });

    fetch("https://hr.geospectrum.com.ph/api/api/user/countInactive", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setInactiveCount(data);
      });

    fetch("https://hr.geospectrum.com.ph/api/api/user/empCount", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setCount(data);
      });
    fetch('https://hr.geospectrum.com.ph/api/api/user/countEmp')
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log(data);
        setEmpCount(data)
      })
  }, []);

  return (
    <>
      <div id='right-section-container'>
        <div id='right-section-total' className="right-section-box">
          <div id='employee-right-inner'>
            <BusinessIcon id='employee-icon'/>
            <div>
              <h6 id='employee-right-tag'>TOTAL PERSONNEL</h6>
              <h3 id='employee-right-number'>{empCount}</h3>
            </div>
          </div>
        </div>
        <div id='right-section-employee' className="right-section-box">
          <div id='employee-right-inner'>
            <PermContactCalendarIcon id='employee-icon'/>
            <div>
              <h6 id='employee-right-tag'>Employees</h6>
              <h3 id='employee-right-number'>{count.EmployeeCount}</h3>
            </div>
          </div>
        </div>
        <div id='right-section-digitizer' className="right-section-box">
          <div id='employee-right-inner'>
            <PermContactCalendarOutlinedIcon id='employee-icon'/>
            <div>
              <h6 id='employee-right-tag'>Digitizers</h6>
              <h3 id='employee-right-number'>{count.DigitizerCount}</h3>
            </div>
          </div> 
        </div>
        <div id='right-section-active' className="right-section-box2">
          <div id='employee-right-inner'>
            <PersonIcon id='employee-icon'/>
            <div>
              <h6 id='employee-right-tag'>Active Accounts</h6>
              <h3 id='employee-right-number'>{activeCount}</h3>
            </div>
          </div>
        </div>
        <div id='right-section-inactive' className="right-section-box2">
          <div id='employee-right-inner'>
            <PersonOffIcon id='employee-icon'/>
            <div>
              <h6 id='employee-right-tag'>Inactive Accounts</h6>
              <h3 id='employee-right-number'>{inactiveCount}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
