import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Modal,
  MenuItem,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  Autocomplete,
  TableContainer,
  TableHead,
  InputAdornment,
  TableRow,
  Tooltip,
  IconButton,
  Chip,
  Stack,
  Skeleton,
  Badge,
  Dialog,
  FormGroup,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import GMSlogo from "../../../images/gms_logo.png";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LoopIcon from "@mui/icons-material/Loop";
import LeavePDFFile from "../../../documents/leaveRequest";
import CancelIcon from "@mui/icons-material/Cancel";
import ArchiveIcon from "@mui/icons-material/Archive";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function UserRequest() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);
  const [supervisorName, setSupervisorName] = useState([]);
  const user_id = userData.user_id;
  const [trigger, setTrigger] = useState(false);
  // Fetch User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);

        fetch(
          "https://hr.geospectrum.com.ph/api/api/user/userData" +
          data.supervisor
        )
          .then((res) => {
            return res.json();
          })
          .then((supervisor) => {
            setSupervisorName(supervisor.firstname + " " + supervisor.lastname);
          });
      });
  }, []);

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };
  const errorLeave = () => {
    Swal.fire({
      icon: "error",
      title: "Check remaining Leave",
      text: "Request is not submitted",
    });
    setButtonDisabled(false);
    cancel();
    handleClose();
  };
  // Create Leave Request
  const [request_type, setRequest_type] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [message, setMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const [vacationLeave, setVacationLeave] = useState(userData.vacationLeave);
  // const [sickLeave, setSickLeave] = useState(userData.sickLeave);
  // const [paternityLeave, setPaternityLeave] = useState(userData.paternityLeave);
  // const [maternityLeave, setMaternityLeave] = useState(userData.maternityLeave);

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[currentDate.getMonth()];

  const precreate = async () => {
    if (buttonDisabled) {
      console.log(
        "A request is already in progress. Preventing duplicate requests."
      );
      return;
    }
    setButtonDisabled(true);
    if (
      request_type.trim() === "" ||
      start_date.trim() === "" ||
      end_date.trim() === "" ||
      message.trim() === ""
    ) {
      // Input is empty
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
      alertError();
      return;
    }

    create();
    setButtonDisabled(false);
  };

  const sendMailHR = async () => {
    const send = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: "medillojianna@geospectrum.com.ph",
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has submitted a new leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
          subject: "New Leave Request",
        })
        .then()
        .catch((err) => console.error(err));
    };
    send();
  };

  const create = async () => {
    let updatedLeaves = { ...userData };

    switch (request_type) {
      case "Emergency":
      case "Personal Leave":
        if (userData.account_role !== "digitizer" && countDays > userData.vacationLeave) {
          errorLeave();
          return;
        } else if (userData.account_role === "digitizer" && userData.vacationLeave > 0 && countDays > userData.vacationLeave) {
          errorLeave();
          return;
        }
        updatedLeaves.vacationLeave -= countDays;
        break;
      case "Sick Leave":
        if (countDays > userData.sickLeave) {
          errorLeave();
          return;
        }
        updatedLeaves.sickLeave -= countDays;
        break;
      case "Maternity":
        if (countDays > userData.maternityLeave) {
          errorLeave();
          return;
        }
        updatedLeaves.maternityLeave -= countDays;
        break;
      case "Paternity":
        if (countDays > userData.paternityLeave) {
          errorLeave();
          return;
        }
        updatedLeaves.paternityLeave -= countDays;
        break;
      default:
        break;
    }

    if (
      userData.execomm === true ||
      userData.email === "accounting@geospectrum.com.ph"
    ) {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/leaveRequest/createLeave",
        {
          user_email: user.email,
          user_id,
          request_type,
          start_date,
          end_date,
          message,
          supervisor: userData.supervisor,
          days: countDays,
          supervisor_approve: true,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      sendMailHR();
      // setTrigger(!trigger);
    } else {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/leaveRequest/createLeave",
        {
          user_email: user.email,
          user_id,
          request_type,
          start_date,
          end_date,
          message,
          supervisor: userData.supervisor,
          days: countDays,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      sendMail();
      // setTrigger(!trigger);
    }
    try {
      await axios.put(`https://hr.geospectrum.com.ph/api/api/user/update${user.email}`, updatedLeaves);
      setTrigger(!trigger);
      alertSuccess();
      setRequest_type("");
      setStart_date("");
      setEnd_date("");
      setMessage("");
    } catch (error) {
      console.error("Error updating leaves:", error);
      // Handle error appropriately
    }

    setTimeout(() => {
      setButtonDisabled(false);
    }, 3000);
  };


  const cancel = async () => {
    setRequest_type("");
    setStart_date("");
    setEnd_date("");
    setMessage("");
    handleClose();
    setCountDays(0);
  };

  // Send Mail
  const sendMail = async (userEMAIL) => {
    const send = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: userData.supervisor, //supervisor email userData.supervisor
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has submitted a new leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
          subject: "New Leave Request",
        })
        .then()
        .catch((err) => console.error(err));
    };

    send();
  };

  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRequest_type("");
    setStart_date("");
    setEnd_date("");
    setMessage("");
    setCountDays(0);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "350px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  //

  const card_style = {
    // minWidth: 255,
    width: "100%",
    minHeight: 90,
    maxHeight: 115,
    backgroundColor: "#b4b4b4",
    color: "white",
  };

  // Count Leave Day/s
  const [countDays, setCountDays] = useState(0);
  const handleStartDateChange = (e) => {
    const latestValue = e.target.value;
    setEnd_date(latestValue);
  };

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  const currentMonth = currentDate.getMonth();
  const startDateMonth = startDate.getMonth();
  const endDateMonth = endDate.getMonth();
  const isCurrentMonth =
    startDateMonth === currentMonth && endDateMonth === currentMonth;

  const calculateTotalDays = () => {
    // Check if endDate is today or in the future
    const today = new Date();
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 4);
    currentDateMinus3Days.setHours(0, 0, 0, 0);

    if (end_date !== "") {
      if (endDate >= currentDateMinus3Days) {
        let totalDays = 0;
        let currentDate = startDate;
        while (currentDate <= endDate) {
          // Check if the current day is not Saturday (6) or Sunday (0)
          if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            totalDays++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setCountDays(parseInt(totalDays));
      } else {
        alertDateError();
      }
    }
  };
  const alertDateError = () => {
    Swal.fire({
      icon: "error",
      title: "Input correct date",
      text: "Please select a date equal to or later than the current date.",
    });
    handleClose();
    handleCloseReqCancel();
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();

    // Get the current date 3 days ago
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 8); //changed to 1 week

    if (start_date !== "") {
      if (selectedDate < currentDateMinus3Days || selectedDate > start_date) {
        // If the selected date is more than 3 days ago, show an error
        alertDateError();
      } else {
        // Update the state with the selected date
        setStart_date(selectedDate.toISOString().split("T")[0]);
      }
    } else {
      // Compare the selected date with the current date minus 3 days
      if (selectedDate < currentDateMinus3Days) {
        // If the selected date is more than 3 days ago, show an error
        alertDateError();
      } else {
        // Update the state with the selected date
        setStart_date(selectedDate.toISOString().split("T")[0]);
      }
    }
  };

  const handleDateChangeEnd = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date();

    // Get the current date 3 days ago
    const currentDateMinus3Days = new Date();
    currentDateMinus3Days.setDate(currentDateMinus3Days.getDate() - 8); //change to 1 week
    // Compare the selected date with the current date
    if (selectedDate < currentDateMinus3Days) {
      // If the selected date is in the past, don't update the state
      alertDateError();
    } else {
      // Update the state with the selected date
      setEnd_date(selectedDate);
    }
  };

  // Retrieve Own Leave Request

  const [leaveRequest, setLeaveRequest] = useState(null);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    fetch(
      "https://hr.geospectrum.com.ph/api/api/leaveRequest/getleave" +
      user.email,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setLeaveRequest(data);
        setLoading(false);
      });
  }, [trigger]);

  // Polling Function to fetch data
  // useEffect(() => {
  //   const fetchData = () => {
  //     fetch(`https://hr.geospectrum.com.ph/api/api/leaveRequest/getleave${user.email}`, {
  //       headers: {
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (!res.ok) {
  //           throw new Error(`HTTP error! status: ${res.status}`);
  //         }
  //         return res.json();
  //       })
  //       .then((data) => {
  //         setLeaveRequest(data);
  //       })
  //       .catch((error) => {
  //         console.error('Fetch error:', error);
  //       });
  //   };
  //   // Start polling
  //   const intervalId = setInterval(fetchData, 15000); // Poll every  15 seconds
  //   // Clear interval on cleanup
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [user.email, user.token]);

  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = leaveRequest
    ? leaveRequest.filter((item) => {
      const itemName =
        item.request_type + moment(item.createdAt).format("LL") || ""; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // View Specific Request
  const [requestView, setRequestView] = useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = (idx) => {
    setOpen1(true);
    fetch(
      "https://hr.geospectrum.com.ph/api/api/leaveRequest/viewRequest" + idx,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setRequestView(data);
      });
  };
  const handleClose1 = () => setOpen1(false);

  const [countLeave, setCountLeave] = React.useState();
  React.useEffect(() => {
    fetch(
      "https://hr.geospectrum.com.ph/api/api/leaveRequest/countUserLeaveStat" +
      user.email
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        if (data && data.InProgressCount !== undefined) {
          setCountLeave(data);
        } else {
          console.error("Data or InProgressCount is undefined.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.email]);

  const cancelRequest = async (id, reDays, reType) => {
    let leaveVacation = userData.vacationLeave;
    let leaveSick = userData.sickLeave;
    let leaveMaternity = userData.maternityLeave;
    let leavePaternity = userData.paternityLeave;

    const send = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: userData.supervisor,
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has cancelled a leave request. You can review and manage leave requests on the portal. Thank you",
          subject: "Leave Request Cancelled",
        })
        .then()
        .catch((err) => console.error(err));
    };

    const sendHR = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: "medillojianna@geospectrum.com.ph",
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has cancelled a leave request. You can review and manage leave requests on the portal. Thank you",
          subject: "Leave Request Cancelled",
        })
        .then()
        .catch((err) => console.error(err));
    };

    Swal.fire({
      title: "Cancel leave request?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userData.execomm === true) {
          sendHR();
        } else {
          send();
          sendHR();
        }
        if (reType === "Emergency" || reType === "Personal Leave") {
          leaveVacation = userData.vacationLeave + reDays;
        } else if (reType === "Sick Leave") {
          leaveSick = userData.sickLeave + reDays;
        } else if (reType === "Maternity") {
          leaveMaternity = userData.maternityLeave + reDays;
        } else if (reType === "Paternity") {
          leavePaternity = userData.paternityLeave + reDays;
        }

        axios.put(
          "https://hr.geospectrum.com.ph/api/api/user/update" + user.email,
          {
            vacationLeave: leaveVacation,
            sickLeave: leaveSick,
            paternityLeave: leavePaternity,
            maternityLeave: leaveMaternity,
          }
        );
        axios
          .delete(
            "https://hr.geospectrum.com.ph/api/api/leaveRequest/delete" + id,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          )
          .then(() => {
            // This code will execute after the axios request is completed
            setTrigger(!trigger);
          });
        Swal.fire({
          title: "Request cancelled!",
          text: "Your request has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const archive = (idx) => {
    Swal.fire({
      title: "Archive Notification",
      text: "Are you sure you want to archive this request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            isArchiveUser: true,
          }
        );
        setTrigger(!trigger);
        Swal.fire({
          title: "Archived!",
          text: "Your file has been archived.",
          icon: "success",
        });
      }
    });
  };


  // Dialog (Cancellation Request for approved leave request)
  const [openReqCancel, setOpenReqCancel] = React.useState(false);
  const [dates, setDates] = useState([]);
  const [txtisDisabled, setTxtisDisabled] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [cancelDays, setCancelDays] = useState(0);
  const handleClickOpenReqCancel = (idx) => {
    fetch(
      `https://hr.geospectrum.com.ph/api/api/leaveRequest/viewRequest${idx}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setRequestView(data);
        const generatedDates = getDatesBetween(data.start_date, data.end_date);

        // Filter out weekends
        const filteredDates = generatedDates.filter(date => {
          const day = date.getDay();
          return day !== 0 && day !== 6; // 0 is Sunday, 6 is Saturday
        });

        setDates(filteredDates);
      })
      .catch((error) => {
        console.error('There has been a problem with fetch operation:', error);
      });
    setOpenReqCancel(true);
  };

  const handleCloseReqCancel = () => {
    setOpenReqCancel(false);
    setTxtisDisabled(false)
    setSelectedStartDate();
    setSelectedEndDate();
    setCancelReason("");
    setCancelDays(0)
  };

  function getDatesBetween(start, end) {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= new Date(end)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const handleChangeStartDate = (event, value) => {
    if (value) {
      // Keep the value as a date
      setSelectedStartDate(value);
    }
  };
  const handleChangeEndDate = (event, value) => {
    if (value) {
      setSelectedEndDate(value);
    }
  };

  // Checkbox
  const setSelectAll = (start, end, day) => {
    if (txtisDisabled === false) {
      setSelectedStartDate();
      setSelectedEndDate();
      setCancelDays()
    }
    else {
      setSelectedStartDate();
      setSelectedEndDate();
      setCancelDays(0)
    }
    setTxtisDisabled(!txtisDisabled)
    // console.log(start, end, day)
  }

  const calculateTotalCancelDays = () => {
    // Initialize total days counter
    let totalDays = 0;
    if (selectedStartDate && selectedEndDate) {
      // Ensure startDate is before endDate
      if (selectedStartDate > selectedEndDate) {
        //throw new Error("Start date should be before end date.");
        alertDateError();
      }

      // Loop through each day from start date to end date
      let currentDate = new Date(selectedStartDate);
      while (currentDate <= selectedEndDate) {
        // Check if the current day is not Saturday (6) or Sunday (0)
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          totalDays++;
        }
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    setCancelDays(totalDays); // Return the total number of weekdays
  };

  const submitError = () => {
    Swal.fire({
      icon: "error",
      title: "Missing Details",
      text: "Please complete all required details.",
    });
    handleCloseReqCancel();
  };

  const submitSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Submitted",
      text: "Your request is sent and is given for approval.",
    });
    handleCloseReqCancel();
  };


  const submitCancellation = async (idx) => {
    let stat;
    if (txtisDisabled === true) {
      stat = "Full Cancellation"
    }
    else {
      stat = "Partial Cancellation"
    }

    if (!cancelReason.trim()) {
      return submitError();
    }

    if (!txtisDisabled && cancelDays === 0) {
      return submitError();
    }
    try {
      const requestData = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        status: stat,
        isArchive: false,
        selectedEndDate: selectedEndDate,
        selectedStartDate: selectedStartDate,
        cancelDays: cancelDays,
        cancelReason: cancelReason,
        hr_approve: null,
      };

      await axios.put("https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx, requestData);

      // Actions after successful update
      sendMailHRCancel();
      handleCloseReqCancel();
      submitSuccess();
      setTrigger(prevState => !prevState);
    } catch (error) {
      console.error("Failed to update cancellation:", error);
      submitError();
    }
  };

  const sendMailHRCancel = async () => {
    const send = async () => {
      axios
        .post("https://hr.geospectrum.com.ph/api/api/email/sendMail", {
          userEmail: "medillojianna@geospectrum.com.ph",
          // userEmail: "it.support@geospectrum.com.ph",
          message:
            "This is to inform you that " +
            userData.firstname +
            " " +
            userData.lastname +
            " has submitted a cancellation of leave request. Please review the details and take appropriate action. You can review and manage leave requests on the portal. Thank you",
          subject: "Cancellation of Leave Request",
        })
        .then()
        .catch((err) => console.error(err));
    };
    send();
  };
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      {/* Dialog for leave cancellation (Approved Request) */}
      <Dialog
        open={openReqCancel}
        onClose={handleCloseReqCancel}
        maxWidth={"md"}
        fullWidth
      >
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sx={{ marginBottom: 3 }}>
              <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2em" }}>
                Request for Cancellation of Leave Request
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "#4188e0" }}>
                <i>REMINDER: To cancel your leave, please check the corresponding box. Alternatively, you can choose a different date within the initially specified range on which you have taken the leave.</i>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                <i>Leave Details:</i>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormGroup >
                <FormControlLabel control={<Checkbox onChange={() => {
                  setSelectAll(requestView.start_date, requestView.end_date, requestView.days);
                }} />} label="Cancel Leave" style={{ width: '100%' }} />
              </FormGroup>
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                value={requestView.request_type}
                label="Request Type"
                disabled
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  style: { width: '100%' },
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                value={moment(requestView.start_date).format("ll")}
                label="Start Date"
                disabled
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  style: { width: '100%' },
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={3.3}>
              <TextField
                value={moment(requestView.end_date).format("ll")}
                label="End Date"
                disabled
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  style: { width: '100%' },
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={requestView.days}
                label="Day/s"
                disabled
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  style: { width: '100%' },
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "italize" }}>
                <i>Select New Date:</i>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                onBlur={calculateTotalCancelDays}
                disabled={txtisDisabled}
                options={dates}
                value={selectedStartDate ? selectedStartDate : null}
                onChange={handleChangeStartDate}
                getOptionLabel={(option) => moment(option).format('LL')} // Format the date as a string for display
                renderInput={(params) => (
                  <TextField size="small" {...params} label="Start Date" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                onBlur={calculateTotalCancelDays}
                disabled={txtisDisabled}
                options={dates}
                value={selectedEndDate ? selectedEndDate : null}
                onChange={handleChangeEndDate}
                getOptionLabel={(option) => moment(option).format('LL')} // Format the date as a string for display
                renderInput={(params) => (
                  <TextField size="small" {...params} label="End Date" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Day/s"
                value={cancelDays}
                fullWidth
                disabled
                InputLabelProps={{
                  style: { width: '100%' },
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => setCancelReason(e.target.value)}
                value={cancelReason}
                label="Reason for Cancellation"
                required
                multiline
                rows={3}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { submitCancellation(requestView.request_uniqueId) }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {user && (
        <Grid
          container
          item
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "white" }}
        >
          <Grid item xs={0} sm={0} md={4} lg={4} xl={4}></Grid>

          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
          >
            <Card
              onClick={handleOpen}
              sx={{
                width: "100%",
                minHeight: 90,
                backgroundColor: "#3d5a80",
                color: "white",
                "&:hover": {
                  boxShadow: "5px 5px #888888",
                },
                cursor: "pointer",
              }}
            >
              <CardContent>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 13, md: 13, lg: 13, xl: 15 },
                    }}
                  >
                    Submit Request
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AddIcon sx={{ fontSize: 30 }} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={4}
            sm={3}
            md={2}
            lg={2}
            xl={2}
          >
            <Card sx={card_style}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 13, md: 13, lg: 13, xl: 15 },
                    }}
                  >
                    In-progress Request
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  00
                  {countLeave && countLeave.InProgressCount !== undefined
                    ? countLeave.InProgressCount
                    : ""}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={4}
            sm={3}
            md={2}
            lg={2}
            xl={2}
          >
            <Card sx={card_style}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 13, md: 13, lg: 13, xl: 15 },
                    }}
                  >
                    Approved Request
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  00
                  {countLeave && countLeave.ApprovedCount !== undefined
                    ? countLeave.ApprovedCount
                    : ""}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={4}
            sm={3}
            md={2}
            lg={2}
            xl={2}
          >
            <Card sx={card_style}>
              <CardContent>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 13, md: 13, lg: 13, xl: 15 },
                    }}
                  >
                    Rejected Request{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  00
                  {countLeave && countLeave.RejectedCount !== undefined
                    ? countLeave.RejectedCount
                    : ""}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              label="Search request"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ paddingBottom: "20px" }}
            />
            <TableContainer component={Paper} sx={{ marginBottom: "30px", overflowY: 'scroll', maxHeight: { xs: "55vh" } }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Request Type
                    </TableCell>
                    {isXsScreen && (
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Date Requested
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      End Date
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    // Display Skeleton components while loading
                    Array(7)
                      .fill()
                      .map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <>
                      {filteredData.map((leave) => (
                        <TableRow key={leave._id}>
                          <TableCell align="left">
                            {leave.request_type}
                          </TableCell>
                          {isXsScreen && (
                            <TableCell align="left">
                              {moment(leave.createdAt).format("lll")}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {moment(leave.start_date).format("ll")}
                          </TableCell>
                          <TableCell align="left">
                            {moment(leave.end_date).format("ll")}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row">
                              <Chip
                                size="small"
                                color={
                                  leave.status === "Rejected"
                                    ? "error"
                                    : leave.status === "Approved"
                                      ? "success"
                                      : leave.status === "Cancellation Rejected"
                                        ? "success"
                                        : leave.status === "Partially Cancelled"
                                          ? "success"
                                          : leave.status === "Cancelled"
                                            ? "warning"
                                            : leave.status === "In Progress" ||
                                              leave.status === "Final approval" ||
                                              leave.status === "Partial Cancellation" ||
                                              leave.status === "Full Cancellation"
                                              ? "primary"
                                              : "default" // or any other default color
                                }
                                label={leave.status}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item>
                                <Tooltip
                                  title="View"
                                  sx={{
                                    color: "#0F1140",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleOpen1(leave._id)}
                                  >
                                    <RemoveRedEyeIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>

                              <Grid item>
                                <PDFDownloadLink
                                  document={
                                    <LeavePDFFile
                                      name={
                                        userData.firstname +
                                        " " +
                                        userData.lastname
                                      }
                                      department={userData.department}
                                      position={userData.position}
                                      id={userData.user_id}
                                      leaveType={leave.request_type}
                                      reason={leave.message}
                                      datesRequested={
                                        moment(leave.start_date).format("LL") +
                                        " - " +
                                        moment(leave.end_date).format("LL")
                                      }
                                      date={moment(leave.createdAt).format(
                                        "LL"
                                      )}
                                      days={leave.days}
                                      supervisor={supervisorName}
                                      isHRApproved={
                                        leave.hr_approve === true
                                          ? "APPROVED"
                                          : leave.hr_approve === false
                                            ? "REJECTED"
                                            : "Pending"
                                      }
                                      isSupApproved={
                                        leave.supervisor_approve === true
                                          ? "APPROVED"
                                          : leave.supervisor_approve === false
                                            ? "REJECTED"
                                            : "Pending"
                                      }
                                    />
                                  }
                                  fileName={`LeaveRequest_${leave.user_id}`}
                                >
                                  {({ loading }) =>
                                    loading ? (
                                      <Tooltip
                                        title="Loading"
                                        sx={{
                                          color: "#1B4B59",
                                        }}
                                      >
                                        <IconButton>
                                          <LoopIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Download"
                                        sx={{
                                          color: "#1B4B59",
                                        }}
                                      >
                                        <IconButton>
                                          <SimCardDownloadIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                </PDFDownloadLink>
                              </Grid>
                              {(
                                leave.status === "Approved" ||
                                leave.status === "Cancellation Rejected" ||
                                leave.status === "Cancelled"
                              ) && (
                                  <Grid item>
                                    <Tooltip
                                      title="Archive"
                                      sx={{
                                        color: "#0F1140",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => archive(leave.request_uniqueId)}
                                      >
                                        <ArchiveIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}

                              {leave.status === "Approved" && (
                                <>
                                  <Grid item>
                                    <Tooltip
                                      title="Cancel"
                                      sx={{
                                        color: "#0F1140",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleClickOpenReqCancel(leave._id) //cancel/modify request after approval
                                        }
                                      >
                                        <Badge
                                          color="error"
                                          variant="dot"
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                        >
                                          <EventBusyIcon fontSize="small" />
                                        </Badge>
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </>
                              )}

                              {leave.status !== "Approved" &&
                                leave.status !== "Full Cancellation" &&
                                leave.status !== "Partial Cancellation" &&
                                leave.status !== "Cancelled" &&
                                leave.status !== "Cancellation Rejected" &&
                                leave.status !== "Rejected" && (
                                  <Grid item>
                                    <Tooltip
                                      title="Cancel"
                                      sx={{
                                        color: "#0F1140",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          cancelRequest(
                                            leave.request_uniqueId,
                                            leave.days,
                                            leave.request_type
                                          );
                                        }}
                                      >
                                        <CancelIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {/* Create Request Modal */}
      {user && (
        <form>
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  <img src={GMSlogo} width="310" height="auto" />
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={6}>
                    <i> Employee Name:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <i>Department:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.firstname + " " + userData.lastname}</b>
                  </Grid>

                  <Grid item xs={6}>
                    <b>{userData.department}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <i> Designation:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <i> Employment Number:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.position}</b>
                  </Grid>

                  <Grid item xs={6}>
                    <b>{userData.user_id}</b>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  spacing={2}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    {userData.vacationLeave <= 0 &&
                      monthName === "December" &&
                      userData.account_role === "digitizer" ? (
                      <Typography sx={{ color: "#F1463F", textAlign: "left" }}>
                        Please be reminded that your{" "}
                        <b>
                          vacation leave credit for this year has been fully
                          utilized.
                        </b>{" "}
                        As a result, you won't be able to apply for any further
                        vacation leave for the remainder of this year. Please
                        note that your leave credit will reset at the beginning
                        of next year, allowing you to plan your time off
                        accordingly.
                      </Typography>
                    ) : userData.vacationLeave === 0 &&
                      userData.account_role === "digitizer" ? (
                      <Typography sx={{ color: "#1F9AEE", textAlign: "left" }}>
                        REMINDER: You do not have any permitted leave credits
                        available. Therefore, you can not submit any further
                        vacation leave requests at this time.{" "}
                      </Typography>
                    ) : userData.vacationLeave <= 0 &&
                      userData.account_role === "user" ? (
                      <Typography sx={{ color: "#F1463F", textAlign: "left" }}>
                        {" "}
                        Please be reminded that your{" "}
                        <b>
                          vacation leave credit for this year has been fully
                          utilized.
                        </b>{" "}
                        As a result, you won't be able to apply for any further
                        vacation leave for the remainder of this year. Please
                        note that your leave credit will reset at the beginning
                        of next year, allowing you to plan your time off
                        accordingly.{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                  </Grid>

                  <Grid item xs={5}>
                    <TextField
                      required
                      fullWidth
                      onBlur={calculateTotalDays}
                      label="Leave From"
                      InputLabelProps={{
                        shrink: true,
                        style: { width: '100%' },
                      }}
                      type="date"
                      onChange={handleDateChange}
                      value={start_date}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {/* <TextField required fullWidth label="To" InputLabelProps={{ shrink: true, }} type="date" onChange={handleStartDateChange} value={end_date} /> */}
                    <TextField
                      required
                      fullWidth
                      label="To"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      onChange={handleDateChangeEnd}
                      value={end_date}
                      onBlur={calculateTotalDays}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      required
                      disabled
                      fullWidth
                      label="Day/s"
                      type="string"
                      value={countDays}
                      InputLabelProps={{
                        shrink: true,
                        style: { width: '100%' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={
                        start_date === "" ||
                        end_date === undefined ||
                        end_date === ""
                      }
                      required
                      fullWidth
                      select
                      label="Request Type:"
                      value={request_type}
                      onChange={(e) => setRequest_type(e.target.value)}
                    >
                      {userData.vacationLeave > 0 && (
                        <MenuItem value={"Personal Leave"}>
                          Personal Leave
                        </MenuItem>
                      )}
                      <MenuItem value={"Unpaid Leave"}>Unpaid Leave</MenuItem>
                      {userData.account_role !== "digitizer" &&
                        userData.sickLeave !== 0 && (
                          <MenuItem value={"Sick Leave"}>Sick Leave</MenuItem>
                        )}
                      {userData.account_role !== "digitizer" &&
                        userData.vacationLeave !== 0 && (
                          <MenuItem value={"Emergency"}>Emergency</MenuItem>
                        )}
                      {userData.account_role !== "digitizer" &&
                        userData.maternityLeave !== 0 && (
                          <MenuItem value={"Maternity"}>Maternity</MenuItem>
                        )}
                      {userData.account_role !== "digitizer" &&
                        userData.paternityLeave !== 0 && (
                          <MenuItem value={"Paternity"}>Paternity</MenuItem>
                        )}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      onBlur={calculateTotalDays}
                      label="Reason for Requested Leave"
                      required
                      multiline
                      InputLabelProps={{
                        shrink: true,
                        style: { width: '100%' },
                      }}
                      rows={3}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      paddingBottom: 2,
                      color: "#adadad",
                      fontStyle: "italic",
                    }}
                  >
                    Note: Please be aware that the vacation leave encompasses
                    both emergency and personal leave.
                  </Typography>
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ marginRight: "5px" }}
                    onClick={() => {
                      precreate();
                    }}
                    disabled={buttonDisabled}
                  >
                    Submit
                  </Button>
                  <Button
                    color="error"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      cancel();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </form>
      )}

      {/* View Request Modal*/}
      {user && (
        <Modal open={open1} onClose={handleClose1}>
          <Box sx={style}>
            <Box sx={style}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                >
                  Leave Application Form
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={6}>
                    <i> Employee Name:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <i>Department:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.firstname + " " + userData.lastname}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.department}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <i> Designation:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <i> Employment Number:</i>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.position}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <b>{userData.user_id}</b>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  spacing={2}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Request Type"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      size="small"
                      fullWidth
                      value={requestView.request_type}
                    ></TextField>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Start Date"
                      disabled
                      size="small"
                      fullWidth
                      value={moment(requestView.start_date).format("LL")}
                    ></TextField>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="End Date"
                      disabled
                      size="small"
                      fullWidth
                      value={moment(requestView.end_date).format("LL")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Day/s"
                      disabled
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true, style: { width: '100%' } }}
                      value={requestView.days}
                    />
                  </Grid>

                  {requestView.status === "Partial Cancellation" || requestView.status === "Partially Cancelled" ? (
                    <>
                      <Grid item xs={12}>
                        <Typography>
                          <i>New Date Requested:</i>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Start Date"
                          disabled
                          size="small"
                          fullWidth
                          value={moment(requestView.selectedStartDate).format("LL")}
                        ></TextField>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="End Date"
                          disabled
                          size="small"
                          fullWidth
                          value={moment(requestView.selectedEndDate).format("LL")}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Day/s"
                          disabled
                          size="small"
                          fullWidth
                          InputLabelProps={{ shrink: true, style: { width: '100%' } }}
                          value={requestView.cancelDays}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {requestView.status === "Partial Cancellation" || requestView.status === "Partially Cancelled" ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          required
                          multiline
                          label="Reason for Cancellation"
                          InputLabelProps={{ shrink: true }}
                          value={requestView.cancelReason}
                          rows={3}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          required
                          multiline
                          label="Remarks"
                          InputLabelProps={{ shrink: true }}
                          value={requestView.cancelRemarks}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </>
                  ) : requestView.status === "Full Cancellation" || requestView.status === "Cancelled" ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          required
                          multiline
                          label="Reason"
                          InputLabelProps={{ shrink: true }}
                          value={requestView.message}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          required
                          multiline
                          label="Remarks"
                          InputLabelProps={{ shrink: true }}
                          value={requestView.cancelRemarks}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                    </>
                  )}


                  {requestView.status === "In Progress" ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Initial approver remarks"
                          disabled
                          required
                          multiline
                          value={requestView.remarks}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>{" "}
                    </>
                  ) : (
                    <p></p>
                  )}

                  {requestView.status === "Approved" ||
                    requestView.status === "Rejected" ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Initial approver remarks"
                          disabled
                          required
                          multiline
                          value={requestView.remarks}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <TextField
                          label="Final approver remarks"
                          disabled
                          required
                          multiline
                          value={requestView.FAremarks}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>{" "}
                    </>
                  ) : requestView.status === "Pending" ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Reason for requested leave: "
                          disabled
                          required
                          multiline
                          value={requestView.message}
                          InputLabelProps={{ shrink: true }}
                          rows={2}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </Grid>
                <Grid item container justifyContent="flex-end"></Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
