import React, { Fragment } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, } from '@react-pdf/renderer';
import logo from '../../src/images/NewHeader_PNG.png'
import addressLine from '../../src/images/address-line.png'

function LeavePDF(props) {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 10,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.2,
            flexDirection: 'column',
            borderLeftColor: '#083D8F',
            borderLeftWidth: 25,
        },
        logo: {
            width: 320,
            height: 'auto',
            marginTop: 1,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        titleContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: .5,
            paddingBottom: 5,
            borderBottomColor: '#ebebeb',
            borderBottomWidth: 1,
            paddingTop: 5,
            borderTopColor: '#ebebeb',
            borderTopWidth: 1,
        },
        reportTitle: {
            color: '#04051f',
            fontSize: 23,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontFamily: 'Times-Bold',
            color: "#074C94"
        },
        headerContainer: {
            marginTop: 30,
            fontSize: 11,
            textAlign: 'justify',
            lineHeight: 1.4,
            color: "#454545",
        },
        paragraph: {
            marginTop: 12
        },
        textbold: {
            fontWeight: 'bold',
            fontFamily: 'Helvetica',
            color: "#000000"
        },
        period: {
            marginTop: 20,
            fontSize: 10,
            textAlign: 'center',
        },
        ftext: {
            marginTop: 20,
            width: '100%',
            color: "#8F8F8F",
            fontSize: 9,
            fontFamily: 'Helvetica-Oblique'
        },
        address: {
            width: 310,
            height: 'auto',
            marginTop: 355,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    })

    const {
        fname, lname, position, date, dateEmployed, title
    } = props


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={logo} />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Certificate of Employment</Text>
                </View>
                <View style={styles.headerContainer}>
                    <Text>This is to certify that <Text style={styles.textbold}>{title} {fname} {lname}</Text>, has been employed in
                        Geospectrum Marketing Services as <Text style={styles.textbold}>{position}</Text> from {dateEmployed}, up to the present.
                    </Text>
                    <Text style={styles.paragraph}>
                        This certificate is issued this {date} upon the request of <Text style={styles.textbold}>{title} {lname}</Text> for whatever legal purpose it may serve.
                    </Text>
                    <Text style={styles.paragraph}>
                        Should you have any further questions or need additional information, please feel free to reach us through (02) 8637 8026 / 0945 134 2474 or medillojianna@geospectrum.com.ph
                    </Text>
                </View>
                <View style={styles.period}>
                    <Text style={styles.ftext}>*This is a computer-generated document. No signature is required*</Text>
                </View>
                <Image style={styles.address} src={addressLine} />
            </Page>
        </Document>
    )
};

export default LeavePDF;






