import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Grid,
  Button,
  Autocomplete,
  Modal,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import axios from "axios";
import Swal from "sweetalert2";
import DigitizerContainer from "./digitizerContainer.js";
import TableContainer from "./tableContainer.js";
import EmployeeContainer from "./employeeContainer.js";
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import AggreementForm from '../../documents/inventoryAgreement.js'
import moment from "moment";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ITContainer from "./PerisContainer.js";



// Tab Panel function
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`employee-tabpanel-${index}`}
      aria-labelledby={`employee-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `employee-tab-${index}`,
    "aria-controls": `employee-tabpanel-${index}`,
  };
}
//

export default function Inventory() {
  const { user } = useAuthContext();
  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, []);

  // Tabs
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  // Modal add New Item
  const [open, setOpen] = React.useState(false);
  const openAddItem = () => setOpen(true);
  const closeAddItem = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Retrieve All Employee
  const [employee, setEmployee] = useState(null);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/allAccounts", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEmployee(data);
      });
  }, []);

  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Equipment is not created",
    });
    closeAddItem();
  };

  // Create/Assign Item to an Employee
  const [item_name, setItem_name] = useState("");
  const [item_description, setItem_description] = useState("");
  const [assigned_to, setAssigned_to] = useState("");
  const [office, setOffice] = useState("");
  const [notes, setNotes] = useState();
  const [issue_date, setIssue_date] = useState("");
  const [return_date, setReturn_date] = useState("");
  const [date_acquire, setDate_acquire] = useState("");
  const [item_status, setItem_status] = useState("");
  const [serial_no, setSerial_no] = useState("");
  const [user_email, setUser_email] = useState("");
  const [quantity, setQuantity] = useState(0);
  // Form
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");

  const handleCancel = (e) => {
    closeAddItem();
    setItem_name('')
    setItem_description('')
    setAssigned_to('')
    setNotes('')
    setIssue_date('')
    setReturn_date('')
    setItem_status('')
    setSerial_no('')
    setOffice('')
  }

  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Create item/equipment data
  const handleAssingItem = async (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    if (
      item_name.trim() === "" ||
      item_description.trim() === "" ||
      serial_no.trim() === "" ||
      date_acquire.trim() === "" ||
      // issue_date.trim() === "" ||
      item_status.trim() === ""
    ) {
      // Input is empty
      alertError();
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
      return;
    }
    try {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/inventory/create",
        {
          item_name,
          assigned_to,
          item_description,
          notes,
          issue_date,
          office,
          date_acquire,
          return_date,
          item_status,
          serial_no,
          assign_by: userData.user_id,
          quantity
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (assigned_to === '') {
        alertAdded()
      } else {
        CreatePDF()
      }

      // Reset submitting state after the request is complete
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
      setAssigned_to("");
      setSerial_no('')
      setItem_status('')
      setIssue_date('')
      setReturn_date('')
      closeAddItem();
    } catch (error) {
      console.error("Error while posting data:", error);
      // Handle the error as needed
    }
  };

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const time = currentDate.getTime();
  const CreatePDF = async () => {
    try {
      const element = <AggreementForm
        issueDate={moment(issue_date).format("LL")}
        returnDate={moment(return_date).format("LL")}
        name={FName + " " + LName}
        department={department}
        designation={designation}
        id={assigned_to}
        equipment={item_name}
        serial={serial_no}
        description={item_description}
        remarks={notes}
      />;
      const myPdfBlob = await pdf(element).toBlob();
      var formData = new FormData();
      formData.append("file", myPdfBlob, `Agreement_form_${assigned_to}${month}${time}.pdf`);
      formData.append("email", user_email);
      await sendMail(formData);
    } catch (error) {
      console.error('Error in function email:', error);
    }
  };

  // Alert message
  const alertAdded = () => {
    Swal.fire({
      icon: "success",
      title: "Equipment successfully added",
    });
  };
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Equipment successfully added",
      text: "An Equipment Employee Agreement is sent to the employee",
    });
  };

  const sendMail = async (formData) => {
    try {
      await fetch('https://hr.geospectrum.com.ph/api/api/email/inventoryEmailPDF', {
        method: 'POST',
        body: formData,
      });
      alertSuccess()
    } catch (err) {
      console.error('Error sending mail:', err);
    }
  };


  const handleStatusChange = (e) => {
    const value = e.target.value;
    // Validate that the selected value is one of the available options
    if (["Issued", "Available", "Maintenance", "Condemned"].includes(value)) {
      setItem_status(value);
    } else {
      // Handle invalid selection if needed
      console.error(`Invalid item status: ${value}`);
    }
  };

  // Button to download inventory master list
  const downloadExcel = async () => {

    Swal.fire({
      title: "Download list?",
      text: "Inventory list will be download",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch("https://hr.geospectrum.com.ph/api/api/inventory/excelExport",
            {
              headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Inventory_List.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Fetch error:", error);
        }
        Swal.fire({
          title: "Success!",
          text: "Your file has been downloaded.",
          icon: "success"
        });
      }
    });
  };

  return (
    <>
      <Grid container item direction="row" columnSpacing={1} >
        <Grid
          item
          container
          xs={12}
          direction="row"
          sx={{
            backgroundColor: "white",

          }}
        >
          <Typography variant="h5" sx={{ paddingTop: 1, pl: 2 }}>Employee Equipment Inventory</Typography>
          {/* Tab Area */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChangeTab}>
                <Tab label="Table" {...a11yProps(0)} />
                <Tab label="Employee" {...a11yProps(1)} />
                <Tab label="Digitizer" {...a11yProps(2)} />
                <Tab label="peripherals" {...a11yProps(2)} />
              </Tabs>
            </Box>

            {/* Table Tab */}
            <CustomTabPanel value={value} index={0} sx={{ height: { xs: '', sm: '', md: '125vh', lg: '145vh', xl: '155vh' }, overflowY: 'hidden' }}>
              <Grid item container direction="row" >
                <Grid item >
                  <Button variant="contained" onClick={openAddItem}>
                    Add Item
                  </Button>
                </Grid>
                <Grid item sx={{ marginLeft: 2 }}>
                  <Button variant="outlined" color="error" startIcon={<FileOpenIcon />} onClick={() => downloadExcel()}>
                    Download
                  </Button>
                </Grid>
                {/* <Grid item xs={3}>
                  <Card
                    sx={{
                     
                      height: 75,
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    <CardContent>
                      <Grid item container direction="column">
                        <Grid
                          item
                     
                        >
                    Total 
                        </Grid>
                        <Grid item container direction="row" alignItems="center">
                          <Grid item xs={10} sm={8} md={9} lg={9} xl={9}>
                           123123
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> */}
                <Grid item xs={12}>
                  <TableContainer dataFromParent={employee} />
                </Grid>
              </Grid>
            </CustomTabPanel>

            {/* ============================================================================================ */}

            {/* per person tab*/}
            <CustomTabPanel value={value} index={1}>
              <Grid item container direction="row" spacing={2} sx={{ height: { xs: '', sm: '', md: '65vh', lg: '70vh', xl: '70vh' }, overflowY: 'auto' }}>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={openAddItem}>
                    Add Item
                  </Button>
                </Grid>
                <EmployeeContainer />
              </Grid>
            </CustomTabPanel>

            {/* Digitizer per person tab*/}
            <CustomTabPanel value={value} index={2}>
              <Grid item container direction="row" spacing={2} sx={{ height: { xs: '', sm: '', md: '65vh', lg: '70vh', xl: '70vh' }, overflowY: 'auto' }}>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={openAddItem}>
                    Add Item
                  </Button>
                </Grid>
                <DigitizerContainer />
              </Grid>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
            <Grid item container direction="row" sx={{ height: { xs: '', sm: '', md: '65vh', lg: '70vh', xl: '70vh' }, overflowY: 'auto' }}>
              <ITContainer dataFromParent={employee}/>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>

      {/* Modal Adding New Item */}
      <Modal
        open={open}
        onClose={closeAddItem}
        sx={{
          overflow: "hidden",
        }}
      >
        <Box sx={style}>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs="12" sx={{ textAlign: "center" }}>
              <h2>Add Item / Equipment</h2>
            </Grid>
            <Grid item xs="2">
              <TextField
                required
                type="number"
                label="Quantity"
                fullWidth
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Grid>     <Grid item xs="6">
              <TextField
                required
                label="Item/Equipment"
                fullWidth
                onChange={(e) => setItem_name(e.target.value)}
              />
            </Grid>
            <Grid item xs="4">
              <TextField
                required
                type="date"
                label="Date Acquired"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setDate_acquire(e.target.value)}
              />
            </Grid>
            <Grid item xs="6">
              <TextField
                required
                label="Serial No."
                fullWidth
                value={serial_no}
                onChange={(e) => setSerial_no(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                fullWidth
                label="Office"
                onChange={(e) => setOffice(e.target.value)}
                value={office}
                select
              >
                <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs="3">
              <TextField
                required
                select
                label="Item Status"
                value={item_status}
                onChange={handleStatusChange}
                fullWidth
              >
                <MenuItem value={"Issued"}>Issued</MenuItem>
                <MenuItem value={"Available"}>Available</MenuItem>
                <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                <MenuItem value={"Condemned"}>Condemned</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs="12">
              <TextField
                required
                multifield
                label="Item/Equipment Description"
                multiline
                rows={3}
                fullWidth
                onChange={(e) => setItem_description(e.target.value)}
              />
            </Grid>
            <Grid item xs="6">
              {/* <Autocomplete
                onChange={(event, newValue) => {
                  setAssigned_to(newValue ? newValue.user_id : null);
                  setUser_email(newValue ? newValue.email : null);
                }}

                options={employee}
                getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                renderInput={(params) => (
                  <TextField {...params} label="Assign to" fullWidth />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.user_id} value={option}>
                    {`${option.firstname} ${option.lastname}`}
                  </MenuItem>
                )}
              /> */}
              <Autocomplete
                onChange={(event, newValue) => {
                  setAssigned_to(newValue ? newValue.user_id : null);
                  setUser_email(newValue ? newValue.email : null);
                  setFName(newValue ? newValue.firstname : null);
                  setLName(newValue ? newValue.lastname : null);
                  setDepartment(newValue ? newValue.department : null);
                  setDesignation(newValue ? newValue.position : null);
                }}
                options={employee}
                getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                renderInput={(params) => (
                  <TextField {...params} label="Assign to" fullWidth />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.user_id} value={option}>
                    {`${option.firstname} ${option.lastname}`}
                  </MenuItem>
                )}
              />


            </Grid>
            <Grid item xs="3">
              <TextField
                type="date"
                // required
                label="Issue Date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setIssue_date(e.target.value)}
              />
            </Grid>
            <Grid item xs="3">
              <TextField
                type="date"
                label="Return Date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setReturn_date(e.target.value)}
              />
            </Grid>

            <Grid item xs="12">
              <TextField
                multifield
                label="Notes"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs="12" sx={{ textAlign: "right" }}>
              <Button
                color="error"
                variant="contained"
                onClick={handleCancel}
                sx={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                color="success"
                variant="contained"
                onClick={handleAssingItem}
                disabled={buttonDisabled}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
