import React, { Fragment } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, } from '@react-pdf/renderer';
import logo from '../../src/images/logo_line.png'


function LeavePDF(props) {
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.2,
      flexDirection: 'column',
    },
    logo: {
      width: 270,
      height: 'auto',
      marginTop: 45,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    DateContainer: {
      marginTop: 30,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    labelDate: {
      width: 130
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 30,
      borderWidth: 1,
      borderColor: 'black',
    },
    deductiondescription: {
      width: '100%',
      textAlign: 'center',
      paddingLeft: 15,
      fontWeight: 'Bold',
    },
    deductioncontainer: {
      backgroundColor: '#f0f0f0',
      height: 24,
      fontStyle: 'bold',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomColor: 'black',
      borderBottomWidth: .5,
    },
    openrow: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    closerow: {
      flexDirection: 'row',
      borderBottomColor: 'black',
      borderBottomWidth: .5,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
    },
    label: {
      width: '20%',
      textAlign: 'left',
      paddingLeft: 10,
      fontWeight: 'Bold',
    },
    description: {
      width: '30%',
      textAlign: 'left',
      paddingLeft: 10,
      color: "#919191",
      borderRightColor: "black",
      borderRightWidth: .5,
    },
    descriptionRight: {
      width: '30%',
      textAlign: 'left',
      paddingLeft: 10,
      color: "#919191",
    },
    requested: {
      width: '40%',
      textAlign: 'left',
      paddingLeft: 10,
      fontWeight: 'Bold',
    },
    reqdescription: {
      width: '60%',
      textAlign: 'left',
      paddingLeft: 10,
      color: "#919191",
    },
    reco: {
      width: '100%',
      textAlign: 'left',
      paddingLeft: 10,
      fontFamily: 'Helvetica-Oblique'
    },
    approval: {
      width: '40%',
      textAlign: 'left',
      paddingLeft: 10,
    },
    approvalName: {
      width: '60%',
      textAlign: 'left',
      paddingLeft: 10,
    },
    approvalStat: {
      width: '60%',
      textAlign: 'left',
      paddingLeft: 10,
      color: '#5e5c5c'
    },
    address: {
      textAlign: 'center',
      fontSize: '10pt',
      marginTop: 5
    },
  })

  const {
    name, department, id, position, amount, date, purpose,
    hr_approve
  } = props


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.address}>
            Unit 804, Linden Suites Tower 1, 37 San Miguel Avenue,
          </Text>
          <Text style={styles.address}>
            Ortigas Center, Pasig City, Philippines
          </Text>
        <Fragment>
          <View style={styles.DateContainer}>
            <Text style={styles.labelDate}>Date: {date}  </Text>
          </View >
        </Fragment>
        <View style={styles.tableContainer}>
          <View style={styles.deductioncontainer}>
            <Text style={styles.deductiondescription}>CASH ADVANCE FORM</Text>
          </View>
          <View style={styles.closerow}>
            <Text style={styles.label}>Name:</Text>
            <Text style={styles.description}>{name}</Text>
            <Text style={styles.label}>Department:</Text>
            <Text style={styles.descriptionRight}>{department}</Text>
          </View>
          <View style={styles.closerow}>
            <Text style={styles.label}>Designation:</Text>
            <Text style={styles.description}>{position}</Text>
            <Text style={styles.label}>Employee ID:</Text>
            <Text style={styles.descriptionRight}>{id}</Text>
          </View>
          <View style={styles.closerow}>
            <Text style={styles.requested}>Amount:</Text>
            <Text style={styles.reqdescription}>{amount}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.requested}>Purpose:</Text>
            <Text style={styles.reqdescription}>{purpose}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.requested}></Text>
            <Text style={styles.reqdescription}></Text>
          </View>
          <View style={styles.closerow}>
            <Text style={styles.requested}></Text>
            <Text style={styles.reqdescription}></Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.reco}>Recommending Approval:</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.requested}></Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.approval}>Human Resource Officer:</Text>
            <Text style={styles.approvalName}>Jianna Iza Medillo</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.approval}></Text>
            <Text style={styles.approvalStat}>{hr_approve}</Text>
          </View>

        </View>
      </Page>
    </Document>
  )
};

export default LeavePDF;






