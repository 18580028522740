import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Box,
  Grid,
  Button,
  Stepper,
  Step,
  StepButton,
  Typography,
  FormGroup,
  Paper,
  Radio,
  Table,
  TableCell,
  TableContainer,
  RadioGroup,
  TableBody,
  InputAdornment,
  TableRow,
  Divider,
  TextField,
  Skeleton
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAuthContext } from "../../hooks/useAuthContext";
import logo from "../../images/gms_logo.png";
import Swal from "sweetalert2";
import axios from "axios";
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import PayslipPDF from '../../documents/payslip'
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';

// Stepper
const steps = ["Select Employee", "Enter Payroll", "Review Payroll"];

export default function Payroll() {
  const { user } = useAuthContext();
  const styles = {
    caption: {
      fontWeight: "bold",
      fontStyle: "italic",
    },
  };

  // Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleSkip = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // Remove completion status for the current step
    const newCompleted = { ...completed };
    delete newCompleted[activeStep - 1];
    setCompleted(newCompleted);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleComplete1 = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleSkip();
  };
  const handleComplete2 = () => {
    if (
      periodStart.trim() === "" ||
      periodEnd.trim() === "" ||
      (typeof sss_deduc === "string" && sss_deduc.trim() === "") ||
      (typeof setHmdf_deduc === "string" && setHmdf_deduc.trim() === "")
    ) {
      alertError();
      return;
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleSkip();
  };

  // Error Alert if wrong/no input
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Textfield Required",
      text: "Please input pay period and/or calculate the deductions ",
    });
  };

  // Retrieve All Employee and display
  const [trigger, setTrigger] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/all", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(employee)
        setEmployee(data);
        setLoading(false);
      });
  }, [user, trigger]);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = employee
    ? employee.filter((item) => {
      const itemName = item.firstname + item.lastname + item.department + item.position || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Select employee when a radio button is clicked
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const handleRadioChange = (event) => {
    setSelectedEmployee(event.target.value);
    setPeriodEnd("");
    setPeriodStart("");
    setOvertime(0);
    setIncentives(0);
    setLates(0);
    setAbsences(0);
    setUnpaidLeaves(0);
    setPhilhealth_deduc(0);
    setHmdf_deduc(0);
    setSss_deduc(0);
    setTaxes_deduc(0);
    setCashAdv_deduc(0);
    setOthers_deduc(0);
    setConvert_lates(0);
    setSss_loan(0);
    setHmdf_loan(0);
  };

  // Retrieve information on the selected employee
  const [selectedInfo, setSelectedInfo] = useState("");
  const handleOpenEmp = () => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/viewEmployee" + selectedEmployee, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data)
        setSelectedInfo(data);
        setFirstHalf_basic_pay(parseFloat(data.firstHalf_basic_pay))
      });
  };

  // Get the selected Date Value
  const [periodStart, setPeriodStart] = useState("");
  const [periodEnd, setPeriodEnd] = useState("");
  const [isDateRange1To15, setIsDateRange1To15] = useState(false);

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    setPeriodStart(formattedDate);

    // Check if the day of the month is from 1 to 15
    const dayOfMonth = date && date.date(); // Assuming the date object has a 'date' method
    setIsDateRange1To15(dayOfMonth >= 1 && dayOfMonth <= 15);
  };
  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    setPeriodEnd(formattedDate);

    // Check if the day of the month is from 1 to 15
    const dayOfMonth = date && date.date(); // Assuming the date object has a 'date' method
    setIsDateRange1To15(dayOfMonth >= 1 && dayOfMonth <= 15);

    // Sum of all the unpaid leaves (days) within the time frame
    fetch(
      `https://hr.geospectrum.com.ph/api/api/payslip/countOnlyDays/${selectedInfo.user_id}/${periodStart}/${formattedDate}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUnpaidLeaves(data.totalDays);
      });

    // Sum of all the cash advance within the time frame
    // fetch(
    //   `https://hr.geospectrum.com.ph/api/api/payslip/countCashAdvance/${selectedInfo.user_id}/${periodStart}/${formattedDate}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${user.token}`,
    //     },
    //   }
    // )
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     // console.log(data);
    //     setCashAdv_deduc(parseFloat(data.totalCash));
    //   });
  };
  // Convert string values to numbers (Basic pay is divided into 2)
  const basic_Pay = parseFloat(selectedInfo.basic_pay) || 0;
  const allowance_pay = parseFloat(selectedInfo.allowance) || 0;
  // Calculate the sum (Gross PAY)
  const totalPay = parseFloat((basic_Pay + allowance_pay) / 2) || 0;
  const overtimePay = Math.floor(basic_Pay / 30) / 8;
  const deductPerDay = parseFloat(selectedInfo.basic_pay / 30);

  const [takeHomePay, setTakeHomePay] = useState(0);
  const [total_deduction, setTotal_deduction] = useState(0);
  const [grossPay, setGrossPay] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const [incentives, setIncentives] = useState(0);
  const [lates, setLates] = useState(0);
  const [latesTotal, setLatesTotal] = useState(0);
  const [absences, setAbsences] = useState(0);
  const [absencesTotal, setAbsencesTotal] = useState(0);
  const [unpaidLeaves, setUnpaidLeaves] = useState(0);
  const [unpaidLeavesTotal, setUnpaidLeavesTotal] = useState(0);
  const [philhealth_deduc, setPhilhealth_deduc] = useState(0);
  const [hmdf_deduc, setHmdf_deduc] = useState(0);
  const [sss_deduc, setSss_deduc] = useState(0);
  const [taxes_deduc, setTaxes_deduc] = useState(0);
  const [cashAdv_deduc, setCashAdv_deduc] = useState(0);
  const [others_deduc, setOthers_deduc] = useState(0);
  const [allowance_deduc, setAllowance_deduc] = useState(0);
  const [allowance_remaining, setAllowance_remaining] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [convert_lates, setConvert_lates] = useState(0);
  const [firstHalf_basic_pay, setFirstHalf_basic_pay] = useState();

  const [sss_loan, setSss_loan] = useState(0);
  const [hmdf_loan, setHmdf_loan] = useState(0);

  // Calculate Overtime Pay
  const [overtimeTotal, setOvertimeTotal] = useState(0);
  const handleOvertimeChange = (e) => {
    const latesValue = e.target.value;
    setOvertime(latesValue);
    const calculatedTotal = latesValue * overtimePay;
    setOvertimeTotal(parseFloat(calculatedTotal) || 0);
  };

  // Convert Lates to days
  const handleLatesChange = (e) => {
    const latesValue = e.target.value;
    setLates(latesValue);
    // Calculate "Total Converted Lates" based on "Total Lates" divided by 3
    const calculatedLates = Math.floor(latesValue / 3);
    // Update the "Converted Lates"
    setConvert_lates(parseFloat(calculatedLates) || 0);
  };

  // Calculate the deduction base on the Table of Contribution & Withholding Tax
  const calculate = () => {
    const deductDays = 15 - parseFloat(convert_lates + absences + unpaidLeaves);
    const remaining_allowance = parseFloat(allowance_pay / 2 * deductDays) / 15;
    setAllowance_remaining(remaining_allowance)
    setAllowance_deduc(parseFloat((allowance_pay / 2 - remaining_allowance).toFixed(2)));

    let bpay = 0;
    let halfBpay = selectedInfo.basic_pay / 2;
    if (isDateRange1To15) {
      bpay = parseFloat(halfBpay * deductDays) / 15 + overtimeTotal;
    } else {
      if (selectedInfo.firstHalf_basic_pay === '0') {
        bpay = parseFloat(halfBpay * deductDays) / 15 + overtimeTotal;

      } else {
        bpay = parseFloat(selectedInfo.firstHalf_basic_pay) + parseFloat(halfBpay * deductDays) / 15 + overtimeTotal;
      }
    }
    // console.log(selectedInfo.firstHalf_basic_pay)
    setLatesTotal(parseFloat((convert_lates * deductPerDay).toFixed(2)));
    setUnpaidLeavesTotal(parseFloat((unpaidLeaves * deductPerDay).toFixed(2)));
    setAbsencesTotal(parseFloat((absences * deductPerDay).toFixed(2)))

    if (isDateRange1To15) {
      if (bpay < 10000) {
        setPhilhealth_deduc(175);
        setHmdf_deduc(200);
        setSss_deduc(0);
        setFirstHalf_basic_pay(parseFloat(bpay).toFixed(2))
      }
      setPhilhealth_deduc(parseFloat((bpay * 2 * .05).toFixed(2)) / 2);
      setHmdf_deduc(200);
      setSss_deduc(0);
      setFirstHalf_basic_pay(parseFloat(bpay).toFixed(2))
    } else {
      if (bpay > 30000){
        setSss_deduc(parseFloat(1350));
      }
      else{
        setSss_deduc(parseFloat(bpay * .045));
      }
      setPhilhealth_deduc(0);
      setHmdf_deduc(0);
    }
    // console.log(parseFloat(parseFloat((bpay *  2 * .05).toFixed(2)) /  2), latesTotal)
    // console.log(firstHalf_basic_pay)
  };

  useEffect(() => {
    // This effect will run whenever total_deduction or grossPay changes
    setTakeHomePay(grossPay - total_deduction);
  }, [total_deduction, grossPay]);

  // Calculate the GrossPay / Total Deduction and the Take Home Pay
  const nextCalculate = () => {
    // console.log("okay")
    setTotal_deduction(
      (unpaidLeaves * deductPerDay + absences * deductPerDay + convert_lates * deductPerDay + philhealth_deduc + hmdf_deduc + sss_deduc + taxes_deduc + cashAdv_deduc + others_deduc + allowance_deduc + sss_loan + hmdf_loan).toFixed(2)
    );
    setGrossPay(
      parseFloat(selectedInfo.basic_pay, 10) / 2 +
      parseFloat(selectedInfo.allowance, 10) / 2 +
      overtimeTotal +
      incentives
    );
    setTakeHomePay((grossPay - total_deduction).toFixed(2));

  };

  const create = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All informations are correct",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        handleComplete1();
        axios.post(
          `https://hr.geospectrum.com.ph/api/api/payslip/create`,
          {
            user_id: selectedInfo.user_id,
            periodStart,
            takeHomePay,
            grossPay,
            total_deduction,
            periodEnd,
            takeHomePay,
            incentives,
            overtime,
            overtimeTotal,
            lates,
            latesTotal,
            absences,
            absencesTotal,
            unpaidLeaves: unpaidLeavesTotal,
            philhealth_deduc,
            hmdf_deduc,
            sss_deduc,
            taxes_deduc,
            cashAdv_deduc,
            others_deduc,
            remarks,
            allowance_deduc,
            sss_loan,
            hmdf_loan
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        axios.put(
          `https://hr.geospectrum.com.ph/api/api/user/update` + selectedInfo.email,
          {
            firstHalf_basic_pay
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

      }
    });




  };


  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so adding 1
  const time = currentDate.getTime();

  const CreatePDF = async () => {
    try {
      const element = <PayslipPDF
        date={moment(currentDate).format("LL")}
        position={selectedInfo.position}
        department={selectedInfo.department}
        email={selectedInfo.email}
        allowance={selectedInfo.allowance / 2}
        user_id={selectedInfo.user_id}
        periodEnd={periodEnd}
        periodStart={periodStart}
        name={selectedInfo.firstname + " " + selectedInfo.lastname}
        salary={selectedInfo.basic_pay / 2}
        incentives={incentives}
        overtimeTotal={overtimeTotal}
        takeHomePay={takeHomePay}
        grossPay={grossPay}
        total_deduction={total_deduction}
        lates={lates}
        latesTotal={latesTotal}
        philhealth_deduc={philhealth_deduc}
        hmdf_deduc={hmdf_deduc}
        sss_deduc={sss_deduc}
        taxes_deduc={taxes_deduc}
        cashAdv_deduc={cashAdv_deduc}
        others_deduc={others_deduc}
        absences={absences}
        absencesTotal={absencesTotal}
        unpaidLeaves={unpaidLeaves}
        unpaidLeavesTotal={unpaidLeavesTotal}
        allowance_deduc={allowance_deduc}
        hmdf_loan={hmdf_loan}
        sss_loan={sss_loan}
      />;
      const myPdfBlob = await pdf(element).toBlob();

      var formData = new FormData();
      formData.append("file", myPdfBlob, `Payslip_form_${selectedInfo.lastname}${month}${time}.pdf`);
      formData.append("periodEnd", periodEnd);
      formData.append("periodStart", periodStart);
      formData.append("email", selectedInfo.email);

      await sendMail(formData);
    } catch (error) {
      console.error('Error in function email:', error);
    }
    setPeriodEnd("");
    setPeriodStart("");
    setActiveStep(0);
    setCompleted({});
    setOvertime(0);
    setIncentives(0);
    setLates(0);
    setAbsences(0);
    setUnpaidLeaves(0);
    setPhilhealth_deduc(0);
    setHmdf_deduc(0);
    setSss_deduc(0);
    setTaxes_deduc(0);
    setCashAdv_deduc(0);
    setOthers_deduc(0);
    setConvert_lates(0);
    setSss_loan(0);
    setHmdf_loan(0);
  };

  const sendMail = async (formData) => {
    const alertSuccess = () => {
      Swal.fire({
        icon: "success",
        title: "Email sent",
        text: "Payslip is sent to the employee",
      });
    };

    try {
      await fetch('https://hr.geospectrum.com.ph/api/api/email/createPDF', {
        method: 'POST',
        body: formData,
      });
      alertSuccess()
    } catch (err) {
      console.error('Error sending mail:', err);
    }
  };

  //Comma Formats
  const formattedAmountBP = new Intl.NumberFormat('en-US').format(parseFloat(selectedInfo.basic_pay / 2, 10));
  const formattedAmountAllo = new Intl.NumberFormat('en-US').format(parseFloat(selectedInfo.allowance / 2, 10));
  const formattedAmountGP = new Intl.NumberFormat('en-US').format(parseFloat(totalPay, 10));
  const formattedAmountPH = new Intl.NumberFormat('en-US').format(parseFloat(philhealth_deduc, 10));
  const formattedAmountHMDF = new Intl.NumberFormat('en-US').format(parseFloat(hmdf_deduc, 10));
  const formattedAmountSSS = new Intl.NumberFormat('en-US').format(parseFloat(sss_deduc, 10));

  const handleIncentivesChange = (e) => {
    // Allow only numbers and ignore other characters
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    // Set the numeric value without commas to the state
    setIncentives(parseFloat(inputValue) || 0)
  };
  // Format the number with commas for display
  const formattedIncentives = new Intl.NumberFormat('en-US').format(parseFloat(incentives, 10));

  return (
    <>
      <Grid
        item
        container
        sx={{
          backgroundColor: "white",
        }}
      >
        <Grid container item direction="row">
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                sx={{
                  boxShadow: 2,
                  backgroundColor: "#F1F0F0",
                  padding: 2,
                }}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, ml: 1 }}>
                      Payroll Completed
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />

                      <PDFDownloadLink
                        document={<PayslipPDF
                          date={moment(currentDate).format("LL")}
                          position={selectedInfo.position}
                          department={selectedInfo.department}
                          email={selectedInfo.email}
                          allowance={selectedInfo.allowance / 2}
                          user_id={selectedInfo.user_id}
                          periodEnd={periodEnd}
                          periodStart={periodStart}
                          name={selectedInfo.firstname + " " + selectedInfo.lastname}
                          salary={selectedInfo.basic_pay / 2}
                          incentives={incentives}
                          overtimeTotal={overtimeTotal}
                          takeHomePay={takeHomePay}
                          grossPay={grossPay}
                          total_deduction={total_deduction}
                          lates={lates}
                          latesTotal={latesTotal}
                          philhealth_deduc={philhealth_deduc}
                          hmdf_deduc={hmdf_deduc}
                          sss_deduc={sss_deduc}
                          taxes_deduc={taxes_deduc}
                          cashAdv_deduc={cashAdv_deduc}
                          others_deduc={others_deduc}
                          absences={absences}
                          absencesTotal={absencesTotal}
                          unpaidLeaves={unpaidLeaves}
                          unpaidLeavesTotal={unpaidLeavesTotal}
                          allowance_deduc={allowance_deduc}
                          hmdf_loan={hmdf_loan}
                          sss_loan={sss_loan}
                        />}
                        fileName={`payslip_${selectedInfo.lastname}${month}${time}`}
                      >
                        {({ blob, url, loading, error }) => (
                          <Button
                            onClick={CreatePDF}
                            variant="contained"
                            sx={{
                              mr: 1,
                              marginBottom: 5,
                            }}
                          >
                            Send & Download
                          </Button>
                        )}
                      </PDFDownloadLink>


                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* Content */}
                    <form>
                      <FormGroup
                        sx={{ display: activeStep === 0 ? "" : "none" }}
                      >
                        <Grid container item direction="row">
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              label="   Search employee"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ marginTop: '15px', paddingLeft: "5px" }}
                            />
                            <TableContainer component={Paper} sx={{ marginBottom: "30px", height: { xs: '', sm: '', md: '48vh', lg: '48vh', xl: '60vh' }, overflowY: 'scroll' }}>
                              <Table>
                                <TableBody>
                                  {loading ? (
                                    // Display Skeleton components while loading
                                    Array(10).fill().map((_, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          <Skeleton variant="text" animation="wave" />
                                        </TableCell>
                                        <TableCell>
                                          <Skeleton variant="text" animation="wave" />
                                        </TableCell>
                                        <TableCell>
                                          <Skeleton variant="text" animation="wave" />
                                        </TableCell>
                                        <TableCell>
                                          <Skeleton variant="text" animation="wave" />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <>
                                      {filteredData.map((emp, index) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            transition:
                                              "background-color 0.5s ease",
                                            "&:hover": {
                                              backgroundColor: "#F5F5F6",
                                              boxShadow: "none",
                                            },
                                          }}
                                        >
                                          <TableCell padding="checkbox">
                                            <FormControl>
                                              <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={selectedEmployee}
                                                onChange={handleRadioChange}
                                              >
                                                <Radio value={emp.user_id} />
                                              </RadioGroup>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell align="left">
                                            {emp.firstname + " " + emp.lastname}
                                          </TableCell>
                                          <TableCell align="left">
                                            {emp.user_id}
                                          </TableCell>
                                          <TableCell align="left">
                                            {emp.position}
                                          </TableCell>
                                          <TableCell align="left">
                                            {emp.department}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </FormGroup>

                      <FormGroup
                        sx={{ display: activeStep === 1 ? "" : "none", ml: 2, height: { xs: '', sm: '', md: '65vh', lg: '68vh', xl: '75vh' }, overflowY: 'scroll' }}
                      >
                        <Grid container item direction="row" spacing={2}>
                          {/* <Button onClick={test}>asd</Button> */}
                          <Grid
                            item
                            xs={10}
                            sx={{
                              marginBottom: -2,
                            }}
                          >
                            <h2>
                              {" "}
                              Enter Pay Period*{" "}
                              <Typography
                                sx={{ fontStyle: "italic", color: "#4072a3" }}
                              >
                                (payroll for 15 days period)
                              </Typography>
                            </h2>
                          </Grid>
                          <Grid item xs={2}>
                            <h4>
                              {selectedInfo.firstname +
                                " " +
                                selectedInfo.lastname}
                            </h4>
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                {/* DatePicker for Pay Period Start */}
                                <MuiDatePicker
                                  label="Pay Period Start"
                                  value={periodStart}
                                  onChange={handleStartDateChange}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />

                                {/* DatePicker for Pay Period End */}
                                <MuiDatePicker
                                  label="Pay Period End"
                                  value={periodEnd}
                                  onChange={handleEndDateChange}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                          {!isDateRange1To15 || isDateRange1To15 === '' || isDateRange1To15 === null || isDateRange1To15 === undefined ? (
                            <Grid item xs="3">
                              <TextField size="small" fullWidth label="Salary (1 - 15)"
                                value={firstHalf_basic_pay}
                                type="number"
                                onChange={(e) =>
                                  setFirstHalf_basic_pay(parseFloat(e.target.value) || 0)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₱
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          ) : (
                            <>
                            </>
                          )}
                          <Grid item xs="3">
                            <TextField
                              fullWidth
                              label="Basic Pay"
                              size="small"
                              disabled
                              value={formattedAmountBP}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="3">
                            <TextField
                              fullWidth
                              label="Allowance"
                              size="small"
                              disabled
                              value={formattedAmountAllo}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="3">
                            <TextField
                              fullWidth
                              disabled
                              label="Gross Pay"
                              size="small"
                              value={formattedAmountGP}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                          <Grid item xs="2">
                            <p>Overtime x {overtimePay}</p>
                          </Grid>
                          <Grid item xs="3">
                            <TextField
                              size="small"
                              fullWidth
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              label="Number of Hour/s (Overtime)"
                              value={overtime}
                              onChange={handleOvertimeChange}
                            />
                          </Grid>
                          <Grid item xs="3">
                            <TextField
                              size="small"
                              fullWidth
                              label="Incentives"
                              // value={incentives}
                              // onChange={(e) =>
                              //   setIncentives(parseFloat(e.target.value) || 0) }
                              onChange={handleIncentivesChange}
                              value={formattedIncentives}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          {/* <Grid item xs="1">
                                </Grid>
                          <Grid item xs="3">
                            <TextField size='small' fullWidth label="Take Home Pay" value={takeHomePay} />
                          </Grid> */}
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>Deductions </Typography>   <Typography variant="body1" sx={{ fontStyle: "italic", color: "red" }}>Please remember to click the "Calculate" button after making changes. </Typography>
                          </Grid>
                          <Grid item xs="4">
                            <TextField
                              fullWidth
                              label="Total Lates (Day/s)"
                              size="small"
                              value={lates}
                              onChange={handleLatesChange}
                            />
                          </Grid>
                          <Grid item xs="4">
                            <TextField
                              fullWidth
                              label="Total Absences (Day/s)"
                              size="small"
                              value={absences}
                              onChange={(e) =>
                                setAbsences(parseFloat(e.target.value) || 0)
                              }
                            />
                          </Grid>
                          <Grid item xs="4">
                            <TextField
                              fullWidth
                              // disabled
                              label="Unpaid Leaves (Day/s)"
                              size="small"
                              value={unpaidLeaves}
                              onChange={(e) =>
                                setUnpaidLeaves(parseFloat(e.target.value) || 0)
                              }
                            />
                          </Grid>
                          <Grid item xs="12" sx={{ paddingBottom: "0px" }}>
                            <Divider />
                          </Grid>
                          <Grid item xs="1.5">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={calculate}
                            >
                              calculate
                            </Button>
                            {/* <Button
                              variant="contained"
                              color="success"
                              onClick={test}
                            >
                              asdsad
                            </Button> */}
                          </Grid>
                          <Grid item xs="3.5">
                            <TextField
                              fullWidth
                              // disabled
                              label="Philhealth (Premium Share)"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              size="small"
                              value={formattedAmountPH}
                              onChange={(e) =>
                                setPhilhealth_deduc(parseFloat(e.target.value) || 0)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="3.5">
                            <TextField
                              fullWidth
                              // disabled
                              label="HMDF (Premium Share)"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              size="small"
                              value={formattedAmountHMDF}
                              onChange={(e) =>
                                setHmdf_deduc(parseFloat(e.target.value) || 0)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="3.5">
                            <TextField
                              fullWidth
                              // disabled
                              label="SSS (Premium Share)"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              size="small"
                              value={formattedAmountSSS}
                              onChange={(e) =>
                                setSss_deduc(parseFloat(e.target.value) || 0)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          
                          <Grid item xs="2.4">
                            <TextField
                              fullWidth
                              label="Withholding Taxes"
                              size="small"
                              type="number"
                              step="any"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              value={taxes_deduc}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setTaxes_deduc(isNaN(value) ? 0 : value); // Check if the parsed value is NaN and fallback to  0
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs="2.4">
                            <TextField
                              fullWidth
                              // disabled
                              type="number"
                              step="any"
                              label="Cash Advances"
                              size="small"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              value={cashAdv_deduc}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setCashAdv_deduc(isNaN(value) ? 0 : value);
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="2.4">
                            <TextField
                              fullWidth
                              // disabled
                              type="number"
                              step="any"
                              label="SSS Loan"
                              size="small"
                              value={sss_loan}
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setSss_loan(isNaN(value) ? 0 : value);
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="2.4">
                            <TextField
                              fullWidth
                              // disabled
                              type="number"
                              step="any"
                              label="HMDF Loan"
                              InputLabelProps={{
                                style: { width: '100%' }
                              }}
                              size="small"
                              value={hmdf_loan}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setHmdf_loan(isNaN(value) ? 0 : value);
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="2.4">
                            <TextField
                              fullWidth
                              label="Others"
                              size="small"
                              type="number"
                              step="any"
                              value={others_deduc}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setOthers_deduc(isNaN(value) ? 0 : value);
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₱
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Remarks"
                              multiline
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                              rows={2}
                              sx={{
                                width: "100%",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>

                      <FormGroup
                        sx={{ display: activeStep === 2 ? "" : "none", m: 2 }}
                      >
                        <Grid container item direction="row" spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <img src={logo} height="auto" width="30%" />
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Payroll for the Period:
                          </Grid>
                          <Grid item xs="10">
                            {periodStart} - {periodEnd}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Employee Name:
                          </Grid>
                          <Grid item xs="3">
                            {selectedInfo.firstname +
                              " " +
                              selectedInfo.lastname}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Department:
                          </Grid>
                          <Grid item xs="5">
                            {selectedInfo.department}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Basic Pay:
                          </Grid>
                          <Grid item xs="10">
                            ₱ {selectedInfo.basic_pay / 2}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Allowance:
                          </Grid>
                          <Grid item xs="10">
                            ₱ {selectedInfo.allowance / 2}
                          </Grid>
                          {/* <Grid item xs="2"
                            sx={
                              styles.caption
                            }
                          >
                            Gross Pay:
                          </Grid>
                          <Grid item xs="10">
                            ₱ {totalPay}
                          </Grid> */}
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Overtime Pay:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {overtimeTotal}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Incentives:
                          </Grid>
                          <Grid item xs="5">
                            ₱ {incentives}
                          </Grid>
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                          <Grid item xs="12">
                            <Typography sx={{ fontSize: "20px" }}>
                              Deductions
                            </Typography>
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Philhealth (Premium Share):
                          </Grid>
                          <Grid item xs="3">
                            ₱ {parseFloat(philhealth_deduc).toFixed(2)}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            HMDF (Premium Share):
                          </Grid>
                          <Grid item xs="5">
                            ₱ {hmdf_deduc}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            SSS (Premium Share):
                          </Grid>
                          <Grid item xs="3">
                            ₱ {parseFloat(sss_deduc).toFixed(2)}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Withholding Taxes:
                          </Grid>
                          <Grid item xs="5">
                            ₱ {taxes_deduc}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            SSS Loan:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {parseFloat(sss_loan).toFixed(2)}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            HMDF Loan:
                          </Grid>
                          <Grid item xs="5">
                            ₱ {parseFloat(hmdf_loan).toFixed(2)}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Cash Advances:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {cashAdv_deduc}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Others:
                          </Grid>
                          <Grid item xs="5">
                            ₱ {others_deduc}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Total Absences:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {parseFloat(absences * deductPerDay).toFixed(2)} - {absences} day/s
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Unpaid Leaves:
                          </Grid>
                          <Grid item xs="5">
                            ₱ {(unpaidLeaves * deductPerDay).toFixed(2)} - {unpaidLeaves} day/s
                            day/s
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Allowance Deduction:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {allowance_deduc} - {absences} day/s
                          </Grid>    <Grid item xs="2" sx={styles.caption}>
                            Total Lates:
                          </Grid>
                          <Grid item xs="3">
                            ₱ {(convert_lates * deductPerDay).toFixed(2)} - {lates} day/s
                          </Grid>
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Gross Pay:
                          </Grid>
                          <Grid item xs="2">
                            ₱ {grossPay.toLocaleString()}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Total Deductions:
                          </Grid>
                          <Grid item xs="2">
                            ₱ {total_deduction.toLocaleString()}
                          </Grid>
                          <Grid item xs="2" sx={styles.caption}>
                            Take Home Pay:
                          </Grid>
                          <Grid item xs="2">
                            ₱ {takeHomePay.toLocaleString()}
                          </Grid>
                          <Grid item xs="12">
                            <Divider />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </form>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={() => {
                          handleBack();
                          setTrigger(!trigger);
                        }}
                        sx={{
                          ml: 1,
                          marginBottom: 5,
                        }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        variant="contained"
                        onClick={handleSkip}
                        sx={{ mr: 1, marginBottom: 5 }}
                      >
                        Skip
                      </Button>

                      {selectedEmployee === "" ? (
                        <>
                          <Typography
                            sx={{
                              textAlign: "center",
                              padding: "10px",
                              color: "red",
                            }}
                          >
                            Select Employee
                          </Typography>
                        </>
                      ) : (
                        <>
                          {completedSteps() === 0 ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleComplete1();
                                handleOpenEmp();
                                setTrigger(!trigger);
                              }}
                              sx={{ mr: 1, marginBottom: 5 }}
                            >
                              {completedSteps() === totalSteps() - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          ) : completedSteps() === 1 ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleComplete2();
                                nextCalculate();
                                setTrigger(!trigger);
                              }}
                              sx={{ mr: 1, marginBottom: 5 }}
                            >
                              {completedSteps() === totalSteps() - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                onClick={() => {
                             
                                  create();
                                  setTrigger(!trigger);
                                }}
                                sx={{ mr: 1, marginBottom: 5 }}
                              >
                                {completedSteps() === totalSteps() - 1
                                  ? "Finish"
                                  : "Next"}
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
