import React from 'react'
import { Grid, TextField, Card, Box, Divider, Modal, Button, CardContent } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import sampleLogo from "../../images/projectimg.jpg";
import ModalAdd from './addProjectModal'

export default function Project() {
  // Modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}>
        <Box sx={style}>
          <ModalAdd />
        </Box>
      </Modal>

      <Grid container item
        direction="row">
        <Grid container item
          direction="column"
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{
            backgroundColor: "white",
            boxShadow: "2px 3px 4px 4px #e1e3e6"
          }}>
          <Grid container item
            direction="row"
            sx={{
              backgroundColor: "#0f1140",
              padding: "10px",
              color: "white",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Grid item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}>
              PROJECTS
            </Grid>
            <Grid item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}>
              <Button variant='contained'
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  '&:hover': {
                    backgroundColor: "3bb4e3",
                    color: "white"
                  },
                }} onClick={handleOpen}
              >Add</Button>
            </Grid>
          </Grid>
          <Grid item>
            <List sx={{
              width: '100%',
              minHeight: 600,
            }}>
              <ListItem>
                <ListItemButton >
                  <ListItemText>
                    Project 1
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton >
                  <ListItemText>
                    Project 2
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Grid container item
          direction="column"
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          sx={{
            paddingLeft: 1
          }}
          alignItems="center">

          <Grid container item
            direction="row"
            sx={{
              backgroundColor: "white",
              boxShadow: "1px 2px 3px 1px #888888",
              borderRadius: "10px"
            }}
            justifyContent="center"
            alignItems="flex-start"
            rowGap={1}>
            <Grid item
              xs={12}
              sx={{
                textAlign: "center",
                paddingTop: "10px"
              }}>
              <img src={sampleLogo} width="180" height="auto" />
            </Grid>

            <Grid item xs={2}>
            </Grid>
            <Grid item
              xs={2}
            >
              Company:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item
              xs={2}
            >
              Company Address:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item xs={2}>
            </Grid>

            <Grid item xs={2}>
            </Grid>
            <Grid item
              xs={2}
            >
              Amount of Contract:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item
              xs={2}
            >
              Kind of Goods:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item xs={2}>
            </Grid>

            <Grid item xs={2}>
            </Grid>
            <Grid item
              xs={2}
            >
              Website Address:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item
              xs={2}
            >
              Description:
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item xs={2}>
            </Grid>

            <Grid item xs={2}>
            </Grid>
            <Grid item
              xs={2}
            >
              <b>Start Date:</b>
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item
              xs={2}
            >

              <b>End Date:</b>
            </Grid>
            <Grid item
              xs={2}
            >
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container
              direction="row"
              columnSpacing={2}
              sx={{
                paddingLeft: "40px",
              }}

            >
              <Grid item
                xs={12}
                sx={{
                  fontSize: "20px",
                }}
              >
                Client Contact
              </Grid>
              <br /><br />
              <Grid item
                xs={3}
              >
                Client Name:
              </Grid>
              <Grid item
                xs={3}
              >
              </Grid>
              <Grid item
                xs={3}
              >
                Representative:
              </Grid>
              <Grid item
                xs={3}
              >
              </Grid>

              <Grid item
                xs={3}
              >
                Email:
              </Grid>
              <Grid item
                xs={3}
              >
              </Grid>
              <Grid item
                xs={3}
              >
                Rep. Email:
              </Grid>
              <Grid item
                xs={3}
              >

              </Grid>

              <Grid item
                xs={3}
              >
                Contact No.:
              </Grid>
              <Grid item
                xs={3}
              >
              </Grid>
              <Grid item
                xs={3}
              >
                Rep. Contact No.:
              </Grid>
              <Grid item
                xs={3}
              >

              </Grid>
              <br /><br />

              <Grid item
                xs={12}
              >
                Remarks:
              </Grid>
              <Grid item
                xs={12}
              >
                <TextField fullWidth
                  disabled
                  multiline
                  minRows={6}
                  value="remarks"
                  sx={{
                    padding: "20px"
                  }}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item container
            direction="row"
          >

            <Grid item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <Card sx={{
                minWidth: 275,
                maxHeight: 135,
                backgroundColor: "#304e67",
                color: "white",
                margin: "10px"
              }}>
                <CardContent>
                  <Grid item container
                    direction="column"
                  >
                    <Grid item
                      sx={{
                        height: 40,
                        paddingLeft: 0.5
                      }}
                    >
                      <h4>Open</h4>
                    </Grid>
                    <Grid item container
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item
                        xs={2}
                      >
                      </Grid>
                      <Grid item>
                        <h2>0000 Total</h2>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <Card sx={{
                minWidth: 275,
                maxHeight: 135,
                backgroundColor: "#304e67",
                color: "white",
                margin: "10px"
              }}>
                <CardContent>
                  <Grid item container
                    direction="column"
                  >
                    <Grid item
                      sx={{
                        height: 40,
                        paddingLeft: 0.5
                      }}
                    >
                      <h4>Ongoing</h4>
                    </Grid>
                    <Grid item container
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item
                        xs={2}
                      >
                      </Grid>
                      <Grid item>
                        <h2>0000 Total</h2>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <Card sx={{
                minWidth: 275,
                maxHeight: 135,
                backgroundColor: "#304e67",
                color: "white",
                margin: "10px"
              }}>
                <CardContent>
                  <Grid item container
                    direction="column"
                  >
                    <Grid item
                      sx={{
                        height: 40,
                        paddingLeft: 0.5
                      }}
                    >
                      <h4>Acceptance</h4>
                    </Grid>
                    <Grid item container
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item
                        xs={2}
                      >
                      </Grid>
                      <Grid item>
                        <h2>0000 Total</h2>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <Card sx={{
                minWidth: 275,
                maxHeight: 135,
                backgroundColor: "#304e67",
                color: "white",
                margin: "10px"
              }}>
                <CardContent>
                  <Grid item container
                    direction="column"
                  >
                    <Grid item
                      sx={{
                        height: 40,
                        paddingLeft: 0.5
                      }}
                    >
                      <h4>Cleared</h4>
                    </Grid>
                    <Grid item container
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item
                        xs={2}
                      >
                      </Grid>
                      <Grid item>
                        <h2>0000 Total</h2>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>


          </Grid>

        </Grid>


      </Grid>
    </>
  )
}
