import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  MenuItem,
  Box,
  Button,
  Modal,
  CardActionArea,
  CardContent,
  Divider,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TodayIcon from "@mui/icons-material/Today";
import MapIcon from "@mui/icons-material/Map";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import Swal from "sweetalert2";

export default function Rules() {
  const { user } = useAuthContext();

  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, [user]);

  // Create Regulation
  const [regulation_type, setRegulation_type] = useState("");
  const [regulation_description, setRegulation_description] = useState("");
  const create = async () => {
    if (regulation_type.trim() === "" || regulation_description.trim() === "") {
      // Input is empty
      // console.log(user_id)
      alertError();
      return;
    }
    axios.post(
      "https://hr.geospectrum.com.ph/api/api/regulation/create",
      {
        regulation_type,
        regulation_description,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    alertSuccess();
    setRegulation_type("");
    setRegulation_description("");
  };
  //

  // Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Modal Add New Regulation
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => setOpenModalAdd(false);

  // Modal View Regulations
  const [regulations, setRegulations] = React.useState(false);
  const [openModalRegs, setOpenModalRegs] = React.useState(false);
  const handleOpenModalRegs = (regType) => {
    fetch("https://hr.geospectrum.com.ph/api/api/regulation/getRegulation" + regType, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setRegulations(data);
      });

    setOpenModalRegs(true);
  };
  const handleCloseModalRegs = () => setOpenModalRegs(false);

  // Success Updating Alert
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Regulation has been added",
    });
    handleCloseModalAdd();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Information is needed",
    });
    handleCloseModalAdd();
  };

  return (
    <>
      {/* Modal Add New Rule/Regulation */}
      <Modal open={openModalAdd} onClose={handleCloseModalAdd}>
        <Box sx={style}>
          <Grid item container direction="row" spacing={2}>
            <Grid item>
              <h2>Add New Regulation</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                label="Type"
                onChange={(e) => setRegulation_type(e.target.value)}
                fullWidth
              >
                <MenuItem value={"Personnel"}>Personnel</MenuItem>
                <MenuItem value={"Production Area"}>Production Area</MenuItem>
                <MenuItem value={"Process"}>Process</MenuItem>
                <MenuItem value={"Punctuality"}>Punctuality</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={3}
                label="Description"
                onChange={(e) => setRegulation_description(e.target.value)}
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button variant="contained" color="success" onClick={create}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal View Rules and Regulations */}
      <Modal
        sx={{
          overflow: "hidden",
        }}
        open={openModalRegs}
        onClose={handleCloseModalRegs}
      >
        <Box sx={style}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "auto",
              maxHeight: 740,
            }}
            component="nav"
            aria-labelledby=" Rules and Regulations"
            subheader={
              <ListSubheader component="div">
                Rules and Regulations
              </ListSubheader>
            }
          >
            {regulations &&
              regulations.map((rules, index) => (
                <ListItemButton key={index}>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={rules.regulation_description} />
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Modal>
      <Grid
        container item sx={{ height: { xs: '', sm: '', md: '88vh', lg: '88vh', xl: '89vh' }, overflow: "auto" }}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}

        >
          <Grid item sx={{
              textAlign: "center",
            }} >
            <Typography variant="h6">
              EMPLOYEES’ CODE OF CONDUCT AND DISCIPLINE AND SAFETY RULES AND
              REGULATIONS
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Divider />
          </Grid>

          <Grid
            item
            xs={8}
            sx={{
              textAlign: "center",
            }}
          >
            Geospectrum Marketing Services is issuing this updated Employees’ Code
            of Conduct and Discipline and Safety Rules and Regulations as of
            January 2, 2020. The Employees’ Code of Conduct and Discipline and
            Safety Rules and Regulations that is stated in the Contract of
            Project-Based Employment provision number 14:
            <br />
            <br />
            “I shall faithfully abide, obey and comply with the Code of Employees
            Discipline and Safety Rules and Regulations of the firm.” All Project
            Personnel signed to Contract of Project-Based Employment is required
            to follow these new Rules and Regulations set by the firm.
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item direction="row" xs={10} columns={12}>
            <Grid
              item
              sx={{
                textAlign: "center",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {userData.account_role === "superadmin" ? (
                <Button variant="contained" onClick={handleOpenModalAdd}>
                  Add new
                </Button>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardActionArea
                onClick={() => {
                  handleOpenModalRegs("Personnel");
                }}
              >
                <Card
                  sx={{
                    minHeight: 250,
                    backgroundColor: "#ffffff",
                    color: "black",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <SupervisorAccountIcon
                          sx={{
                            fontSize: { xs: '', sm: '', md:110 , lg: 130, xl: 140 },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <h2>Personnel</h2>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardActionArea
                onClick={() => {
                  handleOpenModalRegs("Production Area");
                }}
              >
                <Card
                  sx={{
                    minHeight: 250,
                    backgroundColor: "#ffffff",
                    color: "black",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <MapIcon
                          sx={{
                            fontSize: { xs: '', sm: '', md:110 , lg: 130, xl: 140 },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <h2>Production Area</h2>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardActionArea
                onClick={() => {
                  handleOpenModalRegs("Process");
                }}
              >
                <Card
                  sx={{
                    minHeight: 250,
                    backgroundColor: "#ffffff",
                    color: "black",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <SettingsIcon
                          sx={{
                            fontSize: { xs: '', sm: '', md:110 , lg: 130, xl: 140 },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <h2>Process</h2>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardActionArea
                onClick={() => {
                  handleOpenModalRegs("Punctuality");
                }}
              >
                <Card
                  sx={{
                    minHeight: 250,
                    backgroundColor: "#ffffff",
                    color: "black",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Grid
                      item
                      container
                      direction="column"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <TodayIcon
                          sx={{
                            fontSize: { xs: '', sm: '', md:110 , lg: 130, xl: 140 },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <h2>Punctuality</h2>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
