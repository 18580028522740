import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import "./index.css";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Hidden, Box, Card, CardContent, CardMedia, IconButton, Typography, Grid, Tooltip, Badge } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import PaymentIcon from "@mui/icons-material/Payment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LogoutIcon from "@mui/icons-material/Logout";
import ReorderIcon from "@mui/icons-material/Reorder";
import bannerLogo from "../../images/gspec_header_png_whiteG_B.png";
import ArchiveIcon from '@mui/icons-material/Archive';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ArticleIcon from '@mui/icons-material/Article';
import COEPDF from '../../documents/certOFEmp.js'
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import moment from 'moment';
import {
  CssBaseline,
} from "@mui/material";
import { useLogout } from "../../hooks/useLogout";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import CakeIcon from '@mui/icons-material/Cake';
import Clock from 'react-live-clock';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Routes
import Home from "../dashboard/index.js";
import Announcement from "../announcement/index.js";
import Calendar from "../calendar/index.js";
import LeaveRequest from "../leaveRequest/index.js";
import Project from "../projects/index.js";
import CashAdvance from "../cashAdvance/index";
import Employee from "../employee/index.js";
import Department from "../department/index.js";
import Payslip from "../payslip";
import Rules from "../rules";
import Inventory from "../inventory/index.js";
import Archive from "../archive/index.js";
import BeatLoader from "react-spinners/BeatLoader";
import ItemRequest from "../itemRequest/index.js";
import Employeeleave from "../employeeLeave/index.js";

// Supervisor
import RegularUserCashAdvance from "../Userpage/CashAdvance/index.js";
import UserPayslip from "../Userpage/payslip/index";
import UserItemRequest from "../Userpage/ItemRequest/index.js";
import MobileLayout from "./mobile/index.js";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  height: { md: '8vh', lg: '5vh', xl: '7vh' },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    height: { md: '8vh', lg: '5vh', xl: '7vh' },
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function useAppbarHeight() {
  const [appbarHeight, setAppbarHeight] = React.useState(0)

  React.useEffect(() => {
    const appBar = document.querySelector("header.MuiAppBar-root")
    setAppbarHeight(appBar?.clientHeight || 0)

    function handleResize() {
      setAppbarHeight(appBar?.clientHeight || 0)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return appbarHeight
}

export default function MiniDrawer() {
  const { user } = useAuthContext();

  const toolBarHeight = useAppbarHeight()
  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  const [birthday, setBirthday] = useState([]);
  React.useEffect(() => {

    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });


    fetch("https://hr.geospectrum.com.ph/api/api/user/selectBday")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setBirthday(data);
      });

  }, []);

  const navigate = useNavigate();

  const { logout } = useLogout();
  const handleClickLogout = () => {
    logout();
  };

  // Preloader
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    height: `calc(${toolBarHeight}px)`,
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Birthday Dialog
  const [openDia, setOpenDia] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDia(true);
  };
  const handleClose = () => {
    setOpenDia(false);
  };
  const currentDate = new Date();
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openDia}
        onClose={handleClose}
      >
        <DialogTitle>Birthdays this Month</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" >
            {birthday &&
              birthday.map((bday, index) => (
                <Grid item key={index} sx={{ width: '100%' }}>
                  <Card sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
                        <Typography component="div" variant="h6">
                          {bday.firstname + " " + bday.lastname}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                          {moment(bday.birth_date).format('MMMM DD')}
                        </Typography>
                      </CardContent>
                    </Box>
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={`https://hr.geospectrum.com.ph/api/profile/` + bday.photo_path}
                    />
                  </Card>
                </Grid>
              ))}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <div className="center_loader">
          <BeatLoader
            color={"#5197c1ff"}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Hidden lgDown>
            <div id='app-container'
            >
              <CssBaseline />
              <AppBar position="fixed" open={!open}>
                <div id="toolbar">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                    sx={{
                      marginRight: 5,
                      ...(!open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <h5 id='account-role'>
                    {userData.account_role === "superadmin" ? "Superadmin" : "Supervisor"}
                  </h5>
                  <p id='date-and-time'>
                    <PDFDownloadLink
                      document={<COEPDF
                        date={moment(currentDate).format("LL")}
                        position={userData.position}
                        department={userData.department}
                        fname={userData.firstname}
                        lname={userData.lastname}
                        title={userData.civil_status === "Single" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Married" && userData.gender === "Female" ? "Mrs." : userData.civil_status === "Divorced" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Widowed" && userData.gender === "Female" ? "Mrs.": "Mr."}
                        dateEmployed={moment(userData.date_employed).format("LL")}
                        email={userData.email}
                      />}
                      fileName={`Certificate of Employment_${userData.lastname}`}
                    >
                      {({ blob, url, loading, error }) => (
                        <Tooltip title="Certificate of Employment">
                          <IconButton aria-label="test" sx={{ color: "white", paddingRight: 1 }}>
                         
                              <ArticleIcon fontSize="small" />
            
                          </IconButton>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>


                    <Tooltip title="Birthday">
                      <IconButton aria-label="test" sx={{ color: "white", paddingRight: 2 }} onClick={handleClickOpen}>
                       
                          <CakeIcon fontSize="small" />
                      
                      </IconButton>
                    </Tooltip>


                    {(new Date()).toLocaleDateString('en', { weekday: 'long' })}, {(new Date()).toLocaleDateString('en', { month: 'long' })} {(new Date()).getDate()}, {(new Date()).getFullYear()} &nbsp; <Clock format={'h:mm:ssa'} ticking={true} interval={1000} />
                  </p>
                </div>
              </AppBar>
              <Drawer
                variant="permanent"
                open={!open}
                PaperProps={{
                  sx: {
                    backgroundColor: "#0F1140",
                    color: "white",
                    width: 800
                  },
                }}
              >
                <DrawerHeader>
                  <button id='drawer-header-logo' onClick={() => navigate('/admin/home')}>
                    <img src={bannerLogo} alt="Banner Logo" />
                  </button>
                  <button id='drawer-header-logo'
                    onClick={handleDrawerOpen}
                  >
                    {!open ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ReorderIcon />
                    )}
                  </button>
                </DrawerHeader>
                {[
                  "Announcement",
                  "Leave Request",
                  "Cash Advance",
                  "Item Request",
                  "Archive",
                ].map((text, index) => (
                  <button className={!open ? 'drawer-list-item' : 'drawer-list-item-closed'}
                    onClick={() =>
                      navigate(
                        `/admin/${text.toLowerCase().replace(/\s/g, "")}`
                      )
                    }
                  >
                    {index === 0 ? (
                      <InfoIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    ) : index === 1 ? (
                      <DescriptionIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    ) : index === 2 ? (
                      <AccountBalanceWalletIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    ) : index === 3 ? (
                      <PostAddIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    ) : (
                      <ArchiveIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    )}
                    {!open ?
                      <p className='drawer-list-label'>
                        {text}
                      </p>
                      : null
                    }

                  </button>
                ))}
                <div className='divider' />
                {["Inventory", "Departments", "Employees", "Payslip", "Employee Leave"].map(
                  (text, index) => (
                    <button className={!open ? 'drawer-list-item' : 'drawer-list-item-closed'}
                      onClick={() =>
                        navigate(
                          `/admin/${text.toLowerCase().replace(/\s/g, "")}`
                        )
                      }
                    >
                      {index === 0 ? (
                        <Inventory2Icon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      ) : index === 1 ? (
                        <BusinessIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      ) : index === 2 ? (
                        <GroupsIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      ) : index === 3 ? (
                        <PaymentIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      ) : (
                        <DateRangeIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      )}
                      {!open ?
                        <p className='drawer-list-label'>
                          {text}
                        </p>
                        : null
                      }
                    </button>
                  ))}

                <div className='divider' />

                {["Rules & Regulations"].map((text, index) => (
                  <button className={!open ? 'drawer-list-item' : 'drawer-list-item-closed'}
                    onClick={() =>
                      navigate(
                        `/admin/${text.toLowerCase().replace(/\s/g, "")}`
                      )
                    }
                  >
                    {index === 0 ?
                      <MenuBookIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                      : <LogoutIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                    }
                    {!open ?
                      <p className='drawer-list-label'>
                        {text}
                      </p>
                      : null
                    }
                  </button>
                ))}

                <div className='divider' />

                <button className={!open ? 'drawer-list-item' : 'drawer-list-item-closed'}
                  onClick={handleClickLogout}
                >

                  <LogoutIcon className={!open ? 'drawer-list-icon' : 'drawer-list-icon-closed'} />
                  {!open ?
                    <p className='drawer-list-label'>
                      Logout
                    </p>
                    : null
                  }
                </button>
              </Drawer>

              <div component="main" id='routes-container'>
                <div id='header-filler' />
                <Routes>
                  <Route path="/"> </Route>
                  <Route path="/home" element={<Home />}>
                    {" "}
                  </Route>
                  <Route path="/announcement" element={<Announcement />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/leaverequest" element={<LeaveRequest />} />
                  <Route path="/cashadvance" element={userData.account_role === 'supervisor' || userData.email === 'it.support@geospectrum.com.ph' ? <RegularUserCashAdvance /> : <CashAdvance />} />
                  <Route path="/projects" element={<Project />} />
                  <Route path="/employees" element={<Employee />} />
                  <Route path="/departments" element={<Department />} />
                  <Route path="/archive" element={<Archive />} />
                  <Route path="/employeeleave" element={<Employeeleave />} />
                  <Route path="/itemrequest" element={userData.account_role === 'superadmin' || userData.email === 'pgtodoc7@gmail.com' ? <ItemRequest /> : <UserItemRequest />} />
                  <Route path="/payslip" element={userData.email === 'medillojianna@geospectrum.com.ph' || userData.email === 'vssantos@geospectrum.com.ph' ? <Payslip /> : <UserPayslip />} />
                  <Route path="/rules&regulations" element={<Rules />} />
                  <Route path="/inventory" element={<Inventory />} />
                  <Route path="*" element={<div>page not found</div>} />
                </Routes>
              </div>
            </div>
          </Hidden>
          <Hidden lgUp>
            <MobileLayout userData={userData} />
          </Hidden>
        </>)}
    </>
  );
}
