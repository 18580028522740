import React, { Fragment } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, } from '@react-pdf/renderer';
import logo from '../../src/images/logo_line.png'


function LeavePDF(props) {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 10,
            paddingTop: 30,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1,
            flexDirection: 'column',
        },
        logo: {
            width: 270,
            height: 'auto',
            marginTop: 15,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        address: {
            textAlign: 'center',
            fontSize: '10pt',
            marginTop: 5
        },
        address2: {
            textAlign: 'center',
            fontSize: '10pt',
            marginTop: 0,
            paddingBottom: 20,
            borderBottomColor: '#ebebeb',
            borderBottomWidth: 1,
        },
        centerCont: {
            marginTop: 3,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 8,
            borderBottomColor: '#ebebeb',
            borderBottomWidth: 1,
        },
        centerCont2: {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'left',
            paddingBottom: 5,
            borderBottomColor: '#ebebeb',
            borderBottomWidth: 1,
        },
        title: {
            fontSize: 18,
            textAlign: 'center',
        },
        title2: {
            fontSize: 15,
            textAlign: 'left',
        },
        closerow: {
            flexDirection: 'row',
            borderBottomColor: '#ebebeb',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 17,
        },
        openrow: {
            flexDirection: 'row',
            // borderBottomColor: '#bff0fd',
            // borderBottomWidth: 1,
            alignItems: 'center',
            height: 17,
            fontStyle: 'bold',
        },
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 5,
            borderWidth: 1,
            borderColor: '#ebebeb',
        },
        descriptionL: {
            width: '20%',
            textAlign: 'left',
            paddingLeft: 3,
            fontWeight: 'Bold',
            fontSize: 11,
            borderRightWidth: 1,
            borderRightColor: '#ebebeb',
        },
        dataL: {
            width: '30%',
            textAlign: 'left',
            paddingLeft: 8,
            color: "#919191",
            fontSize: 10,
            borderRightWidth: 1,
            borderRightColor: '#ebebeb',
        },
        descriptionD: {
            width: '20%',
            textAlign: 'left',
            paddingLeft: 3,
            fontWeight: 'Bold',
            fontSize: 11,
            borderRightWidth: 1,
            borderRightColor: '#ebebeb',
        },
        dataD: {
            width: '30%',
            textAlign: 'left',
            paddingLeft: 8,
            color: "#919191",
            fontSize: 10
        },
        equipCont: {
            marginTop: 10,
            flexDirection: 'row',
        },
        equipCont2: {
            marginTop: 2,
            flexDirection: 'row',
            paddingBottom: 8,
        },
        equipDescrip: {
            width: '45%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 11,
        },
        equipSpacer: {
            width: '10%',
            textAlign: 'left',
            paddingLeft: 3,
            fontWeight: 'Bold',
            fontSize: 11,
        },
        equipDescrip2: {
            width: '45%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 11
        },
        equipDescripx1: {
            width: '45%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 10,
            backgroundColor: '#FBFBFB',
            color: "#919191",
        },
        equipDescripx2: {
            width: '45%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 10,
            backgroundColor: '#FBFBFB',
            color: "#919191",
        },
        descripCont: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 5,
            borderWidth: 1,
            borderColor: '#ebebeb',
        },
        description: {
            width: '100%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 10,
            color: "#919191",
            paddingBottom: 5,
            paddingTop: 5
        },
        textCont: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 3,
        },
        textContF: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 20,
        },
        text: {
            width: '100%',
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 11,
            paddingTop: 5,
            lineHeight: 1.5,
            textAlign: 'justify'
        },
        textF: {
            width: '100%',
            color: "red",
            textAlign: 'left',
            paddingLeft: 8,
            fontSize: 9,
            paddingTop: 5,
            lineHeight: 1.5,
            textAlign: 'justify',
            fontStyle: 'italic'
        },
        signContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 45,
        },
        sign: {
            width: '30%',
            textAlign: 'center',
            fontSize: 11,
            paddingTop: 5,
            lineHeight: 1.5,
            marginRight: 10,
            borderTopWidth: 1,
            borderTopColor: "black"
        },
        sign1: {
            width: '30%',
            textAlign: 'center',
            fontSize: 7,
            lineHeight: 0.5,
        },
    })

    const {
        issueDate,
        returnDate,
        name,
        department,
        designation,
        id,
        equipment,
        serial,
        description,
        remarks,
    } = props

    function isValidDate(dateString) {
        // Check if dateString is a valid date
        return !isNaN(Date.parse(dateString));
    }

    return (
        <Document>
            <Page size={{ width: 8.5 * 72, height: 13 * 72 }} style={styles.page}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.address}>
                    Unit 804, Linden Suites Tower 1, 37 San Miguel Avenue,
                </Text>
                <Text style={styles.address2}>
                    Ortigas Center, Pasig City, Philippines
                </Text>
                <View style={styles.centerCont}>
                    <Text style={styles.title}>Employee Equipment Agreement</Text>
                </View>
                <View style={styles.tableContainer}>
                    <View style={styles.closerow}>
                        <Text style={styles.descriptionL}>Issue Date:</Text>
                        <Text style={styles.dataL}>
                            {issueDate && isValidDate(issueDate) ? issueDate : ' '}
                        </Text>
                        <Text style={styles.descriptionD}>Return Date:</Text>
                        <Text style={styles.dataD}>
                            {returnDate && isValidDate(returnDate) ? returnDate : ' '}
                        </Text>
                    </View>

                    <View style={styles.closerow}>
                        <Text style={styles.descriptionL}> Employee Name:</Text>
                        <Text style={styles.dataL}>{name}</Text>
                        <Text style={styles.descriptionD}>Department: </Text>
                        <Text style={styles.dataD}> {department}</Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.descriptionL}> Designation:</Text>
                        <Text style={styles.dataL}>{designation}</Text>
                        <Text style={styles.descriptionD}>Employee ID: </Text>
                        <Text style={styles.dataD}> {id}</Text>
                    </View>
                </View>
                <View style={styles.centerCont2}>
                    <Text style={styles.title2}>Equipment Information</Text>
                </View>
                <View style={styles.equipCont}>
                    <Text style={styles.equipDescrip}>Item/Equipment Name:</Text>
                    <Text style={styles.equipSpacer}> </Text>
                    <Text style={styles.equipDescrip2}> Serial No:</Text>
                </View>
                <View style={styles.equipCont2}>
                    <Text style={styles.equipDescripx1}>{equipment}</Text>
                    <Text style={styles.equipSpacer}> </Text>
                    <Text style={styles.equipDescripx2}> {serial}</Text>
                </View>
                <View style={styles.equipCont}>
                    <Text style={styles.equipDescrip}>Item/Equipment Description:</Text>
                </View>
                <View style={styles.descripCont}>
                    <Text style={styles.description}>{description}</Text>
                </View>
                <View style={styles.textCont}>
                    <Text style={styles.text}>                           The Employer and the Employee agreed that the equipment above is company-owned, and the
                        Employee shall use it for work-related purposes only. The Employee is accountable for the proper
                        care and maintenance of the equipment. Should the equipment sustain any damage, loss, or theft
                        outside the workplace, the Employee is liable for the associated costs and replacement expenses.
                        If the equipment experiences wear and tear resulting in breakage, the Employee must promptly
                        report it to the IT department. Upon the termination of the Employment Agreement, the Employee
                        is obligated to return the equipment to the Employer.</Text>
                    <Text style={styles.text}>                 Upon receipt of this Agreement, both parties acknowledge that the Employee has received the
                        office equipment above in functional condition and agrees to adhere to the stipulated terms and
                        conditions for its use.

                    </Text>
                </View>
                <View style={styles.equipCont}>
                    <Text style={styles.equipDescrip}>Remarks:</Text>
                </View>
                <View style={styles.descripCont}>
                    <Text style={styles.description}>{remarks}</Text>
                </View>

                <View style={styles.signContainer}>
                    <Text style={styles.sign}>{name}</Text>
                    <Text style={styles.sign1}></Text>
                    <Text style={styles.sign1}></Text>
                    <Text style={styles.sign1}>{designation}</Text>
                </View>
                <View style={styles.signContainer}>
                    <Text style={styles.sign1}></Text>
                    <Text style={styles.sign}>Engr. Pamela Anne Todoc</Text>
                    <Text style={styles.sign}>Jianna Iza Medillo</Text>
                    <Text style={styles.sign1}></Text>
                    <Text style={styles.sign1}>Spatial IT Supervisor</Text>
                    <Text style={styles.sign1}>  Human Resources & Administrative Officer</Text>
                </View>

                <View style={styles.textContF}>
                    <Text style={styles.textF}>"This agreement shall be deemed executed and legally binding upon the parties hereto, and each party
                        acknowledges and agrees that the exchange of this agreement constitutes a valid and enforceable
                        form of execution."
                    </Text>
                </View>
            </Page>
        </Document>
    )
};

export default LeavePDF;






