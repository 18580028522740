import React from 'react'
import Logo from '../../../images/gms_logo_small.png'
import eartheye from '../../../images/partner_eartheye.png'
import iceye from '../../../images/partner_iceye.png'
import ntt from '../../../images/partner_nttdata.png'
import planet from '../../../images/partner_planet.png'
import restec from '../../../images/partner_restec.png'
import spaceknow from '../../../images/partner_spaceknow.png'

import './aboutContainer.css';

export default function aboutContainer() {
  return (
    <div id='about-container'>
      <div id='about-row'>
        <img src={Logo} id='about-logo' alt="logo" height="auto"/>
        <p id='about-desc'>
          <b>GEOSPECTRUM Marketing Services</b> is a Filipino-owned business
          entity that caters to the growing need for accurate, up-to-date and
          timely information to support decision making and planning
          activities. <br />
          <br />
          <b>GEOSPECTRUM</b> distributes high resolution satellite imagery and
          value-added products that address a wide range of applications.
        </p>
      </div>
      <div id='about-row-bottom'>
         <img id="partner-logo" src={planet} alt="planet" />
         <img id="partner-logo" src={iceye} alt="iceye" />
         <img id="partner-logo" src={ntt} alt="ntt" />
         <img id="partner-logo" src={restec} alt="restec" />
         <img id="partner-logo" src={eartheye} alt="eartheye" />
         <img id="partner-logo" src={spaceknow} alt="spaceknow" />
      </div>
    </div>
  )
}
