import React, { useState, useEffect } from 'react';
import { useAuthContext } from "../../../hooks/useAuthContext";
import moment from "moment";
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  Chip,
  Stack,
  Modal,
  Tabs,
  Tab,
  Box,
  FormControl,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GMSlogo from "../../../images/gms_logo.png";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import axios from "axios";
import Swal from "sweetalert2";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UserArchive() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  // CustomTabPanel
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserData(data);
      });
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const [archiveRequest, setArchiveRequest] = useState(null);

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/getArchiveleave" + user.email, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setArchiveRequest(data);
      });
  }, [userData, trigger]);

  const filteredData = archiveRequest
    ? archiveRequest.filter((item) => {
      const itemName = item.request_type + item.status + item.request_type + moment(item.createdAt).format("LL") || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Modal View Specific LEave Request
  const [viewOneRequest, setViewOneRequest] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (idx) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/viewOneLeave" + idx)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setViewOneRequest(data);
      });
  };
  const handleClose = () => setOpen(false);
  //

  const [archiveRequestCA, setArchiveRequestCA] = useState(null);
  const [searchTermCA, setSearchTermCA] = useState('');
  React.useEffect(() => {
    fetch('https://hr.geospectrum.com.ph/api/api/cashAdvance/getArchiveCA' + user.email)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        setArchiveRequestCA(data);
      });
  }, [userData]);

  const filteredDataCA = archiveRequestCA
    ? archiveRequestCA.filter((item) => {
      const itemName = item.status + item.amount + moment(item.createdAt).format("LL") || ''; // Handle potential undefined
      const searchTermLower = searchTermCA.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  // Modal View Specific Cash Advance
  const [viewOneCARequest, setViewOneCARequest] = useState("");
  const [openCA, setOpenCA] = React.useState(false);
  const handleOpenCA = (idx) => {
    setOpenCA(true);
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/viewOneCAdvance" + idx)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setViewOneCARequest(data);
      });
  };
  const handleCloseCA = () => setOpenCA(false);
  //

  const unarchive = (idx) => {
    Swal.fire({
      title: "Unarchive Notification",
      text: "Are you sure you want to unarchive this request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/leaveRequest/update" + idx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            isArchiveUser: false,
          }
        )
        setTrigger(!trigger)
        Swal.fire({
          title: "Unarchived!",
          text: "Your file has been unarchived.",
          icon: "success"
        });
      }
    });
  }


  return (
    <>
    {/* Modal Leave Request */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {viewOneRequest &&
            viewOneRequest.map((request, index) => (
              <Grid
                key={request._id}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <img src={GMSlogo} width="310" height="auto" />
                </Grid>
                <Grid
                  item
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                >
                  Leave Application Form
                </Grid>
                {request.user.map((users, index) => (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={2}>
                      Name:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.firstname + " " + users.lastname}</b>
                    </Grid>
                    <Grid item xs={2}>
                      Designation:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.position}</b>
                    </Grid>
                    <Grid item xs={2}>
                      Department:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.department}</b>
                    </Grid>
                    <Grid item xs={2}>
                      ID Number:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.user_id}</b>
                    </Grid>
                  </Grid>
                ))}
                <Grid
                  item
                  container
                  direction="row"
                  sx={{
                    marginBottom: "40px",
                  }}
                >
                  <Grid item xs={3}>
                    Request Type:
                  </Grid>
                  <Grid item xs={3}>
                    <b>{request.request_type}</b>
                  </Grid>
                  <Grid item xs={3}>
                    Date Requested:
                  </Grid>
                  <Grid item xs={3}>
                    <b>{moment(request.createdAt).format("LL")}</b>
                  </Grid>
                  <Grid item xs={3}>
                    Date Start:
                  </Grid>
                  <Grid item xs={3}>
                    <b> {moment(request.start_date).format("LL")}</b>
                  </Grid>
                  <Grid item xs={3}>
                    Date End:
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    <b> {moment(request.end_date).format("LL")}</b>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      disabled
                      rows={3}
                      defaultValue={request.message}
                      sx={{
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Remarks"
                      defaultValue={request.remarks}
                      value={request.remarks}
                      size="small"
                      multiline
                      disabled
                      rows={4}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Modal>

   {/* Modal Cash Advance */}
      <Modal open={openCA} onClose={handleCloseCA}>
                <Box sx={style}>
                    {viewOneCARequest &&
                        viewOneCARequest.map((request, index) => (
                            <Grid
                                key={request._id}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    sx={{
                                        marginBottom: "30px",
                                    }}
                                >
                                    <img src={GMSlogo} width="310" height="auto" />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    REQUEST FOR CASH ADVANCE
                                </Grid>
                                {request.user.map((users, index) => (
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <Grid item xs={2}>
                                            Name:
                                        </Grid>
                                        <Grid item xs={4}>
                                            <b>{users.firstname + " " + users.lastname}</b>
                                        </Grid>
                                        <Grid item xs={2}>
                                            Designation:
                                        </Grid>
                                        <Grid item xs={4}>
                                            <b>{users.position}</b>
                                        </Grid>
                                        <Grid item xs={2}>
                                            Department:
                                        </Grid>
                                        <Grid item xs={4}>
                                            <b>{users.department}</b>
                                        </Grid>
                                        <Grid item xs={2}>
                                            ID Number:
                                        </Grid>
                                        <Grid item xs={4}>
                                            <b>{users.user_id}</b>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Grid item xs={4}>
                                        Amount Requested:
                                    </Grid>
                                    <Grid item xs={8}>
                                        <b> ₱ {request.amount}</b>
                                    </Grid>
                                    <Grid item xs={4}>
                                        Date Requested:
                                    </Grid>
                                    <Grid item xs={8}>
                                        <b> {moment(request.createdAt).format("LL")}</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            label="Purpose"
                                            value={request.purpose}
                                            defaultValue={request.purpose}
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: "100%",
                                                marginTop: "15px",
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Remarks"
                                            value={request.remarks}
                                            size="small"
                                            multiline
                                            rows={4}
                                            sx={{
                                                width: "100%",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Box>
            </Modal>

      <Grid
        container
        sx={{
          backgroundColor: 'white',
        }}
        spacing={2}
      >
        <Box sx={{ width: '100%' , paddingTop: {xs: "2vh"}}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Leave Request" {...a11yProps(0)} />
              <Tab label="Cash Advance" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* Archive Table  - Leave Request  */}
          <CustomTabPanel value={value} index={0}>
            <Grid item xs={12}>
              <TextField
                size='small'
                label="Search request"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ paddingBottom: '20px'}}
              />
              <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Request Type</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Date Requested</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((item) => (
                      <>
                        <TableRow>
                          <TableCell align="left">{item.request_type}</TableCell>
                          <TableCell align="left">
                            {moment(item.createdAt).format("LLL")}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row">
                              <Chip  size="small"
                                color={
                                  item.status === "Rejected"
                                    ? "error"
                                    : item.status === "Approved"
                                      ? "success"
                                      : item.status === "Cancellation Rejected"
                                        ? "success"
                                        : item.status === "Partially Cancelled"
                                          ? "success"
                                          : item.status === "Cancelled"
                                            ? "warning"
                                            : item.status === "In Progress" ||
                                            item.status === "Final approval" ||
                                            item.status === "Partial Cancellation" ||
                                            item.status === "Full Cancellation"
                                              ? "primary"
                                              : "default" 
                              } label={item.status} />
                            </Stack>

                          </TableCell>
                          <TableCell align="left">
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item>
                                <Tooltip
                                  title="View"
                                  sx={{
                                    color: "#0F1140",
                                  }}
                                  onClick={() => {
                                    handleOpen(item.request_uniqueId);
                                  }}

                                >
                                  <IconButton>
                                    <RemoveRedEyeIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                  <Tooltip
                                    title="Unarchive"
                                    sx={{
                                      color: "#0F1140",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => unarchive(item.request_uniqueId)}
                                    >
                                        <UnarchiveIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>

                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </CustomTabPanel>

          {/* Archive Table  - Cash Advance*/}
          <CustomTabPanel value={value} index={1}>
            <TextField
              size='small'
              label="Search request"
              value={searchTermCA}
              onChange={(e) => setSearchTermCA(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ paddingBottom: '20px' }}
            />
            <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Date Requested</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDataCA.map((item) => (
                    <>
                      <TableRow>
                        <TableCell align="left">{item.amount}</TableCell>
                        <TableCell align="left">
                          {moment(item.createdAt).format("LLL")}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row">
                            <Chip size="small" color={item.status === "Rejected"
                              ? "error"
                              : item.status === "Approved"
                                ? "success"
                                : item.status === "Pending"
                                  ? "default"
                                  : "default" // or any other default color
                            } label={item.status} />
                          </Stack>

                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                          >
                            <Grid item>
                              <Tooltip
                                title="View"
                                sx={{
                                  color: "#0F1140",
                                }}
                                onClick={() => {
                                  handleOpenCA(item.request_uniqueId);
                                }}
                              >
                                <IconButton>
                                  <RemoveRedEyeIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>

                    </>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>

        </Box>

      </Grid>
    </>
  )
}
