import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Modal,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  InputAdornment,
  Chip,
  Stack
} from "@mui/material";
import { useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import GMSlogo from "../../../images/gms_logo.png";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment/moment";
import PDFFile from '../../../documents/cashAdvance'
import { PDFDownloadLink } from "@react-pdf/renderer";
import LoopIcon from '@mui/icons-material/Loop';
import CancelIcon from '@mui/icons-material/Cancel';


export default function CashAdvance() {
  const { user } = useAuthContext();

  // Fetch Current User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, []);
  const user_id = userData.user_id;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Create Cash Advance Request
  const [amount, setAmount] = useState(0);
  const [purpose, setPurpose] = useState("");
  const create = async () => {
    setButtonDisabled(true);
    let message = "Please be informed that " + userData.firstname + " " + userData.lastname + " has submitted a cash advance request. Open the portal at your earliest convenience and review the request. "
    if (amount === 0 || purpose.trim() === "") {
      // Input is empty
      // console.log(user_id)
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000); 
      alertError();
      return;
    }
    axios.post(
      "https://hr.geospectrum.com.ph/api/api/cashAdvance/request",
      { user_email: user.email, user_id, amount, purpose },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setTimeout(() => {
      setButtonDisabled(false);
    }, 3000); 
    
    alertSuccess();
    setAmount(0);
    setPurpose("");
    sendMail(message);
  };
  const cancel = async () => {
    handleClose();
    setAmount(0);
    setPurpose("");
  }

  // Send Email
  const sendMail = async (umessage) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/mailingalt', {
        userEmail: "medillojianna@geospectrum.com.ph",
        message: umessage,
        subject: 'Cash Advance Request'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  // Retrieve Own Cash Advance Request
  const [cashAdvance, setCashAdvance] = useState(null);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/getRequest" + user.email, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setCashAdvance(data);
      });
  }, [cashAdvance]);

  const [cashAdvancecount, setCashAdvancecount] = useState(null);
  React.useEffect(() => {

    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/countUserCAStat" + user.email)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        if (data && data.InProgressCount !== undefined) {
          setCashAdvancecount(data);
        } else {
          console.error('Data or InProgressCount is undefined.');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.email]);

  // Table Page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };

  // Modal View One Cash Advance Request
  const [cashAdvanceView, setCashAdvanceView] = useState("");
  const [openView, setOpenView] = React.useState(false);
  const handleOpenView = (idx) => {
    setOpenView(true);
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/getOneRequest" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setCashAdvanceView(data);
      });
  };
  const handleCloseview = () => setOpenView(false);

  // Modal Create Cash Advance Request
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  //

  const card_style = {
    // minWidth: 255,
    width: "100%",
    minHeight: 90,
    maxHeight: 115,
    backgroundColor: "#b4b4b4",
    color: "white",
  };

  const handleAmountChange = (e) => {
    // Allow only numbers and ignore other characters
    const inputValue = e.target.value.replace(/[^0-9]/g, '');

    // Set the numeric value without commas to the state
    setAmount(inputValue);
  };

  // Format the number with commas for display
  const formattedAmount = new Intl.NumberFormat('en-US').format(parseInt(amount, 10));


  const cancelRequest = async (id) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/mailingalt', {
        userEmail: "medillojianna@geospectrum.com.ph",
        message: "This is to inform you that " + userData.firstname + " " + userData.lastname + " has cancelled a Cash Advance request. You can review and manage leave requests on the portal. Thank you",
        subject: 'Cash Advance Request Cancelled'
      }).then().catch((err) => console.error(err))
    }

    Swal.fire({
      title: "Cancel request?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        send()
        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/cashAdvance/delete" + id, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        )
        Swal.fire({
          title: "Request cancelled!",
          text: "Your request has been deleted.",
          icon: "success"
        });
      }
    });

  }

  return (
    <>
      <Grid
        container
        item
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={0} sm={0} md={4} lg={4} xl={4}></Grid>
        <Grid item container direction="row" xs={6} sm={3} md={2} lg={2} xl={2}>
          <Card
            onClick={handleOpen}
            sx={{
              width: "100%",
              minHeight: 90,
              backgroundColor: "#00AB9B",
              color: "white",
              "&:hover": {
                boxShadow: "5px 5px #888888",
              },
              cursor: "pointer",
            }}
          >
            <CardContent>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold" , fontSize: { xs: 13, md: 13, lg: 13, xl: 15 }}}>
                  Submit Request
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AddIcon sx={{ fontSize: 30 }} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container direction="row" xs={5} sm={3} md={2} lg={2} xl={2}>
          <Card sx={card_style}>
            <CardContent>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: 13, md: 13, lg: 13, xl: 15 } }}>
                  In Progress
                </Typography>
              </Grid>
              <Grid item xs={12}>
                00{cashAdvancecount && cashAdvancecount.InProgressCount !== undefined ? cashAdvancecount.InProgressCount : ''}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container direction="row" xs={5} sm={3} md={2} lg={2} xl={2}>
          <Card sx={card_style}>
            <CardContent>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold" , fontSize: { xs: 13, md: 13, lg: 13, xl: 15 }}}>
                  Approved Request
                </Typography>
              </Grid>
              <Grid item xs={12}>
                00{cashAdvancecount && cashAdvancecount.ApprovedCount !== undefined ? cashAdvancecount.ApprovedCount : ''}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container direction="row" xs={5} sm={3} md={2} lg={2} xl={2}>
          <Card sx={card_style}>
            <CardContent>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: 13, md: 13, lg: 13, xl: 15 } }}>
                  Rejected Request{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                00{cashAdvancecount && cashAdvancecount.RejectedCount !== undefined ? cashAdvancecount.RejectedCount : ''}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Date Requested
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Purpose
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>

                  {cashAdvance &&
                    cashAdvance.map((cAdvance, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {moment(cAdvance.createdAt).format("lll")}
                        </TableCell>
                        <TableCell align="left">{cAdvance.purpose}</TableCell>
                        <TableCell align="left">{cAdvance.amount}</TableCell>
                        <TableCell align="left">
                          <Stack direction="row">
                            <Chip size="small" color={
                              cAdvance.status === "Rejected"
                                ? "error"
                                : cAdvance.status === "Approved"
                                  ? "success"
                                  : cAdvance.status === "Pending"
                                    ? "primary"
                                    : "default" // or any other default color
                            } label={cAdvance.status} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <Tooltip
                                title="View"
                                sx={{
                                  color: "#0F1140",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleOpenView(cAdvance._id)}
                                >
                                  <RemoveRedEyeIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item>
                              <PDFDownloadLink document={<PDFFile
                                name={userData.firstname + " " + userData.lastname}
                                department={userData.department}
                                position={userData.designation}
                                id={userData.user_id}
                                amount={cAdvance.amount}
                                date={moment(cAdvance.createdAt).format("LL")}
                                purpose={cAdvance.purpose}
                                hr_approve={cAdvance.hr_approve === true ? "APPROVED" : cAdvance.hr_approve === false ? "REJECTED" : "Pending"}
                              />
                              }
                                fileName={`CashAdvance_${cAdvance.user_id}`}>
                                {({ loading }) => (loading ?
                                  <Tooltip
                                    title="Loading"
                                    sx={{
                                      color: "#1B4B59",
                                    }}>
                                    <IconButton>
                                      <LoopIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  :
                                  <Tooltip
                                    title="Download"
                                    sx={{
                                      color: "#1B4B59",
                                    }}>
                                    <IconButton>
                                      <SimCardDownloadIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>)}
                              </PDFDownloadLink>
                            </Grid>
                            {cAdvance.status !== "Approved" && cAdvance.status !== "Rejected" && (
                              <Grid item>
                                <Tooltip
                                  title="Cancel"
                                  sx={{
                                    color: "#0F1140",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => cancelRequest(cAdvance.request_uniqueId)}
                                  >
                                    <CancelIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* Create Cash Advance Request Modal */}
      <form>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sx={{
                  marginBottom: "30px",
                }}
              >
                <img src={GMSlogo} width="310" height="auto" />
              </Grid>

              <Grid
                item
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}
              >
                Request for Cash Advance
              </Grid>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Grid item xs={6}>
                  <i> Employee Name:</i>
                </Grid>
                <Grid item xs={6}>
                  <i>Department:</i>
                </Grid>
                <Grid item xs={6}>
                  <b>{userData.firstname + " " + userData.lastname}</b>
                </Grid>
                <Grid item xs={6}>
                  <b>{userData.department}</b>
                </Grid>
                <Grid item xs={6}>
                  <i> Designation:</i>
                </Grid>
                <Grid item xs={6}>
                  <i> Employment Number:</i>
                </Grid>
                <Grid item xs={6}>
                  <b>{userData.position}</b>
                </Grid>
                <Grid item xs={6}>
                  <b>{userData.user_id}</b>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                  <TextField
                    onChange={handleAmountChange}
                    value={formattedAmount}
                    fullWidth
                    type="text"  // Change type to text to allow for formatted display
                    label="Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₱</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                  <TextField
                    onChange={(e) => setPurpose(e.target.value)}
                    value={purpose}
                    label="Purpose"
                    required
                    multiline
                    rows={4}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                spacing={2}
                sx={{
                  marginBottom: "20px",
                }}
              ></Grid>
              <Grid item container justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    create();
                  }}
                  sx={{ marginRight: "5px" }}
                  disabled={buttonDisabled}
                >
                  Submit
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    cancel();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>

      {/* View Certain Cash Advance Modal */}
      <Modal open={openView} onClose={handleCloseview}>
        <Box sx={style}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              Cash Advance Form
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{
                marginBottom: "20px",
              }}
            >
              <Grid item xs={6}>
                <i> Employee Name:</i>
              </Grid>
              <Grid item xs={6}>
                <i>Department:</i>
              </Grid>
              <Grid item xs={6}>
                <b>{userData.firstname + " " + userData.lastname}</b>
              </Grid>
              <Grid item xs={6}>
                <b>{userData.department}</b>
              </Grid>
              <Grid item xs={6}>
                <i> Designation:</i>
              </Grid>
              <Grid item xs={6}>
                <i> Employment Number:</i>
              </Grid>
              <Grid item xs={6}>
                <b>{userData.position}</b>
              </Grid>
              <Grid item xs={6}>
                <b>{userData.user_id}</b>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                <TextField
                  disabled
                  fullWidth
                  label="Amount"
                  value={cashAdvanceView.amount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₱</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                <TextField
                  disabled
                  value={cashAdvanceView.purpose}
                  required
                  multiline
                  rows={4}
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
            {cashAdvanceView.status === "Pending" ? (
              <></>
            ) : (
              <>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                    <i> Remarks:</i>
                  </Grid>

                  <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                    <TextField
                      disabled
                      value={cashAdvanceView.remarks}
                      required
                      multiline
                      rows={4}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              item
              container
              direction="row"
              spacing={2}
              sx={{
                marginBottom: "20px",
              }}
            ></Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
