import * as React from "react";
import "./dashboard.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState, useEffect } from "react";

// Component
import UserContainer from "./userContainer/userContainer.js";
import MissionContainer from "./missionContainer/missionContainer.js";
import InfoContainer from "./infoContainer/infoContainer.js";
import AboutContainer from "./aboutContainer/aboutContainer.js";
import ProjectChart from "./projectChart";
import { Hidden } from "@mui/material";


export default function Dashboard() {

  const { user } = useAuthContext();

  return (
    <>
      {user && (
        <>
        <Hidden lgDown>
          <div id='dashboard-container'>
          <div id='first-row'>
            <UserContainer/>
            <InfoContainer/>
            <MissionContainer/>
          </div>
          <Hidden xlDown><br/></Hidden>
          
          <div id='second-row'>
            <AboutContainer/>
          </div>
          </div>
        </Hidden>
        <Hidden lgUp>
          <div id='dashboard-container'>
            <UserContainer/>
            <InfoContainer/>
            <MissionContainer/>
          </div>
        </Hidden>
        </>
      )}
    </>
  );
}
