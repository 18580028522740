import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import FeedIcon from '@mui/icons-material/Feed';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArchiveIcon from '@mui/icons-material/Archive';
import LogoutIcon from '@mui/icons-material/Logout';

import bannerLogo from "../../../images/gspec_header_png_whiteG_B.png";

import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useLogout } from '../../../hooks/useLogout';

import Home from "../../dashboard/index.js";
import Announcement from "../../announcement/index.js";
import Calendar from "../../calendar/index.js";
import LeaveRequest from "../../leaveRequest/index.js";
import Project from "../../projects/index.js";
import CashAdvance from "../../cashAdvance/index";
import Employee from "../../employee/index.js";
import Department from "../../department/index.js";
import Payslip from "../../payslip";
import Rules from "../../rules";
import Inventory from "../../inventory/index.js";
import Archive from "../../archive/index.js";
import ItemRequest from "../../itemRequest/index.js";
import Employeeleave from "../../employeeLeave/index.js";

import RegularUserCashAdvance from "../../Userpage/CashAdvance/index.js";
import UserPayslip from "../../Userpage/payslip/index.js";
import UserItemRequest from "../../Userpage/ItemRequest/index.js";

import COEPDF from '../../../documents/certOFEmp.js'
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import moment from 'moment';

import './index.css';

const settings = ['Profile', 'Logout'];

function MobileLayout(props) {
  const {userData} = props
  const [anchorElRequest, setAnchorElRequest] = React.useState(null);

  const handleClickRequest = (event) => {
    setAnchorElRequest(event.currentTarget);
  };
  const handleCloseRequest = (target) => {
    setAnchorElRequest(null);
    // console.log(['leaverequest', 'cashadvance', 'itemrequest'].includes(target))
    if(['leaverequest', 'cashadvance', 'itemrequest'].includes(target)){
      navigate(`/admin/${target.toLowerCase().replace(/\s/g, "")}`)
    }
    
  };

  const navigate = useNavigate();

  const { logout } = useLogout();
  const handleClickLogout = () => {
    logout();
  };

  const currentDate = new Date();
  
  return (
    <div className='mobile-container'>
      <AppBar position='fixed'>
        <div id='toolbar'>
          <img src={bannerLogo} alt="Banner Logo" id='banner-logo'/>
          <div style={{flexGrow: 1}}/>
          <div className='toolbar-name'> 
            {userData.account_role === "superadmin" ? "Superadmin" : "Supervisor"}
            <div style={{width: 5}}/>
            <Tooltip title="Logout">
              <IconButton onClick={handleClickLogout}>
                <LogoutIcon sx={{color: 'white'}}/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </AppBar>

      <div component="main" id='routes-container'>
        <div id='header-filler'/>
        <Routes>
          <Route path="/"> </Route>
          <Route path="/home" element={<Home />}>
            {" "}
          </Route>
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/leaverequest" element={<LeaveRequest />} />
          <Route path="/cashadvance" element={userData.account_role === 'supervisor' || userData.email === 'it.support@geospectrum.com.ph' ? <RegularUserCashAdvance /> : <CashAdvance />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/employees" element={<Employee />} />
          <Route path="/departments" element={<Department />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/employeeleave" element={<Employeeleave />} />
          <Route path="/itemrequest" element={userData.account_role === 'superadmin' || userData.email === 'pgtodoc7@gmail.com'  ? <ItemRequest /> : <UserItemRequest />} />
          <Route path="/payslip" element={userData.account_role === 'supervisor' || userData.email === 'it.support@geospectrum.com.ph' ? <UserPayslip /> : <Payslip />} />
          <Route path="/rules&regulations" element={<Rules />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="*" element={<div>page not found</div>} />
        </Routes>
      </div>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels
          onChange={(event, newValue) => {
            console.log(newValue);
            if(newValue !== "request"){
              navigate(`/admin/${newValue.toLowerCase().replace(/\s/g, "")}`)
            }
          }}>
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} value="home"/>
          <BottomNavigationAction label="Announcements" icon={<InfoIcon />} value="announcement"/>
          <BottomNavigationAction label="Requests" icon={<FeedIcon onClick={handleClickRequest}/>} value="request" />
          <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} value="archive"/>
          
        </BottomNavigation>
        <Menu
          anchorEl={anchorElRequest}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElRequest)}
          onClose={handleCloseRequest}>
          <MenuItem onClick={()=>handleCloseRequest('leaverequest')}>Leave Request</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('cashadvance')}>Cash Advance</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('itemrequest')}>Item Requests</MenuItem>
          <MenuItem onClick={()=>handleCloseRequest('COErequest')}>
            <PDFDownloadLink
              document={<COEPDF
                date={moment(currentDate).format("LL")}
                position={userData.position}
                department={userData.department}
                fname={userData.firstname}
                lname={userData.lastname}
                title={userData.civil_status === "Single" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Married" && userData.gender === "Female" ? "Mrs." : userData.civil_status === "Divorced" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Widowed" && userData.gender === "Female" ? "Mrs.": "Mr."}
                dateEmployed={moment(userData.date_employed).format("LL")}
                email={userData.email}
              />}
              fileName={`Certificate of Employment_${userData.lastname}`}
            >
              COE Request
            </PDFDownloadLink>
          </MenuItem>
        </Menu>
      </Paper>

    </div>
  );
}
export default MobileLayout;
