import { Button, Card, Box, Typography, } from '@mui/material'
import CardContent from '@mui/material/CardContent';
import React from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Signout() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/');
  };
  return (
    <>
      <div className='body' >
        {
          loading ?
            <BeatLoader
              color={"#5197c1ff"}
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            :
            <Card sx={{
              minHeight: 125,
              display: 'flex',
              backgroundColor: "#f5f5f5"
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography sx={{ marginBottom: 2 }}>  You are not Logged In. Please log in and try again.</Typography>
                  <Button variant="contained" onClick={navigateToLogin}>Log In</Button>
                </CardContent>
              </Box>
            </Card>


        }
      </div>
    </>
  )
}
