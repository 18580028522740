import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from 'moment';
import CakeIcon from '@mui/icons-material/Cake';
import { Link } from 'react-router-dom';
import InfoIcon from "@mui/icons-material/Info";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PostAddIcon from '@mui/icons-material/PostAdd';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import PaymentIcon from "@mui/icons-material/Payment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LogoutIcon from "@mui/icons-material/Logout";
import ReorderIcon from "@mui/icons-material/Reorder";
import bannerLogo from "../../../images/gspec_header_png_whiteG_B.png";
import ArchiveIcon from '@mui/icons-material/Archive';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useAuthContext } from "../../../hooks/useAuthContext";
import DialogTitle from '@mui/material/DialogTitle';
import DateRangeIcon from "@mui/icons-material/DateRange";
import COEPDF from '../../../documents/certOFEmp.js'
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import ArticleIcon from '@mui/icons-material/Article';

import {
  Grid, Tooltip, Card, CardContent, CardMedia, IconButton,
  List,
  Typography,
  Divider,
  Box,
  Toolbar,
  CssBaseline,
  Button,
  Badge,
  Hidden
} from "@mui/material";
import { useLogout } from "../../../hooks/useLogout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Clock from 'react-live-clock';
// Routes
import RegularUser from "../Dashboard/UserDashboard";
import RegularUserRequest from "../RequestPage/index";
import RegularUserCashAdvance from "../CashAdvance/index";
import UserDepartment from "../../department/index";
import UserEmployee from "../../employee/index";
import UserRegulation from "../../rules/index";
import UserPayslip from "../payslip/index";
import UserArchive from "../Archive/index";
import ItemRequest from "../ItemRequest/index";

// Payslip
import Payslip from '../../payslip/index'
import Employeeleave from "../../employeeLeave/index.js";

import './index.css';
import UserMobileLayout from "./mobile/index.js";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);
  const [birthday, setBirthday] = useState();
  const { logout } = useLogout();

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });

    fetch("https://hr.geospectrum.com.ph/api/api/user/selectBday")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data)
        setBirthday(data);
      });

  }, [user]);

  const handleClick = () => {
    logout();
  };

  // Preloader
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  // Birthday Dialog
  const [openDia, setOpenDia] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDia(true);
  };
  const handleClose = () => {
    setOpenDia(false);
  };
  const currentDate = new Date();
  return (
    <>

      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openDia}
        onClose={handleClose}
      >
        <DialogTitle>Birthdays this Month</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" >
            {birthday &&
              birthday.map((bday, index) => (
                <Grid item key={index} sx={{ width: '100%' }}>
                  <Card sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
                        <Typography component="div" variant="h6">
                          {bday.firstname + " " + bday.lastname}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                          {moment(bday.birth_date).format('MMMM DD')}
                        </Typography>
                      </CardContent>
                    </Box>
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={`https://hr.geospectrum.com.ph/api/profile/` + bday.photo_path}
                    />
                  </Card>
                </Grid>
              ))}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <div className="center_loader">
          <BeatLoader
            color={"#5197c1ff"}
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Hidden lgDown>
            <Box
              sx={{
                display: "flex",
                height: "auto",
              }}
            >
              <CssBaseline />
              <AppBar position="fixed" open={!open}>
                <div id="toolbar">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                    sx={{
                      marginRight: 5,
                      ...(!open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <h5 id='account-role'>
                    Employee
                  </h5>
                  <p id='date-and-time'>
                    <PDFDownloadLink
                      document={<COEPDF
                        date={moment(currentDate).format("LL")}
                        position={userData.position}
                        department={userData.department}
                        fname={userData.firstname}
                        lname={userData.lastname}
                        title={userData.civil_status === "Single" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Married" && userData.gender === "Female" ? "Mrs." : userData.civil_status === "Divorced" && userData.gender === "Female" ? "Ms." : userData.civil_status === "Widowed" && userData.gender === "Female" ? "Mrs.": "Mr."}
                        dateEmployed={moment(userData.date_employed).format("LL")}
                        email={userData.email}
                      />}
                      fileName={`Certificate of Employment_${userData.lastname}`}
                    >
                      {({ blob, url, loading, error }) => (
                        <Tooltip title="Certificate of Employment">
                          <IconButton aria-label="test" sx={{ color: "white", paddingRight: 1 }}>
                            {/* <Badge color="error" variant="dot"  > */}
                              <ArticleIcon fontSize="small" />
                            {/* </Badge> */}
                          </IconButton>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>

                    <IconButton sx={{ color: "white", paddingRight: 2 }} onClick={handleClickOpen} >
                      {/* <Badge color="error" variant="dot"  > */}
                        <CakeIcon fontSize="small" />
                      {/* </Badge> */}
                    </IconButton>

                    {(new Date()).toLocaleDateString('en', { weekday: 'long' })}, {(new Date()).toLocaleDateString('en', { month: 'long' })} {(new Date()).getDate()}, {(new Date()).getFullYear()} &nbsp; <Clock format={'h:mm:ssa'} ticking={true} interval={1000} />
                  </p>
                </div>


              </AppBar>
              <Drawer
                variant="permanent"
                open={!open}
                PaperProps={{
                  sx: {
                    backgroundColor: "#0F1140",
                    color: "white",
                  },
                }}
              >
                <DrawerHeader>
                  <Button onClick={() => navigate('/user/home')}>
                    <img src={bannerLogo} width="180" height="auto" alt="Banner Logo" />
                  </Button>
                  <IconButton
                    onClick={handleDrawerOpen}
                    sx={{
                      color: "white",
                    }}
                  >
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ReorderIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  {/* {["Calendar", "Request", "Cash Advance", "Projects"].map( */}
                  {["Leave Request", "Item Request", "Cash Advance", "Archive"].map(
                    (text, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{
                          display: "block",
                        }}
                      >
                        <ListItemButton
                          // href={`/${text.toLowerCase().replace(/\s/g, '')}`}
                          onClick={() =>
                            navigate(
                              `/user/${text.toLowerCase().replace(/\s/g, "")}`
                            )
                          }
                          sx={{
                            minHeight: 48,
                            justifyContent: !open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: !open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            {/* {index % 2 === 0 ? <InfoIcon /> : <MailIcon />} */}
                            {index === 0 ? (
                              <DescriptionIcon />
                            ) : index === 1 ? (
                              <PostAddIcon />
                            ) : index === 2 ? (
                              <AccountBalanceWalletIcon />
                            ) : (
                              <ArchiveIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={text}
                            sx={{ opacity: !open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                </List>
                <Divider />


                <List>
                  {["Departments", "Employees", "Payslip", "Employee Payslip", "Employee Leave"].map((text, index) => {
                    // Check if it's the "Employee Payslip" item and if the email matches
                    if (text === "Employee Payslip" && userData.email !== 'accounting@geospectrum.com.ph') {
                      // If the condition is not met, return null (or an empty fragment)
                      return null;
                    }
                    if (text === "Employee Leave" && userData.email !== 'alfredosolis564@gmail.com' && userData.email !== 'agnes.bicaldo@yahoo.com' && userData.email !== 'raelsaberon@gmail.com' && userData.email !== 'jodeva03@gmail.com') {
                      // If the condition is met, return null (or an empty fragment)
                      return null;
                    }

                    return (
                      <ListItem key={text} disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          onClick={() => navigate(`/user/${text.toLowerCase().replace(/\s/g, "")}`)}
                          sx={{
                            minHeight: 48,
                            justifyContent: !open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: !open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            {index === 0 ? (
                              <BusinessIcon />
                            ) : index === 1 ? (
                              <GroupsIcon />
                            ) : index === 2 ? (
                              <PaymentIcon />
                            ) : index === 3 ? (
                              <PaymentsIcon />
                            ) : (
                              <DateRangeIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={text}
                            sx={{ opacity: !open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>



                <Divider />
                <List>
                  {["Rules & Regulations"].map((text, index) => (
                    <ListItem key={text} disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        // href={`/${text.toLowerCase().replace(/\s/g, '')}`}
                        onClick={() =>
                          navigate(`/user/${text.toLowerCase().replace(/\s/g, "")}`)
                        }
                        sx={{
                          minHeight: 48,
                          justifyContent: !open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: !open ? 3 : "auto",
                            justifyContent: "center",
                            color: "white",
                          }}
                        >
                          {index === 0 ? <MenuBookIcon /> : <LogoutIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: !open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />
                <List>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={handleClick}
                      sx={{
                        minHeight: 48,
                        justifyContent: !open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: !open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                        Logout
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Drawer>

              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  direction="column"
                  // justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{
                    paddingLeft: { xs: "0px", sm: "10px", md: "25px" },
                    paddingRight: "5px",
                    backgroundColor: "#fafbfc",
                    marginTop: "1px",
                  }}
                >
                  <Grid item>
                    <DrawerHeader />
                  </Grid>
                  <Grid item container>
                    <Routes>
                      <Route path="/"> </Route>
                      <Route path="/home" element={<RegularUser />}></Route>
                      <Route path="/leaverequest" element={<RegularUserRequest />}></Route>
                      <Route path="/cashadvance" element={<RegularUserCashAdvance />}></Route>
                      <Route path="/departments" element={<UserDepartment />}></Route>
                      <Route path="/employees" element={<UserEmployee />}></Route>
                      <Route path="/rules&regulations" element={<UserRegulation />}></Route>
                      <Route path="/archive" element={<UserArchive />}></Route>
                      <Route path="/itemrequest" element={<ItemRequest />}></Route>
                      <Route path="/payslip" element={<UserPayslip />}></Route>
                      <Route path="/employeeleave" element={userData.account_role !== 'user' ? <RegularUser /> : <Employeeleave />}></Route>
                      <Route path="/employeepayslip" element={userData.email !== 'accounting@geospectrum.com.ph' ? <UserPayslip /> : <Payslip />}></Route>
                      <Route path="*" element={<div>page note found</div>} />
                    </Routes>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <UserMobileLayout userData={userData}/>
          </Hidden>
        </>
      )}
    </>
  );
}
