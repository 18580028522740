import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";


import './infoContainer.css';
import { Hidden } from "@mui/material";

export default function InfoContainer() {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);

      });
  }, []);

  // Count Request
  const [countOnLeave, setCountOnLeave] = useState();

  const [inProgressLeave, setInProgressLeave] = useState("");
  const [inProgressCA, setInProgressCA] = useState("");

  const [empCount, setEmpCount] = useState([])
  const [deptCount, setDeptCount] = useState([])

  React.useEffect(() => {
    if (userData.account_role === "superadmin") { 
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/countLeaveProgress", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data)
          setInProgressLeave(data.InProgressCount);
        });
        fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/countCashAdprogress", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setInProgressCA(data.InProgressCount);
          });
    } else {
      fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/supervisorcountProgress"+ user.email, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data)
          setInProgressLeave(data.InProgressCount);
        });

        fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/countUserCAStat" + user.email)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.InProgressCount !== undefined) {
            setInProgressCA(data.InProgressCount);
          } else {
            console.error('Data or InProgressCount is undefined.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

  }, [userData]);

  React.useEffect(() => {
    fetch('https://hr.geospectrum.com.ph/api/api/user/countEmp')
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log(data);
        setEmpCount(data)
      })

      fetch('https://hr.geospectrum.com.ph/api/api/leaveRequest/countLeaveToday')
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log(data.count);
        setCountOnLeave(data.count)
      })

  }, [])

  React.useEffect(() => {
    fetch('https://hr.geospectrum.com.ph/api/api/department/count',{
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log(data);
        setDeptCount(data)
      })
  }, [])

  const navigate = useNavigate()
  return (
    <div id='info-container'>
      <div id='info-row'>
        <div id='info-box' onClick={()=>navigate('/admin/leaverequest')} className="info-click">
          <h1 id='info-count'>{String(inProgressLeave)}</h1>
          <h5 id='info-label'>Leave Requests</h5>
        </div>
        <div id='info-box' onClick={()=>navigate('/admin/leaverequest')} className="info-click">
          <h1 id='info-count'>{String(inProgressCA)}</h1>
          <h5 id='info-label'>Cash Advance <Hidden xlDown>Requests</Hidden></h5>
        </div>
      </div>
      <div id='info-row'>
        <div id='info-box' className="">
          <h1 id='info-count'>{empCount - countOnLeave}</h1>
          <h5 id='info-label'>Present Employees</h5>
        </div>
        <div id='info-box' onClick={()=>navigate('/admin/employeeleave')} className="info-click">
          <h1 id='info-count'>{countOnLeave}</h1>
          <h5 id='info-label'>Employees on leave</h5>
        </div>
      </div>
      <div id='info-row-filler'/>
      <div id='info-row'>
        <div id='info-box-2'>
          <h1 id='info-count'>{empCount}</h1>
          <h5 id='info-label'>Total Employees</h5>
        </div>
        <div id='info-box-3'>
          <h1 id='info-count'>{deptCount}</h1>
          <h5 id='info-label'>Total Departments</h5>
        </div>
      </div>
    </div>
  )
}
