// LoginComponent.js

import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLogin } from "../../hooks/useLogin";
import img from "../../images/logo_line.png";

import './login.css'

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading, error } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  // Show Password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-lg-6 side-image"/>

        <div className="col-lg-6 col-sm-12 right">
          <form className='form' onSubmit={handleSubmit}>
            <div className="input-box">
              <img className='logo' src={img} alt="" />
              <header>Login account</header>
              <div className="input-field">
                <TextField
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text"
                  className="input"
                  id="email"
                  required=""
                  autoComplete="off"
                />
                {/* <label htmlFor="email">Email</label> */}
              </div>
              <div className="input-field">
                <TextField
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type={showPassword ? "Text" : "Password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          disableRipple
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" />
                          ) : (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="input"
                  id="pass"
                  required=""
                />
                {/* <label htmlFor="pass">Password</label> */}
              </div>
              <div className="error">
              {error && error}
              </div>
              <div className="input-field">
                <input type="submit" className="submit" value="Submit"/>
              </div>
              <div className="signin">
                <span>
                  Don't have an account? <a href={`signup`}>Sign up here</a>
                </span>
              </div>
              <div className="signin forgotpass">
                <span>
                  <a href={`forgot-password`}>Forgot password?</a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
}
