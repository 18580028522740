import React, { Fragment } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, } from '@react-pdf/renderer';
import logo from '../../src/images/logo_line.png'


function LeavePDF(props) {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 30,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.2,
            flexDirection: 'column',
        },
        logo: {
            width: 270,
            height: 'auto',
            marginTop: 45,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        DateContainer: {
            marginTop: 30,
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        labelDate: {
            width: 130
        },
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 30,
            borderWidth: 1,
            borderColor: 'black',
        },
        deductiondescription: {
            width: '100%',
            textAlign: 'center',
            paddingLeft: 15,
            fontWeight: 'Bold',
        },
        deductioncontainer: {
            backgroundColor: '#f0f0f0',
            height: 24,
            fontStyle: 'bold',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomColor: 'black',
            borderBottomWidth: .5,
        },
        openrow: {
            flexDirection: 'row',
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
        },        
        openrow2: {
            flexDirection: 'row',
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
            borderTopColor: 'black',
            borderTopWidth: .5,
        },
        closerow: {
            flexDirection: 'row',
            borderBottomColor: 'black',
            borderBottomWidth: .5,
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
        },
        label: {
            width: '20%',
            textAlign: 'left',
            paddingLeft: 10,
            fontWeight: 'Bold',
        },
        description: {
            width: '30%',
            textAlign: 'left',
            paddingLeft: 10,
            color: "#919191",
            borderRightColor: "black",
            borderRightWidth: .5,
        },
        descriptionRight: {
            width: '30%',
            textAlign: 'left',
            paddingLeft: 10,
            color: "#919191",
        },
        requested: {
            width: '40%',
            textAlign: 'left',
            paddingLeft: 10,
            fontWeight: 'bold',
        },
        reqdescription: {
            width: '60%',
            textAlign: 'left',
            paddingLeft: 10,
            color: "#919191",
            flexWrap: 'wrap',
        },
        reco: {
            width: '100%',
            textAlign: 'left',
            paddingLeft: 10,
            fontFamily: 'Helvetica-Oblique'
        },
        approval: {
            width: '40%',
            textAlign: 'left',
            paddingLeft: 10,
        },
        approvalName: {
            width: '60%',
            textAlign: 'left',
            paddingLeft: 10,
        },
        approvalStat: {
            width: '60%',
            textAlign: 'left',
            paddingLeft: 10,
            color: '#5e5c5c'
        },
        address: {
            textAlign: 'center',
            fontSize: '10pt',
            marginTop: 5
        },
        tableContainer2: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: 10,
            borderWidth: .5,
            borderColor: 'black',
            marginTop: 20
        },
        qtyTitle: {
            width: '10%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
        },
        untTitle: {
            width: '20%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
        },
        itmTitle: {
            width: '50%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
        },
        totalTitle: {
            width: '20%',
            textAlign: 'center',
            fontWeight: 'Bold',
        },
        qtyDecrip: {
            width: '10%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
            color: "#919191",
        },
        untDecrip: {
            width: '20%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
            color: "#919191",
        },
        itmDecrip: {
            width: '50%',
            textAlign: 'center',
            fontWeight: 'Bold',
            borderRightColor: "black",
            borderRightWidth: .5,
            color: "#919191",
        },
        totalDecrip: {
            width: '20%',
            textAlign: 'center',
            fontWeight: 'Bold',
            color: "#919191",
        },
        openColumn:{
            flexDirection: 'column',
            alignItems: 'flex-start', 
            height: 'auto', 
            fontStyle: 'bold',  
            marginBottom: 10

        },
        requestedCol: {
            width: '100%', 
            textAlign: 'left',
            paddingLeft: 10,
            fontWeight: 'bold', 
        },
        reqdescriptionCol: {
            width: '100%', 
            textAlign: 'left',
            paddingLeft: 10,
            color: '#919191',
        },
        
    })

    const {
        name, department, id, position, item, purpose, endUser,
        price, link, isITApproved, isHRApproved, date, unitPrice, quantity
    } = props


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.address}>
                    Unit 804, Linden Suites Tower 1, 37 San Miguel Avenue,
                </Text>
                <Text style={styles.address}>
                    Ortigas Center, Pasig City, Philippines
                </Text>
                <Fragment>
                    <View style={styles.DateContainer}>
                        <Text style={styles.labelDate}>Date: {date}  </Text>
                    </View >
                </Fragment>
                <View style={styles.tableContainer}>
                    <View style={styles.deductioncontainer}>
                        <Text style={styles.deductiondescription}>EQUIPMENT REQUEST FORM</Text>
                    </View>
                    <View style={styles.closerow}>
                        <Text style={styles.label}>Name:</Text>
                        <Text style={styles.description}>{name}</Text>
                        <Text style={styles.label}>Department:</Text>
                        <Text style={styles.descriptionRight}>{department}</Text>
                    </View>
                    <View style={styles.closerow}>
                        <Text style={styles.label}>Designation:</Text>
                        <Text style={styles.description}>{position}</Text>
                        <Text style={styles.label}>Employee ID:</Text>
                        <Text style={styles.descriptionRight}>{id}</Text>
                    </View>


                    <View style={styles.tableContainer2}>
                        <View style={styles.closerow}>
                            <Text style={styles.qtyTitle}>Quantity</Text>
                            <Text style={styles.untTitle}>Unit Price</Text>
                            <Text style={styles.itmTitle}>Item</Text>
                            <Text style={styles.totalTitle}>Total</Text>
                        </View>
                        <View style={styles.openrow}>
                            <Text style={styles.qtyDecrip}>{quantity}</Text>
                            <Text style={styles.untDecrip}>{unitPrice}</Text>
                            <Text style={styles.itmDecrip}>{item}</Text>
                            <Text style={styles.totalDecrip}>{price}</Text>
                        </View>
                    </View>

                    <View style={styles.openColumn}>
                        <Text style={styles.requestedCol}>Purpose:</Text>
                        <Text style={styles.reqdescriptionCol}>{purpose}</Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.requested}>End user of requested item/s:</Text>
                        <Text style={styles.reqdescription}>{endUser}</Text>
                    </View>
                    <View style={styles.openColumn}>
                        <Text style={styles.requestedCol}>Shop Link (if applicable):</Text>
                        <Text style={styles.reqdescriptionCol}>{link}</Text>
                    </View>
                    <View style={styles.openrow2}>
                        <Text style={styles.reco}>Recommending Approval:</Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.approval}>Spatial IT Supervisor:</Text>
                        <Text style={styles.approvalName}>Pamela Anne Todoc </Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.approval}></Text>
                        <Text style={styles.approvalStat}>{isITApproved}</Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.approval}>Human Resource Officer:</Text>
                        <Text style={styles.approvalName}>Jianna Iza Medillo</Text>
                    </View>
                    <View style={styles.openrow}>
                        <Text style={styles.approval}></Text>
                        <Text style={styles.approvalStat}>{isHRApproved}</Text>
                    </View>

                </View>
            </Page>
        </Document>
    )
};

export default LeavePDF;






