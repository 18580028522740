import {
    Button, Grid, TextField, InputAdornment,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Tooltip,
    Stack,
    Chip,
    Skeleton,
    Paper,
    IconButton,
    DialogContent,
    DialogActions,
    Dialog,
    useMediaQuery,
    Autocomplete,
    MenuItem,
} from '@mui/material'
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import { useTheme } from '@mui/material/styles';
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Swal from "sweetalert2";
import axios from "axios";

const ITContainer = ({ dataFromParent }) => {
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [employee, setEmployee] = useState(null);
    const [item_name, setItem_name] = useState("");
    const [item_description, setItem_description] = useState("");
    const [assigned_to, setAssigned_to] = useState();
    const [assigned_toNext, setAssigned_toNext] = useState("");
    const [notes, setNotes] = useState();
    const [issue_date, setIssue_date] = useState("");
    const [date_acquire, setDate_acquire] = useState("");
    const [return_date, setReturn_date] = useState("");
    const [office, setOffice] = useState("");
    const [item_status, setItem_status] = useState("");
    const [serial_no, setSerial_no] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [viewOneInventory, setViewOneInventory] = useState("");
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [department, setDepartment] = useState("");
    const [designation, setDesignation] = useState("");
    const [user_email, setUser_email] = useState("");

    useEffect(() => {
        // When dataFromParent changes, update storedData
        setEmployee(dataFromParent);
    }, [dataFromParent]);

    React.useEffect(() => {
        fetch("https://hr.geospectrum.com.ph/api/api/inventory/getPeris", {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setItems(data);
                setLoading(false);
            });
    }, [trigger]);

    useEffect(() => {
        // Polling Function to fetch data
        const fetchData = () => {
            fetch("https://hr.geospectrum.com.ph/api/api/inventory/getPeris", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! status: ${res.status}`);
                    }
                    return res.json();
                })
                .then((data) => {
                    setItems(data);
                })
                .catch((error) => {
                    console.error('Fetch error:', error);
                });
        };
        // Start polling
        const intervalId = setInterval(fetchData, 10000); // Poll every  10 seconds
        // Clear interval on cleanup
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const filteredData = items
        ? items.filter((item) => {
            const itemName =
                item.item_name +
                item.item_description +
                item.item_status +
                item.office +
                (item.user?.[0]?.lastname || "") +
                (item.user?.[0]?.firstname || "") +
                (item.user?.[0]?.department || "") || "";

            const searchTermLower = searchTerm.toLowerCase();
            return itemName.toLowerCase().includes(searchTermLower);
        })
        : [];

    // Dialog
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = (idx) => {
        setOpen(true);
        fetch("https://hr.geospectrum.com.ph/api/api/inventory/viewOneInventory" + idx, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data)
                setViewOneInventory(data)
                setItem_name(data[0].item_name)
                setItem_description(data[0].item_description)
                setAssigned_to(data[0].assigned_to)
                setNotes(data[0].notes)
                setIssue_date(data[0].issue_date)
                setReturn_date(data[0].return_date)
                setItem_status(data[0].item_status)
                setSerial_no(data[0].serial_no)
                setQuantity(data[0].quantity)
                setDate_acquire(data[0].date_acquire)
                setOffice(data[0].office)

            });
    };
    const handleClose = () => {
        setOpen(false);
        setItem_name('')
        setItem_description('')
        setAssigned_to('')
        setNotes('')
        setIssue_date('')
        setReturn_date('')
        setItem_status('')
        setSerial_no('')
        setOffice('')
    };

    const alertSuccess = () => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: "The item/equipment is updated.",
        });
        handleClose();
    };
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleUpdateItem = (unq) => {
        setButtonDisabled(true)
        const alertReasssign = () => {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "The item/equipment is updated and reassigned.",
            });
            handleClose();
        };

        if (assigned_toNext === '') {
            axios.put(
                "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
                {
                    item_name,
                    item_description,
                    notes,
                    issue_date,
                    item_status,
                    return_date,
                    serial_no,
                    quantity,
                    date_acquire,
                    office
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            ).then(() => {
                // This code will execute after the axios request is completed
                setTrigger(!trigger);
            })
            alertSuccess();
        } else {
            axios.put(
                "https://hr.geospectrum.com.ph/api/api/inventory/update" + unq,
                {
                    item_name,
                    assigned_to: assigned_toNext,
                    item_description,
                    notes,
                    issue_date,
                    item_status,
                    return_date,
                    serial_no,
                    quantity,
                    date_acquire
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            ).then(() => {
                // This code will execute after the axios request is completed
                setTrigger(!trigger);
            })
            alertReasssign();
        }
        setTimeout(() => {
            setButtonDisabled(false);
        }, 2000);
        setAssigned_toNext('')
    };
    const handleStatusChange = (e) => {
        const value = e.target.value;
        // Validate that the selected value is one of the available options
        if (["Issued", "Available", "Maintenance", "Condemned"].includes(value)) {
            setItem_status(value);
        } else {
            // Handle invalid selection if needed
            console.error(`Invalid item status: ${value}`);
        }
    };
    const handleDeleleItem = (uqid) => {
        handleClose();
        Swal.fire({
            title: "Delete Item?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm!"
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(
                    "https://hr.geospectrum.com.ph/api/api/inventory/delete" + uqid,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                ).then(() => {
                    // This code will execute after the axios request is completed
                    setTrigger(!trigger);

                    Swal.fire({
                        title: "Deleted!",
                        text: "Item has been deleted.",
                        icon: "success"
                    });
                })
            }
        });
    };

    // ==============================================
    const [openDlg, setOpenDlg] = React.useState(false);
    const handleOpenAddDlg = (idx) => {
        setOpenDlg(true);
    };
    const handleCloseAddDlg = () => {
        setOpenDlg(false);
        setItem_name('')
        setItem_description('')
        setAssigned_to('')
        setNotes('')
        setIssue_date('')
        setReturn_date('')
        setItem_status('')
        setSerial_no('')
        setOffice('')
    };

    const alertAdded = () => {
        Swal.fire({
            icon: "success",
            title: "Equipment successfully added",
        });
    };

    const alertError = () => {
        Swal.fire({
            icon: "error",
            title: "Information Required",
            text: "Equipment is not created",
        });
        handleCloseAddDlg();
    };

    const handleNewItem = async () => {
        setButtonDisabled(true)
        if (
            item_name.trim() === "" ||
            item_description.trim() === "" ||
            serial_no.trim() === "" ||
            date_acquire.trim() === "" ||
            issue_date.trim() === "" ||
            item_status.trim() === ""
        ) {
            // Input is empty
            alertError();
            setTimeout(() => {
                setButtonDisabled(false);
            }, 3000);
            return;
        }
        try {
            axios.post(
                "https://hr.geospectrum.com.ph/api/api/inventory/create",
                {
                    item_name,
                    assigned_to,
                    item_description,
                    notes,
                    issue_date,
                    office,
                    date_acquire,
                    return_date,
                    item_status,
                    serial_no,
                    assign_by: user.email,
                    quantity,
                    isPeris: true
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            ).then(() => {
                // This code will execute after the axios request is completed
                setTrigger(!trigger);
            })

            alertAdded()


            // Reset submitting state after the request is complete
            setTimeout(() => {
                setButtonDisabled(false);
            }, 3000);
            setAssigned_to("");
            setSerial_no('')
            setItem_status('')
            setIssue_date('')
            setReturn_date('')
            handleCloseAddDlg();
        } catch (error) {
            console.error("Error while posting data:", error);
            // Handle the error as needed
        }
    };

    const downloadExcel = async () => {
        Swal.fire({
            title: "Download list?",
            text: "Peripheral list will be download",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch("https://hr.geospectrum.com.ph/api/api/inventory/exportPeris",
                        {
                            headers: {
                                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                Authorization: `Bearer ${user.token}`,
                            },
                        }
                    );
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const blob = await response.blob();
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Inventory_List.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } catch (error) {
                    console.error("Fetch error:", error);
                }
                Swal.fire({
                    title: "Success!",
                    text: "Your file has been downloaded.",
                    icon: "success"
                });
            }
        });
    };
    return (
        <>
            {/* View specific item */}
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
            >
                {viewOneInventory &&
                    viewOneInventory.map((item, indexx) => (
                        <>
                            <DialogContent>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs="12">
                                        <h2> Update Equipment/Item Information</h2>
                                    </Grid>
                                    <Grid item xs="2">
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            value={quantity}
                                            required
                                            label="Quantity"
                                            fullWidth
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="10">
                                        <TextField
                                            value={item_name}
                                            required
                                            label="Item/Equipment"
                                            fullWidth
                                            onChange={(e) => setItem_name(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="4">
                                        <TextField
                                            required
                                            type="date"
                                            label="Date Acquired"
                                            value={date_acquire}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            onChange={(e) => setDate_acquire(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="4">
                                        <TextField
                                            value={issue_date}
                                            type="date"
                                            label="Issue Date"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setIssue_date(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="4">
                                        <TextField
                                            value={return_date}
                                            type="date"
                                            label="Return Date"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setReturn_date(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="8">
                                        <TextField
                                            value={serial_no}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Serial No."
                                            fullWidth
                                            onChange={(e) => setSerial_no(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Office"
                                            onChange={(e) => setOffice(e.target.value)}
                                            value={office}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                        >
                                            <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                                            <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs="12">
                                        <TextField
                                            value={item_description}
                                            multiline
                                            rows={3}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Item/Equipment Description"
                                            fullWidth
                                            onChange={(e) => setItem_description(e.target.value)}
                                        />
                                    </Grid>
                                    {item.user.map((itms, index) => (
                                        <Grid key={index} item xs="4">
                                            <TextField
                                                disabled
                                                value={itms.firstname + " " + itms.lastname}
                                                required
                                                label="Assigned to"
                                                rows={3}
                                                fullWidth
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs="1" sx={{ textAlign: "center", margin: "auto" }}>
                                        <TrendingFlatIcon />
                                    </Grid>
                                    <Grid item xs="4">
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setAssigned_toNext(newValue ? newValue.user_id : null);
                                                setUser_email(newValue ? newValue.email : null);
                                                setFName(newValue ? newValue.firstname : null);
                                                setLName(newValue ? newValue.lastname : null);
                                                setDepartment(newValue ? newValue.department : null);
                                                setDesignation(newValue ? newValue.position : null)
                                            }}
                                            options={employee}
                                            getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                                            isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Reassign to"
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <MenuItem {...props} key={option.user_id} value={option}>
                                                    {`${option.firstname} ${option.lastname}`}
                                                </MenuItem>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs="3">
                                        <TextField
                                            required
                                            select
                                            label="Item Status"
                                            value={item_status}
                                            onChange={handleStatusChange}
                                            fullWidth
                                        >
                                            <MenuItem value={"Issued"}>Issued</MenuItem>
                                            <MenuItem value={"Available"}>Available</MenuItem>
                                            <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                                            <MenuItem value={"Condemned"}>Condemned</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs="12">
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={notes}
                                            multifield
                                            label="Notes"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => handleDeleleItem(item.inventory_uniqueId)} color="error">
                                    delete
                                </Button>
                                <Button
                                    onClick={() => handleUpdateItem(item.inventory_uniqueId)}
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </>
                    ))}

            </Dialog>

            {/* Add item */}
            <Dialog
                fullScreen={fullScreen}
                open={openDlg}
                onClose={handleCloseAddDlg}
                maxWidth={'md'}
            >
                <DialogContent>
                    <Grid container item direction="row" spacing={2}>
                        <Grid item xs="12" sx={{ textAlign: "center" }}>
                            <h2>Add Item / Equipment</h2>
                        </Grid>
                        <Grid item xs="2">
                            <TextField
                                required
                                type="number"
                                label="Quantity"
                                fullWidth
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </Grid>     <Grid item xs="6">
                            <TextField
                                required
                                label="Item/Equipment"
                                fullWidth
                                onChange={(e) => setItem_name(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="4">
                            <TextField
                                required
                                type="date"
                                label="Date Acquired"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={(e) => setDate_acquire(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="6">
                            <TextField
                                required
                                label="Serial No."
                                fullWidth
                                value={serial_no}
                                onChange={(e) => setSerial_no(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required
                                fullWidth
                                label="Office"
                                onChange={(e) => setOffice(e.target.value)}
                                value={office}
                                select
                            >
                                <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                                <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs="3">
                            <TextField
                                required
                                select
                                label="Item Status"
                                value={item_status}
                                onChange={handleStatusChange}
                                fullWidth
                            >
                                <MenuItem value={"Issued"}>Issued</MenuItem>
                                <MenuItem value={"Available"}>Available</MenuItem>
                                <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                                <MenuItem value={"Condemned"}>Condemned</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs="12">
                            <TextField
                                required
                                multifield
                                label="Item/Equipment Description"
                                multiline
                                rows={3}
                                fullWidth
                                onChange={(e) => setItem_description(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="6">
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    setAssigned_to(newValue ? newValue.user_id : null);
                                    setUser_email(newValue ? newValue.email : null);
                                    setFName(newValue ? newValue.firstname : null);
                                    setLName(newValue ? newValue.lastname : null);
                                    setDepartment(newValue ? newValue.department : null);
                                    setDesignation(newValue ? newValue.position : null);
                                }}
                                options={employee}
                                getOptionLabel={(emp) => `${emp.firstname} ${emp.lastname}`}
                                isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                renderInput={(params) => (
                                    <TextField {...params} label="Assign to" fullWidth />
                                )}
                                renderOption={(props, option) => (
                                    <MenuItem {...props} key={option.user_id} value={option}>
                                        {`${option.firstname} ${option.lastname}`}
                                    </MenuItem>
                                )}
                            />


                        </Grid>
                        <Grid item xs="3">
                            <TextField
                                type="date"
                                required
                                label="Issue Date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={(e) => setIssue_date(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="3">
                            <TextField
                                type="date"
                                label="Return Date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={(e) => setReturn_date(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs="12">
                            <TextField
                                multifield
                                label="Notes"
                                multiline
                                rows={4}
                                fullWidth
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="error">
                        delete
                    </Button>
                    <Button
                        onClick={() => handleNewItem()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} sx={{ mt: 1 }}>
                <TextField
                    size="small"
                    label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mr: 1,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px', // Set your desired border-radius here
                        },
                    }}
                />
                <TextField
                    size="small"
                    label="Status"
                    select
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        minWidth: 110, mr: 1, '& .MuiOutlinedInput-root': {
                            borderRadius: '20px', // Set your desired border-radius here
                        },
                    }}
                >
                    <MenuItem value={""}>
                        <Stack>
                            <Chip size="small" color="primary"
                                label={"All"} />
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"Available"}>
                        <Stack>
                            <Chip size="small" color="default"
                                label={"Available"} />
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"Issued"}>
                        <Stack>
                            <Chip size="small" color="success"
                                label={"Issued"} />
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"Maintenance"}>
                        <Stack>
                            <Chip size="small" color="warning"
                                label={"Maintenance"} />
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"Condemned"}>
                        <Stack>
                            <Chip size="small" color="error"
                                label={"Condemned"} />
                        </Stack>
                    </MenuItem>
                </TextField>
                <Fab size="small" color="error" aria-label="add" sx={{ boxShadow: 'none' }} onClick={() => handleOpenAddDlg()}>
                    <AddIcon fontSize="small" />
                </Fab>
                <Fab size="small" color="success" aria-label="add" sx={{ boxShadow: 'none', backgroundColor: "black", ml: 1 }} onClick={() => downloadExcel()}>
                    <FileOpenIcon fontSize="small" />
                </Fab>
                {/* <Button variant='contained' color="error"> Add Item </Button> */}
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ height: { xs: '', sm: '', md: '41vh', lg: '54vh', xl: '62vh' }, overflowY: 'scroll' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                       Quantity
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Item Name
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Office
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Assigned To
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Date Issued
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                                {loading ? (
                                    // Display Skeleton components while loading
                                    Array(7).fill().map((_, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" animation="wave" />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <>
                                        {filteredData.map((item, index) => (
                                            <TableRow key={item._id}>
                                                <TableCell align="left">{item.quantity}</TableCell>
                                                <TableCell align="left">{item.item_name}</TableCell>
                                                <TableCell align="left">
                                                    {item.office !== null ? item.office : "N/A"}
                                                </TableCell>

                                                {item.user.length > 0 ? (
                                                    item.user.map((users, index) => (
                                                        <TableCell key={index} align="left">
                                                            {users.firstname + " " + users.lastname}
                                                        </TableCell>
                                                    ))
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}

                                                <TableCell key={index} align="left">
                                                    {item.issue_date ? moment(item.issue_date).format("L") : ""}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row">
                                                        <Chip
                                                            size="small"
                                                            color={
                                                                item.item_status === "Condemned"
                                                                    ? "error"
                                                                    : item.item_status === "Issued"
                                                                        ? "success"
                                                                        : item.item_status === "Maintenance"
                                                                            ? "warning"
                                                                            : "default" // or any other default color
                                                            }
                                                            label={item.item_status}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Tooltip
                                                                title="View"
                                                                sx={{
                                                                    color: "#0F1140",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handleClickOpen(item.inventory_uniqueId);
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </TableHead>
                            <TableBody>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </>
    )
}

export default ITContainer;