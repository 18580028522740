import React, { Fragment } from 'react';
import { Page, Text, View, Document, Image, StyleSheet, } from '@react-pdf/renderer';
import logo from '../../src/images/logo_line.png'
import flogo from '../../src/images/flogo.png'
import signature from '../../src/images/e-Signature.png'

function PayslipPDF(props) {
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.2,
      flexDirection: 'column',
    },
    logo: {
      width: 120,
      height: 'auto',
      marginTop: 2,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    esignature: {
      width: 'auto',
      height: 53,
      marginTop: 19
    },
    esig: {
      width: '58%',
      textAlign: 'left',
      paddingRight: 8,
      color: "#919191"
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 5,
    },
    reportTitle: {
      color: '#04051f',
      fontSize: 12,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    DateContainer: {
      marginTop: 7,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    labelDate: {
      width: 120,
      fontSize: 9
    },
    headerContainer: {
      marginTop: 2,
      fontSize: 9
    },
    period: {
      marginTop: 0,
      fontSize: 10
    },
    periodDate: {
      paddingBottom: 3,
      fontFamily: 'Helvetica-Oblique'
    },

    tableContainer: {
      fontSize: 9,
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 0,
      borderWidth: 1,
      borderColor: '#b3b3b3',
    },
    openrow: {
      flexDirection: 'row',
      // borderBottomColor: '#b3b3b3',
      // borderBottomWidth: 1,
      alignItems: 'center',
      height: 13,
      fontStyle: 'bold',
    },
    closerow: {
      flexDirection: 'row',
      borderBottomColor: '#b3b3b3',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 18,
      fontStyle: 'bold',
    },
    description: {
      width: '40%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',
    },
    amount: {
      width: '60%',
      textAlign: 'left',
      paddingRight: 8,
      color: "#919191"
    },
    space: {
      width: '10%',
    },
    totaldescription: {
      width: '30%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',
    },
    totalamount: {
      width: '60%',
      textAlign: 'left',
      paddingRight: 8,
      color: "#919191"
    },
    deductiondescription: {
      width: '100%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',

    },
    deductioncontainer: {
      flexDirection: 'row',
      backgroundColor: 'black',
      color: "white",
      alignItems: 'center',
      height: 14,
      fontStyle: 'bold',
    },
    takehomedescription: {
      width: '50%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',

    },
    takehomeamount: {
      width: '50%',
      textAlign: 'right',
      paddingRight: 15,
      fontWeight: 'Bold',

    },
    takehomecontainer: {
      flexDirection: 'row',
      backgroundColor: 'black',
      alignItems: 'center',
      color: "white",
      height: 14,
      fontStyle: 'bold',
      borderBottomColor: '#b3b3b3',
      borderBottomWidth: 1,
    },
    approved: {
      width: '50%',
      textAlign: 'right',
      paddingRight: 15,
      fontWeight: 'Bold',

    },
    approvedBy: {
      width: '50%',
      flexDirection: 'row',
      alignItems: 'center',
      fontStyle: 'bold',
    },
    approvedDescrip: {
      fontSize: 7,
      width: '50%',
      flexDirection: 'row',
      alignItems: 'center',
      fontStyle: 'bold',
      fontSize: '9px'
    },
    descriptionL: {
      width: '40%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',
    },
    amountL: {
      width: '30%',
      textAlign: 'left',
      paddingRight: 8,
      color: "#919191"
    },
    descriptionD: {
      width: '20%',
      textAlign: 'left',
      paddingLeft: 15,
      fontWeight: 'Bold',
    },
    amountD: {
      width: '10%',
      textAlign: 'left',
      paddingRight: 8,
      color: "#919191"
    },
    ftext:{
      width: '85%',
      color: "#919191",
      fontSize: 7,
      fontFamily: 'Helvetica-Oblique'
    },    
    fimg:{
      width: 30,
      height: 'auto',
    },


  });

  const formatNumber = (value) => {
    if (value !== undefined && value !== null) {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }
    return '';
  };


  const {
    position, department, date, email, allowance, user_id, periodEnd, periodStart, name, salary, incentives, overtimeTotal, takeHomePay,
    grossPay, total_deduction, lates, latesTotal,
    philhealth_deduc, hmdf_deduc, sss_deduc, taxes_deduc, cashAdv_deduc, others_deduc,
    absences, absencesTotal, unpaidLeaves, unpaidLeavesTotal, allowance_deduc, hmdf_loan, sss_loan
  } = props
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>Employee Payslip</Text>
        </View>
        <Fragment>
          <View style={styles.DateContainer}>
            <Text style={styles.labelDate}>Date: {date} </Text>
            <Text >{ }</Text>
          </View >
        </Fragment>
        <View style={styles.headerContainer}>
          <Text>{name}</Text>
          <Text>{position} | {department}</Text>
        </View>
        <View style={styles.period}>
          <Text style={styles.periodDate}>Payroll for the period: {periodStart} - {periodEnd}</Text>
        </View>

        <View style={styles.tableContainer}>
          <View style={styles.openrow}>
            <Text style={styles.description}>Basic salary</Text>
            {/* <Text style={styles.amount}>{salary}</Text> */}
            <Text style={styles.amount}>{formatNumber(salary)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Allowance</Text>
            {/* <Text style={styles.amount}>{allowance}</Text> */}
            <Text style={styles.amount}>{formatNumber(allowance)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Overtime pay</Text>
            {/* <Text style={styles.amount}>{overtimeTotal}</Text> */}
            <Text style={styles.amount}>{formatNumber(overtimeTotal)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Incentives</Text>
            {/* <Text style={styles.amount}>{incentives}</Text> */}
            <Text style={styles.amount}>{formatNumber(incentives)}</Text>
          </View>
          <View style={styles.openrow}>
        
          </View>
          <View style={styles.closerow}>
            <Text style={styles.space}></Text>
            <Text style={styles.totaldescription}>Gross pay:</Text>
            {/* <Text style={styles.totalamount}> {grossPay}</Text> */}
            <Text style={styles.totalamount}>{formatNumber(grossPay)}</Text>
          </View>

          <View style={styles.deductioncontainer}>
            <Text style={styles.deductiondescription}>DEDUCTIONS</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Philhealth (premium share)</Text>
            {/* <Text style={styles.amount}>{philhealth_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(philhealth_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>HDMF (premium share) </Text>
            {/* <Text style={styles.amount}>{hmdf_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(hmdf_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>SSS (premium share)</Text>
            {/* <Text style={styles.amount}>{sss_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(sss_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Withholding taxes </Text>
            {/* <Text style={styles.amount}>{taxes_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(taxes_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Cash advances </Text>
            {/* <Text style={styles.amount}>{cashAdv_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(cashAdv_deduc)}</Text>
          </View>

          <View style={styles.openrow}>
            <Text style={styles.description}>HMDF Loan </Text>
            {/* <Text style={styles.amount}>{others_deduc}</Text> */}
            <Text style={styles.amount}>{hmdf_loan}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>SSS Loan </Text>
            {/* <Text style={styles.amount}>{others_deduc}</Text> */}
            <Text style={styles.amount}>{sss_loan}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Others </Text>
            {/* <Text style={styles.amount}>{others_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(others_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.descriptionL}>Late/s </Text>
            {/* <Text style={styles.amountL}>{latesTotal}</Text> */}
            <Text style={styles.amountL}>{formatNumber(latesTotal)}</Text>
            <Text style={styles.descriptionD}>No. of Days </Text>
            <Text style={styles.amountD}> {lates} day/s</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.descriptionL}>Absences</Text>
            {/* <Text style={styles.amountL}>{absencesTotal}</Text> */}
            <Text style={styles.amountL}>{formatNumber(absencesTotal)}</Text>
            <Text style={styles.descriptionD}>No. of Days </Text>
            <Text style={styles.amountD}> {absences} day/s</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.description}>Allowance Deductions </Text>
            {/* <Text style={styles.amount}>{allowance_deduc}</Text> */}
            <Text style={styles.amount}>{formatNumber(allowance_deduc)}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.descriptionL}>Unpaid Leaves </Text>
            {/* <Text style={styles.amountL}>{unpaidLeavesTotal} </Text> */}
            <Text style={styles.amountL}>{formatNumber(unpaidLeavesTotal || 0)}</Text>
            <Text style={styles.descriptionD}>No. of Days </Text>
            <Text style={styles.amountD}> {unpaidLeaves} day/s</Text>
          </View>
          <View style={styles.openrow}>
          </View>
          <View style={styles.closerow}>
            <Text style={styles.space}></Text>
            <Text style={styles.totaldescription}>Total Deductions: </Text>
            {/* <Text style={styles.totalamount}>{total_deduction}</Text> */}
            <Text style={styles.totalamount}>{formatNumber(total_deduction)}</Text>
          </View>
          <View style={styles.takehomecontainer}>
            <Text style={styles.takehomedescription}>TAKE HOME PAY: </Text>
            {/* <Text style={styles.takehomeamount}>PHP  {takeHomePay}</Text> */}
            <Text style={styles.takehomeamount}>{`PHP ${formatNumber(takeHomePay)}`}</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.esig}></Text>
            <Image style={styles.esignature} src={signature} />
          </View>
          <View style={styles.openrow}>
            <Text style={styles.approved}>Approved by: </Text>
            <Text style={styles.approvedBy}>Jianna Iza Medillo</Text>
          </View>
          <View style={styles.openrow}>
            <Text style={styles.approved}></Text>
            <Text style={styles.approvedDescrip}>Human Resource Management and Administrative Officer</Text>
          </View>
        </View>
        <View style={styles.period}>
        <Text style={styles.ftext}>"This document serves as an official and valid copy of the employee's payslip for the specified period."</Text>
        </View>
        {/* <Image style={styles.fimg} src={flogo} /> */}



      </Page>
    </Document>
  )
};

export default PayslipPDF;