import React, { useState } from "react";
import { Grid, Box, Stepper, Step, StepButton, Typography, Button, FormGroup, InputAdornment, IconButton, OutlinedInput, Stack, InputLabel, TextField } from '@mui/material';
import Logo from '../../../src/images/logo_line.png'
import axios from "axios";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate, } from "react-router-dom";

const steps = ['Input Email', 'Verify OTP', 'Create Password'];

export default function ForgotPass() {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [email, setEmail] = useState();
    const [otp, setOtp] = useState('');
    const [matchotp, setMatchotp] = useState('');
    const [error, setError] = useState(null);
    const [errorOTP, setErrorOTP] = useState(null);
    const [errorPass, setErrorPass] = useState(null);
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        axios.post("https://hr.geospectrum.com.ph/api/api/user/forgotPass", {
            email: email,
        })
            .then((res) => {
                // console.log(res.data);
                //success
                // setError(null);
                sendMail()
                const newActiveStep =
                    isLastStep() && !allStepsCompleted()
                        ? // It's the last step, but not all steps have been completed,
                        // find the first step that has been completed
                        steps.findIndex((step, i) => !(i in completed))
                        : activeStep + 1;
                setActiveStep(newActiveStep);
            })
            .catch((err) => {
                if (err.response) {
                    // console.log(err.response.data);
                    setError(err.response.data);
                } else {
                    setError({ message: "An unexpected error occurred." });
                }
            });
    };

    const sendMail = async () => {
        const alert = () => {
            Swal.fire({
                icon: "success",
                title: "OTP sent",
                text: "One-Time Password is sent to your email!",
            });
        };

        const OTP = Math.floor(Math.random() * 90000000) + 10000000;
        // console.log(OTP);
        setOtp(OTP)
        const send = async () => {
            axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
                userEmail: email,
                message: " Use the following One-Time Password to complete your Password Recovery Procedure. OTP is valid for 15 minutes. Your OTP is " + OTP,
                subject: 'Password Recovery'
            }).then().catch((err) => console.error(err))
        }
        alert();
        send()
    }

    const resendMail = async () => {
        const OTP = Math.floor(Math.random() * 90000000) + 10000000;
        const send = async () => {
            axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
                userEmail: email,
                message: " Use the following One-Time Password to complete your Password Recovery Procedure. OTP is valid for 15 minutes. Your OTP is " + OTP,
                subject: 'Password Recovery'
            }).then().catch((err) => console.error(err))
        }
        Swal.fire({
            title: "Generate New OTP?",
            text: "One-Time Password will be send to your email",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log(OTP);
                setOtp(OTP)
                send()
                Swal.fire({
                    title: "Sent!",
                    text: "Your new One-Time Password is sent.",
                    icon: "success"
                });


            }
        });
    }

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const handleConfirmOTP = () => {
        if (parseInt(matchotp) === otp) {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
            setErrorOTP(null)
        } else {
            // console.log("not match")
            setErrorOTP("One-Time Password did not match")
        }
    }

    const sendMailSuccess = async () => {
        const send = async () => {
            axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
                userEmail: email,
                message: "<p>Your password has been successfully changed!</p> <p>If you did not initiate this password change or have any concerns about your account's security, please get in touch with our IT team or administrator immediately. Your account security is of utmost importance to us, and we want to ensure that your information is always protected.</p> <p>You can reach out to our IT support team at it.support@geospectrum.com.ph or contact us through Slack. Please provide details about the situation so that we can assist you promptly. Thank you.</p > ",
                subject: 'Password Recovery'
            }).then().catch((err) => console.error(err))
        }
        send()
    }

    const handleChangePass = () => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&_*])(?=.*[0-9]).{8,}$/;

        const alert = () => {
            Swal.fire({
                icon: "success",
                title: "Password changed successfully",
                text: "You can now login to your portal!",
            });
        };

        if (!passwordRegex.test(newPass)) {
            setErrorPass(
                'Password is weak.'
            );
            return;
        }

        if (newPass === confirmPass) {

            axios.post("https://hr.geospectrum.com.ph/api/api/user/newPass", {
                confirmPass,
                email: email,
            })
                .then((res) => {
                    //success
                    setErrorPass(null);
                    alert()
                    navigate('/')
                    sendMailSuccess()
                })
                .catch((err) => {
                });

        } else {
            // Passwords do not match, display an error
            setErrorPass('Passwords do not match');
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const handleClickShowPasswordNew = () => setShowPasswordNew(!showPasswordNew);
    const handleMouseDownPasswordNew = () => setShowPasswordNew(!showPasswordNew);

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100vh' }}
            >
                <Grid item xs={10} sm={6} md={5} lg={4} xl={3}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {allStepsCompleted() ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>

                                    <FormGroup
                                        sx={{
                                            display: activeStep === 0 ? "" : "none",
                                            m: 1,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        <Grid container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" sx={{ backgroundColor: "#F8F8F8", padding: 5, borderRadius: 5, }}>
                                            <Grid item xs={12} sx={{ padding: 2, textAlign: "center", marginBottom: 3 }}>
                                                <img src={Logo} height={25} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Stack spacing={1}>
                                                    <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                                                    <OutlinedInput
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        fullWidth
                                                        id="email-login"
                                                        type="email"
                                                        name="email"
                                                        placeholder="account@company.com"
                                                    />
                                                </Stack>
                                                {error && <Typography sx={{ textAlign: "center", marginTop: 0.5, marginLeft: .5 }} color="error">{error}</Typography>}
                                                <Typography variant="body2" sx={{ textAlign: "center", marginTop: 1, marginBottom: 3, color: "#7d7c7c" }}>
                                                    We'll send a One Time Password to your email.
                                                </Typography>
                                                <Button fullWidth size="large" type="submit" variant="contained" color="primary" onClick={handleNext}>
                                                    Send OTP

                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup
                                        sx={{
                                            display: activeStep === 1 ? "" : "none",
                                            m: 1,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        <Grid container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" sx={{ backgroundColor: "#fafafc", padding: 5, borderRadius: 5 }}>
                                            <Grid item xs={12} sx={{ padding: 2, textAlign: "center", marginBottom: 1 }}>
                                                <img src={Logo} height={25} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="body2" sx={{ textAlign: "center", marginTop: 1, marginBottom: 2, color: "#7d7c7c" }}>
                                                    Your <b>One-Time Password</b> was sent successfully to your email ({email}). Please check your inbox and enter the received code to verify your identity and complete the recovery process.
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    onChange={(e) => setMatchotp(e.target.value)}
                                                    style={{ textAlign: 'center' }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                />
                                                {errorOTP && <Typography sx={{ textAlign: "center", marginTop: 0.5, marginLeft: .5 }} color="error">{errorOTP}</Typography>}

                                                <Button fullWidth size="large" type="submit" variant="contained" color="primary" onClick={handleConfirmOTP} sx={{ marginTop: 3 }}>
                                                    confirm
                                                </Button>
                                                <Button fullWidth size="large" type="submit" variant="contained" onClick={resendMail} sx={{
                                                    marginTop: 1, backgroundColor: '#445858', color: "white", '&:hover': {
                                                        backgroundColor: '#1e2b2b',
                                                        borderColor: '#0062cc',
                                                        boxShadow: 'none',
                                                    }
                                                }}>
                                                    Resend
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>

                                    <FormGroup
                                        sx={{
                                            display: activeStep === 2 ? "" : "none",
                                            m: 1,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        <Grid container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" sx={{ backgroundColor: "#fafafc", padding: 5, borderRadius: 5 }}>
                                            <Grid item xs={12} sx={{ padding: 2, textAlign: "center", marginBottom: 2 }}>
                                                <img src={Logo} height={25} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2, color: "#7d7c7c" }}>
                                                    You're one step closer on recovering your password! As a friendly reminder, please ensure to <b>be responsible and safeguard your login credentials </b>to maintain the security of your account.
                                                </Typography>
                                                <TextField
                                                    label="New password"
                                                    fullWidth
                                                    onChange={(e) => setNewPass(e.target.value)}
                                                    style={{ textAlign: 'center' }}
                                                    type={showPassword ? "Text" : "Password"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    disableRipple
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityIcon fontSize="small" />
                                                                    ) : (
                                                                        <VisibilityOffIcon fontSize="small" />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    label="Confirm password"
                                                    fullWidth
                                                    onChange={(e) => setConfirmPass(e.target.value)}
                                                    style={{ textAlign: 'center', marginTop: 6 }}
                                                    type={showPasswordNew ? "Text" : "Password"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={handleClickShowPasswordNew}
                                                                    onMouseDown={handleMouseDownPasswordNew}
                                                                    disableRipple
                                                                >
                                                                    {showPasswordNew ? (
                                                                        <VisibilityIcon fontSize="small" />
                                                                    ) : (
                                                                        <VisibilityOffIcon fontSize="small" />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {errorPass && <Typography sx={{ textAlign: "center", marginTop: 0.5, marginLeft: .5 }} color="error">{errorPass}</Typography>}
                                                <Button fullWidth size="large" type="submit" variant="contained" color="primary" onClick={handleChangePass} sx={{ marginTop: 3 }}>
                                                    submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                    {/* 

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                                            Next
                                        </Button>
                                        {activeStep !== steps.length &&
                                            (completed[activeStep] ? (
                                                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                    Step {activeStep + 1} already completed
                                                </Typography>
                                            ) : (
                                                <Button onClick={handleComplete}>
                                                    {completedSteps() === totalSteps() - 1
                                                        ? 'Finish'
                                                        : 'Complete Step'}
                                                </Button>
                                            ))}
                                    </Box> */}
                                </React.Fragment>
                            )}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}