import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  Modal,
  FormControlLabel,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  InputAdornment,
  TableRow,
  IconButton,
  Tooltip,
  Radio,
  FormControl,
  RadioGroup,
  Chip,
  Stack,
  Typography,
  Checkbox,
  FormGroup
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import ArchiveIcon from '@mui/icons-material/Archive';
import GMSlogo from "../../images/gms_logo.png";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import { PDFDownloadLink } from "@react-pdf/renderer";
import CAPDF from '../../documents/cashAdvance.js'

export default function CashAdvance() {
  const { user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState([]);

  // Fetch Current User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setUserData(data);
      });
  }, []);

  // Modal View Specific Cash Advance Request
  const [viewOneRequest, setViewOneRequest] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (idx) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/viewOneCAdvance" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setViewOneRequest(data);
      });
  };
  const handleClose = () => {
    setOpen(false)
    setIsNeedApproval("")
    setHr_approve("")
    setManager_approve("")
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const currentDate = new Date();
  const time = currentDate.getTime();


  // Retrieve and display CashAdvance Request
  const [cashAdvance, setCashAdvance] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const isArch = false
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/getAllRequest", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCashAdvance(data);
      });
  }, [trigger]);

  const filteredData = cashAdvance
    ? cashAdvance.filter((item) => {
      const itemName = item.user[0].firstname + item.user[0].lastname + item.request_type + moment(item.createdAt).format("LL") || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];
  //
  const [hr_approve, setHr_approve] = useState();
  const [manager_approve, setManager_approve] = useState();
  const [isNeedApproval, setIsNeedApproval] = useState();
  const [status, setStatus] = useState();
  const [remarks, setRemarks] = useState();
  const [newRemarks, setNewRemarks] = useState(remarks);
  const [manager_remarks, setManager_remarks] = useState();
  const [newmanager_remarks, setNewmanager_remarks] = useState(manager_remarks);
  const [messageNoappr, setMessageNoappr] = useState();
  const [message, setMessage] = useState();
  const [messageManager, setMessageManager] = useState();
  // Radio Button function
  const handleRadioChangeHRApprove = (event) => {
    setHr_approve(event.target.value);
    if (event.target.value == "false") {
      setStatus("Rejected");
      setMessageNoappr("We regret to inform you that your cash advance request has been rejected by the Human Resources department. If you have any questions or concerns regarding this decision, please reach out to the HR team for further clarification. Thank you for your understanding.")
    } else {
      setStatus("Approved");
      setMessageNoappr("Good news! Your cash advance request has been approved by the Human Resources department. The approved amount will be processed and credited to your account soon. Thank you!.")
      setMessage("Your cash advance request has been approved by the human resources officer and is given for the approval of the General Manager. Please check the status of your leave request on the portal. Thank you!.")
      setMessageManager("This is to inform you that the human resource officer has approved an employee cash advance request and is given for final approval. Kindly review the request on the portal. Thank you!")
    }
  };

  const handleRadioChangeManagerApprove = (event) => {
    setManager_approve(event.target.value);
    if (event.target.value == "false") {
      setStatus("Rejected");
      setMessage("We regret to inform you that your cash advance request has been rejected by the General Manager. If you have any questions or concerns regarding this decision, please reach out to the HR team for further clarification. Thank you for your understanding.")
    } else {
      setStatus("Approved");
      setMessage("Good news! Your cash advance request has been approved by the General Manager. The approved amount will be processed and credited to your account soon. Thank you!.")
    }
  };
  // Update Request Remarks and Approval (Radiobutton)
  const updateHR = (useremail, reqUID) => {
    // console.log(reqUID)
    if (isNeedApproval) {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/cashAdvance/update" +
        reqUID,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          remarks: newRemarks,
          hr_approve,
          status: "Final approval",
        }
      )
      sendMail(useremail);
      sendMailManager();
    } else {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/cashAdvance/update" +
        reqUID,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          remarks: newRemarks,
          hr_approve,
          status,
        }
      )
      sendMailNoappr(useremail);
    }

    setTrigger(!trigger)
    alertSuccess();
    setHr_approve("")
    setIsNeedApproval("")
  };

  const updateManager = (useremail, reqUID) => {
    axios.put(
      "https://hr.geospectrum.com.ph/api/api/cashAdvance/update" +
      reqUID,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        manager_remarks: newmanager_remarks,
        manager_approve,
        status,
      }
    )
    setTrigger(!trigger)
    alertSuccess();
    sendMail(useremail);
    setHr_approve("")
  };

  const sendMail = async (userEMAIL) => {
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/mailingalt', {
        userEmail: userEMAIL,
        message,
        subject: 'Cash Advance Status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailNoappr = async (userEMAIL) => {
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/mailingalt', {
        userEmail: userEMAIL,
        message: messageNoappr,
        subject: 'Cash Advance Status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const sendMailManager = async () => {
    // console.log(user.email, message)
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/mailingalt', {
        userEmail: "vssantos@geospectrum.com.ph", //vssantos@geospectrum.com.ph
        message: messageManager,
        subject: 'Cash Advance'
      }).then().catch((err) => console.error(err))
    }
    send()
  }
  // Success Alert message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been Updated",
    });
    handleClose();
  };

  //Count Cash Advance
  const [allCashAdvanceCount, setAllCashAdvanceCount] = useState("");
  const [inProgressCount, setInProgressCount] = useState("");
  const [completedCount, setCompletedCount] = useState("");
  React.useEffect(() => {

    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/countCashAdprogress", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setInProgressCount(data.InProgressCount);
        setCompletedCount(data.CompletedCount);
      });
    fetch("https://hr.geospectrum.com.ph/api/api/cashAdvance/allCashAdCount", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setAllCashAdvanceCount(data);
      });
  }, [allCashAdvanceCount, inProgressCount, completedCount]);


  const archive = (idx) => {
    Swal.fire({
      title: "Archive Notification",
      text: "Are you sure you want to archive this request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(
          "https://hr.geospectrum.com.ph/api/api/cashAdvance/update" + idx,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            isArchive: true,
          }
        )
        Swal.fire({
          title: "Archived!",
          text: "Your file has been archived.",
          icon: "success"
        });
      }
    });
  }

  const handleNeddApproval = (event) => {
    setIsNeedApproval(event)
  }
  return (
    <>
      <Grid item container direction="row" gap={2} columns={12.5}>
        <Grid item xs={12} sm={5} md={2} lg={2} xl={3}>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card
            sx={{
              width: { xs: 310, sm: 340, md: 210, lg: 280, xl: 300 },
              maxHeight: 135,
              backgroundColor: "#00AB9B",
              color: "white",
              paddingLeft: 2
            }}
          >
            <Grid item container direction="column">
              <Grid
                item
                sx={{
                  height: 40,
                  paddingLeft: 0.5,
                }}
              >
                <p>In Progress</p>
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <Grid item xs={2} sm={4} md={3} lg={3} xl={3}>
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      margin: 0,
                      padding: .5
                    }}
                  />
                </Grid>
                <Grid item xs={10} sm={8} md={9} lg={9} xl={9}>
                  <Typography sx={{ fontSize: { xs: 25, sm: 25, md: 20, lg: 25, xl: 27 } }}> 00{inProgressCount} Request</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
          <Card
            sx={{
              width: { xs: 310, sm: 340, md: 210, lg: 280, xl: 300 },
              maxHeight: 135,
              backgroundColor: "#298FDB",
              color: "white",
              paddingLeft: 2
            }}
          >

            <Grid item container direction="column">
              <Grid
                item
                sx={{
                  height: 40,
                  paddingLeft: 0.5,
                }}
              >
                <p>Completed</p>
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <Grid item xs={2} sm={4} md={3} lg={3} xl={3}>
                  <AssignmentTurnedInIcon
                    sx={{
                      fontSize: 50,
                      margin: 0,
                      padding: .5
                    }}
                  />
                </Grid>
                <Grid item xs={10} sm={8} md={9} lg={9} xl={9}>
                  <Typography sx={{ fontSize: { xs: 25, sm: 25, md: 20, lg: 25, xl: 27 } }}>00{completedCount} Request</Typography>
                </Grid>
              </Grid>
            </Grid>

          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card
            sx={{
              width: { xs: 310, sm: 340, md: 210, lg: 280, xl: 300 },
              maxHeight: 135,
              backgroundColor: "#FFB901",
              color: "white",
              paddingLeft: 2
            }}
          >
            <Grid item container direction="column">
              <Grid
                item
                sx={{
                  height: 40,
                  paddingLeft: 0.5,
               
                }}
              >
                <p>All Request</p>
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <Grid item xs={2} sm={4} md={3} lg={3} xl={3}>
                  <SummarizeIcon
                    sx={{
                      fontSize: 50,
                      margin: 0,
                      padding: .5
                    }}
                  />
                </Grid>
                <Grid item xs={10} sm={8} md={9} lg={9} xl={9}>
                  <Typography sx={{ fontSize: { xs: 25, sm: 25, md: 20, lg: 25, xl: 27 } }}>00{allCashAdvanceCount} Request</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

      </Grid>

      <Grid item container sx={{ marginTop: 2 }}>

        <TableContainer component={Paper} sx={{ marginBottom: "30px", height: { xs: '', sm: '', md: '55vh', lg: '65vh', xl: '75vh' }, overflowY: 'scroll' }}>
          <TextField
            size='small'
            label="Search request"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: '20px', marginLeft: "10px" }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </TableCell>
                {/* <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Purpose
                </TableCell> */}
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {filteredData.map((cAdvance, index) => (
                <TableRow>
                  {cAdvance.user.map((users, index) => (
                    <TableCell key={index} align="left">
                      {users.firstname + " " + users.lastname}
                    </TableCell>
                  ))}
                  <TableCell align="left">{cAdvance.amount.toLocaleString()}</TableCell>
                  {/* <TableCell align="left">{cAdvance.purpose}</TableCell> */}
                  <TableCell align="left">
                    {moment(cAdvance.createdAt).format("lll")}
                  </TableCell>
                  <TableCell align="left">
                    <Stack direction="row">
                      <Chip size="small" color={
                        cAdvance.status === "Rejected"
                          ? "error"
                          : cAdvance.status === "Approved"
                            ? "success"
                            : cAdvance.status === "Final approval"
                              ? "primary"
                              : cAdvance.status === "Pending"
                                ? "default"
                                : "default" // or any other default color
                      } label={cAdvance.status} />
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Tooltip
                          title="View"
                          sx={{
                            color: "#0F1140",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              handleOpen(cAdvance.request_uniqueId);
                            }}
                          >
                            <RemoveRedEyeIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>

                        <PDFDownloadLink document={<CAPDF

                          id={cAdvance.user_id}
                          name={cAdvance.user[0].firstname + " " + cAdvance.user[0].lastname}
                          department={cAdvance.user[0].department}
                          position={cAdvance.user[0].position}
                          amount={cAdvance.amount}
                          purpose={cAdvance.purpose}
                          date={moment(cAdvance.createdAt).format("LL")}
                          hr_approve={cAdvance.hr_approve === true ? "APPROVED" : cAdvance.hr_approve === false ? "REJECTED" : "Pending"}



                        />
                        }
                          fileName={`CashAdvance_${cAdvance.user[0].lastname}${time}`}>
                          <Tooltip
                            title="Download"
                            sx={{
                              color: "#1B4B59",
                            }}
                          // onClick={() => {
                          //   downloadPDF(cAdvance.request_uniqueId);
                          // }}
                          >
                            <IconButton>
                              <SimCardDownloadIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                        </PDFDownloadLink>

                      </Grid>
                      <Grid item>
                        <Tooltip
                          title="Archive"
                          sx={{
                            color: "#1B4B59",
                          }}
                          onClick={() => {
                            archive(cAdvance.request_uniqueId);
                          }}
                        >
                          <IconButton>
                            <ArchiveIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* View Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {viewOneRequest &&
            viewOneRequest.map((request, index) => (
              <Grid
                key={request._id}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                >
                  <img src={GMSlogo} width="220" height="auto" />
                </Grid>
                <Grid
                  item
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  REQUEST FOR CASH ADVANCE
                </Grid>
                {request.user.map((users, index) => (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item xs={2}>
                      Name:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.firstname + " " + users.lastname}</b>
                    </Grid>
                    <Grid item xs={2}>
                      Designation:
                    </Grid>
                    <Grid item xs={4}>
                      <b>{users.position}</b>
                    </Grid>

                  </Grid>
                ))}
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={4}>
                    Amount Requested:
                  </Grid>
                  <Grid item xs={8}>
                    <b> ₱ {request.amount.toLocaleString()}</b>
                  </Grid>
                  <Grid item xs={4}>
                    Date Requested:
                  </Grid>
                  <Grid item xs={8}>
                    <b> {moment(request.createdAt).format("LL")}</b>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      label="Purpose"
                      value={request.purpose}
                      defaultValue={request.purpose}
                      multiline
                      rows={3}
                      sx={{
                        width: "100%",
                        marginTop: "15px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="HR Remarks"
                      defaultValue={request.remarks}
                      value={remarks}
                      size="small"
                      disabled={userData.account === "Manager" || request.status === "Approved"}
                      onChange={(e) => setNewRemarks(e.target.value)}
                      multiline
                      rows={2}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="General Manager Remarks"
                      defaultValue={request.manager_remarks}
                      disabled={userData.email === "medillojianna@geospectrum.com.ph" || request.status === "Approved"}
                      value={remarks}
                      size="small"
                      onChange={(e) => setNewmanager_remarks(e.target.value)}
                      multiline
                      rows={2}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>

                {userData.account_role === "superadmin" && userData.account === "Manager" ? (
                  <>
                    {request.hr_approve === false ? (
                      <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                        Employee's request is rejected by the Human Resource Officer
                      </Typography>
                    ) : request.manager_approve === false ? (
                      <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                        Cash advance request is rejected
                      </Typography>
                    ) : request.manager_approve === true ? (
                      <Typography sx={{ color: "#3d9cf5", textAlign: "center" }}>
                        Cash advance request is approved
                      </Typography>
                    ) : request.hr_approve === true ? (
                      <>
                        <Grid item container direction="row">
                          <Grid item xs={12} sx={{ color: "blue" }}>
                            The Human Resource Officer approved the  employee's cash advance request.
                          </Grid>
                          <Grid item xs={4}>
                            General Manager:
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <RadioGroup
                                row
                                defaultValue={request.manager_approve}
                                value={manager_approve}
                                onChange={handleRadioChangeManagerApprove}
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  value={true}
                                  label="Approve"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  value={false}
                                  label="Reject"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                          <Button variant="contained" onClick={() => updateManager(request.user_email, request.request_uniqueId)}>
                            confirm
                          </Button>
                        </Grid></>
                    ) : (
                      <Typography sx={{ color: "green" }}>
                        The request remains pending as the human resource officer has yet to decide or even open it.
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {request.hr_approve === false ? (
                      <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                        Request rejected
                      </Typography>
                    ) : request.manager_approve === false ? (
                      <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                        Request is rejected by the General Manager
                      </Typography>
                    ) : request.manager_approve === true ? (
                      <Typography sx={{ color: "#3d9cf5", textAlign: "center" }}>
                        Request is approved by the General Manager
                      </Typography>
                    ) : request.hr_approve === true ? (
                      <>
                        <Typography sx={{ color: "#3d9cf5", textAlign: "center" }}>
                          Request is approved.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Grid item container direction="row">
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel control={<Checkbox checked={isNeedApproval} onChange={(e) => handleNeddApproval(e.target.checked)} />} label="Need final approval?" />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={4}>
                            Human Resource Officer:
                          </Grid>
                          <Grid item xs={8}>
                  
                              <RadioGroup
                                row
                                defaultValue={request.hr_approve}
                                value={hr_approve}
                                onChange={handleRadioChangeHRApprove}
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  value={true}
                                  label="Approve"
                            
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  value={false}
                                  label="Reject"
                                />
                              </RadioGroup>
                     
                          </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                          <Button variant="contained" onClick={() => updateHR(request.user_email, request.request_uniqueId)}>
                            confirm
                          </Button>
                        </Grid>
                      </>
                    )}

                  </>
                )}
              </Grid>
            ))}
        </Box>
      </Modal>
    </>
  );
}
