import React, { useState, useEffect } from "react";
import {
  Button, Grid, TextField, MenuItem, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography,
  TableContainer,
  InputAdornment,
  Tooltip,
  IconButton,
  Chip,
  Stack,
  useMediaQuery,
  DialogContent,
  DialogActions,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  InputLabel,
  Container,
  Toolbar,
  AppBar,
} from "@mui/material";
import { subDays, subHours } from 'date-fns';
import { useAuthContext } from "../../hooks/useAuthContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GMSlogo from "../../images/gms_logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Slide from '@mui/material/Slide';
import RequestPDF from '../../documents/itemRequest'
import { PDFDownloadLink, Text, pdf } from "@react-pdf/renderer";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Chart from './chart'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Itemrequest() {

  const { user } = useAuthContext();
  const [userData, setUserData] = useState([]);

  // Dialog (submit request)
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setQuantity("")
    setItem("")
    setPurpose("")
    setEnd_user("")
    setPrice(0)
    setUnitPrice(0)
    setShop_link("")
    setOffice("")
  };

  // Dialog (vuew request)
  const [reqViewOne, setReqViewOne] = useState([]);
  const [openReq, setOpenReq] = React.useState(false);
  const handleClickOpenReq = (id) => {
    setOpenReq(true);
    fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/viewRequest" + id, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setReqViewOne(data);
      });
  };
  const handleCloseReq = () => {
    setOpenReq(false);

  };

  const handleClickPurchase = (id, itmName, qty, ofc) => {
    Swal.fire({
      title: 'Item purchased?',
      html: `<style>
      .custom-input {
        margin-top:  0; 
        font-size:  17px; 
        width:  70%;
      }
    </style>
    <input type="number" required id="actualExpense" class="swal2-input custom-input" placeholder="Actual expense (PHP)">
    <br>
    <select id="expenseUnder" class="swal2-input custom-input">
    <option value="GMS">Geospectrum Marketing Services</option>
    <option value="GASI">Geospectrum Analytics Services Inc.</option>
  </select>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      preConfirm: () => {
        const actualExpense = document.getElementById('actualExpense').value;
        const expenseUnder = document.getElementById('expenseUnder').value;

        if (!actualExpense || !expenseUnder) {
          Swal.showValidationMessage('Please fill all fields');
          return false;
        }

        return { actualExpense, expenseUnder };
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const { actualExpense, expenseUnder } = result.value;

        axios.post(
          "https://hr.geospectrum.com.ph/api/api/inventory/create",
          {
            item_name: itmName,
            item_status: "Available",
            assign_by: userData.user_id,
            quantity: qty,
            date_acquire: currentDate,
            office: ofc
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        axios.put(
          "https://hr.geospectrum.com.ph/api/api/itemRequest/update" + id,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            status: "Done",
            actualExpense: actualExpense,
            expenseUnder: expenseUnder
          }
        ).then(() => {
          setTrigger(!trigger);
          Swal.fire({
            title: 'Done!',
            text: 'Item has been updated.',
            icon: 'success'
          });
        }).catch((error) => {
          console.error('Error updating item:', error);
          Swal.showValidationMessage(`Failed to update item: ${error}`);
        });
      }
    });
  }


  const [quantity, setQuantity] = useState("");
  const [item, setItem] = useState("");
  const [purpose, setPurpose] = useState("");
  const [end_user, setEnd_user] = useState("");
  const [office, setOffice] = useState("");
  const [price, setPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [shop_link, setShop_link] = useState("");
  const [request, setRequest] = useState(null);
  const [count, setCount] = useState();
  const [trigger, setTrigger] = useState(false);
  // Retrieve Current User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserData(data);
      });
  }, []);

  // Polling Data
  const [pollingIntervalId, setPollingIntervalId] = useState(null);
  useEffect(() => {
    const fetchData = () => {
      fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getItemRequest", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          setRequest(data);
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    };
    // Start polling
    const intervalId = setInterval(fetchData, 4000); // Poll every  5 seconds
    setPollingIntervalId(intervalId);
    // Clear interval on cleanup
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Render on first t
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getItemRequest", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data)
        setRequest(data);
      });
  }, [trigger]);


  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/count", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data)
        setCount(data);
      });
  }, [trigger]);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredData = request
    ? request.filter((item) => {
      const itemName = item.item + item.user[0].firstname + item.user[0].lastname + item.status + item.price || ''; // Handle potential undefined
      const searchTermLower = searchTerm.toLowerCase();
      return itemName.toLowerCase().includes(searchTermLower);
    })
    : [];

  const handlePriceChange = (e) => {
    // Allow only numbers and ignore other characters
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    // Set the numeric value without commas to the state
    setUnitPrice(parseFloat(inputValue) || 0)
  };
  // Format the number with commas for display
  const formattedUnitPrice = new Intl.NumberFormat('en-US').format(parseFloat(unitPrice, 10));
  const formattedPrice = new Intl.NumberFormat('en-US').format(parseFloat(price, 10));
  const calculateTotal = () => {
    let total = parseFloat(quantity * unitPrice)
    setPrice(total)

  }

  // create request
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Request has been submitted",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Information Required",
      text: "Request is not submitted",
    });
    handleClose();
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const createRequest = async () => {
    setButtonDisabled(true);
    // send email notification
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: "medillojianna@geospectrum.com.ph", // medillojianna@geospectrum.com.ph
        message: `Please be informed that ${userData.firstname} ${userData.lastname} has submitted a request for an item through the portal. Kindly check the portal at your earliest convenience to review and process the request. Thank you for your attention to this matter.`,
        subject: 'Equipment Request'
      }).then().catch((err) => console.error(err))
    }
    // validate if inputs are empty
    if (
      quantity.trim() === "" ||
      item.trim() === "" ||
      purpose.trim() === "" ||
      price === 0 ||
      end_user.trim() === ""
    ) {
      // Input is empty
      alertError();
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
      return;
    }

    try {
      axios.post(
        "https://hr.geospectrum.com.ph/api/api/itemRequest/create",
        {
          office, quantity, item, purpose, end_user, price, unitPrice, shop_link, user_id: userData.user_id, it_approve: true, status: "In Progress"
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ).then(() => {
        // This code will execute after the axios request is completed
        setTrigger(!trigger);
      })
    } catch (error) {
      console.error("Error creating item request:", error);
      // Handle the error (e.g., show an error message to the user)
      return;
    }
    // setTrigger(!trigger);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
    alertSuccess()
    send()
  }

  const [it_approve, setIt_approve] = useState();
  const [hr_approve, setHr_approve] = useState();
  const [status, setStatus] = useState();
  const [message, setMessage] = useState();
  const [messageHR, setMessageHR] = useState();
  const [remarks, setRemarks] = useState("");
  const [newRemarks, setNewRemarks] = useState(remarks);
  const [remarksHR, setRemarksHR] = useState("");
  const [newRemarksHR, setNewRemarksHR] = useState(remarksHR);
  const [isArchive, setIsArchive] = useState();

  const handleHRApprove = (event) => {
    setHr_approve(event.target.value);
    if (event.target.value == "false") {
      setHr_approve(false);
      setIsArchive(true);
      setStatus("Rejected");
      setMessage("We regret to inform you that your recent item request has been reviewed by the HR department and unfortunately, it has been rejected. If you have any questions or require further clarification regarding the rejection, please feel free to reach out to the HR department. Thank you for your understanding.")
    } else {
      setHr_approve(true);
      setIsArchive(false);
      setStatus("For purchase");
      setMessage("We are pleased to inform you that your recent item request has been approved by the HR department. Your requested item(s) will be processed and made available to you shortly. If you have any questions or concerns regarding the approved items please feel free to reach out to the HR department. Thank you for your understanding and cooperation.")
    }
  };

  const handleITApprove = (event) => {
    if (event.target.value == "false") {
      setIt_approve(false);
      setIsArchive(true);
      setStatus("Rejected");
      setMessage("We regret to inform you that your recent item request has been reviewed by the IT department and unfortunately, it has been rejected. If you have any questions or require further clarification regarding the rejection, please feel free to reach out. Thank you for your understanding.")

    } else {
      setIt_approve(true);
      setIsArchive(false);
      setStatus("In Progress");
      setMessage("Your item request is approved by the IT supervisor and is given for the approval of the Human Resource Officer. Please check the status of your request on the portal.")
      setMessageHR("This is to inform you that " + userData.firstname + " " + userData.lastname + " has approved an employee's item request. Please update the records accordingly.")
    }
  };


  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so adding 1
  const time = currentDate.getTime();
  const updateButton = async (reqID, userEmail, itmName, qty) => {
    const alert = () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Request has been updated",
      });
      handleCloseReq();
    };
    try {
      axios.put(
        "https://hr.geospectrum.com.ph/api/api/itemRequest/update" +
        reqID,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          remarks: newRemarks,
          remarksHR: newRemarksHR,
          hr_approve,
          status,
          it_approve,
          // isArchive
        }
      ).then(response => {
        // Handle response if needed
        setTrigger(!trigger);
      })
      alert();
      sendMail(userEmail);
      if (it_approve === true) {
        sendMailHR();
      };
      setHr_approve(null)
    } catch (error) {
      console.error('Error updating leave request:', error);
    }
    setNewRemarks('')
    setNewRemarksHR('')
    setHr_approve('');
    setIt_approve('')
  };
  const sendMail = async (userEMAIL) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: userEMAIL,
        message,
        subject: 'Equipment request status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }
  const sendMailHR = async (userEMAIL) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMailtoHR', {
        message: messageHR,
        subject: 'Equipment request status'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  // Dialog chart

  const [openLS, setOpenLS] = React.useState(false);
  const handleOpenChart = () => {
    setOpenLS(true);
  };
  const handleCloseChart = () => {
    setOpenLS(false);
  };

  return (
    <>
      {/* submit a request dialog */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                marginBottom: "30px",
              }}
            >
              <img src={GMSlogo} width="310" height="auto" />
            </Grid>

            <Grid
              item
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              EQUIPMENT REQUEST FORM
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Grid item xs={2}>
                <TextField
                  required
                  onBlur={calculateTotal}
                  fullWidth
                  label="Quantity"
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                  InputLabelProps={{
                    style: { width: '100%' },
                  }}
                />
              </Grid>   <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  label="Item"
                  onChange={(e) => setItem(e.target.value)}
                  value={item}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handlePriceChange}
                  onBlur={calculateTotal}
                  value={formattedUnitPrice}
                  label="Unit Price Estimation"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  label="Total Price"
                  value={formattedPrice}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Purpose of request"
                  required
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => setPurpose(e.target.value)}
                  value={purpose}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  label="End user of requested item/s:"
                  onChange={(e) => setEnd_user(e.target.value)}
                  value={end_user}
                  InputLabelProps={{
                    style: { width: '100%' },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label="Office"
                  onChange={(e) => setOffice(e.target.value)}
                  value={office}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                >
                  <MenuItem value={"Ortigas Office"}>Ortigas Office</MenuItem>
                  <MenuItem value={"Futurepoint Office"}>Futurepoint Office</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Shop Link (if available)"
                  onChange={(e) => setShop_link(e.target.value)}
                  value={shop_link}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={() => {
            createRequest();
          }} autoFocus disabled={buttonDisabled}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* view a request dialog */}
      <Dialog
        fullScreen={fullScreen}
        open={openReq}
        onClose={handleCloseReq}
        maxWidth={'md'}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                marginBottom: "5px",
              }}
            >
              <img src={GMSlogo} width="310" height="auto" />
            </Grid>

            <Grid
              item
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              EQUIPMENT REQUEST FORM
            </Grid>

            <Grid
              item
              container
              direction="row"
              spacing={2}
              sx={{
                marginBottom: "20px",
              }}
            >
              <Grid item xs={2}>
                <TextField
                  required
                  fullWidth
                  label="Quantity"
                  type="number"
                  disabled
                  value={reqViewOne.quantity}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' }
                  }}
                />
              </Grid>   <Grid item xs={10}>
                <TextField
                  required
                  fullWidth
                  label="Item"
                  disabled
                  value={reqViewOne.item}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={reqViewOne.unitPrice}
                  label="Unit Price Estimation"
                  InputLabelProps={{ shrink: true,   style: { width: '100%' } }}
                />
              </Grid>    <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={reqViewOne.price}
                  label="Total Price"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Purpose of request"
                  required
                  multiline
                  rows={3}
                  fullWidth
                  disabled
                  value={reqViewOne.purpose}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  label="End user of requested item/s:"
                  disabled
                  value={reqViewOne.end_user}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label="Office:"
                  disabled
                  value={reqViewOne.office}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Shop Link"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={reqViewOne.shop_link}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  disabled={userData.account_role === "superadmin" || reqViewOne.status === "Rejected" || reqViewOne.status === "Done" || reqViewOne.status === "For purchase"}
                  label="IT Supervisor Remarks"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={reqViewOne.remarks? reqViewOne.remarks : ""}
                  onChange={(e) => setNewRemarks(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="HR Remarks"
                  disabled={userData.account_role === "supervisor" || reqViewOne.status === "Rejected" || reqViewOne.status === "Done" || reqViewOne.status === "For purchase"}
                  InputLabelProps={{ shrink: true }}
                  defaultValue={reqViewOne.remarksHR? reqViewOne.remarksHR : ""}
                  onChange={(e) => setNewRemarksHR(e.target.value)}
                />
              </Grid>
            </Grid>

            {userData.account_role === "superadmin" ? (
              <>
                <Grid item container direction="row">
                  <Grid xs={12}>
                    {reqViewOne.hr_approve === false ? (
                      <Typography sx={{ color: "#f75276", textAlign: "center" }}>
                        Employee's item request is rejected
                      </Typography>
                    ) : reqViewOne.hr_approve === true ? (
                      <Typography sx={{ color: "#2265a8", textAlign: "center" }}>
                        Employee's item request is approved
                      </Typography>
                    ) : reqViewOne.it_approve === false ? (
                      <Typography sx={{ color: "#f75276" }}>
                        Employee's item request is rejected by the IT Supervisor
                      </Typography>
                    ) : reqViewOne.it_approve === true ? (
                      <>
                        <Grid item container direction="row">
                          <Grid item xs={12} sx={{ color: "blue" }}>
                            The IT Supervisor approved the employee's item request
                          </Grid>
                          <Grid item xs={4}>
                            Human Resouce Officer:
                          </Grid>
                          <Grid item xs={8}>

                            <RadioGroup
                              row
                              defaultValue={hr_approve}
                              onChange={(event) => handleHRApprove(event)}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                value={true}
                                label="Approve"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                value={false}
                                label="Reject"
                              />
                            </RadioGroup>

                          </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                          <Button variant="contained"
                            onClick={() => updateButton(reqViewOne.request_uniqueId, reqViewOne.user[0].email, reqViewOne.item, reqViewOne.quantity)}
                            disabled={hr_approve === null || hr_approve === undefined}
                          >
                            confirm
                          </Button>
                        </Grid>

                      </>
                    ) : (
                      <Typography sx={{ color: "green", textAlign: "center" }}>
                        The request remains pending as the IT supervisor hasn't
                        decided or even opened it.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {reqViewOne.hr_approve === true ? (
                  <Typography sx={{ color: "blue" }}>
                    Item request approved
                  </Typography>
                ) : reqViewOne.hr_approve === false ? (
                  <Typography sx={{ color: "#f75276" }}>
                    The HR rejected the request.
                  </Typography>
                ) : reqViewOne.supervisor_approve === false ? (
                  <>
                    <Typography sx={{ color: "#f75276" }}>
                      You have rejected this request.
                    </Typography>
                  </>
                ) : reqViewOne.supervisor_approve === true ? (
                  <>
                    <Typography sx={{ color: "#3d9cf5" }}>
                      The HR department has received the request and is under review; please await approval.
                    </Typography>
                  </>
                ) : (
                  <Grid item container direction="row">
                    <Grid item xs={4}>
                      IT Supervisor:
                    </Grid>
                    <Grid item xs={8}>

                      <RadioGroup
                        row
                        defaultValue={reqViewOne.supervisor_approve}
                        value={it_approve}
                        onChange={(event) => handleITApprove(event)}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value={true}
                          label="Approve"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={false}
                          label="Reject"
                        />
                      </RadioGroup>

                    </Grid>
                  </Grid>
                )}

                {reqViewOne.hr_approve === false || reqViewOne.it_approve === true || reqViewOne.it_approve === false || reqViewOne.hr_approve === true ? (
                  <p></p>
                ) : (
                  <Grid item container justifyContent="flex-end">
                    <Button variant="contained"
                      onClick={() => updateButton(reqViewOne.request_uniqueId, reqViewOne.user[0].email)}
                      disabled={it_approve === null || it_approve === undefined}
                    >
                      confirm
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Dialog Chart */}
      <Dialog
        fullScreen
        open={openLS}
        onClose={handleCloseChart}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: "#04051f" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseChart}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Chart
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseChart}>
              Exit
            </Button>
          </Toolbar>
        </AppBar>
        <Chart />
      </Dialog>

      <Grid container item direction="row" columnSpacing={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          xs={12}
          sm={6}
          md={1.5}
          lg={1.5}
          xl={1.5}
        >
          <Grid
            item
            sx={{
              paddingTop: "10px",
            }}
          >
            <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: '#04051f', color: "white", }}>submit request</Button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          // justifyContent="flex-end"
          sx={{
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              xl: "flex-end",
            },
          }}
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={3}
        >
          <Grid
            item
            xs={2}
            sm={2}
            md={4}
            lg={3}
            xl={3}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <AssignmentIcon
              sx={{
                fontSize: 50,
                margin: 0,
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={8}
            lg={5}
            xl={5}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: { xs: 13, md: 13, lg: 13, xl: 15, fontWeight: "bold" } }}>In Progress</Typography>
            <p style={{ margin: 0 }}>00{count?.InProgressCount}</p>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          sx={{
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              xl: "flex-end",
            },
          }}
          // sx={{
          //   justifyContent:  {xs: "flex-start", sm: "flex-start", md: "center", xl: "flex-end"}
          // }}
          xs={12}
          sm={6}
          md={2.5}
          lg={3}
          xl={3}
        >
          <Grid
            item
            xs={2}
            sm={2}
            md={4}
            lg={3}
            xl={3}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <AssignmentTurnedInIcon
              sx={{
                fontSize: 50,
                margin: 0,
              }}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={8}
            lg={4}
            xl={5}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: { xs: 13, md: 13, lg: 13, xl: 15, fontWeight: "bold" } }}>Completed</Typography>
            <p style={{ margin: 0 }}>00{count?.CompletedCount}</p>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          sx={{
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              xl: "flex-end",
            },
          }}
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={3}
        >
          <Grid
            item
            xs={2}
            sm={2}
            md={4}
            lg={3}
            xl={3}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <SummarizeIcon
              sx={{
                fontSize: 50,
                margin: 0,
              }}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={8}
            lg={6}
            xl={5}
            sx={{
              // backgroundColor: "#ffb901",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: { xs: 13, md: 13, lg: 13, xl: 15, fontWeight: "bold" } }}>All Request</Typography>
            <p style={{ margin: 0 }}>00{count?.AllCount}</p>
          </Grid>
        </Grid>


      </Grid>
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={12}>
          <TextField
            size='small'
            label="Search request"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ padding: 1, marginTop: '15px' }}
            autoComplete="off"
          />
          <FormControl sx={{ marginTop: 3, minWidth: 130 }}>
            <TextField
              size="small"
              label="Status"
              select
              onChange={(e) => setSearchTerm(e.target.value)}
            >
              <MenuItem value={""}>
                <Stack>
                  <Chip size="small" color="default"
                    label={"All"} />
                </Stack>
              </MenuItem>
              <MenuItem value={"Done"}>
                <Stack>
                  <Chip size="small" color="success"
                    label={"Done"} />
                </Stack>
              </MenuItem>
              <MenuItem value={"In Progress"}>
                <Stack>
                  <Chip size="small" color="primary"
                    label={"In Progress"} />
                </Stack>
              </MenuItem>
              <MenuItem value={"For purchase"}>
                <Stack>
                  <Chip size="small" color="warning"
                    label={"For purchase"} />
                </Stack>
              </MenuItem>
              <MenuItem value={"Rejected"}>
                <Stack>
                  <Chip size="small" color="error"
                    label={"Rejected"} />
                </Stack>
              </MenuItem>
            </TextField>
          </FormControl>
          <Button variant="outlined" color="error" sx={{ marginTop: 3.2, ml: 1, }} onClick={handleOpenChart}>Chart</Button>
          <TableContainer component={Paper} sx={{ marginBottom: "30px", height: { xs: '', sm: '', md: '55vh', lg: '60vh', xl: '68vh' }, overflowY: 'scroll' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Requested by:
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Item
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total Price
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Actual
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Budgeted
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {filteredData.map((req) => (
                  <TableRow
                    key={req._id}
                  >
                    <TableCell align="left">
                      {req.user[0].firstname + " " + req.user[0].lastname}
                    </TableCell>
                    <TableCell align="left">
                      {req.quantity}
                    </TableCell>
                    <TableCell align="left">
                      {req.item}
                    </TableCell>
                    <TableCell align="left">
                      <Stack direction="row">
                        <Chip size="small" variant="elevated" color={
                          req.status === "Rejected"
                            ? "error"
                            : req.status === "Done"
                              ? "success"
                              : req.status === "For purchase"
                                ? "warning"
                                : req.status === "In Progress"
                                  ? "primary"
                                  : "default" // or any other default color
                        } label={req.status} />
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {req.price.toLocaleString()}
                    </TableCell>
                    <TableCell align="left">
                      {req.actualExpense ? req.actualExpense.toLocaleString() : ''}
                    </TableCell>
                    <TableCell align="left">
                      {req.expenseUnder ? req.expenseUnder.toLocaleString() : ''}
                    </TableCell>
                    <TableCell align="left">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Tooltip
                            title="View"
                            sx={{
                              color: "#0F1140",
                            }}
                          >
                            <IconButton onClick={() => handleClickOpenReq(req.request_uniqueId)}
                            >
                              <RemoveRedEyeIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <PDFDownloadLink document={<RequestPDF
                            name={req.user[0].firstname + " " + req.user[0].lastname}
                            id={req.user[0].user_id}
                            department={req.user[0].department}
                            position={req.user[0].position}
                            item={req.item}
                            purpose={req.purpose}
                            endUser={req.end_user}
                            price={req.price.toLocaleString()}
                            unitPrice={req.unitPrice.toLocaleString()}
                            link={req.shop_link}
                            quantity={req.quantity}
                            isITApproved={req.it_approve === true ? "APPROVED" : req.it_approve === false ? "REJECTED" : "Pending"}
                            isHRApproved={req.hr_approve === true ? "APPROVED" : req.hr_approve === false ? "REJECTED" : "Pending"}
                            date={moment(req.createdAt).format("LL")}
                          />
                          }
                            fileName={`ItemRequest_${req.user[0].lastname + moment(req.createdAt).format("L")}`}>

                            <Tooltip
                              title="Download"
                              sx={{
                                color: "#1B4B59",
                              }}
                            >
                              <IconButton>
                                <SimCardDownloadIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </PDFDownloadLink>
                        </Grid>

                        {req.hr_approve === true && req.it_approve === true && req.status === "For purchase" && (
                          <Grid item>
                            <Tooltip
                              title="Purchased"
                              sx={{
                                color: "#0F1140",
                              }}
                            >
                              <IconButton onClick={() => handleClickPurchase(req.request_uniqueId, req.item, req.quantity, req.office)}
                              >
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}

                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
