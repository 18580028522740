import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { red } from "@mui/material/colors";
import axios from "axios";
import Swal from "sweetalert2";
import AnnoImage from "../../images/announcement.jpg";
import { useAuthContext } from "../../hooks/useAuthContext";
import moment from "moment/moment";

import './announcement.css'

export default function Announcement() {
  const [userData, setUserData] = useState([]);
  const [announcement, setAnnouncement] = useState(null);
  const { user } = useAuthContext();
  const [trigger, setTrigger] = useState(false);

  // Retrieve Current User Data
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserData(data);
      });
  }, []);

  // Retrieve Announcement
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/announcement/all", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAnnouncement(data);
      });
  }, [trigger]);

  // Modal Create Announcement
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Modal View Announcement
  const [annoView, setAnnoView] = useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = (idx) => {
    setOpen1(true);
    fetch("https://hr.geospectrum.com.ph/api/api/announcement/annoData" + idx, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAnnoView(data);
      });
  };
  const handleClose1 = () => {
    setOpen1(false)
  };

  // Alert Message
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Announcement has been created",
    });
    handleClose();
  };
  const alertError = () => {
    Swal.fire({
      icon: "error",
      title: "Textfield / Checkbox Required",
      text: "Announcement is not created",
    });
    handleClose();
  };

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [supervisorView, setsupervisorView] = useState(false);
  const [digitizerView, setDigitizerView] = useState(false);
  const [employeeView, setEmployeeView] = useState(false);
  const [isSelected, setisSelected] = useState(false);

  function setSupervisor(e) {
    setsupervisorView(e.target.checked);
    setDigitizerView(false)
  }
  function setDigitizer(f) {
    setDigitizerView(f.target.checked);
    setsupervisorView(false)
  }
  function setEmployee(e) {
    setEmployeeView(e.target.checked);
  }
  function setAllView(e) {
    // setEmployeeView(e.target.checked);
    setDigitizerView(e.target.checked);
    setsupervisorView(e.target.checked);
    setisSelected(!isSelected);
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  // Create Announcement
  const create = async () => {
    if (isSubmitting) {
      return;
    }

    if (title.trim() === "" || content.trim() === "") {
      // Input is empty
      alertError();
      return;
    }
    if (
      supervisorView == false &&
      digitizerView == false &&
      employeeView == false
    ) {
      alertError();
      return;
    }

    if (digitizerView == true && supervisorView == true) {
      fetch("https://hr.geospectrum.com.ph/api/api/announcement/getAllEmail", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
           sendMAIL(data)
        });

    } else if (digitizerView == true) {
      fetch("https://hr.geospectrum.com.ph/api/api/announcement/getEmail" + "digitizer", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // // console.log(data);
           sendMAIL(data)
        });
    } else if (supervisorView == true) {
      fetch("https://hr.geospectrum.com.ph/api/api/announcement/getEmail" + "supervisor", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
           sendMAIL(data)
        });
    }

    axios.post(
      "https://hr.geospectrum.com.ph/api/api/announcement/create",
      {
        title,
        created_by: userData.user_id,
        content,
        digitizerView,
        supervisorView,
        employeeView,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    ).then(response => {
      // Handle response if needed
      setTrigger(!trigger);
    }).catch(error => {
      // Handle error if needed
      console.error("Error:", error);
    });
    setTrigger(!trigger);
    setIsSubmitting(false);
    setsupervisorView(false);
    setDigitizerView(false);
    setEmployeeView(false);
    setisSelected(false);
    setContent("");
    setTitle("");
    alertSuccess();
  };

  const sendMAIL = async (email) => {
    const send = async () => {
      axios.post('https://hr.geospectrum.com.ph/api/api/email/sendMail', {
        userEmail: email,
        message: `<p>Announcement Subject: <b> ${title} </b> </p> <p>Please be informed that a new announcement has been posted on our portal. To stay updated and access the latest information, please log in to your portal account at hr.geospectrum.com.ph.</p> <p>Thank you for your attention.</p>`,
        subject: 'Announcement'
      }).then().catch((err) => console.error(err))
    }
    send()
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "400px", sm: "600px", md: "900px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Upload Button
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const deleteAnno = (idx) => {
    handleClose1()
    Swal.fire({
      title: "Delete announcement?",
      text: "You won't be able to revert after deletion!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(
          "https://hr.geospectrum.com.ph/api/api/announcement/delete" + idx, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(() => {
          // This code will execute after the axios request is completed
          setTrigger(!trigger);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        })
      }
      
    });
  }

    React.useEffect(() => {
      window.onclick = function(event){
        console.log(event.target.className)
        if (event.target.className === 'modal1') {
          setOpen1(false)
        }
        else if (event.target.className === 'modal') {
          setOpen(false)
        }
      }
    })

    return (
      <>
        {/* CREATE Announcement Modal */}
        <div id={open ? 'modal-box' : 'modal-box-hidden'} className='modal'>
          <div id='modal-container'>
            <div id='ann-modal-content'>
              <h2 id='ann-modal-title'>CREATE NEW ANNOUNCEMENT</h2>
              <div id='ann-modal-header'>
                <label for='announcement'>
                  <span>Announcement Subject</span>
                  <input required type='text' label="Announcement Subject" value={title}
                    onChange={(e) => setTitle(e.target.value)}/>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div id='checkbox'>
                  <input type='checkbox' onChange={setAllView}/>
                  All&nbsp;
                </div>
                <div id='checkbox'>
                  <input type='checkbox' onChange={setSupervisor} disabled={isSelected}/>
                  Supervisors&nbsp;
                </div>
                <div id='checkbox'>
                  <input type='checkbox' onChange={setEmployee} disabled={isSelected}/>
                  Employee&nbsp;
                </div>
                <div id='checkbox'>
                  <input type='checkbox' onChange={setDigitizer} disabled={isSelected}/>
                  Digitizer&nbsp;
                </div>
              </div>
              <textarea id='ann-modal-textarea' value={content}
                rows="8" onChange={(c) => setContent(c.target.value)}/>
              <button id='modal-information-button'
                onClick={() => { create(); }}>PUBLISH</button>
            </div>
          </div>
        </div>

        {/* VIEW Announcement Modal */} 
        <div id={open1 ? 'modal-box' : 'modal-box-hidden'} className='modal1'>
          <div id='modal-container'>
            <div id='ann-modal-content'>
              <img id='ann-modal-image' src={AnnoImage} alt='announcements'/>
              <h2 id='ann-modal-title'>{annoView[0]?.title || ''}</h2>
              <p>{annoView[0]?.content || ''}</p>
              <br/>
              <button id='modal-top-cancel'  onClick={() => {
                deleteAnno(annoView[0]?.announcement_uniqueId || '')}}>
                DELETE
              </button>
            </div>
          </div>
        </div>

        {user && (
          <div id='announcement-container'>
            <div id='announcement-header'>
              <h1 id='announcement-header-text'>Announcement</h1>
              <button id='announcement-header-button' onClick={handleOpen}>
                POST
              </button>
            </div>
            <div id='announcement-cards'>
              {announcement && 
                announcement.map((anno, index) => (
                  <div id='announcement-card' 
                    onClick={() => handleOpen1(anno.announcement_uniqueId)}>
                    <div id='announcement-card-header'>
                      <Avatar sx={{ bgcolor: red[500], marginRight: '1ch'}} 
                        src={`https://hr.geospectrum.com.ph/api/profile/` + anno.created[0].photo_path}/>
                      <div id='announcement-card-header-title'>
                        <p id='announcement-card-header-name'>{anno.created[0].firstname + " " + anno.created[0].lastname}</p>
                        <small>{moment(anno.createdAt).format('LLL')}</small>
                      </div>
                    </div>
                    <img id='ann-card-image' src={AnnoImage} alt='announcement media'/>
                    <h4 style={{textAlign: 'center'}}>{anno.title}</h4>
                  </div>
                ))}
            </div>
            
            {/* Announcement card */}

            
          </div>
        )}
      </>
    );
  }
