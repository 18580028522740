import React, { useState, useEffect } from "react";
import './App.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { useAuthContext } from './hooks/useAuthContext'
import SignIn from './pages/signup/Signup.js'
import Login from './pages/login/login.js'
import Signout from './pages/signout'
import Inactive from './pages/inactive.js'
import ForgotPass from './pages/forgotpassword/index.js'
// Main
import Main from './pages/maincontainer/index'
import UserMain from './pages/Userpage/userMainContainer/index'


// export default App;
function App() {
  const { user } = useAuthContext();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    let isPageReloading = false;
  
    const handleBeforeUnload = (event) => {
      // Use PerformanceNavigationTiming to check if the page is being reloaded
      const navigationType = performance.getEntriesByType('navigation')[0].type;
      if (navigationType === 'reload') {
        isPageReloading = true;
      } else {
        // Clear localStorage only if the page is being closed (not reloaded)
        if (!isPageReloading) {
          localStorage.clear();
        }
      }
    };
  
    const handleUnload = () => {
      // Add any necessary cleanup code here
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
  useEffect(() => {
    if (user) {
      fetch('https://hr.geospectrum.com.ph/api/api/user/userData' + user.email)
        .then(res => res.json())
        .then(data => setUserRole(data))
        .catch(error => console.error("Error fetching user data:", error));
    } else {
      setUserRole('');
    }
  }, [user]);

  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="*" element={<Signout />} />
      </Routes>
    );
  }

  if (userRole.account_status === false) {
    return (
      <Routes>
        <Route path="/" element={<Inactive />} />
        <Route path="*" element={<Inactive />} />
      </Routes>
    );
  }

  if (userRole.account_role === "superadmin" || userRole.account_role === "supervisor") {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/admin/home" />} />
        <Route path="/admin/*" element={<Main />} />
        <Route path="/signup" element={!user ? <SignIn /> : <Navigate to="/" />} />
      </Routes>
    );
  }

  if (userRole.account_role === "user" || userRole.account_role === "" || userRole.account_role === "digitizer" || userRole.account_role === "probationary") {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/user/home" />} />
        <Route path="/user/*" element={<UserMain />} />
        <Route path="/signup" element={!user ? <SignIn /> : <Navigate to="/" />} />
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    );
  }

  // Handle other cases if needed
  return null;
}

export default App;

