import { Button, Grid, Card, Box, Typography, CardContent, SvgIcon, Stack, Avatar, CardHeader, Divider, CardActions } from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PhpIcon from '@mui/icons-material/Php';
import { LineChart } from '@mui/x-charts/LineChart';
import SyncIcon from '@mui/icons-material/Sync';
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import PaymentsIcon from '@mui/icons-material/Payments';
import React, { useState, useEffect } from "react";
import { ResponsiveContainer } from 'recharts';
import AtmIcon from '@mui/icons-material/Atm';

export default function ChartCont() {
    const [expense, setExpense] = useState(0);
    const [lineDataGMS, setLineDataGMS] = useState();
    const [lineDataGASI, setLineDataGASI] = useState();
    const [exUnder, setExUnder] = useState({ GASICount: 0, GMSCount: 0 });
    const [exUnderTotal, setExUnderTotal] = useState({ currentMonthTotal: 0, currentYearTotal: 0, currentMonthTotalGASI: 0, currentYearTotalGASI: 0 });
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [trigger, setTrigger] = useState(false);
    React.useEffect(() => {
        fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getMonthAndYear")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data)
                setExpense(data);
            });

        fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/countExunder")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setExUnder(prevState => ({
                    ...prevState,
                    GASICount: data.GASICount,
                    GMSCount: data.GMSCount
                }));
            });
        fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/totalExpUnder")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data)
                setExUnderTotal(data);
            });

        fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getchartGMS")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data)
                setLineDataGMS(data);
            });

        fetch("https://hr.geospectrum.com.ph/api/api/itemRequest/getchartGASI")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data)
                setLineDataGASI(data);
            });
    }, [trigger]);

    const xLabels = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ];

    return (
        <>
            <Grid container direction={"row"} spacing={2}>
                <Grid item xs="12" sx={{ marginBottom: { xs: 2, sm: 4, md: 3, lg: 8, xl: 8 } }}></Grid>
                <Grid item xs={12} md={1} lg={1} xl={2}></Grid>
                {/* Card 1 */}
                <Grid item xs={12} md={2.5} lg={2.5} xl={2}>
                    <CardContent sx={{ flex: '1 0 auto', maxWidth: "400px", backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="overline"

                                >
                                    Current month
                                </Typography>
                                <Typography variant="h4">
                                    {expense ? expense.currentMonthTotal.toLocaleString() : ""}
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={{
                                    backgroundColor: 'error.main',
                                    height: 56,
                                    width: 56
                                }}
                            >
                                <SvgIcon>
                                    <AccountBalanceWalletIcon />
                                </SvgIcon>
                            </Avatar>
                        </Stack>

                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}
                            >
                                <SvgIcon

                                    fontSize="small"
                                >

                                </SvgIcon>
                                <Typography

                                    variant="body2"
                                >
                                   {expense.lastMonthTotal && expense.currentMonthTotal  ? (((expense.currentMonthTotal - expense.lastMonthTotal) / expense.lastMonthTotal)*100).toFixed(2): 0}  %
                                </Typography>
                            </Stack>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                Since last month
                            </Typography>
                        </Stack>

                    </CardContent>
                </Grid>
                {/* Card 2 */}
                <Grid item xs={12} md={2.5} lg={2.5} xl={2}>
                    <CardContent sx={{ flex: '1 0 auto', maxWidth: "400px", backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="overline"
                                >
                                    Last month
                                </Typography>
                                <Typography variant="h4">
                                    {expense ? expense.lastMonthTotal.toLocaleString() : ""}
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={{
                                    backgroundColor: 'warning.main',
                                    height: 56,
                                    width: 56
                                }}
                            >
                                <SvgIcon>
                                    <PhpIcon />
                                </SvgIcon>
                            </Avatar>
                        </Stack>

                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}
                            >
                                <SvgIcon

                                    fontSize="small"
                                >

                                </SvgIcon>
                                <Typography

                                    variant="body2"
                                >
                                    
                                </Typography>
                            </Stack>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                              
                            </Typography>
                        </Stack>

                    </CardContent>
                </Grid>
                {/* Card 3 */}
                <Grid item xs={12} md={2.5} lg={2.5} xl={2}>
                    <CardContent sx={{ flex: '1 0 auto', maxWidth: "400px", backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="overline"
                                >
                                    Current year
                                </Typography>
                                <Typography variant="h4">
                                    {expense ? expense.currentYearTotal.toLocaleString() : ""}
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={{
                                    backgroundColor: 'success.main',
                                    height: 56,
                                    width: 56
                                }}
                            >
                                <SvgIcon>
                                    <PaymentsIcon />
                                </SvgIcon>
                            </Avatar>
                        </Stack>

                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}
                            >
                                <SvgIcon

                                    fontSize="small"
                                >

                                </SvgIcon>
                                <Typography

                                    variant="body2"
                                >
                                     {expense.lastYearTotal && expense.currentYearTotal  ? (((expense.currentYearTotal - expense.lastYearTotal) / expense.lastYearTotal)*100).toFixed(2): 0} %
                                </Typography>
                            </Stack>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                Since last year
                            </Typography>
                        </Stack>

                    </CardContent>
                </Grid>
                {/* Card 4 */}
                <Grid item xs={12} md={2.5} lg={2.5} xl={2}>
                    <CardContent sx={{ flex: '1 0 auto', maxWidth: "400px", backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack spacing={1}>
                                <Typography
                                    color="text.secondary"
                                    variant="overline"
                                >
                                    Last year {currentYear - 1}
                                </Typography>
                                <Typography variant="h4">
                                    {expense ? expense.lastYearTotal.toLocaleString() : ""}
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: 56,
                                    width: 56
                                }}
                            >
                                <SvgIcon>
                                    <AtmIcon />
                                </SvgIcon>
                            </Avatar>
                        </Stack>

                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}
                            >
                                <SvgIcon

                                    fontSize="small"
                                >

                                </SvgIcon>
                                <Typography

                                    variant="body2"
                                >
                                   
                                </Typography>
                            </Stack>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                           
                            </Typography>
                        </Stack>

                    </CardContent>
                </Grid>
                <Grid item xs={0} md={1} lg={1} xl={2}></Grid>

                {/*Line Chart */}
                <Grid item xs={12} md={1} lg={1} xl={2}></Grid>
                <Grid item xs={12} md={6} lg={6} xl={5}>
                    <CardContent sx={{ flex: '1 0 auto', backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <CardHeader
                            action={(
                                <Button
                                    color="inherit"
                                    size="small"
                                    startIcon={(
                                        <SvgIcon fontSize="small">
                                            <SyncIcon />
                                        </SvgIcon>
                                    )}
                                    onClick={() => { setTrigger(!trigger) }}
                                >
                                    Sync
                                </Button>
                            )}
                            title="This Year"
                        />
                        <CardContent>
                            <ResponsiveContainer height={270}>
                                <LineChart

                                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                                    series={[
                                        {
                                            data: [
                                                lineDataGMS ? lineDataGMS.monthlyTotals.JANTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.FEBTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.MARTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.APRTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.MAYTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.JUNTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.JULTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.AUGTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.SEPTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.OCTTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.NOVTOTAL : 0,
                                                lineDataGMS ? lineDataGMS.monthlyTotals.DECTOTAL : 0,
                                            ],
                                            color: "#3c5a9a",
                                             label: 'GMS'
                                        },
                                        {
                                            data: [
                                                lineDataGASI ? lineDataGASI.monthlyTotals.JANTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.FEBTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.MARTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.APRTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.MAYTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.JUNTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.JULTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.AUGTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.SEPTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.OCTTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.NOVTOTAL : 0,
                                                lineDataGASI ? lineDataGASI.monthlyTotals.DECTOTAL : 0,
                                            ],
                                            color: "#adadad",
                                             label: 'GASI'
                                        },
                                    ]}
                                />
                            </ResponsiveContainer>
                        </CardContent>
                        <Divider />
                    </CardContent>
                </Grid>
                {/*Pie Chart */}
                <Grid item xs={12} md={4} lg={4} xl={3}>
                    <CardContent sx={{ flex: '1 0 auto', backgroundColor: "#ffffff", boxShadow: "1px 3px 3px #888888" }}>
                        <CardHeader
                            title="Expense Under"
                        />
                        <CardContent>
                            <PieChart
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: exUnder.GASICount, label: 'GASI', color: "#adadad" },
                                            { id: 1, value: exUnder.GMSCount, label: 'GMS', color: "#3c5a9a" },
                                        ],
                                        arcLabel: (item) => `${item.value}`, color: 'white',
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                        innerRadius: 30,
                                        outerRadius: 100,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                        startAngle: -360,
                                        endAngle: 180,
                                    },

                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fill: 'white',
                                    },
                                }}
                                height={200}
                            />
                        </CardContent>
                        <Grid container direction={'row'}>
                            <Grid container item xs="6">
                                <Grid item xs="12">
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        This month
                                    </Typography>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        GMS: PHP {exUnderTotal ? exUnderTotal.currentMonthTotal.toLocaleString() : 0}
                                    </Typography>
                                </Grid>
                                <Typography
                                    align="right" // Align text to the right
                                    color="text.secondary"
                                    variant="caption"
                                >
                                    GASI: PHP {exUnderTotal ? exUnderTotal.currentMonthTotalGASI.toLocaleString() : 0}
                                </Typography>

                            </Grid>
                            <Grid container item xs="6">
                                <Grid item xs="12">
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        This year
                                    </Typography>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        GMS: PHP {exUnderTotal ? exUnderTotal.currentYearTotal.toLocaleString() : 0}
                                    </Typography>
                                </Grid>
                                <Typography
                                    align="right" // Align text to the right
                                    color="text.secondary"
                                    variant="caption"
                                >
                                    GASI: PHP {exUnderTotal ? exUnderTotal.currentYearTotalGASI.toLocaleString() : 0}
                                </Typography>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={12} md={1} lg={1} xl={2}></Grid>
            </Grid>
        </>


    )
}
