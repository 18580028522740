import React from 'react'
import { Grid, Button } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';


export default function index() {

  return (
    <>
      <Grid container
        direction="row"
        spacing={2}
        alignItems="center">
        <Grid item
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={5}
          sx={{
            fontSize: { xs: "20px", sm: "20px", md: "60px" },
            fontWeight: "bold"
          }}>
          Company Calendar
        </Grid>
        <Grid item
          xs={5}>
          <Button variant="contained" >Create Event</Button>
        </Grid>
        <Grid item
          xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
              <DemoItem>

                <DateCalendar />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  )
}
