import { Grid, Divider, TextField, InputLabel, Select, MenuItem, FormControl, Button,styled  } from '@mui/material'
import React from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function AddProjectModal() {

  const [status, setStatus] = React.useState('');

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
      <Grid container
        direction="column"
      >
        <Grid item>
          Add Project
        </Grid>
        <Divider
          sx={{
            paddingTop: "10px"
          }} />

        <Grid item container
          direction="row"
          sx={{
            paddingTop: "10px"
          }}
          spacing={2}
        >
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Project Title" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Company" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Amount of Contract" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Company Address" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Kinds of Goods" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Company Website" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Client Name" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Contact Number" type="search" />
          </Grid>  <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Email" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Representative " type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Representative Email" type="search" />
          </Grid>
          <Grid item
            xs={6}
          >
            <TextField size="small" fullWidth label="Representative Contact" type="search" />
          </Grid>
          <Grid item
            xs={12}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"Open"}>Open</MenuItem>
                <MenuItem value={"Ongoing"}>Ongoing</MenuItem>
                <MenuItem value={"Acceptance"}>Acceptance</MenuItem>
                <MenuItem value={"Cleared"}>Cleared</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
          >
            <TextField
              label="Description"
              multiline
              rows={4}
              sx={{
                width: "100%"
              }}
            />
          </Grid>
          <Grid item
            xs={12}
          >
            <TextField
              label="Remarks"
              multiline
              rows={4}
              sx={{
                width: "100%"
              }}
            />
          </Grid>

          <Grid item
          xs={11 }
          >
            <Button component="label" variant='contained' startIcon={<CloudUploadIcon />}>
              Upload Logo
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>

          <Grid item
          xs={1}>
            <Button variant='contained'>
              Add 
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
