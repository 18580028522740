import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Divider
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthContext } from "../../../hooks/useAuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment/moment";
import { useTheme } from "@mui/material/styles";
import PayslipPDF from '../../../documents/payslip'
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";

export default function Payslip() {
  const { user } = useAuthContext();

  // Fetch User Data
  const [userData, setUserData] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/user/userData" + user.email)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUserData(data);
      });
  }, []);

  // Get Payslip
  const [payslip, setPayslip] = useState([]);
  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/payslip/getPayslip" + userData.user_id, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(employee)
        setPayslip(data);
      });
  }, [userData]);


  React.useEffect(() => {
    // Function to fetch data
    const fetchData = () => {
      fetch(`https://hr.geospectrum.com.ph/api/api/payslip/getPayslip${userData.user_id}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          setPayslip(data);
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    };
    // Start polling
    const intervalId = setInterval(fetchData, 7000); // Poll every   5 seconds
    // Clear interval on cleanup
    return () => {
      clearInterval(intervalId);
    };
  }, [userData.user_id]);

  const [viewPayslip, setViewPayslip] = useState([]);
  const handleView = (id) => {
    setOpen(true);
    fetch("https://hr.geospectrum.com.ph/api/api/payslip/viewSlip" + id)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setViewPayslip(data);
      });
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    setOpen(false);
  };

  // Accordion
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const amountStyle = {

    color: "#2A0114"
  };
  const descripStyle = {
    fontWeight: "light",
    color: "#7B7B7B"
  };
  const invisibleTextStyle = {
    visibility: 'hidden', // or use opacity: 0; for a faded effect
  };


  const formatNumber = (value) => {
    if (value !== undefined && value !== null) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(value);
    }
    return ''; // or handle it according to your needs
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Payslip for 15 days period "}
        </DialogTitle>
        <DialogContent>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs="2">
              Payroll for the Period:
            </Grid>
            <Grid item xs="10">
              {viewPayslip.periodStart} - {viewPayslip.periodEnd}
            </Grid>
            <Grid item xs="2">
              Basic Pay:
            </Grid>
            <Grid item xs="10">
              ₱ {userData.basic_pay / 2}
            </Grid>
            <Grid item xs="2">
              Allowance:
            </Grid>
            <Grid item xs="10">
              ₱ {userData.allowance / 2}
            </Grid>
            <Grid item xs="12">
              <Divider />
            </Grid>
            <Grid item xs="2">
              Overtime Pay:
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.overtimeTotal}
            </Grid>
            <Grid item xs="2">
              Incentives:
            </Grid>
            <Grid item xs="5">
              ₱ {viewPayslip.incentives}
            </Grid>
            <Grid item xs="12">
              <Divider />
            </Grid>
            <Grid item xs="12">
              <Typography sx={{ fontSize: "20px" }}>Deductions</Typography>
            </Grid>
            <Grid item xs="2">
              Philhealth (Premium Share):
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.philhealth_deduc}
            </Grid>
            <Grid item xs="2">
              HMDF (Premium Share):
            </Grid>
            <Grid item xs="5">
              ₱ {viewPayslip.hmdf_deduc}
            </Grid>
            <Grid item xs="2">
              SSS (Premium Share):
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.sss_deduc}
            </Grid>
            <Grid item xs="2">
              Withholding Taxes:
            </Grid>
            <Grid item xs="5">
              ₱ {viewPayslip.taxes_deduc}
            </Grid>
            <Grid item xs="2">
              Cash Advances:
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.cashAdv_deduc}
            </Grid>
            <Grid item xs="2">
              Others:
            </Grid>
            <Grid item xs="5">
              ₱ {viewPayslip.others_deduc}
            </Grid>
            <Grid item xs="2">
              Total Absences:
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.absences}
            </Grid>
            <Grid item xs="2">
              Unpaid Leaves:
            </Grid>
            <Grid item xs="5">
              ₱ {viewPayslip.unpaidLeaves}
            </Grid>
            <Grid item xs="2">
              Total Lates:
            </Grid>
            <Grid item xs="3">
              ₱ {viewPayslip.latesTotal}
            </Grid>
            <Grid item xs="12">
              <Divider />
            </Grid>
            <Grid item xs="2">
              Gross Pay:
            </Grid>
            <Grid item xs="2">
              ₱ {viewPayslip.grossPay}
            </Grid>
            <Grid item xs="2">
              Total Deductions:
            </Grid>
            <Grid item xs="2">
              ₱ {viewPayslip.total_deduction}
            </Grid>{" "}
            <Grid item xs="2">
              Take Home Pay:
            </Grid>
            <Grid item xs="2">
              ₱ {viewPayslip.takeHomePay}
            </Grid>
            <Grid item xs="12">
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item container direction="row">
        <Grid item xs="12">
          {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 740 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Payslip ID
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Date Created
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Pay Period
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        minWidth: { xs: "200", lg: "280" },
                      }}
                    >
                      Remarks
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      View
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payslip &&
                    payslip.map((slip, index) => (
                      <TableRow key={index}>
                        <TableCell>{slip.payslip_uniqueId}</TableCell>
                        <TableCell>
                          {moment(slip.createdAt).format("LL")}
                        </TableCell>
                        <TableCell>
                          {moment(slip.periodStart).format("LL") +
                            " - " +
                            moment(slip.periodEnd).format("LL")}
                        </TableCell>
                        <TableCell sx={{ maxWidth: 280 }}>
                          {slip.remarks}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            onClick={() => {
                              handleView(slip.payslip_uniqueId);
                            }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper> */}

          <TableContainer sx={{ maxHeight: 740 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", width: '40%' }}>
                    Date Created
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Pay Period
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          {payslip &&
            payslip.map((slip, index) => (
              <Accordion key={slip._id} expanded={expanded === `panel${slip.payslip_uniqueId}`} onChange={handleChange(`panel${slip.payslip_uniqueId}`)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {moment(slip.createdAt).format("LL")}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>   {moment(slip.periodStart).format("LL") + " - " + moment(slip.periodEnd).format("LL")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}>
                    <Grid item container xs={12} sm={12} md={5} lg={5} xl={5}
                      sx={{ backgroundColor: "#FBFBFB", marginRight: '10px', borderRadius: 1 }}>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5" sx={{ marginBottom: 1 }}>
                          Earnings
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Basic salary
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {formatNumber(userData.basic_pay / 2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Allowance
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {formatNumber(userData.allowance / 2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Overtime pay
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {formatNumber(slip.overtimeTotal)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Incentives
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.incentives}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1, height: "26vh" }}>
                        <Typography variant="body2" sx={invisibleTextStyle}>
                          no text
                        </Typography>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 1, marginTop: 2 }}>
                        <Divider />
                      </Grid>

                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                          Gross Pay
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                          ₱ {formatNumber(slip.grossPay)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={5} lg={5} xl={5}
                      sx={{ backgroundColor: "#FBFBFB", marginRight: '10px', borderRadius: 1 }}>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5" sx={{ marginBottom: 1 }}>
                          Deductions
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Philhealth (premium share)
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.philhealth_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          HDMF (premium share)
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.hmdf_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          SSS (premium share)
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.sss_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Withholding taxes
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {formatNumber(slip.taxes_deduc)}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Cash advances
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.cashAdv_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          HMDF Loan
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.hmdf_loan}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          SSS Loan
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.sss_loan}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Others
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.others_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Late/s
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.latesTotal}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Absences
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.absences}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Allowance Deductions
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.allowance_deduc}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={descripStyle}>
                          Unpaid Leaves
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="body1" sx={amountStyle}>
                          {slip.unpaidLeaves}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 1, marginTop: 2 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                          Total Deductions:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="h6" sx={{ marginTop: 1 }}>
                          ₱ {formatNumber(slip.total_deduction)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 1, marginTop: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={8} sm={8} md={9} lg={9} xl={9} sx={{ paddingLeft: 1 }}>
                        <Typography variant="body1" sx={{ marginTop: 1, fontWeight: "bold", color: "#260414" }}>
                          Take Home Pay:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                        <Typography variant="h6" sx={{ marginTop: 1, color: "#2E071B" }}>
                          ₱ {formatNumber(slip.takeHomePay)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item >
                    <PDFDownloadLink
                      document={<PayslipPDF
                        date={moment(slip.createdAt).format("LL")}
                        position={userData.position}
                        department={userData.department}
                        email={userData.email}
                        allowance={userData.allowance / 2}
                        user_id={slip.user_id}
                        periodEnd={slip.periodEnd}
                        periodStart={slip.periodStart}
                        name={userData.firstname + " " + userData.lastname}
                        salary={userData.basic_pay / 2}
                        incentives={parseFloat(slip.incentives)}
                        overtimeTotal={parseFloat(slip.overtimeTotal)}
                        takeHomePay={parseFloat(slip.takeHomePay)}
                        grossPay={parseFloat(slip.grossPay)}
                        total_deduction={parseFloat(slip.total_deduction)}
                        lates={slip.lates}
                        latesTotal={parseFloat(slip.latesTotal)}
                        philhealth_deduc={parseFloat(slip.philhealth_deduc)}
                        hmdf_deduc={parseFloat(slip.hmdf_deduc)}
                        sss_deduc={parseFloat(slip.sss_deduc)}
                        taxes_deduc={parseFloat(slip.taxes_deduc)}
                        cashAdv_deduc={parseFloat(slip.cashAdv_deduc)}
                        others_deduc={parseFloat(slip.others_deduc)}
                        absences={slip.absences}
                        absencesTotal={parseFloat(slip.absencesTotal)}
                        unpaidLeaves={" "} //day/s missing
                        unpaidLeavesTotal={parseFloat(slip.unpaidLeaves)}//unpaidLeavesTotal
                        allowance_deduc={parseFloat(slip.allowance_deduc)}
                        hmdf_loan={parseFloat(slip.hmdf_loan)}
                        sss_loan={parseFloat(slip.sss_loan)}
                      />}
                      fileName={`payslip_${userData.lastname}`}
                    >
                      {({ blob, url, loading, error }) => (
                        <Button variant="contained" color="error" sx={{ marginTop: 2 }} >Download</Button>
                      )}
                    </PDFDownloadLink>



                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}


        </Grid>
      </Grid>
    </>
  );
}
