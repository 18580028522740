import { Hidden } from "@mui/material";
import React, { useState, useEffect } from "react";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import ManIcon from "@mui/icons-material/Man";
import { useAuthContext } from "../../../hooks/useAuthContext";
import SpaIcon from "@mui/icons-material/Spa";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";

import './tabBar.css';

export default function TabBar() {
  const { user } = useAuthContext();

  // Count leave
  const [sickCount, setSickCount] = useState("");
  const [emergencyCount, setEmergencyCount] = useState("");
  const [personalCount, setPersonalCount] = useState("");
  const [unpaidCount, setUnpaidCount] = useState("");
  const [maternityCount, setMaternityCount] = useState("");
  const [paternityCount, setPaternityCount] = useState("");

  React.useEffect(() => {
    fetch("https://hr.geospectrum.com.ph/api/api/leaveRequest/countleaves", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setSickCount(data.SickLeaveCount);
        setEmergencyCount(data.EmergencyLeaveCount);
        setPersonalCount(data.PersonalLeaveCount);
        setUnpaidCount(data.UnpaidLeaveCount);
        setMaternityCount(data.MaternityLeaveCount);
        setPaternityCount(data.PaternityLeaveCount);
      });
  }, [
    sickCount,
    unpaidCount,
    emergencyCount,
    personalCount,
    maternityCount,
    paternityCount,
  ]);

  return (
    <div id='tab-container'>
      <div id='sick-unpaid-box' className='leave-box'>
        <h4 className='tab-title'>SICK LEAVE</h4>
        <div className='leave-icon-data'>
          <LocalHospitalIcon className='tab-icon'/>
          <p className='tab-tag'>{sickCount} <Hidden mdDown>request{Number(sickCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>

      <div id='emergency-maternity-box' className='leave-box'>
        <h4 className='tab-title'>EMERGENCY</h4>
        <div className='leave-icon-data'>
          <SpaIcon className='tab-icon'/>
          <p className='tab-tag'>{emergencyCount} <Hidden mdDown>request{Number(emergencyCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>
      
      <div id='personal-paternity-box' className='leave-box'>
        <h4 className='tab-title'>PERSONAL LEAVE</h4>
        <div className='leave-icon-data'>
          <AirplanemodeActiveIcon className='tab-icon'/>
          <p className='tab-tag'>{personalCount} <Hidden mdDown>request{Number(personalCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>

      <div id='sick-unpaid-box' className='leave-box'>
        <h4 className='tab-title'>UNPAID LEAVE</h4>
        <div className='leave-icon-data'>
          <CreditCardOffIcon className='tab-icon'/>
          <p className='tab-tag'>{unpaidCount} <Hidden mdDown>request{Number(unpaidCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>
      
      <div id='emergency-maternity-box' className='leave-box'>
        <h4 className='tab-title'>MATERNITY</h4>
        <div className='leave-icon-data'>
          <PregnantWomanIcon className='tab-icon'/>
          <p className='tab-tag'>{maternityCount} <Hidden mdDown>request{Number(maternityCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>

      <div id='personal-paternity-box' className='leave-box'>
        <h4 className='tab-title'>PATERNITY</h4>
        <div className='leave-icon-data'>
          <ManIcon className='tab-icon'/>
          <p className='tab-tag'>{paternityCount} <Hidden mdDown>request{Number(paternityCount) > 1 ? 's': ''}</Hidden> </p>
        </div>
      </div>
    </div>
  );
}
